import React from 'react';
import {useSelector} from "react-redux";

import SidebarItem from "./SidebarItem";
import {MenuItem} from "react-pro-sidebar";

const MowersContainer = ({
                             mowers, OnMowerChecked, inDetailPageOfMobile,
                             onSelectMower, mode, isCollapsed, routePath
                         }) => {

    const {selectedMowers, selectedMower, allMowersNotification} = useSelector((state) => state.mower);

    if (!mowers) {
        return React.Fragment;
    }

    const Wrapper = inDetailPageOfMobile ? React.Fragment : MenuItem;

    return mowers.map((mower, index) => (
        <Wrapper key={index}>
            <SidebarItem
                isCollapsed={inDetailPageOfMobile ? true : isCollapsed}
                mower={mower}
                notifications={allMowersNotification[mower.id]}
                onSelect={onSelectMower}
                selected={selectedMower && mower.id === selectedMower.id}
                isRouteMode={mode.route}
                mowerChecked={OnMowerChecked}
                checked={selectedMowers?.length > 0 && selectedMowers?.find((m) => m.id === mower.id)?.checked}
                routeNum={mower.onRoute && routePath.find(
                    (route) => {
                        return route?.id === mower?.id
                    }
                )?.order_number}
            />
        </Wrapper>
    ));
};

export default MowersContainer;
import { gql } from "apollo-boost";

export const GET_LOGIN_ASSET = gql`
  query get_login_asset {
    loginCollection {
      items {
        title
        background {
          url
        }
      }
    }
  }
`;

export const GET_FORGOT_PASSWORD_ASSET = gql`
  query get_forgot_password_asset {
    forgotPasswordCollection {
      items {
        title
        background {
          url
        }
        success
        successButton
      }
    }
  }
`;

export const GET_TOPBAR_ASSET = gql`
  query get_topbar_asset {
    topbarCollection {
      items {
        logoTitle
        logoImg {
          url
        }
        navbutton {
          json
        }
      }
    }
  }
`;

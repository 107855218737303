import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Input from "Components/Base/Input";
import Button from "Components/Base/Button";
import Footer from "Components/Base/Footer";

import { resetPassword } from "Redux/actions/authAction";

import BgImg from "Assets/images/login-bg.png";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const { isPasswordReseted, inProgress, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!inProgress && isPasswordReseted === false) {
      setPasswordErr(message);
      setConfirmPasswordErr(message);
    } else if (!inProgress && isPasswordReseted === true) {
      setPasswordErr("");
      setConfirmPasswordErr("");
      history.push("/signin");
    }
  }, [inProgress, isPasswordReseted, history, message]);

  const handleClick = () => {
    if (!password) {
      setPasswordErr("Type Password");
      return;
    }
    if (!confirmPassword) {
      setConfirmPasswordErr("Type Confirm Password");
      return;
    }
    if (password === confirmPassword) {
      let token = props.match.params.token;
      dispatch(resetPassword({ token, password }));
    } else {
      setConfirmPassword("");
      setConfirmPasswordErr("Confirm Password is not matched");
    }
  };

  return (
    <FullPage>
      <FormContainer>
        <StyledWrapper>
          <Header>Reset Password</Header>
          <Input
            label="New Password"
            placeholder="New Password"
            error={passwordErr ? true : false}
            helperTxt={passwordErr}
            type="password"
            onChange={setPassword}
            width="100%"
          />
          <Input
            label="Confirm Password"
            placeholder="Confirm Password"
            error={confirmPasswordErr ? true : false}
            helperTxt={confirmPasswordErr}
            type="password"
            value={confirmPassword}
            onChange={setConfirmPassword}
            width="100%"
          />
          <Button
            onClick={handleClick}
            fontSize={14}
            showShadow={true}
            text="Reset Password"
            width="100%"
          />
        </StyledWrapper>
        <Footer />
      </FormContainer>
      <BGContainer>
        <StyledImage src={BgImg} alt="background" />
      </BGContainer>
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 35%;
  min-width: 450px;
  padding-bottom: ${(props) => props.theme.spacing[10]}px;
  background-color: ${(props) => props.theme.color.grey[700]};

  @media (max-width: 1023px) {
    width: 100%;
    height: 100vh;
    min-width: 320px;
  }
`;

const BGContainer = styled.div`
  height: 100vh;
  width: 65%;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const StyledWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
`;

const Header = styled.h1`
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.theme.spacing[10]}px;
`;

export default ResetPassword;

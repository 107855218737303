import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DropdownIcon from "../../Assets/images/dropdownIcon.png";
import HusqvarnaLogo from "../../Assets/images/husqvarna-logo.png";
import NexmowLogo from "../../Assets/images/nexmow-logo.png";
import CheckIcon from "../../Assets/images/check.png";


export default function ManufacturerDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        {id: 1, logo: HusqvarnaLogo, label: "Husqvarna", text: "husqvarna"},
        {id: 2, logo: NexmowLogo, label: "NexMow", text: "nexmow"},
    ];

    useEffect(() => {
        setSelectedOption(props?.selected || null);
    }, [props.selected]);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (value) => {
        props.onSelect(value);
        setIsOpen(false);
    };

    const checkSelection = (val) => {
        return val?.text === selectedOption;
    };
    const getFilledDropdown = (text) => {
        const opt = options.find((opt) => opt.text === text);
        return <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <div>
                <img src={opt.logo} alt={opt.label}/>
            </div>
            <div>
                <span>{opt.label}</span>
            </div>
        </div>
    }

    return (
        <>
            <DropDownContainer>
                <Label>
                    {props.label}
                </Label>
                <DropDownHeader disabled={props.disable} onClick={ props.disable ? undefined : toggling}>
                    {selectedOption ?
                        getFilledDropdown(selectedOption)
                        :
                        <div>{"Please Select"}</div>
                    }
                    <Icon rotate={isOpen}>
                        <img src={DropdownIcon} alt="icon" width={10}/>
                    </Icon>
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options.map((option, index) => (
                                <ListItem
                                    onClick={() => onOptionClicked(option)}
                                    key={index}
                                >
                                    <div>
                                        <img src={option.logo} alt={option.label}/>
                                    </div>
                                    <div>
                                        <span>{option.label}</span>
                                        {checkSelection(option) &&
                                            <span style={{marginLeft: "10px"}}>
                                            <img src={CheckIcon} width={"12px"} alt={"checkMark"}/>
                                        </span>
                                        }
                                    </div>
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        </>
    );
}
const Icon = styled("div")`
    margin-left: 20px;
    transform:${props => props.rotate ? "rotate(3.142rad)" : " rotate(0)"}
`;

const Label = styled("div")`
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 13px;
    color: #E2E2E2;
    font-weight: bold;
`;

const DropDownContainer = styled("div")`
  width: 90%;
  text-transform: capitalize;
  position: relative;
`;

const DropDownHeader = styled("div")`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: ${props => props.disabled ? "#16161B" : "#292B2F"};
  margin-bottom: 0.1em;
  text-align: center;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1rem;
  color: white;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  text-align: -webkit-center;
  z-index: 1000;
  width: 100%;
`;

const DropDownList = styled("ul")`
  margin: 0;
  background-color: #292B2F;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
`;

const ListItem = styled("li")`
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0px 5px;
  padding: 13px 10px;
  border-bottom: 1px solid black;
  color: grey;
  background-color: #292B2F;
  cursor: pointer;
  &:hover {
    border-radius: 2px;
    color: white;
    background-color: #16161B;
  };
  &:first-child {
    border-top: 1px solid white;
  };
`;

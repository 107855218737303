import React, {useContext} from "react";
import styled from "styled-components";

import IconButton from "Components/Base/IconButton";
import Spacer from "Components/Base/Spacer"

import ExitImg from "Assets/images/exit.png";
import ExpandImg from "Assets/images/expand.png";
import FilterImg from "Assets/images/filter.png";
import SearchImg from "Assets/images/search.png";
import PlusIcon from "Assets/images/plus.png";
import MinusIcon from "Assets/images/minus.png";

import { DeviceContext } from "Context/DeviceContext";

export const Header = ({ onSearch, onCollapse, isCollapsed, onOpenFilterMenu, onOpenCreateRobotbar, onOpenRemoveRobotBar, value }) => {
  const { smallDevice } = useContext(DeviceContext);

  return (
    <Container isCollapsed={isCollapsed}>
      {!smallDevice && (
        <IconButton
          url={isCollapsed ? ExpandImg : ExitImg}
          onClick={onCollapse}
          size="sm"
          tooltip={isCollapsed ? "Expand" : "Collapse"}
        />
      )}
      {!isCollapsed? (
        <>
          <SerachBarWrapper>
            <SearchIcon src={SearchImg} />
            <SearchBar value={value} onChange={onSearch} placeholder="Search" />
          </SerachBarWrapper>
            <MinusWrapper>
                <IconButton url={MinusIcon} onClick={onOpenRemoveRobotBar} size="sm" tooltip="Remove Robots" noBackground />
            </MinusWrapper>
          <IconButton url={PlusIcon} onClick={onOpenCreateRobotbar} size="sm" tooltip=" Add Robots" noBackground />
          <IconButton url={FilterImg} onClick={onOpenFilterMenu} size="sm" tooltip="Filter" />
        </>
      ): (
        <>
          <Spacer size="sm" />
          <IconButton url={SearchImg} size="sm" onClick={onCollapse} tooltip="Search" />
          <Spacer size="sm" />
          <IconButton url={FilterImg} onClick={onOpenFilterMenu} size="sm" tooltip="Filter" />
          <Spacer size="sm" />
          <IconButton url={MinusIcon} onClick={onOpenRemoveRobotBar} size="sm" tooltip="Remove Robots" noBackground />
        <Spacer size="sm" />
        <IconButton url={PlusIcon} onClick={onOpenCreateRobotbar} size="sm" tooltip="Add Robots" noBackground />
        </>
      )}
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isCollapsed ? "column" : "row" };
  padding: ${(props) => props.theme.spacing[5]}px
    ${(props) => props.theme.spacing[4]}px;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`;


export const SerachBarWrapper = styled.div`
  flex: 1;
  margin: 0px ${(props) => props.noMargin ? 0 : props.theme.spacing[3]}px;
  box-sizing: border-box;
  position: relative;
`;
const MinusWrapper = styled.div`
  margin-right: 5px;
`;

export const SearchBar = styled.input`
  background-color: ${(props) => props.theme.color.grey[600]};
  border-radius: ${(props) => props.theme.spacing[3]}px;
  height: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding-left: ${(props) => props.theme.spacing[8]}px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color.text};

  ::placeholder {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: ${(props) => props.theme.color.text};
    opacity: 0.2;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 9px;
  width: 12px;
`;

import React, {useContext, useEffect, useState} from 'react';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

import {CircularProgressComp, MowerActionFooter} from "../../../Components/Common";
import {DialogItem} from "../../../Components/Sidebar/DialogItem";
import StartOptions from "../../../Components/Sidebar/StartOptions";
import ParkOptions from "../../../Components/Sidebar/ParkOptions";
import {getMowersData} from "../index";
import SuccessAnimation from "../../../Components/SuccessAnimation";
import {mowersService} from "../../../Utils/mowerActions";
import {MowerContext} from "../../../Context/MowerContext";
import {maxAllowedMowersOnRoute} from "../../../Contants";


const ConfirmationPopup = ({confirmationPopup, setConfirmationPopup, dialogTitle}) => {

    const {mowers} = useSelector((state) => state.mower);
    const {mowerDetails : mower} = useContext(MowerContext);
    const dispatch = useDispatch();

    const [animationPopup, setAnimationPopup] = useState(false);
    const [duration, setDuration] = useState(10);
    const [startType, setStartType] = useState(null);
    const [parkType, setParkType] = useState(null);
    const [error, setError] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [addToRouteCount, setAddToRouteCount] = useState(0);

    useEffect(() => {
        if(dialogTitle === "Add To Route") {
            let count = 0;
            for(let i = 0; i < mowers?.length ; i++){
                if(mowers[i]?.onRoute){
                    count+=1
                }
            }
            setAddToRouteCount(count);
        }
    },[dialogTitle]) // eslint-disable-line

    const errorManagement = (res) => {
        setError(res?.error ?? "Error: Cannot perform this action");

        setTimeout(() => {
            setError(null);
        },2000)
    };

    const confirmedAction = async () => {
        let res = null;
        let fetchMowers = false;
        setOpenProgressDialog(true);
        switch (dialogTitle) {
            case 'Start Mowers':
                res = await mowersService.startMower(dialogTitle, startType, duration, mower, dispatch);
                if (!res === false) {
                    setOpenProgressDialog(false);
                }
                break;
            case 'Park Mowers':
                res = await mowersService.parkMower(dialogTitle, parkType, duration, mower, dispatch);
                if (!res) {
                    setOpenProgressDialog(false);
                }
                break;
            case 'Add To Route':
                if(addToRouteCount < maxAllowedMowersOnRoute){
                    fetchMowers = true;
                    res = await mowersService.addMower(mower);
                } else {
                    setError("Maximum number of mower en route");
                }
                break;
            case 'Remove From Route':
                fetchMowers = true;
                res = await mowersService.removeMower(mower);
                break;

            default:
                return;
        }
        setOpenProgressDialog(false);
        if(dialogTitle === "Add To Route" && addToRouteCount >= maxAllowedMowersOnRoute) {
            return;
        }
        if(process.env.REACT_APP_DEMO_MODE === 'true') {
            if(res?.result){
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    setConfirmationPopup(false);
                    getMowersData(dispatch, [mower]);
                },2000)
            } else {
                errorManagement(res);
            }
        } else {
            if(res?.result){
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    setError(null);
                    if(res?.result){
                        setConfirmationPopup(false);
                        if(fetchMowers) {
                            getMowersData(dispatch);
                        }
                    }
                }, 2000)
            } else {
                errorManagement(res);
            }
        }
    }

    return (
        <Dialog open={confirmationPopup} onClose={() => setConfirmationPopup(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <PopUpContainer>
                {error && <Error>{error}</Error>}
                <DialogTitle style={{paddingTop: '0px'}}>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogItem mower={mower} />
                    {dialogTitle === "Start Mowers" &&
                        <StartOptions setDuration={setDuration} duration={duration} setStartType={setStartType}/>}
                    {dialogTitle === "Park Mowers" &&
                        <ParkOptions setDuration={setDuration} duration={duration} setParkType={setParkType}/>}
                </DialogContent>
                <MowerActionFooter
                    close={() => setConfirmationPopup(false)}
                    action={confirmedAction}
                />
                {animationPopup && <SuccessAnimation/>}
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
                }
            </PopUpContainer>
        </Dialog>
    );
};

const Error = styled.div`
    color: red;
`;
const PopUpContainer = styled.div`
    padding: 35px;
    color: white;
`;
export default ConfirmationPopup;
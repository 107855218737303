/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {Dialog} from "@material-ui/core";

import {CloseDiv, scrollBarStyles} from "../Common/styles";
import {
    DefaultPage,
    NotificationToggleOptions
} from "../../Contants";
import UserNotificationToggle from "../Toggle/UserNotificationToggle";
import {updateUserNotificationsReadStatus, userNotificationsMarkAllReadStatus} from "../../Utils/api";
import Pagination from "../Pagination";
import {DeviceContext} from "../../Context/DeviceContext";
import CloseImg from "../../Assets/images/x.png";
import NoData from "../../Assets/images/noData.png";
import Button from "../Base/Button";

const UserNotificationScreen = ({
                                    showNotificationPopup,
                                    setShowNotificationPopup,
                                    fleetNotifications,
                                    setFleetNotifications,
                                    page,
                                    setPage,
                                    noOfPages,
                                    toggleValue,
                                    setToggleValue,
                                    updateParentState,
                                    markAllReadDisable
                                }) => {
    const {middleDevice} = useContext(DeviceContext);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        if (renderCount < 2) {
            setRenderCount(renderCount + 1);
        }
    }, [renderCount]);

    const onToggle = (event) => {
        setPage(DefaultPage);
        setToggleValue(event.target.textContent.toLocaleLowerCase());
    };

    const updateNotificationReadStatus = async (notificationId) => {
        let res = await updateUserNotificationsReadStatus(notificationId);
        if (res) {
            updateParentState(notificationId)
        }
    };
    const onPageChange = (page) => {
        setPage(page);
    };
    const handleClosePress = () => {
        setShowNotificationPopup(false)
        setToggleValue("unread")
    }
    const handleMarkAllRead = async () => {
        let isUnique = toggleValue === "unique" ? true : false
        let res = await userNotificationsMarkAllReadStatus(isUnique);
        if (res.result === true) {
            const updatedData = fleetNotifications.map(obj => ({ ...obj, read: true }));
            setFleetNotifications(updatedData)
        }
    }
    const getTimeDifference = (creationTime) => {
        const diff = new Date() - new Date(creationTime);
        const diffMinutes = Math.floor(diff / 1000 / 60);
        if (diffMinutes < 60) {
            return `${diffMinutes}m`;
        } else {
            const diffHours = Math.floor(diffMinutes / 60);
            if (diffHours < 24) {
                return `${diffHours}h`;
            } else {
                const diffDays = Math.floor(diffHours / 24);
                return `${diffDays}d`;
            }
        }
    }
    return (
        <Dialog open={showNotificationPopup} onClose={() => setShowNotificationPopup(false)}
                aria-labelledby="form-dialog-title"
                fullScreen={true} maxWidth={"xl"} PaperProps={{
            style: {
                position: "absolute",
                top: "65px",
            },
        }}>
            <Container smallDevice={middleDevice}>
                <Header>
                    <ToggleWrapper>
                        <TitleSection>
                            <Title>Notifications</Title>
                        </TitleSection>
                        <UserNotificationToggle options={NotificationToggleOptions} currentValue={toggleValue}
                                                onToggle={onToggle}/>
                    </ToggleWrapper>
                    <CloseDiv>
                        <Button text="Mark All Read" disabled={markAllReadDisable} onClick={handleMarkAllRead}/>
                        <img onClick={handleClosePress} style={{cursor: "pointer", marginLeft: 20}} src={CloseImg}
                             alt="icon" width={18}/>
                    </CloseDiv>
                </Header>
            </Container>
            <UsersMainWrapper>
                <NotificationContainer>
                    {fleetNotifications.length > 0 ?
                        fleetNotifications.map(notification => (
                            <Notification key={notification.id}>
                                {notification.read === false &&
                                    <span className="notReadDot"/>
                                }
                                {notification.message &&
                                    <NotificationMessage
                                        onClick={() => updateNotificationReadStatus(notification?.id.toString())}
                                        read={notification.read}
                                    >
                                        {notification.message}
                                    </NotificationMessage>
                                }
                                <NotificationTime>{getTimeDifference(notification.created_at)}</NotificationTime>
                            </Notification>
                        ))
                        :
                        <EmptyWrapper>
                            <Image alt="mower" src={NoData} showBg/>
                            No Data
                        </EmptyWrapper>
                    }
                    {fleetNotifications?.length > 0 && (
                        <Pagination
                            page={page}
                            count={noOfPages}
                            onPageChange={onPageChange}
                        />
                    )}
                </NotificationContainer>
            </UsersMainWrapper>
        </Dialog>
    );
};
const Container = styled.div`
  position: fixed;
  display: flex;
  width: ${props => props.smallDevice ? '104%' : "100%"};
  background-color: #1F2125;
  padding: 35px 35px 0px 35px;
  color: ${(props) => props.theme.color.text};
`;
const UsersMainWrapper = styled.div`
  overflow: auto;
  width: auto;
  ${scrollBarStyles}
`;
const EmptyWrapper = styled.div`
  height: 430px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  margin-left: -70px;
  flex-direction: column;
`;
const Image = styled.img`
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin-bottom: 10px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  flex-direction: row;
`
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 125%;
`
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-size: 25px;
  line-height: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.color.text};
  margin-right: ${(props) => props.theme.spacing[1]}px;
`;
const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 80px;

  .notReadDot {
    height: 8px;
    width: 8.5px;
    border-radius: 50%;
    background-color: #E45525;
    margin-bottom: 20px;
  }
`;

const Notification = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing[1]}px;
`;
const NotificationMessage = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color.text};
  margin-left: ${props => !props.read ? "10px" : "20px"};
  padding-bottom: 15px;
  margin-bottom: 5px;
  border-bottom: 1px black solid;
  width: 95%;
  cursor: pointer;
`;
const NotificationTime = styled.div`
  color: gray;
  font-size: 15px;
  margin-bottom: 20px;
  margin-left: 20px;
`
export default UserNotificationScreen;

import React, { useState } from "react";
import styled from "styled-components";
import { logout } from "Redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

import User from "Assets/images/user.png";


const Bottom = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [onConsole, setOnConsole] = useState(true);


  const onClick = (e) => {
    const text = e.target.innerText;
    if (text === "Console") {
      setOnConsole(true);
    } else {
      setOnConsole(false);
      window.open(process.env.REACT_APP_AVIATOR_API_URL, "_blank");
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Container>
      <Username>
        <img src={User} alt="User" />
        {user && user.first_name ? user.first_name : ""} {user && user.last_name ? user.last_name : ""}
      </Username>
      <Nav onClick={onClick} selected={onConsole? true : false}>Console</Nav>
      <Nav onClick={onClick} selected={!onConsole? true : false}>Aviator</Nav>
      <LogoutButton onClick={() => handleLogout()}>Log out</LogoutButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  margin-top: 50px;
`;

const Username = styled.strong`
  color: white;
  font-size: 15px;
  display: inline-block;

  > img {
    width: 25px;
    margin-right: 5px;
  }
`;

const Nav = styled.div`
  color: #E2E2E2;
  font-family: FreightSans;
  font-size: 12px;
  font-weight: ${(props) => props.selected ? 900 : 500};
  letter-spacing: 0;
  line-height: 13px;
  padding: 20px;
`;

const LogoutButton = styled.button`
  height: 33px;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(180deg, #FC5253 0%, #DB4748 100%);
  color: white;
  font-family: FreightSans-Bold;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  align-self: center;
  margin-bottom: 50px;
`;

export default Bottom;
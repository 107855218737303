export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";

export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const AUTH_FORGOT_PASSWORD_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";

export const CLEAR_FORGOT_PASSWORD = "CLEAR_FORGOT_PASSWORD";

export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";

export const START_ADD_NEW_ROBOT = "START_ADD_NEW_ROBOT";
export const AUTH_POST_FLEET_CRED_REQUEST = "POST_FLEET_AUTH_REQUEST";
export const AUTH_POST_FLEET_CRED_SUCCESS = "POST_FLEET_AUTH_SUCCESS";

export const GET_MOWERS_REQUEST = "GET_MOWERS_REQUEST";
export const GET_MOWERS_SUCCESS = "GET_MOWERS_SUCCESS";
export const ALL_MOWERS_NOTIFICATIONS = "ALL_MOWERS_NOTIFICATIONS";

export const GET_MOWERS_PERI_REQUEST = "GET_MOWERS_PERI_REQUEST";
export const GET_MOWERS_PERI_SUCCESS = "GET_MOWERS_PERI_SUCCESS";

export const SAVE_CURRENT_MOWER_DATA = "SAVE_CURRENT_MOWER_DATA";

export const CLEAR_FILTER = "CLEAR_FILTER";
export const SAVE_FILTER = "SAVE_FILTER";
export const CLEAR_ALL_FILTER = "CLEAR_ALL_FILTER";
export const SELECTED_USER_MODE = "SELECTED_USER_MODE";

export const SAVE_FILTERED_MOWERS = "SAVE_FILTERED_MOWERS";

export const SEND_MOWER_ACTION_REQUEST = "SEND_MOWER_ACTION_REQUEST";
export const SEND_MOWER_ACTION_SUCCESS = "SEND_MOWER_ACTION_SUCCESS";

export const CURRENT_USER_REQUEST = "CURRENT_USER_REQUEST";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";

export const THIRD_PARTY_RESPONSE = "THIRD_PARTY_RESPONSE";

export const ADD_SELECTED_MOWER = "ADD_SELECTED_MOWER"
export const CLEAR_SELECTED_MOWER = "CLEAR_SELECTED_MOWER"
export const ADD_ALL_SELECTED_MOWERS = "ADD_ALL_SELECTED_MOWERS"
export const REMOVE_ALL_SELECTED_MOWERS = "REMOVE_ALL_SELECTED_MOWERS"

export const GET_HOUSES_SUCCESS = "GET_HOUSES_SUCCESS"
export const SELECTED_HOUSE = "SELECTED_HOUSE"
export const SELECTED_MOWER = "SELECTED_MOWER"
export const FILTERED_HOUSES = "FILTERED_HOUSES"
export const PROPERTY_MODE = "PROPERTY_MODE"

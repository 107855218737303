import React from 'react';
import styled from "styled-components";
import {Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis} from "recharts";


const EnvironmentImpact = (props) => {
    const getPath = (x, y, width, height) => {
        const diffuse = 20;

        return `M${x - diffuse},${y + height} 
          L${x + width / 2},${y} 
          L${x + width + diffuse},${y + height}
          Z`;
    };
    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };
    return (
        <div>
            <Row>
                <div className='center'>
                    CO2 Saving
                </div>
            </Row>
            <div>
                <BarChart
                    width={330}
                    height={270}
                    data={props?.data ?? []}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <defs>
                        <linearGradient id="colorVal" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#9DCA60" stopOpacity={1} />
                            <stop offset="95%" stopColor="#9DCA60" stopOpacity={0.3} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#2e3135" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Bar dataKey="val" fill="#9DCA60" shape={<TriangleBar />}>
                        {props?.data?.map((_, index) => {
                            if (index === 0 || index === props?.data?.length - 1) {
                                return <Cell key={`cell-${index}`} fill="transparent" />;
                            } else {
                                return <Cell key={`cell-${index}`} fill="url(#colorVal)" />;
                            }
                        })}
                    </Bar>
                </BarChart>
            </div>
        </div>
    );
};
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    
    .center {
        align-self: center;
   }
`;
export default EnvironmentImpact;

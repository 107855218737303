import React from "react";
import styled from "styled-components";

import StatusPoint from "../Base/StatusPoint";
import {getStatusColor} from "../../Utils/global";

import OnlineIcon from "../../Assets/images/wifi.png";
import OfflineIcon from "../../Assets/images/no-wifi.png";
import Repair from "../../Assets/images/repair.png";
import RouteIcon from "../../Assets/images/route.png";

export const DialogItem = ({action, mower, selected, isRouteMode, routeNum}) => {

    const getMaintenanceStr = () => {
        let s = "";
        if (mower.peripherals && Array.isArray(mower.peripherals)) {
            mower.peripherals.forEach((peri) => {
                if (peri.remaining < 0) {
                    s += `Replace ${peri.item}\n`;
                }
            });
        }
        return s;
    };

    return (
        <MainRow>
            <ItemDiv>
                <Card selected={selected}>
                    <Row>
                        <span>
                          <StatusPoint
                              center={true}
                              color={getStatusColor(mower)}
                              title={getMaintenanceStr()}
                          />
                          <Label isRouteMode={isRouteMode}>{mower.instance}</Label>
                        </span>
                        <div style={{display: "-webkit-inline-box"}}>
                            {mower.need_maintain && <Icon src={Repair}/> }
                            <Icon src={mower.connected ? OnlineIcon : OfflineIcon} />
                            {(action === "Add To Route" && mower?.onRoute) &&
                                <Icon title={"Already on route"} src={RouteIcon} />
                            }
                        </div>
                    </Row>
                    {isRouteMode && routeNum > -1 && <Badge>{routeNum}</Badge>}
                </Card>
            </ItemDiv>
        </MainRow>
    );
};
const Icon = styled.img`
  width: 16px;
  margin-right: 12px;
`;
const MainRow = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: center;
`;
const ItemDiv = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Badge = styled.div`
  padding: 0px 7px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: red;
  position: absolute;
  top: 9px;
  left: 0px;
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 15px;
  margin-left: ${props => props.isRouteMode ? "24px" : "4px" };
  text-transform: capitalize;
  color: white;
`;
const Card = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  margin: 0px ${(props) => props.theme.spacing[1]}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.selected && props.theme.color.grey[500]};
  :hover {
    border-radius: ${(props) => props.theme.spacing[2]}px;
    background-color: ${(props) => props.theme.color.grey[500]};
  }
`;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";
import styled from "styled-components";
import {ProSidebar, SidebarContent, SidebarHeader} from "react-pro-sidebar";
import socketIOClient from "socket.io-client";

import MowersGroupContainer from "./MowersGroupContainer";
import {TopStatusbar} from "./Statusbar";
import MultiActionComp from "./MultiActionComp";
import {Header} from "./Header";
import {ScheduleDialog} from "../ScheduleDialog/ScheduleDialog";
import MowersContainer from "./MowersContainer";

import {
    getStatusColor, getZipcodeOfMower, RED_COLOR, checkNeedAttentionCondition,
    mowerNormalOperationCondition
} from "Utils/global";
import CuttingHeightDialog from "./CuttingHeightDialog";
import {setViewMode} from "../../Redux/actions/filterAction";
import {DeviceContext} from "Context/DeviceContext";
import {
    addAllSelectedMowers, allMowersNotifications,
    clearSelectedMowers, getMowersSuccess, saveFilteredMowers
} from "../../Redux/actions/mowerAction";

import Hide from "../../Assets/images/whiteHideEye.png";
import "react-pro-sidebar/dist/css/styles.css";
import { getMowerLatestNotifications} from "../../Utils/api";

const io = socketIOClient(process.env.REACT_APP_SOCKET_PORT);

export const getMowerIds = (mowers) => {
    return mowers?.map((mower) => mower.id);
}
const filter = (mowers, filterOptions, attributeName) => {
    if (filterOptions.length === 0 || (filterOptions.length === 1 && filterOptions[0] === 'need_maintain')) {
        return mowers;
    }
    return mowers.filter((mower) => {
        let value;
        if (attributeName === "zipcode") {
            value = getZipcodeOfMower(mower);
        } else {
            value = mower[attributeName];
        }
        return filterOptions.includes(value);
    });
};

const filterMaintain = (mowers, filterOptions) => {
    if (filterOptions.length === 0) {
        return mowers;
    } else {
        if (filterOptions.includes('need_maintain')) {
            return mowers.filter((mower) => mower.need_maintain === true);
        } else {
            return mowers;
        }
    }
}

const filterTags = (mowers, filterOptions) => {

    const checkExists = (data, mower) => {
        const objectExists = data.some(item => item.id === mower.id);
        return !!objectExists;
    }

    if (filterOptions.length === 0) {
        return mowers;
    } else {
        let data = [];
        for(let i =0 ; i< mowers.length ; i++){
            if(mowers[i]?.tags){
                for(let j=0;j < mowers[i].tags?.length ; j++){
                    if(filterOptions.includes(mowers[i].tags[j])){
                        if(!checkExists(data, mowers[i])) {
                            data.push(mowers[i])
                        }
                    }
                }
            }
        }
        return data;
    }
}

const filterSchedule = (mowers, filterOptions) => {
    if (filterOptions.length === 0) {
        return mowers;
    }
    return mowers.filter((mower) => {
        const calendarTasks = mower.calendar_tasks;
        if (!calendarTasks || !Array.isArray(calendarTasks) || !calendarTasks[0]) {
            return false;
        }
        for (let opt of filterOptions) {
            if (calendarTasks[0][opt]) {
                return true;
            }
        }
        return false;
    });
};

export const UserViewComponent = ({selectedUserMode, dispatch}) => {
    return <UserViewWrapper>
        <div>
            Viewing As
        </div>
        <div className="wrapper">
            <div className="desc">
                <div className="heading">Name</div>
                {selectedUserMode?.name ?? "N/A"}
            </div>
            <EyeWrapper>
                <EyeIcon onClick={() => dispatch(setViewMode(null))}>
                    <p className={"showHeading"}>Return to Owner View</p>
                    <img src={Hide} width="22px" height="15px" alt={""}/>
                </EyeIcon>
            </EyeWrapper>
            <div className="desc">
                <div className="heading">Role</div>
                {selectedUserMode?.role ?? "N/A"}
            </div>
        </div>
    </UserViewWrapper>
}
export const UserViewComponentCollapsed = ({dispatch}) => {
    return <UserViewWrapperCollapsed>
        <EyeIcon onClick={() => dispatch(setViewMode(null))}>
            <img src={Hide} width="22px" height="15px" alt={""}/>
        </EyeIcon>
    </UserViewWrapperCollapsed>
}

const Sidebar = ({onSelectMower, openFilterMenu, openCreateRobotbar, onOpenRemoveRobotBar, routePath, mode}) => {
    const {selectedUserMode} = useSelector((state) => state.filter);
    const {
        mowers,
        selectedMowers,
        selectedMower,
        filteredMowers,
        allMowersNotification
    } = useSelector((state) => state.mower);
    const dispatch = useDispatch();
    const {smallDevice, middleDevice} = useContext(DeviceContext);
    const {
        statusFilter, modelFilter, manufacturerFilter,
        scheduleFilter, zipcodeFilter, tagsFilter
    } = useSelector((state) => state.filter);
    const [attentionMowersCollapseStatus, setAttentionMowersCollapseStatus] = useState(false);
    const [normalMowersCollapseStatus, setNormalMowersCollapseStatus] = useState(false);
    const [isCollapsed, setCollapsed] = useState(
        (smallDevice && selectedMower != null) || middleDevice);
    const [dialogTitle, setDialogTitle] = useState('');
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [cuttingHeightPopup, setCuttingHeightPopup] = useState(false)
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const [fetchNotificationsData, setFetchNotfificationsData] = useState(true);
    const inDetailPageOfMobile = smallDevice && selectedMower;


    const handleToggleCollapse = () => setCollapsed(!isCollapsed);
    const handleSearchMower = (evt) => {
        setSearchKey(evt.target.value);
    };

    const fetchNotifications = async () => {
        let notificationsObj = {};
        let latestNotificationRes = await getMowerLatestNotifications();
        for (let i = 0; i < latestNotificationRes.length; i++) {
            const docData = latestNotificationRes[i];
            const notificationTime = new Date(docData.created_at);
            let time = notificationTime.getTime() / 1000;
            const obj = {
                mode: docData.mode,
                error_code: docData.error_code,
                activity: docData.activity,
                status: docData.status,
                message: docData.message,
                time: time,
            }
            if (mowerNormalOperationCondition(obj)) {
                obj.message = "Parked until further notice";
            }
            notificationsObj = {...notificationsObj, [docData.automower_id]: [obj.message ?? ""]};
        }
        dispatch(allMowersNotifications(notificationsObj));
        setFetchNotfificationsData(false);
    }
    useEffect(() => {
        io.on('connect', () => {
            console.log('Notification Connection Established')
        });
        let notificationObject = {}
        io.on("LatestNotifications",  ({data}) => {
            if(data){
                const notificationTime = new Date(data.created_at);
                let time = notificationTime.getTime() / 1000;
                    const obj = {
                        mode: data.mode,
                        id: data.automower_id,
                        error_code: data.error_code,
                        activity: data.activity,
                        status: data.status,
                        message: data.message,
                        time: time,
                    }
                if (mowerNormalOperationCondition(obj)) {
                    obj.message = "Parked until further notice";
                }
                if(allMowersNotification[data.automower_id][0] !== data.message){
                    let mowerArray = mowers;
                    let index = mowers.findIndex((mower) => mower.id === data.automower_id);
                    if(index !== -1){
                        mowerArray[index].status = data.status
                        mowerArray[index].error_code = data.error_code
                        mowerArray[index].activity = data.activity
                        mowerArray[index].mode = data.mode
                        dispatch(getMowersSuccess([...mowerArray]))
                        notificationObject = {...notificationObject, [obj.id] : [obj.message ?? ""]}
                        dispatch(allMowersNotifications({...allMowersNotification, ...notificationObject}));
                    }
                }
            }
        })
    },[])
    useEffect(() => {
        if (mowers && fetchNotificationsData) {
            fetchNotifications();
        }
    }, [mowers])

    const [needAttentionMowers, normalOperationMowers] = useMemo(() => {
        if (mowers && allMowersNotification) {
            let result = mowers.filter((mower) => {
                    if(mower.instance){
                        return mower.model?.toLowerCase()?.includes(searchKey.toLowerCase()) || mower.instance?.toLowerCase().includes(searchKey.toLowerCase())
                    } else {
                        return mower
                    }
                }
            );
            result = filter(result, statusFilter, "activity");
            result = filterMaintain(result, statusFilter);
            result = filter(result, modelFilter, "model");
            result = filter(result, manufacturerFilter, "manufacturer");
            result = filter(result, zipcodeFilter, "zipcode");
            result = filterTags(result, tagsFilter);
            result = filterSchedule(result, scheduleFilter);
            const needAttentionMowers = [], normalOperationMowers = [];
            result.forEach((mower) => {
                const statusColor = getStatusColor(mower);
                if (statusColor === RED_COLOR || checkNeedAttentionCondition(allMowersNotification[mower.id])) {
                    needAttentionMowers.push(mower);
                } else {
                    normalOperationMowers.push(mower);
                }
            });
            // Sort mowers need attention
            needAttentionMowers.sort((a, b) => b.updated_at?.seconds - a.updated_at?.seconds);
            dispatch(saveFilteredMowers({filteredMowers: [...needAttentionMowers, ...normalOperationMowers]}));
            return [needAttentionMowers, normalOperationMowers];
        }
        return [];
    }, [
        searchKey, mowers, statusFilter, modelFilter, tagsFilter, manufacturerFilter, scheduleFilter, zipcodeFilter, dispatch,
        allMowersNotification
    ]);

    if (inDetailPageOfMobile) {
        return (
            <MobileWrapper>
                <MowersContainer
                    mowers={needAttentionMowers && needAttentionMowers.concat(normalOperationMowers)}
                    inDetailPageOfMobile={inDetailPageOfMobile}
                    onSelectMower={onSelectMower}
                    mode={mode}
                    isCollapsed={isCollapsed}
                    routePath={routePath}
                />
            </MobileWrapper>
        );
    }
    const handleResult = async (data) => {
        if (data === 'Confirm') {
            await dispatch(clearSelectedMowers())
        }
        setShowScheduleDialog(false);
    }
    const multiRobotAction = async (data) => {
        switch (data) {
            case 'PARK':
                setDialogTitle('Park Mowers')
                break;
            case 'ADD':
                setDialogTitle('Add To Route')
                break;
            case 'REMOVE':
                setDialogTitle('Remove From Route')
                break;
            case 'START':
                setDialogTitle('Start Mowers')
                break;
            default:
                break;
        }
        setConfirmationPopup(true);
    }
    const removeAll = () => {
        dispatch(clearSelectedMowers())
    }
    const addAll = () => {
        let selectedMowers = filteredMowers.filter(mower =>  mower.instance )
        dispatch(addAllSelectedMowers(selectedMowers))
    }
    const openScheduleDialog = () => {
        setShowScheduleDialog(true);
    }
    return (
        <ProSidebar collapsed={isCollapsed}>
            {selectedUserMode &&
                <>
                    {isCollapsed ?
                        <UserViewComponentCollapsed dispatch={dispatch}/>
                        :
                        <UserViewComponent selectedUserMode={selectedUserMode} dispatch={dispatch}/>
                    }
                </>
            }
            <SidebarHeader>
                <Header
                    isCollapsed={isCollapsed} onCollapse={handleToggleCollapse} onOpenFilterMenu={openFilterMenu}
                    onSearch={handleSearchMower} onOpenCreateRobotbar={openCreateRobotbar}
                    onOpenRemoveRobotBar={onOpenRemoveRobotBar} value={searchKey}
                />
                <TopStatusbar mowers={mowers} unSelect={removeAll} action={addAll} selectedMowers={selectedMowers}
                              robotAction={multiRobotAction} openScheduleDialog={openScheduleDialog}
                              cuttingRobotSelect={setCuttingHeightPopup} isCollapsed={isCollapsed}
                />
            </SidebarHeader>
            <Scrollbars renderThumbVertical={() => <div style={scrollStyles}/>}>
                <SidebarContent>
                    <MowersGroupContainer mowers={needAttentionMowers} title="Need Attention" isCollapsed={isCollapsed}
                                          collapse={!attentionMowersCollapseStatus}
                                          setCollapse={setAttentionMowersCollapseStatus}
                                          mode={mode}
                                          routePath={routePath}
                                          inDetailPageOfMobile={inDetailPageOfMobile}
                                          onSelectMower={onSelectMower}
                    />
                    <MowersGroupContainer mowers={normalOperationMowers} title="Normal Operation"
                                          isCollapsed={isCollapsed}
                                          collapse={!normalMowersCollapseStatus}
                                          setCollapse={setNormalMowersCollapseStatus}
                                          mode={mode}
                                          routePath={routePath}
                                          inDetailPageOfMobile={inDetailPageOfMobile}
                                          onSelectMower={onSelectMower}
                    />
                </SidebarContent>
            </Scrollbars>
            {cuttingHeightPopup &&
                <CuttingHeightDialog cuttingHeightPopup={cuttingHeightPopup} mode={mode} mowers={selectedMowers}
                                     setCuttingHeightPopup={setCuttingHeightPopup} routePath={routePath}
                />
            }
            {showScheduleDialog &&
                <ScheduleDialog robotName='Set Schedules for Robots' isMultiple={true} result={handleResult}
                                tasks={[]}
                />
            }
            {confirmationPopup &&
                <MultiActionComp
                    open={confirmationPopup}
                    onClose={() => setConfirmationPopup(false)}
                    dialogTitle={dialogTitle}
                    selectedMower={selectedMower}
                    mode={mode}
                    routePath={routePath}
                />
            }
        </ProSidebar>
    );
};

const MobileWrapper = styled.div`
  display: flex;
  overflowX: auto;
  backgroundColor: #1a1b1f;
  minHeight: 84;
`;
const EyeIcon = styled.div`
  position: absolute;
  border: 1px solid #E55525;
  border-radius: 100%;
  padding: 6px;
  cursor: pointer;

  .showHeading {
    display: none;
    position: absolute;
    font-size: 14px;
    background-color: grey;
    border-radius: 6px;
    width: max-content;
    color: white;
    line-height: 1.3;
    top: -32px;
    left: -64px;
    padding: 0px 5px;
    margin-top: 10px;
  }
;

  &:hover .showHeading {
    display: block;
  }
`;
const EyeWrapper = styled.div`
  position: absolute;
  left: 45%;
`;
const UserViewWrapperCollapsed = styled.div`
  margin: 20px 20px;
`;
const UserViewWrapper = styled.div`
  border: 1px solid #E45525;
  border-top: 4px solid #E45525;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 15px;
  text-align: center;
  margin: 0px 10px;
  background-color: inherit;
  color: #E45525;
  padding: 10px 15px;
  border-radius: 20px;
  animation: blinker 2s linear infinite;
  @keyframes blinker {
    50% {
      opacity: .4;
    }
  };

  .wrapper {
    background-color: inherit;
    place-items: flex-start;
    margin: 0px 15px;
    display: flex;
    margin-top: 20px;
    text-align: left;
    border-radius: 0;
    justify-content: space-between;

    .desc {
      max-width: 42%;

      .heading {
        text-align: center;
        font-size: 13px;
        color: white;
      }
    ;
    }
  }
`;
const scrollStyles = {
    position: "relative",
    display: "block",
    width: "5px",
    borderRadius: "inherit",
    opacity: 0.7,
    backgroundColor: "#FFFFFF",
}
export default Sidebar;

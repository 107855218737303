import React from "react";
import styled from "styled-components";

const UserNotificationToggle = ({ onToggle, currentValue, options }) => {
    return (
        <ButtonWrapper>
            {options &&
                options.map((option) => (
                    <ToggleItem onClick={onToggle} key={option.id} active={option.value === currentValue}>
                        {option.label}
                    </ToggleItem>
                ))}
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 30px;
  border: 1px solid #16161b;
  border-radius: 30px;
  background-color: #16161b;
  overflow: hidden;
  min-width: 170px;
  justify-content: space-between;
`;

const ToggleItem = styled.div`
  padding: 0px 15px;
  background: ${(props) =>
    props.active ? "#F75C28" : "none"};
  border: 1px solid ${(props) => props.active ? "#F75C28" : "none"};
  border-radius: ${(props) => props.active ? "30" : "none"}px;
  color: ${(props) => props.theme.color.text};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
`;

export default UserNotificationToggle;

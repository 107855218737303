
import styled from "styled-components";

const StatusPoint = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  color: ${(props) =>
        props.color === "red"
            ? props.theme.color.red[100]
            : props.color === "green"
                ? props.theme.color.green[500]
                : props.theme.color.red[500]};

  ::before {
    content: "";
    position: absolute;
    left: -15px;
    top: ${(props) => props.center ? "1px" : "-6px"};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.color === "red"
            ? props.theme.color.red[100]
            : props.color === "green"
                ? props.theme.color.green[500]
                : props.theme.color.red[500]};
  }
`;

export default StatusPoint;

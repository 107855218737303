import React, {useState} from 'react';
import {Dialog, DialogContent} from "@material-ui/core";
import styled from "styled-components";

import {CircularProgressComp, MowerActionFooter} from "../Common";
import SuccessAnimation from "../SuccessAnimation";
import {mowersService} from "../../Utils/mowerActions";

const PinDialogue = (props) => {

    const [value, setValue] = useState(props?.mower?.pin ?? null);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [pinLengthError, setPinLengthError] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [error, setError] = useState(null);

    const confirmedAction = async () => {
        if (value?.length <= 10 && value.length > 0) {
            setOpenProgressDialog(true);
            let res = await mowersService.pinMower(props.mower, value);
            setOpenProgressDialog(false);
            if (process.env.REACT_APP_DEMO_MODE === 'true') {
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    props.setPinUpdate(false);
                    setError(null)
                }, 2000)
            } else {
                if (res?.result) {
                    setAnimationPopup(true);
                    setTimeout(() => {
                        setAnimationPopup(false);
                        props.setPinUpdate(false);
                    }, 2000)
                } else {
                    setError(res?.message?.response?.data?.error ?? true);
                    setTimeout(() => {
                        setError(null);
                    }, 2000)
                }
            }
        } else {
            setPinLengthError("Error: Pin should atleast have 1 alphanumeric symbols");
        }
    }
    const valueSetter = (e) => {
        let val = e.target.value.replace(/[^a-z0-9]/gi, '');
        setPinLengthError(false);
        setValue(val.substring(0, 10));
    }

    return (
        <Dialog
            open={props.pinUpdate} maxWidth="sm" aria-labelledby="form-dialog-title"
            onClose={() => props.setPinUpdate(false)}
        >
            <Container>
                {error && <ErrorDiv>Error : can not perform this action</ErrorDiv>}
                <DialogContentWrapper>
                    <Header>Edit Pin Code</Header>
                    <InputField type="text" value={value} onChange={valueSetter}/>
                    <HR />
                    {pinLengthError && <Error>{pinLengthError}</Error>}
                </DialogContentWrapper>
                <MowerActionFooter
                    close={() => props.setPinUpdate(false)}
                    action={confirmedAction}
                />
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
                }
                {animationPopup && <SuccessAnimation/>}
            </Container>
        </Dialog>
    );
};

const Error = styled.p`
    color: red;
`;
const HR = styled.hr`
    color: #E2E2E2;
    margin-top: 10px;
`;
const DialogContentWrapper = styled(DialogContent)`
    width: -webkit-fill-available;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    font-size: 21px;
    font-weight: bold;
`;
const ErrorDiv = styled.div`
    color: red;
`;
const InputField = styled.input`
   border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1F2125;
  font-family: Roboto;
  width: -webkit-fill-available;
  border-radius: 3px;
  font-size: 27px;
  font-weight: bold;
  &:focus {
        outline: none;
    }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 30px;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: ${(props) => props.theme.spacing[7]}px;
  color: ${(props) => props.theme.color.text};
`;
export default PinDialogue;

import { handleActions } from "redux-actions";
import {FILTERED_HOUSES, GET_HOUSES_SUCCESS, PROPERTY_MODE, SELECTED_HOUSE, SELECTED_MOWER} from "../actionTypes";

const initialState = {
    properties : [],
    selected: null,
    propertyMode: false,
    selectedMower: null,
    filteredHouses: []
}

export default handleActions(
    {
        [GET_HOUSES_SUCCESS] : (state, payload) => {
            state.properties = payload.payload;
            state.filteredHouses = payload.payload;
            return {
                ...state
            }
        },
        [SELECTED_HOUSE]: (state, payload) => {
            state.selected = payload.payload;
            return {
                ...state
            }
        },
        [FILTERED_HOUSES]: (state, payload) => {
            state.filteredHouses = payload.payload;
            return {
                ...state
            }
        },
        [PROPERTY_MODE]: (state, payload) => {
            state.propertyMode = payload.payload;
            return {
                ...state
            }
        },
        [SELECTED_MOWER]: (state, payload) => {
            state.selectedMower = payload.payload;
            return {
                ...state
            }
        }
    },
    initialState
);
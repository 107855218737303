import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, DialogActions} from "@material-ui/core";
import styled from "styled-components";

import {CircularProgressComp, DialogHeader} from "../Common";
import {imageSetter} from "../Sidebar/SidebarItem";
import {getMowerManagementData, updateMowerManagementData} from "../../Utils/api";
import SuccessAnimation from "../SuccessAnimation";
import {DialogButton, scrollBarStyles} from "../Common/styles";

import CheckIcon from "../../Assets/images/checkMark.svg";

const tableHeaderHeight = "50px";


const ActionButtonComponent = ({close, action, confirmCL = "#F75C28" }) => {
    return <>
        <DialogActions>
            <Footer>
                <CancelButtonWrapper>
                    <DialogButton onClick={() => close()} color="#565659">
                        Cancel
                    </DialogButton>
                </CancelButtonWrapper>
                <DialogButton onClick={action} color={confirmCL}>
                    Confirm
                </DialogButton>
            </Footer>
        </DialogActions>
    </>
}

const MowerManagement = ({showMowerManagementPopup, setShowMowerManagementPopup}) => {
    const [error, setError] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [mowers, setMowers] = useState(null);
    const [users, setUsers] = useState(null);
    const [dirtyForm, setDirtyForm] = useState(null);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [input, setInput] = useState('');

    const fetchData = async () => {
        setOpenProgressDialog(true);
        let res = await getMowerManagementData();
        if (res.result) {
            setOpenProgressDialog(false);
            setMowers(res?.data?.automowers ?? []);
            setUsers(JSON.parse(JSON.stringify(res?.data?.users ?? [])));
            setDirtyForm(JSON.parse(JSON.stringify(res?.data?.users ?? [])));
        } else {
            setOpenProgressDialog(false);
            setError("Error: Can not fetch users");
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const imageGetter = (mower) => {
        if (mower.manufacturer?.toLowerCase() === 'nexmow') {
            const src = require(`../../Assets/images/automowers/nexmow/nexmow.png`);
            return src.default;
        } else {
            return imageSetter(mower)
        }
    };

    function genericSearch () {
        if(input === ''){
            return mowers;
        } else {
            const includeColumns = ["name","manufacturer","model"];
            const filteredData = mowers.filter(item => {
                return Object.keys(item).some(key =>
                    includeColumns.includes(key) ? item[key]?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) : false
                );
            });
            return  filteredData ?? []
        }
    }
    const sort = genericSearch();

    const mowersData = useMemo(() => {
        if (mowers && Array.isArray(mowers)) {
            let data = [];
            Array.isArray(sort) && sort.map((mower, index) => (
                data.push(
                    <DataBox key={index}>
                        <div>
                            <ImagePan src={imageGetter(mower)}/>
                        </div>
                        <div className="desc">
                            <div className="manu">{mower?.manufacturer ?? "N/A"}</div>
                            <div className="inst">
                                <span className="manufacturer">{mower?.manufacturer?.slice(0, 4) ?? "N/A"} / </span>
                                <span title={mower?.name}>{mower?.name ?? "N/A"}</span>
                            </div>
                        </div>
                    </DataBox>
                )
            ))
            return data;
        }
        return []
    }, [mowers, input]) // eslint-disable-line

    const mowerAssignedChecker = useCallback((mowerId, userObj) => {
        let allowedArray = userObj?.allowed ?? [];
        if (mowerId && Array.isArray(allowedArray)) {
            return allowedArray.includes(mowerId);
        }
        return false;
    }, [users]); // eslint-disable-line

    const assignmentManagement = (mowerId, userObj) => {
        let data = dirtyForm;
        for (let i = 0; i < data?.length; i++) {
            if (data[i]?.id === userObj?.id) {
                if (userObj.allowed.includes(mowerId)) {
                    data[i].allowed = data[i].allowed.filter(e => e !== `${mowerId}`);
                } else {
                    data[i].allowed = [...data[i].allowed, `${mowerId}`]
                }
            }
        }
        setDirtyForm(JSON.parse(JSON.stringify(data)));
    };

    const submitData = async () => {
        setOpenProgressDialog(true);
        let res = await updateMowerManagementData({users: dirtyForm});
        if (res.result) {
            setOpenProgressDialog(false);
            setAnimationPopup(true);
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
                setShowMowerManagementPopup(false);
            }, 2000)
        } else {
            setError("Error occurred cannot update");
            setOpenProgressDialog(false);
        }
    };

    return (
        <Dialog
            open={showMowerManagementPopup}
            onClose={() => setShowMowerManagementPopup(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
        >
            <Container>
                <DialogHeader
                    heading="Mower Management"
                    close={() => setShowMowerManagementPopup(false)}
                    input={input}
                    setInput={val => setInput(val?.trim())}
                    clear={() => setInput("")}
                />
                {error && <ErrorDiv>{error}</ErrorDiv>}
                <Wrapper>
                    <UsersMainWrapper>
                        <DFlex>
                            <MowersListing>
                                {mowers && Array.isArray(mowers) &&
                                    <DataBox style={{...UserNameStyles, left: 0, zIndex: 1}} height={tableHeaderHeight}>
                                        {''}
                                    </DataBox>
                                }
                                {mowersData}
                            </MowersListing>
                            {dirtyForm && Array.isArray(dirtyForm) && dirtyForm.map((obj) => {
                                return <UsersListing>
                                    <UserBox height={tableHeaderHeight} style={{...UserNameStyles}} >
                                        {obj.name}
                                    </UserBox>
                                    {Array.isArray(sort) && sort.map((mower) => (
                                        <UserBox>
                                            {mowerAssignedChecker(mower?.id ?? null, obj) ?
                                                <Image
                                                    onClick={() => assignmentManagement(mower?.id ?? null, obj)}
                                                    showBg={mowerAssignedChecker(mower?.id ?? null, obj)}
                                                    src={CheckIcon}
                                                />
                                                :
                                                <UnCheckDiv
                                                    onClick={() => assignmentManagement(mower?.id ?? null, obj)}
                                                />
                                            }
                                        </UserBox>
                                    ))}
                                </UsersListing>
                            })}
                        </DFlex>
                    </UsersMainWrapper>
                </Wrapper>
                {Array.isArray(mowers) &&
                    <ActionButtonComponent
                        close={() => {
                            setDirtyForm(JSON.parse(JSON.stringify(users)));
                            setShowMowerManagementPopup(false);
                        }}
                        action={submitData}
                    />
                }
                {animationPopup && <SuccessAnimation/>}
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
                }
            </Container>
        </Dialog>
    );
};

const MowersListing = styled.div`
    position: sticky;
    left: 0;
    z-index: 10;
    height: max-content
`;
const UsersListing = styled.div`
    height: max-content
`;
const UsersMainWrapper = styled.div`
    overflow: auto;
    height:  430px;
    width: auto;
    ${scrollBarStyles}
`;
const DFlex = styled.div`
    display: flex;
    max-height:  430px;
    width: max-content;
`;
const Container = styled.div`
  display: flex;
  width: 68vw;
  height: 700px;
  overflow-x: hidden;
  flex-direction: column;
  align-items: start;
  border-radius: 30px;
  background-color: #1F2125;
  padding: ${(props) => props.theme.spacing[7]}px;
  color: ${(props) => props.theme.color.text};
`;
const ErrorDiv = styled.div`
    color: red;
`;
const Wrapper = styled.div`
    width: 100%;
    padding: 10px 40px;
`;
const ImagePan = styled.img`
  width: 60px;
  height: auto;
`;
const DataBox = styled.div`
    display: -webkit-box;
    width: 220px;
    align-items: end;
    height: ${props => props.height ? props.height : "80px"};
    padding-bottom: 23px;
    border-right: 1px solid #15151A;
    background-color: #1F2125;
    border-bottom: 1px solid #15151A;
    
    .desc{
        max-width: 145px;
        margin-left: 10px;
        line-height: 1;
        .manu{
            color: #E2E2E2;
            opacity: 20%;
            font-size: 13px;
        };
        .inst{
            color: #E2E2E2;
            font-size: 16px; 
            overflow: hidden;
            width: 145px; 
            overflow: hidden; 
            text-overflow: ellipsis;
            white-space: nowrap;
            .manufacturer {
                color: #E2E2E2;
                opacity: 20%;
            }
        };
    };
`;
const UserBox = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    width: 140px;
    padding: 0px 10px;
    overflow: hidden;
    align-items: center;
    height: ${props => props.height ? props.height : "80px"};
    border-right: 1px solid #15151A;
    border-bottom: 1px solid #15151A;
`;
const UnCheckDiv = styled.div`
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #15151A;
  background-color: rgba(21,21,26,0.14);
`;
const Image = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  ${props => props.showBg && `background-color: #DE5324; padding: 3px; border: 1px solid #15151A;`};
`;
const CancelButtonWrapper = styled.div`
    margin-right: 50px;
`;

const Footer = styled.div`
    display: flex;
    position: absolute;
    bottom: 50px;
`;
const UserNameStyles = {
    position: "sticky",
    top: 0,
    backgroundColor:" #1F2125",
};

export default MowerManagement;
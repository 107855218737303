import React from "react";
import styled from "styled-components";

import {imageSetter} from "./SidebarItem";
import {checkForManualModels} from "../../Utils/global";
import Tooltip from "../Tooltip/Tooltip";

import Tick from "../../Assets/images/check.png";

export const CuttingHeightDialogItem = ({mower, selected, isRouteMode, routeNum, response}) => {

    const CheckApiResponse = (mowerId,) => {
        if(process.env.REACT_APP_DEMO_MODE === "true"){
            return true
        } else {
            if(response?.[mowerId]?.result){
                return  !response?.[mowerId]?.data?.errors
            } else {
                return false
            }
        }
    };

    const returnString = (mowerId) => {
       if(response?.[mowerId]){
           if(response?.[mowerId]?.success){
               if(response?.[mowerId]?.data?.errors){
                   return response?.[mowerId]?.data?.errors?.[0]?.detail ?? "Some Error occurred"
               } else {
                   return "Can not perform this action";
               }
           } else {
               if(response?.[mowerId]?.data){
                   return response?.[mowerId]?.data ?? "Some Error occurred"
               }
               if(response?.[mowerId]?.message){
                   if (response?.[mowerId]?.message?.toLowerCase().includes('when login to husqvarna: simultaneous logins')) {
                       return 'Error when login to husqvarna: Simultaneous logins detected';
                   } else {
                       return response?.[mowerId]?.message?.substring(0, 80) ?? "Some Error occurred"
                   }
               }
               if(response?.[mowerId]?.error){
                   if (response?.[mowerId]?.error?.toLowerCase().includes('when login to husqvarna: simultaneous logins')) {
                       return 'Error when login to husqvarna: Simultaneous logins detected';
                   } else {
                       return response?.[mowerId]?.error?.substring(0, 80) ?? "Some Error occurred"
                   }
               }else{
                   return "Some error occurred!";    
               }
           }
       }
    }
    return (
        <MainRow>
            <ItemDiv>
                <Card selected={selected}>
                    <Row>
                        <ImageWrapper>
                            <div className="image">
                                <CenterPan src={imageSetter(mower)} />
                            </div>
                            <div>
                                <Model>
                                    {mower.manufacturer.slice(0, 4)} / {mower.model?.replace("HUSQVARNA AUTOMOWER® ", "")}
                                </Model>
                                <Label>{mower.instance}</Label>
                            </div>
                        </ImageWrapper>
                        <OptionsWrapper>
                            <div className="response">
                                {response ?
                                    <>
                                        {checkForManualModels(mower) ?
                                            CheckApiResponse(mower.id) ?
                                                <img src={Tick} alt="successful" />
                                                :
                                                <>
                                                    <Tooltip
                                                        content={
                                                            <TooltipContentWrapper>
                                                                <ErrorRes padding={true}>Error</ErrorRes>
                                                                <ErrorDesc>{returnString(mower.id)}</ErrorDesc>
                                                            </TooltipContentWrapper>
                                                        } direction="top"
                                                    >
                                                        <ErrorRes cursor={true} underline={true} >ERROR</ErrorRes>
                                                    </Tooltip>
                                                </>
                                            :
                                            <Manual>MANUAL</Manual>
                                        }
                                    </>
                                    :
                                    <>
                                        {!checkForManualModels(mower) && <Manual>MANUAL</Manual>}
                                    </>
                                }
                            </div>
                        </OptionsWrapper>
                    </Row>
                    {isRouteMode && routeNum > -1 && <Badge>{routeNum}</Badge>}
                </Card>
            </ItemDiv>
        </MainRow>
    );
};

const OptionsWrapper = styled.span`
    margin: auto 0;
    .response {
        textAlign: right;
    };
`;
const ImageWrapper = styled.span`
    display: flex;
    .image {
        alignSelf: center;
    };
`;
const TooltipContentWrapper = styled.div`
    text-align: left;
    width: 130px;
    word-break:break-all,
`;
const ErrorDesc = styled.p`
    font-size: 11px;
    color: #FFFFFF;
    padding: 0px 5px; 
    margin-top: 10px
`;
const ErrorRes = styled.span`
    font-weight: bold;
    color: #BB3D2E;
    ${props => props.underline && "text-decoration: underline"};
    ${props => props.padding && "padding: 0px 5px"};
    ${props => props.cursor && `cursor: default`};
`;
const Manual = styled.p`
    font-weight: bold;
`;
const MainRow = styled.div`
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  align-items: center;
`;
const Model = styled.p`
    font-size: 11px;
    color: grey;
`;
const CenterPan = styled.img`
  width: 45px;
  height: 30px;
  margin-right: 10px;
`;
const ItemDiv = styled.div`
  width: 95%;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Badge = styled.div`
  padding: 0px 7px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: red;
  position: absolute;
  top: 0px;
  left: 0px;
`;
const Label = styled.span`
  font-size: 16px;
  line-height: 15px;
  margin-left: 4px;
  text-transform: capitalize;
  color: white;
`;
const Card = styled.div`
  display: flex;
  position: relative;
  min-height: 60px;
  margin: 0px ${(props) => props.theme.spacing[1]}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.selected && props.theme.color.grey[500]};
  :hover {
    border-radius: ${(props) => props.theme.spacing[2]}px;
    background-color: ${(props) => props.theme.color.grey[500]};
  }
`;

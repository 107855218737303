export const white = "#FFF";
export const black = "#000";
export const yellow = "#D99242";
export const primary = "#E45525"

export const green = {
  500: "#789B4B",
  550: "#7B9D4E",
  600: "#84AB53",
};

export const red = {
  100: "#E64B39",
  200: "#ffc2a8",
  500: "#D99242",
};

export const grey = {
  100: "#FAFAFA",
  200: "#f0e9e7",
  300: "#E2E2E2",
  400: "#C2C2C2",
  425: "#4E4E4E",
  450: "#46484B",
  500: "#26282D",
  600: "#232428",
  700: "#1F2125",
  800: "#16161B",
  900: "#15151A",
};

export const orange = {
  100: "#C44920",
  200: "#F75C28",
};

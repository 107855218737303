import React from "react";
import "./animation.css";
import { Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const SuccessAnimation = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={true}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <div className="wrapper">
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
    </Dialog>
  );
};

export default SuccessAnimation;

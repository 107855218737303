import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyA2UI8st1R3aSCM4D5LXk1w2CH-nHzpcpM",
  authDomain: "robinautopilot.firebaseapp.com",
  projectId: "robinautopilot",
  storageBucket: "robinautopilot.appspot.com",
  messagingSenderId: "515821625752",
  appId: "1:515821625752:web:8ba521ab43372cd3c6cbed",
  measurementId: "G-Y0L1BT13YW"
};

firebase.initializeApp(config);
const databaseRef = firebase.firestore();

export const mowerRef = databaseRef.collection(process.env.REACT_APP_FIRESTORE_MOWER_COLLECTION);
export const userNotificationRef = databaseRef.collection(process.env.REACT_APP_FIRESTORE_USER_COLLECTION);

export default firebase;

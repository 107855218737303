import { black, green, grey, red, white, yellow, orange, primary } from "./colors";

const theme = {
  borderRadius: {
    1: 5,
    2: 10,
  },
  breakpoints: {
    mobile: 400,
  },
  color: {
    black,
    grey,
    red,
    yellow,
    background: grey[700],
    border: grey[800],
    primary,
    green,
    white,
    orange,
    text: grey[300],
  },
  spacing: {
    1: 5,
    2: 10,
    3: 15,
    4: 20,
    5: 25,
    6: 30,
    7: 35,
    8: 40,
    9: 45,
    10: 50,
    11: 55,
    12: 60,
  },
  topBarSize: 62,
  font: {
    FreightSansBold: "FreightSans-Bold",
  },
};

export default theme;

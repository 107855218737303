import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ path, component, ...rest }) => {
  const { isAutherized, errorType } = useSelector((state) => state.auth);

  const renderPrivateRoute = () => {
    // @ts-ignore
    // React component neeed to be uppercase
    const Component = component; //eslint-disable-line

    if (
      (isAutherized && Component) ||
      (errorType === "trial_expired" && path === "/")
    ) {
      return <Component path={path} component={component} {...rest} />;
    }

    return (
      <Redirect to='/signin' />
    );
  };

  return <Route path={path} render={renderPrivateRoute} />;
};

export default PrivateRoute;

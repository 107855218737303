import React from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import {InputWithClear} from "../Base/Input";
import {
    ActionButtonWrapper, ButtonWrapper, CloseDiv, DialogButton, HeaderWrapper, InputField,
    InputRow, ManufactureSelection, SearchInputWrapper
} from "./styles";

import CloseImg from "../../Assets/images/x.png";
import AddImg from "../../Assets/images/+.png";
import Search from "../../Assets/images/search.png";


export const validateEmail = (email) => {
    if (email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    } else {
        return false;
    }
};

export const CircularProgressComp = ({open, close}) => {
    return <Dialog open={open} onClose={close}>
        <DialogContent>
            {open && <CircularProgress/>}
        </DialogContent>
    </Dialog>
};

export const ActionButtonComponent = ({close, action}) => {
    return <>
        <DialogActions>
            <ButtonWrapper>
                <DialogButton onClick={() => close()} color="#565659">
                    Cancel
                </DialogButton>
                <DialogButton onClick={() => action()} color="#F75C28">
                    Confirm
                </DialogButton>
            </ButtonWrapper>
        </DialogActions>
    </>
};

export const DialogHeader = ({heading, action, actionLabel, close, input, setInput, clear}) => {
    return <>
        <CloseDiv>
            <img onClick={() => close()} style={{cursor: "pointer"}} src={CloseImg} alt="icon" width={18}/>
        </CloseDiv>
        <HeaderWrapper>
            <div className="heading">
                {heading}
            </div>
            {(actionLabel && action) &&
                <div className="addButton" onClick={() => action()}>
                    <img style={{margin: "3px 2px 0 0"}} src={AddImg} alt="icon" width={18}/>
                    <span>{actionLabel}</span>
                </div>
            }
            {setInput &&
                <InputWithClear input={input} setInput={setInput} clear={clear} />
            }
        </HeaderWrapper>
    </>
};

export const MowerActionFooter = ({close, action}) => {
    return <DialogActions>
            <ActionButtonWrapper justify="space-between">
                <DialogButton margin="margin-right: 40px" onClick={() => close()}
                              color="#565659">
                    Cancel
                </DialogButton>
                <DialogButton margin="margin-left: 40px" onClick={action} color="#F75C28">
                    Confirm
                </DialogButton>
            </ActionButtonWrapper>
        </DialogActions>
};

export const SearchInput = ({val, setVal, heading}) => {

    return <SearchInputWrapper>
        <ManufactureSelection>
            <div className="heading">
                {heading}
            </div>
            <div className={"clear"} onClick={() => setVal("")}>
                Clear
            </div>
        </ManufactureSelection>
        <InputRow>
            <div className={"img"} >
                <img height={"14px"} alt={"search"} style={{verticalAlign: "top"}} width={"14px"} src={Search} />
            </div>
            <InputField placeholder={"Search"} type="text" value={val} onChange={e => setVal(e.target.value)}/>
        </InputRow>
    </SearchInputWrapper>
}
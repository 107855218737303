import {
    addMowerToRoute, updateCuttingHeightDemoMowers, updateMower, updateMultiCalenderTasks,
    removeAllMowerFromRoute, removeAllMowerFromRouteLive, removeMowerFromRoute, scheduleSetterDemoMower,
    scheduleSetterDemoMowers, setCuttingHeight, updateCalenderTasks, updateCuttingHeightDemoMower,
    updateNotifyDemoMower, updatePinDemoMower, updateTags
} from "./api";
import {sendDemoMowerAction, sendMowerAction} from "../Redux/actions/mowerAction";
import {checkForManualModels} from "./global";

class MowersService {
     isLiveMode = process.env.REACT_APP_DEMO_MODE !=='true';

     responseObject = (apiRes, isDemo) => {
         if(apiRes?.success || apiRes?.result){
             return {
                 demo: isDemo,
                 error: apiRes?.data?.errors?apiRes?.data?.errors[0]?.detail : null,
                 result : !apiRes?.data?.errors
             }
         } else {
             return {
                 demo: isDemo,
                 error: apiRes?.error,
                 result : false
             }
         }
     }
    // methods for multiple mowers entity
    addMowers = async (selectedMowers) => {
        if(this.isLiveMode){
            let res = false;
            for (let mow of selectedMowers) {
                let apiRes = await updateMower(mow.id, {on_route: true});
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res
        } else {
            const routeIds = [];
            for (let mow of selectedMowers) {
                routeIds.push(mow.id);
            }
            try{
                await addMowerToRoute(routeIds);
                return true
            } catch {
                return false
            }
        }
    }
    removeMowers = async (selectedMowers) => {
        if(this.isLiveMode){
            let res = false;
            for (let mow of selectedMowers) {
                let apiRes = await updateMower(mow.id, {on_route: false});
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res
        } else {
            try{
                for (let mow of selectedMowers) {
                    await removeMowerFromRoute(mow.id);
                }
                return true
            } catch {
                return false
            }
        }
    }
    parkMowers = async (dialogTitle, parkType, duration, selectedMowers, dispatch) => {
        const type = "Park";
        const parkAttributes = {};
        if (dialogTitle === "Park Mowers" && !parkType) {
            alert("Please Select Parking type");
            return false;
        }
        if (dialogTitle === "Park Mowers") {
            parkAttributes.park_type = parkType;
            if (parkType === "Park") {
                parkAttributes.duration = duration;
            }
        }
        if (this.isLiveMode) {
            let res = false;
            for (const mower of selectedMowers) {
                let apiRes = await dispatch(sendMowerAction({mower, type, item: parkAttributes}));
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res;
        } else {
            let res = false;
            for (const mower of selectedMowers) {
                let apiRes = await dispatch(sendDemoMowerAction({mower, type, item: parkAttributes}));
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res;
        }
    }
    startMowers = async (dialogTitle, startType, duration, selectedMowers, dispatch) => {
        const startMoverType = "Start";
        const startAttributes = {};
        if (dialogTitle === "Start Mowers" && !startType) {
            alert("Please Select Start type");
            return false;
        }
        if (dialogTitle === "Start Mowers") {
            startAttributes.start_type = startType;
            if (startType === "Start") {
                startAttributes.duration = parseInt(duration);
            }
        }
        if (this.isLiveMode) {
            let res = false;
            for (const mower of selectedMowers) {
               let apiRes =  await dispatch(sendMowerAction({mower, type: startMoverType, item: startAttributes}));
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res;
        } else {
            let res = false;
            for (const mower of selectedMowers) {
                let apiRes =  await dispatch(sendDemoMowerAction({mower, type: startMoverType, item: startAttributes}));
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return res;
        }
    }
    cuttingHeightMowers = async (value, mowers) => {
        if (this.isLiveMode) {
            let res = {};
            for (const mower of mowers) {
                if(checkForManualModels(mower)){
                    let apiRes = await setCuttingHeight({mower: mower, "cutting_height": value});
                    res = {...res, [mower.id] : apiRes};
                }
            }
            return {
                demo: false,
                result:res
            }
        }else {
            const data = {
                "settings": {
                    "cutting_height": value
                }
            }
           try{
               await updateCuttingHeightDemoMowers(mowers, data)
               return {
                   demo: true,
                   result: true,
               }
           } catch {
               return {
                   demo: true,
                   result: false,
               }
           }
        }
    }
    scheduleMowers = async (tasksArray, selectedMowers) => {
        const ids = selectedMowers.map(o => o.id)
        if(this.isLiveMode){
            let apiRes =  await updateMultiCalenderTasks({calendar_tasks: tasksArray, mower_ids: ids});
            return {
                demo: false,
                result: apiRes?.result ?? false,
                data: apiRes ?? null
            }
        } else {
            let res = false;
            let data = {"calendar_tasks" : tasksArray}
            for (let id of ids) {
                let apiRes = await scheduleSetterDemoMowers(id , data);
                if(this.responseObject(apiRes, false)?.result){
                    res = true;
                }
            }
            return {
                demo: true,
                result : res,
            }
        }
    }

    // methods for individual mower entity
    addMower = async (mower) => {
        if (this.isLiveMode) {
            return await updateMower(mower.id, {on_route: true})
        } else {
            try {
                await addMowerToRoute([mower.id])
                return {
                    demo: true,
                    result: true
                }
            } catch {
                return {
                    demo: true,
                    result: false
                }
            }
        }
    }
    removeMower = async (mower) => {
        if (this.isLiveMode) {
            return await updateMower(mower.id, {on_route: false})
        } else {
            try {
                await removeMowerFromRoute(mower.id);
                return {
                    demo: true,
                    result: true
                }
            } catch {
                return {
                    demo: true,
                    result: false
                }
            }
        }
    }
    parkMower = async (dialogTitle, parkType, duration, mower, dispatch) => {
        const type = "Park";
        const parkAttributes = {};
        if (dialogTitle === "Park Mowers" && !parkType) {
            alert("Please Select Parking type");
            return {
                error: "Please Select Parking type",
                demo: false,
                result : false
            }
        }
        if (dialogTitle === "Park Mowers") {
            parkAttributes.park_type = parkType;
            if (parkType === "Park") {
                parkAttributes.duration = parseInt(duration);
            }
        }
        if (this.isLiveMode) {
            let apiRes = await dispatch(sendMowerAction({mower, type, item: parkAttributes}));
            return this.responseObject(apiRes, false);
        } else {
            let apiRes = await dispatch(sendDemoMowerAction({mower, type, item: parkAttributes}));
            return this.responseObject(apiRes, false);
        }
    }
    startMower = async(dialogTitle, startType, duration, mower, dispatch) => {
        const startMoverType = "Start";
        const startAttributes = {};
        if (dialogTitle === "Start Mowers" && !startType) {
            alert("Please Select Start type");
            return {
                error: "Please Select Start type",
                demo: false,
                result : false
            }
        }
        if (dialogTitle === "Start Mowers") {
            startAttributes.start_type = startType;
            if (startType === "Start") {
                startAttributes.duration = parseInt(duration);
            }
        }
        if (this.isLiveMode) {
            let apiRes = await dispatch(sendMowerAction({mower, type: startMoverType, item: startAttributes}));
            return this.responseObject(apiRes, false);
        } else {
            let apiRes = await dispatch(sendDemoMowerAction({mower, type: startMoverType, item: startAttributes}));
            return this.responseObject(apiRes, false);
        }
    }
    cuttingHeightMower = async (value, mower) => {
        if (this.isLiveMode) {
            let apiRes = await setCuttingHeight( {mower: mower, "cutting_height": value});
            return this.responseObject(apiRes, false);
        } else {
            const data = {
                "settings": {
                    "cutting_height": value
                }
            }
            try{
                await updateCuttingHeightDemoMower(mower.id, data)
                return {
                    demo: true,
                    result: true
                }
            } catch {
                return {
                    demo: true,
                    result: false
                }
            }
        }
    }
    scheduleMower = async (tasksArray, selectedMowerId) => {
        if(this.isLiveMode){
            let apiRes = await updateCalenderTasks({calendar_tasks: tasksArray}, selectedMowerId);
            return {
                demo: false,
                result: apiRes?.success,
                data: apiRes ?? null
            }
        } else {
            let data = {
                "calendar_tasks" : tasksArray
            }
            try{
                let apiRes = await scheduleSetterDemoMower( data, selectedMowerId);
                return {
                    demo: true,
                    result : apiRes?.result ?? false,
                    data: apiRes ?? null
                }
            } catch {
                return {
                    demo: true,
                    result: false
                }
            }
        }
    }
    pinMower = async (mower, value) => {
        const data = {
            "pin": value
        }
        if (this.isLiveMode) {
            let res = await updateMower(mower.id, data)
            return {
                demo: false,
                result: res.result
            }
        } else {
            try{
                await updatePinDemoMower(mower.id, data);
                return {
                    demo: true,
                    result: true
                }
            } catch {
                return {
                    demo: false,
                    result: false
                }
            }
        }
    }
    updateMowerTags = async (mower, tags) => {
        const data = {
            "tags": tags
        }
        let res = await updateTags(mower.id, data)
        return {
            demo: false,
            result: res.result
        }
    }
    notifyMower = async (mower, value) => {
        const data = {
            "notify": value
        }
        if (this.isLiveMode) {
            let res = await updateMower(mower.id, data)
            return {
                demo: false,
                result: res.result
            }
        } else {
            try{
                await updateNotifyDemoMower(mower.id, data);
                return {
                    demo: true,
                    result: true
                }
            } catch {
                return {
                    demo: false,
                    result: false
                }
            }
        }
    }

    clearFromAllRoutes = async (selectedMovers) => {
        if (this.isLiveMode) {
            let res = await removeAllMowerFromRouteLive(selectedMovers);
            return res
        } else {
            try {
               await removeAllMowerFromRoute(selectedMovers);
                return true
            } catch {
                return false
            }
        }
    }
}
export const mowersService = new MowersService();

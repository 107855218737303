import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Dialog} from "@material-ui/core";

import Input, {PasswordInput} from "../Base/Input";
import AddUser from "./AddUser";
import SuccessAnimation from "../SuccessAnimation";
import {CircularProgressComp, DialogHeader} from "../Common";
import {addHusqvarnaUsers, deleteHusqvarnaUsers, editHusqvarnaUsers, husqvarnaCredentialsData} from "../../Utils/api";
import {errorArray} from "../../Contants";
import {scrollBarStyles} from "../Common/styles";

import HusqvarnaLogo from "../../Assets/images/husqvarna-logo.png";
import Edit from "../../Assets/images/edit.png";
import NexmowLogo from "../../Assets/images/nexmow-logo.png";

const ColumnHeads = () => {
    const columnHeader = (val) => (
        <StyledLabel>
            {val}
            <RequiredIcon>*</RequiredIcon>
        </StyledLabel>
    );

    return <>
            <div className='logoRow'>
                {columnHeader("Manufacturer")}
            </div>
            <div className='input'>
                {columnHeader("Username")}
            </div>
            <div className='input'>
                {columnHeader("Password")}
            </div>
    </>
};

const HusqvarnaCredentials = ({showCredentialsPopup, setShowCredentialsPopup}) => {

    const [users, setUsers] = useState([]);
    const [showAddDialogue, setShowAddDialogue] = useState(false);
    const [selectedManufacture, setSelectedManufacture] = useState(null);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);

    useEffect(() => {
        getUsersData();
    },[])

    const getUsersData = async () => {
        setOpenProgressDialog(true);
        let res = await husqvarnaCredentialsData();
        if(res?.data){
            setUsers(res.data);
            setOpenProgressDialog(false);
        } else {
            setOpenProgressDialog(false);
            setError("Error: Can not fetch users");
        }
    };

    const handleAdd = async(data) => {
        setShowAddDialogue(false);
        setOpenProgressDialog(true);
        let res = await addHusqvarnaUsers(data);
        if(res?.result){
            setOpenProgressDialog(false);
            setAnimationPopup(true);
            getUsersData();
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
            }, 2000)
        } else {
            setOpenProgressDialog(false);
            setError(res?.message ?? "Error: Can not add manufacture login");
            setTimeout(() => {
                setError(null);
            }, 4000)
        }
    };

    const handleUpdate = async(data) => {
        setShowAddDialogue(false);
        setSelectedManufacture(null);
        setOpenProgressDialog(true);
        let res = await editHusqvarnaUsers(data);
        if (res?.result) {
            setOpenProgressDialog(false);
            setAnimationPopup(true);
            getUsersData();
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
            }, 2000)
        } else {
            setOpenProgressDialog(false);
            setError(res?.message ?? "Error: Can not update manufacture login");
            setTimeout(() => {
                setError(null);
            }, 4000)
        }
    };

    const onDelete = async (id) => {
        setShowAddDialogue(false);
        setSelectedManufacture(null);
        setOpenProgressDialog(true);
        let res = await deleteHusqvarnaUsers(id);
        if (res?.result) {
            setOpenProgressDialog(false);
            setAnimationPopup(true);
            getUsersData();
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
            }, 2000)
        } else {
            setOpenProgressDialog(false);
            setError("Error: Can not delete manufacture login");
            setTimeout(() => {
                setError(null);
            }, 2000)
        }
    };

    const logoComponent = (manu) => {
        const manufacturers = ["husqvarna", "nexmow"];
        const getValues = (image) => {
            if(manu?.toLowerCase() === "husqvarna"){
                if(image){
                    return HusqvarnaLogo
                } else {
                    return "Husqvarna";
                }
            }
            if(manu?.toLowerCase() === "nexmow"){
                if(image){
                    return NexmowLogo
                } else {
                    return "nexmow";
                }
            }
        }
        if(manufacturers.includes(manu?.toLowerCase())) {
            return <>
                <img src={getValues(true)} alt={"husqvarna"} />
                <ManufactureSubHeading>{getValues(false)}</ManufactureSubHeading>
            </>
        }
    };

    const rowsData = useMemo(() => {
        let rows = [];
        users?.map((user, index) => (
            rows.push(
                <InputRow key={index} onClick={() => setSelectedManufacture(user)}>
                    <div className="logoRow">
                        <div className="logo">
                            {logoComponent(user.manufacturer)}
                        </div>
                    </div>
                    <div className="input">
                        <Input
                            label="Username *"
                            placeholder="Username"
                            type="text"
                            hideHeading={true}
                            disabled
                            error={user?.username === null}
                            helperTxt={user?.username === null ? errorArray?.name : ""}
                            value={user.username}
                            width="90%"
                        />
                    </div>
                    <div className="input">
                        <PasswordInput
                            label="Password *"
                            placeholder="Password"
                            type="password"
                            hideHeading={true}
                            disabled
                            value={user.password ?? ''}
                            width="90%"
                        />
                    </div>
                    <EditImageDiv>
                        <img onClick={() => setShowAddDialogue(true)} src={Edit} alt="edit" width={"40px"} />
                    </EditImageDiv>
                </InputRow>
            )
        ))

        return rows;
    }, [users])

    return (
        <Dialog open={showCredentialsPopup} onClose={() => setShowCredentialsPopup(false)} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            {showAddDialogue &&
                <AddUser
                    selectedManufacture={selectedManufacture} onAdd={(data) => handleAdd(data)}
                    onDelete = {(id) => onDelete(id)} onUpdate={(data) => handleUpdate(data)}
                    onClose={() => {
                        setShowAddDialogue(false);
                        setSelectedManufacture(null);
                    }}
                />
            }
            <Container>
                <DialogHeader
                    heading={"Manufacturer Credentials"}
                    actionLabel={"New Credential"}
                    close={() => setShowCredentialsPopup(false)}
                    action={() => setShowAddDialogue(true)}
                />
                <Wrapper>
                    {error && <ErrorDiv>{error}</ErrorDiv>}
                    <DataHeader>
                        <ColumnHeads />
                    </DataHeader>
                    <InputRowsBlock>
                        {rowsData}
                    </InputRowsBlock>
                </Wrapper>
                {animationPopup && <SuccessAnimation/>}
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
                }
            </Container>
        </Dialog>
    );
};

const RequiredIcon = styled.span`
    color: white;
    margin-left: 5px;
`;
const ManufactureSubHeading = styled.div`
    color: #858585;
`;
const DataHeader = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #E2E2E2;
    font-weight: bold;
    .input {
        width: 37%;
    };
    .logoRow {
        width: 20%;
    };
    .logo{
        margin-top: 5px;
    }
`;
const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  color: ${(props) => props.labelColor? props.labelColor : props.theme.color.text};
  font-weight: bold;
  display: flex;
`;
const ErrorDiv = styled.div`
    color: red;
`;
const EditImageDiv = styled.div`
    display: block;
    width: 6%;
    margin: 2px auto 2px auto;
    cursor: pointer;
`;
const InputRow = styled.div`
    width: 100%;
    display: flex;
    .input {
        width: 37%;
    };
    .logoRow {
        width: 20%;
    };
    .logo{
        margin-top: 5px;
    }
`;
const InputRowsBlock = styled.div`
    width: 100%;
    height: 395px;
    overflow: scroll;
    overflow-x: hidden;
    ${scrollBarStyles}
`;
const Container = styled.div`
  display: flex;
  width: 68vw;
  overflow-x: hidden;
  flex-direction: column;
  align-items: start;
  background-color: #1F2125;
  padding: 35px 35px 0px 35px;
  color: ${(props) => props.theme.color.text};
`;
const Wrapper = styled.div`
    width: 100%;
    padding: 0px 40px;
`;
export default HusqvarnaCredentials;

import React, {useContext} from 'react';
import styled from "styled-components";

import {calenderTasksNullCase, getMowerModel, getSchedule, getStatusColor} from "../../Utils/global";
import StatusPoint from "../Base/StatusPoint";
import {DeviceContext} from "../../Context/DeviceContext";
import {imageSetter} from "./SidebarItem";
import {disabledColor} from "../../Pages/Home/DetailInfoPan/RobotPan";

import CheckIcon from "../../Assets/images/ico-check.png";
import UnCheckIcon from "../../Assets/images/ico-uncheck.png";

const ScheduleComponent = ({LineWrapper, mower }) => {
    return <LineWrapper style={{padding: '4px'}}>
        <span>Schedule</span>
        <p>
            {mower?.calendar_tasks && getSchedule(mower).map((spell, index) => {
                    return <React.Fragment key={index}>{spell}</React.Fragment>
                })
            }
            {(mower?.calendar_tasks === null || mower?.calendar_tasks === undefined) &&
                <>
                    {calenderTasksNullCase(disabledColor)}
                </>
            }
        </p>
    </LineWrapper>
};

const Description = ({mower}) => {
    return <ModelDesc>
        <p>{mower?.manufacturer?.slice(0, 4)} / {getMowerModel(mower)}</p>
        <div>
            <p>{mower?.instance}</p>
        </div>
    </ModelDesc>
};

const RemoveMowerItem = ({mower, checked, selectSetter}) => {
    const { smallDevice } = useContext(DeviceContext);
    const LineWrapper = smallDevice ? Row : Info;

    const getMaintenanceStr = () => {
        let s = "";
        if (mower?.peripherals && Array.isArray(mower.peripherals)) {
            mower.peripherals.forEach((peri) => {
                if (peri.remaining < 0) {
                    s += `Replace ${peri.item}\n`;
                }
            });
        }
        return s;
    };

    return (
        <Wrapper showBg={checked}>
            <Card>
               <StatusPointWrapper>
                   <StatusPoint
                       color={getStatusColor(mower)}
                       title={getMaintenanceStr()}
                   />
               </StatusPointWrapper>
                <ImageWrapper>
                    <ImageComp src={imageSetter(mower)} />
                </ImageWrapper>
                <DescWrapper>
                    <Description mower={mower} />
                    <div>
                        <ScheduleComponent mower={mower} LineWrapper={LineWrapper} />
                    </div>
                    <ButtonWrapper onClick={() => selectSetter(mower)}>
                        <CheckedImage src={checked ? CheckIcon : UnCheckIcon} />
                    </ButtonWrapper>
                </DescWrapper>
            </Card>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    ${props => props.showBg && `
        background-color : #1A1B1F;
        border-radius: 10px;
        margin-right: 30px;
    `}
`;
const DescWrapper = styled.div`
    display: flex;
`;
const Info = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  color: ${(props) => props.theme.color.text};

  > span {
    font-size: 12px;
    color: #E2E2E2;
    line-height: 13px;
    font-weight: bold;
    opacity: 20%;
  };
  
  p {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  };
`;
const ButtonWrapper = styled.div`
    margin-left: 30px;
    margin-top: 10px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.noMargin ? 0 : 15}px;
  padding-bottom: 15px;
  border-bottom: ${(props) => props.noBorder ? "" : "1px solid #16161B"} ;

  > span {
    display: none;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    opacity: 0.2;
  };

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-align: right;
  };
`;
const StatusPointWrapper = styled.div`
  padding-right: 5px;
  margin-top: 4px;
`;
const CheckedImage = styled.img`
  width: 16px;
`;
const ImageWrapper = styled.div`
    margin-top: 13px;
    margin-right: 10px;
`;
const ImageComp = styled.img`
    width: 45px;
`;
const ModelDesc = styled.div`
    padding: 0px 10px 0px 12px;
    width: 120px;
    word-break: break-word;
    & > p {
       font-size: 12px;
       color: #E2E2E2;
       opacity: 20%;
    };
    & > div {
       font-size: 15px;
       color: white
    };
`;
const Card = styled.div`
  display: inline-flex;
  padding : 15px 0px;
  position: relative;
  min-height: 60px;
  margin: 0px ${(props) => props.theme.spacing[3]}px;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  background-color: ${(props) => props.selected && props.theme.color.grey[500]};
`;
export default RemoveMowerItem;

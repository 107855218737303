import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";

import LoadingSpinner from "Components/LoadingSpinner";
import Input from "Components/Base/Input";
import Button from "Components/Base/Button";
import Footer from "Components/Base/Footer";

import { forgotPassword, clearForgotPassword } from "Redux/actions/authAction";
import { GET_FORGOT_PASSWORD_ASSET } from "Graphql/gql";

import BgImg from "Assets/images/login-bg.png";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const { loading, data } = useQuery(GET_FORGOT_PASSWORD_ASSET);
  const { inProgress, isForgotMessageSent, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!email) {
      dispatch(clearForgotPassword());
    }
    if (!inProgress && isForgotMessageSent === false) {
      setMailErr(true);
      setEmail("");
    } else if (!inProgress && isForgotMessageSent === true) {
      setMailErr(false);
      setEmail(email);
    }
  }, [email, inProgress, isForgotMessageSent, dispatch]);

  const assets = useMemo(() => {
    if (data) {
      const items = data.forgotPasswordCollection.items[0];
      return {
        title: items.title,
        background: items.background.url,
        successTxt: items.success,
        successButton: items.successButton,
      };
    }

    return {
      title: "",
      background: null,
      successTxt: "",
      successButton: "",
    };
  }, [data]);

  const handleClick = () => {
    dispatch(forgotPassword({ email }));
  };

  const handleMailCheck = () => {
    window.open("https://" + email.split("@")[1], "_blank");
  };

  if (loading) {
    return <LoadingSpinner loading={loading} />;
  }

  return (
    <FullPage>
      <FormContainer>
        {isForgotMessageSent ? (
          <StyledWrapper>
            <Header>{assets.title}</Header>
            <SuccessTextLabel>{assets.successTxt}</SuccessTextLabel>
            <Button
              onClick={handleMailCheck}
              fontSize={14}
              text={assets.successButton}
              width="100%"
            />
            <StyledLabel>
              Go back to <Link to={"/signin"}>Login</Link>
            </StyledLabel>
          </StyledWrapper>
        ) : (
          <StyledWrapper>
            <Header>{assets.title}</Header>
            <Input
              label="Email"
              placeholder="Email"
              error={mailErr}
              helperTxt={mailErr ? message : ""}
              type="text"
              value={email}
              onChange={setEmail}
              width="100%"
            />
            <Button
              onClick={handleClick}
              fontSize={14}
              showShadow={true}
              text="Reset Password"
              width="100%"
            />
            <StyledLabel>
              Go back to <Link to={"/signin"}>Login</Link>
            </StyledLabel>
          </StyledWrapper>
        )}
        <Footer />
      </FormContainer>
      <BGContainer>
        <StyledImage src={BgImg} alt="background" />
      </BGContainer>
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 35%;
  min-width: 450px;
  padding-bottom: ${(props) => props.theme.spacing[10]}px;
  background-color: ${(props) => props.theme.color.grey[700]};

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 62px;
    min-width: 320px;
  }
`;

const BGContainer = styled.div`
  height: 100vh;
  width: 65%;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const StyledWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  @media (min-width: 1023px) {
    margin-top: 62px;
  }
`;

const Header = styled.h1`
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.theme.spacing[10]}px;
`;

const SuccessTextLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.theme.spacing[9]}px;
`;

const StyledLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color.text};
  margin-top: ${(props) => props.theme.spacing[9]}px;

  > a {
    color: ${(props) => props.theme.color.primary};
    font-weight: bold;
    text-decoration: none;
  }
`;

export default ForgotPassword;

import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";

import Toggle from "Components/Toggle";
import Table from "Components/Table";

import {DefaultPage, ItemPerPage, NotificationHeaderCols, toggleOptionsNotifications} from "Contants";
import Spacer from "Components/Base/Spacer";
import Pagination from "Components/Pagination";
import {MowerContext} from "Context/MowerContext";
import {getHusqvarnaSearchUrl, getStatusColor} from "Utils/global";
import {getTimeStrFromSecond} from "Utils/datetime";
import {NotificationPanLoader} from "../../../Components/SkeletonLoaders/SkeletonLoaderDetailMower";

import QuestionMark from "Assets/images/question-mark.png";
import HoveredQuestionMark from "Assets/images/hover-question-mark.png";

const TroubleshootLink = ({link}) => (
    <a
        href={link}
        target="_blank"
        rel="noreferrer"
        title="Search FAQ on Husqvarna’s site"
    >
        <img
            width="20" height="20" alt="repair" src={QuestionMark}
            onMouseOver={(event) => event.target.src = HoveredQuestionMark}
            onMouseOut={(event) => event.target.src = QuestionMark}
        />
    </a>
);

const NotificationPan = () => {
    const {notifications, mowerDetails} = useContext(MowerContext);
    const [toggleValue, setToggleValue] = useState(toggleOptionsNotifications[1].value);
    const [page, setPage] = useState(DefaultPage);

    useEffect(() => {
        setPage(DefaultPage);
    }, [mowerDetails]);

    if (notifications?.id !== mowerDetails?.id) {
        return <NotificationPanLoader/>
    }

    const getNotificationData = () => {
        let data = [];
        try {
            if (!Array.isArray(notifications[toggleValue])) {
                return data;
            }
            const startIndex = (page - 1) * ItemPerPage;
            const endIndex = page * ItemPerPage;
            notifications[toggleValue]
                .slice(startIndex, endIndex)
                .forEach((notification) => {
                    let troubleshootingLink = getHusqvarnaSearchUrl(notification.message);
                    data.push({
                        col1: <StatusPoint color={getStatusColor(notification)}/>,
                        col2: notification.message,
                        col3: getTimeStrFromSecond(notification.time),
                        col4: <TroubleshootLink link={troubleshootingLink}/>
                    });
                });
        } catch (error) {
            console.log("Error when get notifications:", error);
        }
        return data;
    };

    const onToggle = (event) => {
        setPage(DefaultPage);
        setToggleValue(event.target.textContent.toLocaleLowerCase());
    };

    const onPageChange = (page) => {
        setPage(page);
    };

    return (
        <Container>
            <Row>
                <TitleSection>
                    <Title>Notifications</Title>
                </TitleSection>
                <Toggle
                    options={toggleOptionsNotifications}
                    currentValue={toggleValue}
                    onToggle={onToggle}
                />
            </Row>
            <Spacer/>
            <Table columns={NotificationHeaderCols} data={getNotificationData()} headerColor="#4E4E4E"
                   cellPadding='14px'/>
            {notifications[toggleValue].length > 0 && (
                <Pagination
                    page={page}
                    count={Math.ceil(notifications[toggleValue].length / ItemPerPage)}
                    onPageChange={onPageChange}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 340px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing[5]}px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.color.text};
  margin-right: ${(props) => props.theme.spacing[1]}px;
`;
const StatusPoint = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color === "red"
        ? props.theme.color.red[100]
        : props.color === "green"
            ? props.theme.color.green[500]
            : props.theme.color.red[500]};
  position: relative;
  left: ${(props) => props.theme.spacing[2]}px;
`;

export default NotificationPan;

import React from "react";
import {BrowserRouter as Router, Switch, Redirect} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { PersistGate } from "redux-persist/integration/react";
import theme from "Theme";

import SignIn from "Pages/SignIn";
import InitialPassword from "Pages/InitialPassword";
import ForgotPassword from "Pages/ForgotPassword";
import ResetPassword from "Pages/ResetPassword";
import Home from "Pages/Home";
import Topbar from "Components/Topbar";

import PublicRoute from "Routes/PublicRoute";
import PrivateRoute from "Routes/PrivateRoute";
import store, { persistor } from "Redux/store";
import MowerProvider from "Context/MowerContext";
import DeviceProvider from "Context/DeviceContext";
import LandingPage from "Pages/LandingPage";
import Dashboard from "./Pages/Dashboard/Dashboard";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_CONTENTFUL_URL}/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <DeviceProvider>
              <MowerProvider>
                <Router>
                  <Topbar />
                  <Switch>
                    <PublicRoute exact path="/signin" component={SignIn} />
                    <PublicRoute exact path="/set-password" component={InitialPassword} />
                    <PublicRoute
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <PublicRoute
                      exact
                      path="/reset-password/:token"
                      component={ResetPassword}
                    />
                    <PrivateRoute exact path="/console" component={Home} />
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <Redirect from="/" to="/console" />
                  </Switch>
                </Router>
              </MowerProvider>
            </DeviceProvider>
          </ThemeProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Dialog} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";

import {createUser, deleteUser, getUser, updateUser} from "../../Utils/api";
import Input from "../Base/Input";
import Dropdown from "../Dropdown/Dropdown";
import SuccessAnimation from "../SuccessAnimation";
import Tooltip from "../Tooltip/Tooltip";
import AddUser from "./AddUser";
import {setViewMode} from "../../Redux/actions/filterAction";
import {CircularProgressComp, DialogHeader} from "../Common";
import {errorArray, size_userManagement, tooltipHeading} from "../../Contants";
import {scrollBarStyles} from "../Common/styles";

import Hide from '../../Assets/images/whiteHideEye.png';
import Show from '../../Assets/images/whiteShowEye.png';
import Edit from "../../Assets/images/edit.png";
import QuestionMark from "../../Assets/images/questionMark.png";

const ColumnHeads = () => {
    const columnHead = (val) => (
        <Flex>
            <StyledLabel>
                {val}
            </StyledLabel>
        </Flex>
    );

    const tooltipDesc = () => (
        <TooltipContentWrapper>
            <TooltipHeading>
                User View
            </TooltipHeading>
            <TooltipDesc>{tooltipHeading}</TooltipDesc>
        </TooltipContentWrapper>
    );

    return <>
        <div className="logoRow">
            <Flex>
                <StyledLabel>
                    User View
                </StyledLabel>
                <Tooltip content={tooltipDesc()} direction="top">
                    <CircleDiv>
                        <img height={size_userManagement} width={size_userManagement} src={QuestionMark} alt={'Ques'} />
                    </CircleDiv>
                </Tooltip>
            </Flex>
        </div>
        <div className="input">
            {columnHead("Name")}
        </div>
        <div className="input">
            {columnHead("Email")}
        </div>
        <div className="dropdown">
            {columnHead("Role")}
        </div>
        <div className="dropdown">
            {columnHead("Status")}
        </div>
    </>
};

const UserManagement = ({showUserManagementPopup, setShowUserManagementPopup}) => {

    const dispatch = useDispatch();
    const {selectedUserMode} = useSelector((state) => state.filter);
    const [users, setUsers] = useState([]);
    const [showAddDialogue, setShowAddDialogue] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);


    useEffect(() => {
        getUsersData();
    },[]) // eslint-disable-line

    const structuredData = useCallback((res, i) => {
        return {
            id: res.data[i].id,
            name: (res.data[i]?.first_name ?? "") + " " + (res.data[i]?.last_name ?? ""),
            email: res.data[i]?.email ?? "",
            role: res.data[i]?.role?.role_name ?? "",
            status: res.data[i]?.state ?? ""
        }
    },[]);

    const getUsersData = async () => {
        setOpenProgressDialog(true);
        let res = await getUser();
        if(res?.data && Array.isArray(res?.data)){
            let sortedData = [];
            for(let i = 0; i < res.data.length ; i++){
                let data = structuredData(res, i);
                if(data.status?.toLowerCase() === "active"){
                    sortedData = [{...data},...sortedData];
                } else {
                    sortedData = [...sortedData, {...data}];
                }
            }
            setUsers(sortedData);
            setOpenProgressDialog(false);
        } else {
            setOpenProgressDialog(false);
            setError("Error: Can not fetch users");
        }
    };

    const confirmation = () => {
        setOpenProgressDialog(false);
        setAnimationPopup(true);
        getUsersData();
        setTimeout(() => {
            setAnimationPopup(false);
            setError(null);
        }, 2000)
    };

    const handleResponse = (res) => {
        if(res){
            if(res?.result){
                confirmation();
            } else {
                errorHandeling(
                    (res?.message?.response?.data?.error ?? res?.message?.response?.data?.message)
                    ??
                    "Error cannot perform this action"
                );
            }
        }
    };

    const handleAction = async (data , action) => {
        setShowAddDialogue(false);
        setSelectedUser(null);
        setOpenProgressDialog(true);
        let res = null;
        if(action === "add"){
            res = await createUser(data);
            handleResponse(res);
        }
        if(action === "delete"){
            let res = await deleteUser(data);
            handleResponse(res);
        }
        if(action === "update"){
            let res = await updateUser(data);
            handleResponse(res);
        }
    };

    const errorHandeling = (msg) => {
        setOpenProgressDialog(false);
        setError(msg);
        setTimeout(() => {
            setError(null);
        }, 4000)
    };

    const eyeIconSetter = (user) => {
        return selectedUserMode?.id === user?.id;
    };

    const viewSetter = (user) => {
        if(selectedUserMode && (user?.id === selectedUserMode?.id)){
            dispatch(setViewMode(null));
        } else {
            dispatch(setViewMode(user));
            setShowUserManagementPopup && setShowUserManagementPopup(false);
        }
    };

    const inputComp = (user, label, attribute, type) => {
        return <Input
            label={label}
            placeholder={label}
            type={type}
            hideHeading={true}
            error={user?.[attribute] === null}
            helperTxt={user?.[attribute] === null ? errorArray?.[attribute] : ""}
            value={user?.[attribute] ?? ""}
            disabled
            width="90%"
        />
    };

    const dropdownComponent = (user, label, attribute) => {
        return <Dropdown label={label} disabled error={user?.[attribute] === null} hideHeading={true}
                         selected={user?.[attribute]} options={[]}/>
    };

    const userRows = useMemo(() => {
        let rows = [];
        users?.map((user, index) => (
            rows.push(
                <InputRow key={index}>
                <div className="logoRow">
                    <div className="logo">
                        <EyeIconWrapper
                            className={user.status === 'active' ? "" : "disabled" }
                            onClick={() => user.status === 'active' ? viewSetter(user) : undefined}
                            bg={eyeIconSetter(user)}
                        >
                            <div className={user.status === 'active' ? "icon" : "disabled"}>
                                <div className="eye">
                                    <img src={ user.status === 'active' ?  eyeIconSetter(user) ? Show : Hide : Hide } alt={""} />
                                </div>
                            </div>
                        </EyeIconWrapper>
                    </div>
                </div>
                <div className="input">
                    {inputComp(user, "Name","name", "text")}
                </div>
                <div className="input">
                    {inputComp(user, "Email", "email", "email")}
                </div>
                <div className="dropdown">
                    {dropdownComponent(user,"Role","role")}
                </div>
                <div className="dropdown">
                    {dropdownComponent(user,"Status","status")}
                </div>
                <EditImageDiv>
                    <img
                        onClick={() => {setShowAddDialogue(true);setSelectedUser(user);}}
                        src={Edit} alt="edit"  width={"40px"}
                    />
                </EditImageDiv>
            </InputRow>
            )
        ))
        return rows;
    },[users, selectedUserMode]) // eslint-disable-line

    return (
        <Dialog open={showUserManagementPopup} onClose={() => setShowUserManagementPopup(false)} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            {showAddDialogue &&
                <AddUser
                    selectedUser={selectedUser} onAction={(data, action) => handleAction(data, action)}
                    onClose={() => {
                        setShowAddDialogue(false);
                        setSelectedUser(null);
                    }}
                />
            }
        <Container>
            <DialogHeader
                heading={"User Management"}
                actionLabel={"New User"}
                close={() => setShowUserManagementPopup(false)}
                action={() => setShowAddDialogue(true)}
            />
           <Wrapper>
               {error && error.length > 0 && <ErrorMessage>{error}</ErrorMessage>}
               <HeaderComponent>
                   <ColumnHeads />
               </HeaderComponent>
               <InputRowsBlock>
                   {userRows}
               </InputRowsBlock>
           </Wrapper>
            {animationPopup && <SuccessAnimation/>}
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
            }
        </Container>
        </Dialog>
    );
};
const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  color: ${(props) => props.labelColor? props.labelColor : props.theme.color.text};
  font-weight: bold;
  display: flex;
`;
const Flex = styled.div`
    display: flex;
`;
const HeaderComponent = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #E2E2E2;
    font-weight: bold;
    .logoRow {
        width: 12%;
    };
    .input {
        width: 27%
    };
    .dropdown {
        width: 15%
    };
    .logo{
        margin-top: 5px;
    };
`;
const EditImageDiv = styled.div`
    display: block;
    width: 6%;
    margin: 2px auto 2px auto;
    cursor: pointer;
`;
const TooltipDesc = styled.p`
    font-size: 11px;
    color: #E2E2E2;
    Opacity: 35%;
    line-height: 1.3;
    padding: 0px 5px; 
    margin-top: 10px
`;
const TooltipHeading = styled.span`
    font-weight: bold;
    padding: 0px 5px;
    font-size: 12px;
    color: #E2E2E2;
`;
const InputRowsBlock = styled.div`
    width: 100%;
    height: 395px;
    overflow: scroll;
    overflow-x: hidden;
    ${scrollBarStyles}
`;
const EyeIconWrapper= styled.div`
    .disabled {
        cursor: not-allowed;
        border: 1px grey solid;
        height: 32px;
        width: 34px;
        border-radius: 50%;
        background-color: #292B2F;
        
        .eye {
            margin-left: 6px;
            margin-top: 4px;
        }
    };
    .icon {
        cursor: pointer;
        border: 1px grey solid; 
        height: 32px;
        width: 34px;
        border-radius: 50%;
        background-color: ${(props) => props.bg ? "#E55525" : "#292B2F"};
        .eye {
            margin-left: 6px;
            margin-top: 4px;
        }
    };
`;
const CircleDiv= styled.div`
    margin-left: 10px;
    align-self: center;
    margin-top: -5px;
`;
const TooltipContentWrapper = styled.div`
    text-align: left;
    width: 130px;
    word-break:break-all,
`;
const InputRow = styled.div`
    width: 100%;
    display: flex;
    .logoRow {
        width: 12%;
    };
    .input {
        width: 27%
    };
    .dropdown {
        width: 15%
    };
    .logo{
        margin-top: 5px;
    };
`;
const Container = styled.div`
  display: flex;
  width: 68vw;
  overflow-x: hidden;
  flex-direction: column;
  align-items: start;
  background-color: #1F2125;
  padding: 35px 35px 0px 35px;
  color: ${(props) => props.theme.color.text};
`;
const Wrapper = styled.div`
    width: 100%;
    padding: 0px 40px;
`;
const ErrorMessage = styled.div`
  color: red;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
`;
export default UserManagement;

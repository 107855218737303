import React, {useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

import {CircularProgressComp} from "../../../Components/Common";
import {createPeripheral, updatePeripheral} from "../../../Utils/api";
import SuccessAnimation from "../../../Components/SuccessAnimation";
import Input from "../../../Components/Base/Input";
import Button from "../../../Components/Base/Button";

import CloseImg from "../../../Assets/images/x.png";

const PopUpDialog = (
    {
        openPopup, onClose, setOpenPopup, dialogTitle, currentDate, setCurrentDate, projectedLife, setProjectedLife,
        technician, setTechnician, newPeripheral, peri ,
    }
) => {
    const {selectedMowerId} = useSelector((state) => state.mower);
    const [error, setError] = useState("");
    const [toggleSucess, setToggleSucess] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);

    const successAction = (res, demo = false) => {
        setOpenProgressDialog(false);
       if(demo || res.success){
           setToggleSucess(true);
           setTimeout(() => {
               setToggleSucess(false);
               setOpenPopup(false);
           }, 2000)
       } else {
           const err = res?.message?.response?.data;
           setError(err?.message  ?? "Can not perform this action")
           setTimeout(() => {
               setError("");
           }, 2000)
       }
    };

    const resetPeripheral = async () => {
        const data = {
            fleet_id: selectedMowerId,
            item: peri.item,
            projected_life: Math.round(+projectedLife),
            replaced_date: currentDate + 'T' + new Date().toISOString().split("T")[1],
            technician: technician,
            unit_of_measure: peri.unit,
        }
            let res = await createPeripheral(data);
            successAction(res);
    };

    const setPeripheral = async () => {
        const data = {
            projected_life:  Math.round(+projectedLife),
            replaced_date: currentDate + 'T' + new Date().toISOString().split("T")[1],
            technician: technician,
        }
            let res = await updatePeripheral(data, peri.fleet_peripheral_id);
            successAction(res);
    };

    const confirmAction = () => {
        if(projectedLife === 0){
            return;
        }
        setOpenProgressDialog(true);
        if (newPeripheral) {
            resetPeripheral();
        } else {
            setPeripheral();
        }
    };

    const projectedLifeSetter = (val) => {
        setProjectedLife(val);
    };

    return <>
        <Dialog open={openPopup} onClose={() => onClose()} aria-labelledby="form-dialog-title" maxWidth="xm">
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
            }
            {toggleSucess && <SuccessAnimation/>}
            <Container>
                <IconButton
                    src={CloseImg}
                    onClick={() => setOpenPopup(false)}
                />
                {error && <ErrorDiv>{error}</ErrorDiv>}
                <DialogTitle style={{paddingTop: '0px'}}>{dialogTitle}</DialogTitle>
                <DialogContent style={{width: "300px"}}>
                    <Input
                        label="Replacement Date"
                        type="date"
                        value={currentDate}
                        onChange={setCurrentDate}
                        width="100%"
                    />
                    <Input
                        label="Projected Life"
                        type="number"
                        value={projectedLife}
                        onChange={(val) => projectedLifeSetter(val)}
                        width="100%"
                    />
                    <Input
                        label="Technician who performed work"
                        type="text"
                        placeholder="Technician name"
                        value={technician}
                        onChange={setTechnician}
                        width="100%"
                    />
                </DialogContent>
                <ActionButtonWrapper>
                    <DialogActions>
                        <CancelButton onClick={() => setOpenPopup(false)}>
                            Cancel
                        </CancelButton>
                        <Button onClick={confirmAction} text={'Confirm'} />
                    </DialogActions>
                </ActionButtonWrapper>
            </Container>
        </Dialog>
    </>
}

const IconButton = styled.img`
  align-self: flex-end;
  width: 12px;
  cursor: pointer;
  margin: 4px;
  top: -${(props) => props.theme.spacing[5]}px;
  right: -${(props) => props.theme.spacing[5]}px;
`;
const ActionButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: 25px 40px;
  color: ${(props) => props.theme.color.text};
`;
const CancelButton = styled.button`
  border-radius: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.width};
  cursor: pointer;
  outline: none !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  padding: ${(props) => props.theme.spacing[2]}px;
  background: ${(props) => `linear-gradient(0deg, ${props.theme.color.grey[700]} 0%, ${props.theme.color.grey[800]} 100%)`};
  :focus {
    background: ${(props) => props.theme.grey};
  }
`;
const ErrorDiv = styled.div`
    color: red;
`;

export default PopUpDialog
import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";

import LoadingSpinner from "Components/LoadingSpinner";


const LoadingPopUp = ({ loading, message, disabledClose }) => {
  const [show, setShow] = useState(loading || message);

  useEffect(() => {
    if (loading || message) {
      setShow(true);
    } else {
      setShow(false);
    };
  }, [loading, message]);

  return (
    <Dialog
      open={show}
      onClose={() => !disabledClose && setShow(false)}
      maxWidth="xs"
      fullWidth={true}
    >
      <Container>
        <LoadingSpinner height="100%" loading={loading} />
        {message}
      </Container>
    </Dialog>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: ${(props) => props.theme.spacing[10]}px;
  color: ${(props) => props.theme.color.text};
`;

export default LoadingPopUp;

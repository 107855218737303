/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";

import Input from "../../Components/Base/Input";
import Button from "../../Components/Base/Button";
import {setPasswordForFirstTime, validateTokenForPassword} from "../../Utils/api";
import SuccessAnimation from "../../Components/SuccessAnimation";

import BgImg from "../../Assets/images/login-bg.png";




const errorMessageHandler = (password, confirmPassword) => {
    if(password && confirmPassword){
        if(password?.length === 0 || confirmPassword?.length === 0){
           return "Error : Empty password";
        } else if (password?.length <= 7 || confirmPassword?.length <= 7) {
            return "Error : minimum 8 characters required";
        }
        else {
            return "Error : password does not match";
        }
    } else {
        return "Error : Empty password";
    }
}

const InitialPassword = () => {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const search = useLocation().search;
    const history = useHistory();

    const [validToken, setValidToken] = useState(false);
    const [token, setToken] = useState();
    const [message, setMessage] = useState();
    const [animationPopup, setAnimationPopup] = useState(false);
    const [tokenExpiredMessage, setTokenExpiredMessage] = useState();

    useEffect(()=>{
        if(search){
            let tokenData = new URLSearchParams(search).get('token');
            setToken(tokenData ?? null);
        }
    },[search])


    useEffect(() => {
        if(token){
            let fetch = true;
            if (fetch){
                validateTokenForPassword(token).then(res => {
                    if(res.result){
                        setValidToken(true);
                        setTokenExpiredMessage(res.message);
                    } else {
                        setValidToken(false);
                        setTokenExpiredMessage(res.message);
                    }
                })
            }

            return () => {
                fetch = false;
            };
        }
    }, [token]);

    const handleClick = () => {
        if(
            (password && confirmPassword) &&
            (password === confirmPassword) &&
            (password?.length > 7 && confirmPassword?.length > 7)
        ){
            setPasswordForFirstTime(token, password, confirmPassword).then(res => {
                if(res.result){
                    setAnimationPopup(true);
                    setTimeout(() => {
                        history.push('/signin');
                    }, 4000);
                }
            });
        } else {
            setPassword('');
            setConfirmPassword('');
            setMessage(errorMessageHandler(password, confirmPassword));
        }
    }

    return (
       <FullPage>
           <FormContainer>
               <StyledWrapper>
                   <Header>Set Up Password</Header>

                   {validToken ?
                       <>
                           <Input
                               label="Password"
                               placeholder="Password"
                               type="password"
                               value={password}
                               error={message}
                               helperTxt={message ? message : ""}
                               onChange={setPassword}
                               width="100%"
                           />

                           <Input
                               label="Confirm Password"
                               placeholder="Password"
                               type="password"
                               error={message}
                               helperTxt={message ? message : ""}
                               value={confirmPassword}
                               onChange={setConfirmPassword}
                               width="100%"
                           />

                           <Button
                               onClick={handleClick}
                               fontSize={14}
                               showShadow={true}
                               text="Set Password"
                               width="100%"
                           />
                       </>
                       :
                       <ExpiredToken>
                           {tokenExpiredMessage}
                       </ExpiredToken>

                   }
               </StyledWrapper>
           </FormContainer>
           {animationPopup && <SuccessAnimation/>}
           <BGContainer>
               <StyledImage src={BgImg} alt="background" />
           </BGContainer>
       </FullPage>
    );
};

const FullPage = styled.div`
  display: flex;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 35%;
  min-width: 450px;
  padding-bottom: ${(props) => props.theme.spacing[10]}px;
  background-color: ${(props) => props.theme.color.grey[700]};

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 62px;
    min-width: 320px;
  }
`;
const StyledWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  @media (min-width: 1023px) {
    margin-top: 62px;
  }
`;
const Header = styled.h1`
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.theme.spacing[10]}px;
`;
const BGContainer = styled.div`
  height: 100vh;
  width: 65%;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
  }
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const ExpiredToken = styled.h2`
  font-size: 28px;
  line-height: 30px;
  color: red;
`;

export default InitialPassword;

import React from 'react';
import styled from "styled-components";
import {
    AreaChart,
    XAxis,
    YAxis,
    ReferenceLine, Area,
} from "recharts";

const ProductivityChart = (props) => {
    return (
        <>
            <Row>
                <div>
                    ROI
                </div>
                <div>
                    Lifetime
                </div>
            </Row>
            <div>
                <AreaChart
                    width={750}
                    height={280}
                    data={props?.data ?? []}
                    margin={{
                        top: 20,
                        right: 50,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="15%" stopColor="#129a74" stopOpacity={0.1}/>
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>

                    <XAxis dataKey="name" />
                    <YAxis />
                    <ReferenceLine y={0} stroke="white" strokeDasharray="3 7" />
                    <Area type="monotone" dataKey="pv" stroke="#9AC55E" fill="#9AC55E" strokeWidth={2} fillOpacity={0.1} />
                    <Area type="monotone" dataKey="beforeLaunch" stroke="red" fill="red" strokeWidth={2} fillOpacity={0.1} />
                </AreaChart>
            </div>
        </>
    );
};
const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;
export default ProductivityChart;

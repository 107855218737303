import React from 'react';
import styled from "styled-components";

import "./index.css";

const RPrightMb = "27px";
const RPleftMb = "18px";

const returnThreeTimes = () => {
    let obj = []
    for(let i = 0 ; i < 3; i++){
        obj.push(
            <div style={{
                ...flex_between_padding("25px 2px"), ...mR32,
            }}>
                <CardOMb width="14px" className="loading">
                    <div className="tData" />
                </CardOMb>
                <CardOMb width="40px" className="loading">
                    <div className="tData" />
                </CardOMb>
                <CardOMb width="50px" className="loading">
                    <div className="tData" />
                </CardOMb>
                <CardOMb width="30px" className="loading">
                    <div className="tData" />
                </CardOMb>
                <CardOMb width="60px" className="loading">
                    <div className="tData" />
                </CardOMb>
            </div>
        )
    }
    return obj;
}

const returnTimes = (val) => {
    let obj = []
    for(let i = 0 ; i < val; i++) {
        obj.push(
            <div style={{
                ...flex_between_padding("15px 2px"), ...mR32,
            }}>
                <CardOMb width="9px" className="loading">
                    <div className="notifiData" />
                </CardOMb>
                <CardOMb width="200px" className="loading">
                    <div className="notifiData" />
                </CardOMb>
                <CardOMb width="60px" className="loading">
                    <div className="notifiData" />
                </CardOMb>
                <CardOMb width="9px" className="loading">
                    <div className="notifiData" />
                </CardOMb>
            </div>
        )
    }
    return obj;
}

export const NotificationPanLoader = () => (
    <div className="notifications">
        <div style={{...flex_between_padding(),...alignItems("center")}}>
            <div>
                <CardOMb width="100px" className="loading">
                    <div className="headingM" />
                </CardOMb>
            </div>
            <div  style={{...justify_between, ...border_solid_grey, ...borderR_15}}>
                <div style={{...flex_between_padding("4px 2px")}}>
                    <CardOMb width="35px" mr="3px" className="loading">
                        <div className="toggle" />
                    </CardOMb>
                    <CardOMb width="35px" mr="0px" className="loading">
                        <div className="toggle" />
                    </CardOMb>
                </div>
            </div>
        </div>
        <div style={height_10} />
        <div  style={justify_between}>
            <div style={{
                ...flex_between_padding("20px 0px"),
                ...paddingLeft("60px") , ...width("350px")
            }}>
                <CardOMb width="60px" mr="3px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
                <CardOMb width="55px" mr="3px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
            </div>
        </div>
        <div  style={justify_between}>
            {returnTimes(4)}
        </div>
    </div>
)
export const MaintainancePanLoader = () => (
    <div className="maintainancePan">
        <div style={{...flex_between_padding(), ...alignItems("center")}}>
            <div>
                <CardOMb width="100px" className="loading">
                    <div className="headingM" />
                </CardOMb>
            </div>
            <div  style={{...justify_between, ...border_solid_grey, ...borderR_15}}>
               <div style={{...flex_between_padding("4px 2px")}}>
                   <CardOMb width="45px" mr="3px" className="loading">
                       <div className="toggle" />
                   </CardOMb>
                   <CardOMb width="40px" mr="3px" className="loading">
                       <div className="toggle" />
                   </CardOMb>
                   <CardOMb width="35px" mr="0px" className="loading">
                       <div className="toggle" />
                   </CardOMb>
               </div>
            </div>
        </div>
        <div style={height_10} />
        <div  style={justify_between}>
            <div style={{
                ...flex_between_padding("20px 2px"),
                ...marginLeft("44px"), ...mR32,
            }}>
                <CardOMb width="60px" mr="3px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
                <CardOMb width="55px" mr="3px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
                <CardOMb width="35px" mr="0px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
                <CardOMb width="40px" className="loading">
                    <div className="tHeads" />
                </CardOMb>
            </div>
        </div>
        <div  style={justify_between}>
            {returnThreeTimes()}
        </div>
    </div>
)
export const RobotPanLoader = () => (
    <div className="robotPanWrapper">
        {/*   Top row  */}
        <div style={{...flex_between_padding()}}>
            <div style={flex}>
                <Card width="30px" className="loading">
                    <div className="signal" />
                </Card>
                <Card width="40px" className="loading">
                    <div className="status" />
                </Card>
            </div>
            <div>
                <Card width="20px" className="loading">
                    <div className="signal" />
                </Card>
            </div>
        </div>
        {/*  Body  */}
        <div style={{...flex, ...pT_15}}>
            {/* left */}
            <div style={flex_1}>
                <div style={flex_column} >
                    <Card width="60px" mb="3px" className="loading">
                        <div className="heading" />
                    </Card>
                    <Card width="90px" mb={RPrightMb} className="loading">
                        <div className="desc" />
                    </Card>
                </div>
                <div style={flex_column} >
                    <Card width="80px" mb="3px" className="loading">
                        <div className="heading" />
                    </Card>
                    <Card width="100px" mb={RPrightMb} className="loading">
                        <div className="desc" />
                    </Card>
                </div>
                <div style={flex_column} >
                    <Card width="50px" mb="3px" className="loading">
                        <div className="heading" />
                    </Card>
                    <Card width="60px" mb={RPrightMb} className="loading">
                        <div className="desc" />
                    </Card>
                </div>
                <div style={flex_column} >
                    <Card width="50px" mb="3px" className="loading">
                        <div className="heading" />
                    </Card>
                    <Card width="100px" mb={RPrightMb} className="loading">
                        <div className="desc" />
                    </Card>
                </div>
                <div style={flex_column} >
                    <div style={{display: "flex"}}>
                        <Card width="50px" mb="10px" className="loading">
                            <div className="desc" />
                        </Card>
                        <Card width="30px" mb="10px" className="loading">
                            <div className="desc" />
                        </Card>
                    </div>
                </div>
                <div style={flex_column} >
                    <Card width="130px" mb="20px" className="loading">
                        <div className="ser" />
                    </Card>
                </div>
            </div>
            <div style={self_center}>
                <Card width="100px" mb="20px" className="loading">
                    <div className="mower" />
                </Card>
            </div>
           {/* right */}
           <div style={flex_1}>
               <div style={{...flex_column,...alignItems("flex-end")}} >
                   <Card width="100px" mb={RPleftMb} className="loading">
                       <div className="box" />
                   </Card>
                   <Card width="100px" mb={RPleftMb} className="loading">
                       <div className="box" />
                   </Card>
                   <Card width="100px" mb={RPleftMb} className="loading">
                       <div className="box" />
                   </Card>
                   <div style={{...flex_column}} >
                       <Card width="70px" mb="3px" className="loading">
                           <div className="heading" />
                       </Card>
                       <Card width="100px" mb="20px" className="loading">
                           <div className="desc" />
                       </Card>
                   </div>
                   <Card width="80px" mb="16px" className="loading">
                       <div className="box" />
                   </Card>
               </div>
           </div>

        </div>
    </div>
)


const Card = styled.div`
    width: ${props => props.width ?? "100px"};
    margin-bottom: ${props => props.mb ?? "10px"};
    margin-right: ${props => props.mr ?? "20px"};
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .12);
`;
const CardOMb = styled.div`
    width: ${props => props.width ?? "100px"};
    margin-bottom: 0px;
    margin-right: ${props => props.mr ?? "20px"};
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .12);
`;

const flex = {display: "flex"};
const justify_between = {justifyContent: "space-between"};
const height_10 = {height: "10px"};
const borderR_15 = {borderRadius: "15px"};
const border_solid_grey = {border: "1px solid grey"};
const pT_15 = {paddingTop: "15px"};
const flex_1 = {flex: 1};
const self_center = {alignSelf: "center"};
const mR32 = {marginRight: "32px"};

const paddingLeft = (val) => {
    return {paddingLeft: val}
};
const marginLeft = (val) => {
    return {marginLeft: val}
};
const width = (val) => {
    return {width: val}
};
const alignItems = (align) => {
    return {alignItems: align}
};
const flex_between_padding = (padding) => {
    if(padding){
        return {...flex, ...justify_between, padding: padding}
    } else {
        return {...flex, ...justify_between}
    }
};
const flex_column = {...flex, flexDirection: "column"};
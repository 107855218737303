import { createContext } from "react";
import { useMediaQuery } from 'react-responsive';

export const DeviceContext = createContext({
  smallDevice: null,
  middleDevice: null,
  largeDevice: null,
});

const DeviceProvider = ({ children }) => {
  const smallDevice = useMediaQuery({ maxWidth: 767 });
  const middleDevice = useMediaQuery({ maxWidth: 1024, minWidth: 768 });
  const largeDevice = useMediaQuery({ minWidth: 1025 });

  return (
    <DeviceContext.Provider value={{ smallDevice, middleDevice, largeDevice }}>
      {children}
    </DeviceContext.Provider>
  );
}

export default DeviceProvider;

import React, { useMemo } from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

const IconButton = ({ url, onClick, size, noBackground, tooltip }) => {
  const width = useMemo(() => {
    switch (size) {
      case "sm":
        return 12;
      default:
        return 22;
    }
  }, [size]);
  const Wrapper = tooltip ? Tooltip : React.Fragment;

  return (
    <Wrapper {...(tooltip ? {title: tooltip, placement: "right"} : {})}>
      <Button onClick={onClick} noBackground={noBackground}>
        <img src={url} alt="icon" width={width} />
      </Button>
    </Wrapper>
  );
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: ${(props) => (props.noBackground ? 0 : props.theme.spacing[2])}px;
  background-color: ${(props) => props.theme.color.grey[600]};
  border: none;
  outline: none;
  cursor: pointer;
`;

export default IconButton;

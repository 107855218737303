import styled from "styled-components";

const Footer = (props) => {
  const now = new Date();
  return (
    <SpanFooter {...props} >
      © {now.getFullYear()} Robin Autopilot USA
    </SpanFooter>
  );
};

const SpanFooter = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #afb0ae;
  font-weight: bold;
  width: 100%;
  position: ${(props) => props.position || "relative"};
  top: ${(props) => props.top || 89}px;
  bottom: ${(props) => props.bottom}px;
  text-align: center;
`;

export default Footer;

import React, { useEffect, useState } from "react";
import {useHistory, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import MapImg from "Assets/images/landing-page-map.png";
import StoreImg from "Assets/images/landing-page-store.png";
import Button from "Components/Base/Button";
import Footer from "Components/Base/Footer";
import LoadingPopUp from "Components/LoadingPopUp";
import { gotoBCStore } from "Utils/bigcommerce";
import { currentUser } from "Redux/actions/authAction";

import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);


const LandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { user, isThirdParty, errorType } = useSelector(state => state.auth);
  const search = useLocation().search;
  useEffect(()=>{
    if(search){
      let loginMyth = new URLSearchParams(search).get('loginMyth');
      if(loginMyth){
        window.opener.postMessage({
          loginMyth: loginMyth,
        }, '*');
      }
    }
  },[search])
  useEffect(() => {
    if (errorType === "trial_expired") {
      setMessage(
        `Your trial has expired. If you wish to re-activate,
        call our sales team at 972.666.9750 or contact them
        by email at sales@robinautopilot.com.`
      );
    }
  }, [errorType, setMessage]);

  useEffect(() => {
    if (!isThirdParty) {
      dispatch(currentUser());
    }
  }, [dispatch, isThirdParty]);
  const goToConsole = () => {
    history.push("/console");
  };

  useEffect(() => {
    if (user) {
      ReactGA.send({ hitType: "pageview", page: "/signin" });
      ReactGA.set({ userId: user.id });
    }
  }, [user]);

  const goToStore = async () => {
    setLoading(true);
    setMessage(await gotoBCStore(user));
    setLoading(false);
  };

  return (
    <FullPage>
      <Container>
        <Item onClick={goToConsole}>
          <ItemImage src={MapImg} />
          <ItemTitle>Fleet Console</ItemTitle>
          <ItemDescription>
            Review status of all robots in your fleet, send<br />
            fleet commands based on weather or other<br />
            triggers, and generate maintenance routes.
          </ItemDescription>
          <Button text="ENTER" width="100%" onClick={goToConsole} />
        </Item>
        <Item onClick={goToStore}>
          <ItemImage src={StoreImg} />
          <ItemTitle>Store</ItemTitle>
          <ItemDescription>
            Purchase partner equipment and Powered<br />
            by Robin accessories at subscriber<br />
            discounted rates.
          </ItemDescription>
          <Button text="ENTER" width="100%" />
        </Item>
      </Container>
      <Footer position={"absolute"} bottom={20} top="no" />
      <LoadingPopUp loading={loading} message={message} disabledClose={true} />
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  wight: 100vw;
  background-color: #000;
  background-size: auto 100%;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing[8]}px;
  cursor: pointer;

  button {
    visibility: hidden;
  }

  :hover {
    border-radius: ${(props) => props.theme.spacing[6]}px;px;
    background-color: #1B1D20;
    box-shadow: 0 8px 20px 0 rgba(0,0,0,0.4);
  }

  :hover > button {
    visibility: visible;
  }
`;

const ItemImage = styled.img`
  width: 80%
`;

const ItemTitle = styled.div`
  font-family: FreightSans;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.57px;
  line-height: 27px;
  margin: ${(props) => props.theme.spacing[8]}px;
`;

const ItemDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.38px;
  line-height: 25px;
  margin-bottom: ${(props) => props.theme.spacing[10]}px;
`;

export default LandingPage;

import React, {useMemo} from 'react';
import styled from "styled-components";

import Search from "../../Assets/images/search.png";
import CheckIcon from "../../Assets/images/ico-check.png";
import UnCheckIcon from "../../Assets/images/ico-uncheck.png";

const SelectDropdownComp = (
    {clear, dropdownSelection, setDropdownSelection, heading,
                                inputString,setInputString, list, attribute}
) => {
    function genericSearch () {
        if(inputString === "") {
            return list;
        } else {
            let data = [];
            // eslint-disable-next-line array-callback-return
            list?.map((object) => {
                const value = object[attribute];
                if (typeof value === "string") {
                    if(value.toString().toLowerCase().includes(inputString?.toLowerCase())){
                        data.push({...object});
                    }
                }
            })
            return data;
        }
    }
    const sort = genericSearch();

    const listing = useMemo(() => {
        let rows = [];
        sort?.map((item , index) => (
            rows.push(
                <DropdownOption
                    key={index}
                    isSelected={dropdownSelection?.id === item?.id}
                >
                    <div className="wrapper">
                        <div className="email">{item?.caps ?? item?.username}</div>
                        <CheckMark
                            onClick={() => setDropdownSelection(item)} width={"21px"}
                            src={dropdownSelection?.id === item?.id ? CheckIcon : UnCheckIcon}
                        />
                    </div>
                </DropdownOption>
            )
        ))
        return rows;
    },[dropdownSelection, list, inputString]) // eslint-disable-line

    return (
        <Wrapper>
            <div>
                <ManufactureSelection>
                    <div className="heading">
                        {heading}
                    </div>
                    <div className={"clear"} onClick={() => clear()}>
                        Clear
                    </div>
                </ManufactureSelection>
                <InputRow>
                    <div className={"img"} >
                        <img height={"14px"} alt={"search"} style={{verticalAlign: "top"}} width={"14px"} src={Search} />
                    </div>
                    {dropdownSelection ?
                        <InputField placeholder={"Search"} type="text"
                                    value={dropdownSelection?.caps ?? dropdownSelection?.username} onChange={e => {
                                setInputString(e.target.value);
                                setDropdownSelection(null);
                            }}
                        />
                        :
                        <InputField placeholder={"Search"} type="text" value={inputString} onChange={e => {
                                setInputString(e.target.value);
                                setDropdownSelection(null);
                            }}
                        />
                    }
                </InputRow>
                <Hr />
            </div>
            <Listing>
                {listing}
            </Listing>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    margin-top: 10px;
`;
const Hr = styled.hr`
    margin-top: 10px;
`;
const CheckMark = styled.img`
  width: ${props => props.width ?? "14px"}; 
  margin-left: 20px;
`;
const Listing = styled.div`
    margin-top: 30px;
`;
const InputRow = styled.div`
  display: flex;
  .img{
    width: 14px;
    height: 14px;
    margin-right: 10px;
  };
`;
const DropdownOption = styled.div`
  color: white;
  font-size: 16px;
  margin-top: 5px;
  ${props => props.isSelected && `
    background-color: #1A1B1F;
    border-radius: 15px;
  `};
  
  .wrapper {
    display: flex;
    margin: 0px 25px;
    padding: 15px 0px;
    border-bottom: 1px solid #15151A;
    background-color: inherit;
    justify-content: space-between;
    border-radius: 0px;
    ${props => props.isSelected && `
        border-bottom: 1px solid #1A1B1F;
    `};
    
    .email{
        word-break: break-all;
    };
  };
`;
const InputField = styled.input`
   border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1F2125;
  color: white;
  font-family: Roboto;
  width: -webkit-fill-available;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  &:focus {
        outline: none;
    }
`;
const ManufactureSelection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  
  .heading{
    color: #E2E2E2;
    opacity: 30%;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.4px;
  };
  .clear {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    color: #E45525;
    letter-spacing: 0.4px;
  };
`;

export default SelectDropdownComp;
import React, {useContext} from 'react';
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import {DeviceContext} from "../../Context/DeviceContext";
import LaborSavings from "./LaborSavings";
import ProductivityChart from "./ProductivityChart";
import FleetWorking from "./FleetWorking";
import QuestionMark from '../../Assets/images/questionMark.png';
import IncidentCount from "./IncidentCount";
import EnvironmentImpact from "./EnvironmentImpact";


const heading = (heading) => {
    const size = "25px"
    return <Heading>
                { heading }
                <CircleDiv>
                    <img height={size} width={size} src={QuestionMark} alt={'Ques'} />
                </CircleDiv>
            </Heading>
}
const Dashboard = () => {
    const { smallDevice, largeDevice } = useContext(DeviceContext);

    const productivityChartValue = [
        {
            name: "2010",
            pv: undefined,
            beforeLaunch: -2400
        },
        {
            name: "2011",
            pv: 0,
            beforeLaunch: 0
        },
        {
            name: "2012",
            pv: 2398,
            beforeLaunch: undefined,
        },
        {
            name: "2013",
            pv: 2100,
            beforeLaunch: undefined,
        },
        {
            name: "2014",
            pv: 4200,
            beforeLaunch: 0,
        },
        {
            name: "2015",
            pv: 5200,
            beforeLaunch: undefined,
        },
        {
            name: "2016",
            pv: 8900,
            beforeLaunch: undefined,
        },
        {
            name: "2017",
            pv: 10500,
            beforeLaunch: undefined,
        },
    ];

    const fleetWorkingLineData = {
        trend:[
            {
                name: 'apr 11',
                uv: 10,
            },
            {
                name: 'apr 12',
                uv: 80,
            },
            {
                name: 'apr 13',
                uv: 200,
            },
            {
                name: 'apr 14',
                uv: 60,
            },
        ],
        comparison : {
            pastMonth: 66,
            thisWeek: 74,
            lastWeek: 45,
        }
    };
    const laborSaving = {
        amount: 75000,
        labour1: 13,
        labour2: 30,
        labour3: 15,
    };

    const incidentCount = [
        {
            date: 'apr1',
            val:20
        },
        {
            date: 'apr2',
            val: 10
        },
        {
            date: 'apr3',
            val: 31
        },
        {
            date: 'apr4',
            val: 44
        },
    ];
    const environmentImpact = [
        {},
        {
            date: '2020',
            val:20
        },
        {
            date: '2021',
            val: 15
        },
        {
            date: '2022',
            val: 31
        },
        {}
    ];
    return (
        <Page smallDevice={smallDevice} largeDevice={largeDevice}>
            <Scrollbars>
                <Row>
                    <Box width="66%" padding="56px 20px 43px 50px">
                        {heading("Productivity")}
                        <CardWrapper>
                            <Card>
                                <ProductivityChart data={productivityChartValue}/>
                            </Card>
                        </CardWrapper>
                    </Box>
                    <Box width="34%" padding="56px 50px 43px 20px">
                        {heading("Labor Savings")}
                        <CardWrapper>
                            <Card>
                                <LaborSavings data={laborSaving} />
                            </Card>
                        </CardWrapper>
                    </Box>
                </Row>
                <Row>
                    <Box width="33%" padding="56px 10px 43px 50px">
                        {heading("Service Level Performance")}
                        <CardWrapper>
                            <Card>
                                <FleetWorking data={fleetWorkingLineData}/>
                            </Card>
                        </CardWrapper>
                    </Box>
                    <Box width="33%" padding="90px 20px 43px 10px">
                        <CardWrapper>
                            <Card>
                                <IncidentCount data={incidentCount} />
                            </Card>
                        </CardWrapper>
                    </Box>
                    <Box width="34%" padding="56px 50px 43px 20px">
                        {heading("Environmental Impact")}
                        <CardWrapper>
                            <Card>
                                <EnvironmentImpact data={environmentImpact} />
                            </Card>
                        </CardWrapper>
                    </Box>
                </Row>
            </Scrollbars>
        </Page>
    );
};
const Row = styled.div`
    display: flex;
    width: 100%;
    color: white;
    background-color: black;
`;

const Card = styled.div`
    padding: 45px 25px 25px 25px;
    font-weight: bold;
    background-color: #202327;
    border-radius: 25px;
    margin: 30px 0px;
    width: 100%;
    height: inherit;
`;
const Heading = styled.div`
    font-size: 25px;
    display: flex;
    font-weight: bold;
`;
const CircleDiv= styled.div`
    margin-left: 10px;
    align-self: center;
`;
const CardWrapper= styled.div`
    width: 100%;
    height: 365px;
`;
const Box = styled.div`
    width: ${props => props.width};
    padding:  ${props => props.padding};
`;

const Page = styled.div`
  width: 100%;
  z-index: 10;
  height: ${(props) => !props.smallDevice && "100vh"};
  box-sizing: border-box;
  padding-top: ${(props) => !props.smallDevice && props.theme.topBarSize}px;
  flex-direction: ${(props) => (props.smallDevice ? "column" : "row")};
`;
export default Dashboard;

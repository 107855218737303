/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "@material-ui/core";

import {addAllSelectedMowers, removeAllSelectedMowers} from "../../Redux/actions/mowerAction";

import CheckIcon from "Assets/images/ico-check.png";
import UnCheckIcon from "Assets/images/ico-uncheck.png";
import MidCheckIcon from "Assets/images/ico-mid-check.png";
import AddToRouteIcon from "Assets/images/add-to-route-icon.png";
import DropdownArrow from "Assets/images/drop_down.png";
import LeftDropdownArrow from "Assets/images/left_drop_down.png";
import RemoveFromRouteIcon from "Assets/images/remove-from-route-icon.png";
import ParkIcon from "Assets/images/park.png";
import CropHeight from "Assets/images/cropHeightImage.png";
import Calender from "Assets/images/multi_calender.svg";
import StartIcon from "Assets/images/play-circle.png";

const Statusbar = ({title, mowers, mowersCollapsed, status}) => {

    const {selectedMowers} = useSelector((state) => state.mower);
    const dispatch = useDispatch();
    const [tempSelected, setTempSelected] = useState([])

    const onSelectAll = () => {
        let selectedMowers = mowers.filter(mower =>  mower.instance )
        dispatch(addAllSelectedMowers(selectedMowers))
    };

    const onUnSelect = () => {
        dispatch(removeAllSelectedMowers(mowers))
    };

    useEffect(() => {
        if (selectedMowers?.length === 0) {
            setTempSelected([])
        } else {
            let mowersData = [];
            for (let i = 0; i < mowers?.length; i++) {
                for (let j = 0; j < selectedMowers?.length; j++) {
                    if (mowers[i]?.id === selectedMowers[j]?.id) {
                        mowersData.push(mowers[i]);
                    }
                }
            }
            setTempSelected([...mowersData]);
        }
    }, [selectedMowers?.length, mowers])

    const allChecked = tempSelected.length === mowers?.length;
    const midChecked = tempSelected.length > 0;

    return (
        <Row>
            {mowers?.length &&
                <CheckedImage
                    onClick={() => {
                        if (allChecked || midChecked) {
                            onUnSelect();
                        } else {
                            onSelectAll();
                        }
                    }}
                    src={allChecked ? CheckIcon : midChecked ? MidCheckIcon : UnCheckIcon}
                />
            }
            <StyledLabel>{title} ({mowers ? mowers?.length : 0})</StyledLabel>
            <CollapseImage
                onClick={() => mowersCollapsed(status)}
                src={status ? LeftDropdownArrow : DropdownArrow}
            />
        </Row>
    );
};

export const TopStatusbar = ({
                                 mowers, action, robotAction, unSelect, selectedMowers,
                                 cuttingRobotSelect, openScheduleDialog, isCollapsed
                             }) => {

    const [tempSelected, setTempSelected] = useState([]);

    useEffect(() => {
        if (selectedMowers?.length > 0) {
            for (let i = 0; i < selectedMowers.length; i++) {
                for (let j = 0; j < mowers?.length; j++) {
                    if (selectedMowers[i].id === mowers[j].id) {
                        tempSelected.push(mowers[j])
                        setTempSelected([...tempSelected])
                    }
                }
            }
        } else {
            setTempSelected([])
        }
    }, [selectedMowers, mowers])
    const allChecked = selectedMowers?.length === mowers?.length;
    const midChecked = selectedMowers?.length > 0;

    if (isCollapsed) {
        return null
    }
    return (
        <Row>
            {mowers?.length &&
                <CheckedImage
                    onClick={() => {
                        if (allChecked || midChecked) {
                            unSelect(false);
                        } else {
                            action(true);
                        }
                    }}
                    src={selectedMowers?.length === mowers.length ? CheckIcon : selectedMowers?.length > 0 ? MidCheckIcon : UnCheckIcon}
                />
            }
            <TopBarStyledLabel>{`(${selectedMowers?.length})`}</TopBarStyledLabel>
            {mowers?.length &&
                <Tooltip title="Schedule" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={Calender}
                                 onClick={() => midChecked && openScheduleDialog(true)}
                    />
                </Tooltip>
            }
            {mowers?.length &&
                <Tooltip title="Cutting Height" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={CropHeight}
                                 onClick={() => midChecked && cuttingRobotSelect(true)}
                    />
                </Tooltip>
            }
            {mowers?.length &&
                <Tooltip title="Park" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={ParkIcon}
                                 onClick={() => midChecked && robotAction('PARK')}
                    />
                </Tooltip>
            }
            {mowers?.length &&
                <Tooltip title="Start" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={StartIcon}
                                 onClick={() => midChecked && robotAction('START')}
                    />
                </Tooltip>
            }
            {mowers?.length &&
                <Tooltip title="Add to Route" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={AddToRouteIcon}
                                 onClick={() => midChecked && robotAction('ADD')}
                    />
                </Tooltip>
            }
            {mowers?.length &&
                <Tooltip title="Remove from Route" placement="bottom">
                    <RouteImages cursor={selectedMowers?.length > 0} src={RemoveFromRouteIcon}
                                 onClick={() => midChecked && robotAction('REMOVE')}
                    />
                </Tooltip>
            }
        </Row>
    );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing[4]}px
    ${(props) => props.theme.spacing[4]}px;
`;
const StyledLabel = styled.span`
  font-size: 17px;
  width: 100%;
  padding-left:20px;
  font-weight: bold;
  line-height: 15px;
  color: ${(props) => props.theme.color.text};
`;
const TopBarStyledLabel = styled.span`
   font-size: 13px;
  width: 100%;
  padding-left:20px;
  line-height: 15px;
  color: ${(props) => props.theme.color.text};
`;

const CheckedImage = styled.img`
  width: 19px;
`;
const RouteImages = styled.img`
  width: 24px;
  margin-right: 15px;
  cursor: ${props => props.cursor ? "pointer" : "not-allowed"};
`;
const CollapseImage = styled.img`
  width: 10px;
  height: 8px;
  margin-right: 10px;
  cursor: pointer;
`;

export default Statusbar;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Spacer from "Components/Base/Spacer";
import {clearFilter, saveFilter} from "Redux/actions/filterAction";

const animatedComponents = makeAnimated();

const getFilterName = (label) => {
  if (label === "Manufacturer") {
    label = "manufacturer";
  } else if (label === "Zip Code") {
    label = "zipcode";
  } else if (label === "Tags") {
      label = "tags";
  };
  return label.toLowerCase() + "Filter";
};
const getLabelName = (name) => {
    switch (name) {
        case 'MOWING':
        case 'LEAVING':
        case 'GOING_HOME':
            return {value: ['MOWING','LEAVING','GOING_HOME'], label: 'Mowing',isMulti: true};
        case 'CHARGING':
            return {value: 'CHARGING', label: 'Charging',isMulti: false};
        case 'NOT_APPLICABLE':
            return {value: 'NOT_APPLICABLE', label: 'Error',isMulti: false};
        case 'PARKED_IN_CS':
            return {value: 'PARKED_IN_CS', label: 'Parked',isMulti: false};
        case 'need_maintain':
            return {value: "need_maintain", label: 'Need Maintenance',isMulti: false};
        default:
            return {value: name , label: name, isMulti: false};
    }
}
const Selector = ({ options, label, isMulti = true, onChange, onClear, selectedValue }) => {
  const dispatch = useDispatch();
  const Filter = useSelector(
    (state) => state.filter
  );
  const filterName = getFilterName(label);
  const [selectedData, setSelectedData] = useState([]);

    useEffect(() => {
        let temp = Filter[filterName].map(opt => (getLabelName(opt)));
        const labels = temp.map(o => o.label)
        const filtered = temp.filter(({label}, index) => !labels.includes(label, index + 1))
        setSelectedData([...filtered]);
    }, [filterName, Filter])
  const handleChange = (data) => {
    setSelectedData(data);
    if (!data) {
      data = [];
    }
    let values = [];
    if (Array.isArray(data)) {
      for (let v of data) {
          if (v.isMulti){
              values = v.value;
          }else {
              values.push(v.value);
          }
      }
    }
    dispatch(saveFilter({ filterName , values }));
  };

  const clear = () => {
    setSelectedData(null);
    dispatch(clearFilter({ filterName }));
  }

  return (
    <Container>
      <Header>
        <HeaderLabel>{label}</HeaderLabel>
        <BtnGroup>
          <TextButton onClick={onClear ? onClear : clear}>Clear</TextButton>
        </BtnGroup>
      </Header>
      <Spacer size="sm" />
      <Select
        closeMenuOnSelect={true}
        onChange={onChange ? onChange : handleChange}
        styles={{
          control: () => ({
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
          }),
          clearIndicator: () => ({
            display: "none",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          indicatorsContainer: () => ({
            display: "none",
          }),
          menu: () => ({
            backgroundColor: "#1F2125",
            borderRadius: 5,
            position: "absolute",
            width: "100%",
            zIndex: 1,
            boxShadow: "0 30px 30px 0 rgba(13,13,13,0.5)",
          }),
          option: () => ({
            borderBottom: "1px solid #16161B",
            color: "#E2E2E2",
            fontWeight: "bold",
            fontSize: 12,
            padding: 10,
          }),
          multiValue: () => ({
            backgroundColor: "transparent",
            borderRadius: 12,
            border: "1px solid #E2E2E2",
            color: "#E2E2E2",
            marginRight: 5,
            display: "flex",
          }),
          singleValue: () => ({
            backgroundColor: "transparent",
            color: "#E2E2E2",
            marginRight: 5,
          }),
          multiValueLabel: () => ({
            color: "#E2E2E2",
            borderRadius: 2,
            fontSize: "85%",
            overflow: "hidden",
            padding: 3,
            paddingLeft: 6,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            boxSizing: "border-box",
          }),
        }}
        components={animatedComponents}
        isMulti={isMulti}
        options={options}
        value={selectedValue ? selectedValue : selectedData}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.primary};
`;

const HeaderLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color.text};
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
`;

const TextButton = styled.button`
  font-size: 10px;
  font-weight: bold;
  line-height: 11px;
  color: ${(props) => props.theme.color.primary};
  background-color: transparent;
  border: none;
  outline: none;
`;

export default Selector;

import { createAction } from "redux-actions";
import {
    getMowerPeripherals,
    mowersDataArray,
    mowersDataArrayBasedOnUser,
    sendActionAPI,
    sendDemoActionAPI
} from "Utils/api";
import {
    ADD_ALL_SELECTED_MOWERS,
    ADD_SELECTED_MOWER,
    ALL_MOWERS_NOTIFICATIONS,
    CLEAR_SELECTED_MOWER,
    GET_MOWERS_PERI_REQUEST,
    GET_MOWERS_PERI_SUCCESS,
    GET_MOWERS_REQUEST,
    GET_MOWERS_SUCCESS,
    REMOVE_ALL_SELECTED_MOWERS,
    SAVE_CURRENT_MOWER_DATA,
    SAVE_FILTERED_MOWERS,
    SEND_MOWER_ACTION_REQUEST,
    SEND_MOWER_ACTION_SUCCESS,
} from "Redux/actionTypes";
import store from "../store";
import {mowerRef} from "../../fbconfig";
import {getMowerIds} from "../../Components/Sidebar";

export const getMowers = (selectedMowers) => async (dispatch) => {
    dispatch(getMowersRequest());
    const {mower: {mowers, selectedMowerId}} = store.getState();
    const {filter: {selectedUserMode}} = store.getState();
    let response = null;
    let data = [];

    if (selectedUserMode) {
        response = await mowersDataArrayBasedOnUser(selectedUserMode);
    } else {
        response = await mowersDataArray();
    }
    if (process.env.REACT_APP_DEMO_MODE === 'true') {
        if (selectedMowers?.length > 0) {
            let resMowers = [];
            let ids = getMowerIds(selectedMowers);
            for (let i = 0; i < ids.length; i++) {
                let index = mowers.findIndex(data => data.id === ids[i]);
                mowers.splice(index, 1);
            }
            for (let i = 0; i < ids?.length; i++) {
                const val = ids[i];
                const snapshot = await mowerRef.doc(val).get();
                const subRobot = {
                    ...snapshot.data(),
                    id: snapshot.id,
                };
                if (selectedMowerId && subRobot.id === selectedMowerId) {
                    dispatch(saveCurrentMowerData(subRobot));
                }
                resMowers.push(subRobot);
            }
            dispatch(clearSelectedMowers());
            dispatch(getMowersSuccess([...resMowers, ...mowers]));
        } else {
            for (let i = 0; i < response?.data?.length; i++) {
                const val = response?.data[i].id;
                const snapshot = await mowerRef.doc(val).get();
                const subRobot = {
                    ...snapshot.data(),
                    id: snapshot.id,
                };
                if (selectedMowerId && subRobot.id === selectedMowerId) {
                    dispatch(saveCurrentMowerData(subRobot));
                }
                data.push(subRobot);
            }
            dispatch(clearSelectedMowers());
            dispatch(getMowersSuccess(data));
        }
    } else {
        // eslint-disable-next-line array-callback-return
        response?.data.map((item) => {
            data.push(item);
            if (selectedMowerId && item.id === selectedMowerId) {
                dispatch(saveCurrentMowerData(item));
            }
        })
        dispatch(clearSelectedMowers());
        dispatch(getMowersSuccess(data));
    }
};

export const getPeripherals = () => async (dispatch) => {
    dispatch(getMowersPeriRequest());
    const data = await getMowerPeripherals();
    dispatch(getMowersPeriSuccess(data));
};


export const sendMowerAction = ({mower, type, item = {}}) => async (dispatch) => {
    dispatch(sendMowerActionRequest());
    const response = await sendActionAPI({mower, type, item});
    dispatch(sendMowerActionSuccess(response));
    return response;
};

export const sendDemoMowerAction = ({mower, type, item = {}}) => async (dispatch) => {
    dispatch(sendMowerActionRequest());
    const response = await sendDemoActionAPI({mower, type, item});
    dispatch(sendMowerActionSuccess(response));
    return response;
};


export const saveCurrentMowerData = createAction(SAVE_CURRENT_MOWER_DATA);

export const saveFilteredMowers = createAction(SAVE_FILTERED_MOWERS);

const getMowersRequest = createAction(GET_MOWERS_REQUEST);
export const getMowersSuccess = createAction(GET_MOWERS_SUCCESS);
export const allMowersNotifications = createAction(ALL_MOWERS_NOTIFICATIONS);

export const sendMowerActionRequest = createAction(SEND_MOWER_ACTION_REQUEST);
export const sendMowerActionSuccess = createAction(SEND_MOWER_ACTION_SUCCESS);

const getMowersPeriRequest = createAction(GET_MOWERS_PERI_REQUEST);
const getMowersPeriSuccess = createAction(GET_MOWERS_PERI_SUCCESS);

export const addSelectedMower = createAction(ADD_SELECTED_MOWER)
export const clearSelectedMowers = createAction(CLEAR_SELECTED_MOWER)
export const addAllSelectedMowers = createAction(ADD_ALL_SELECTED_MOWERS)
export const removeAllSelectedMowers = createAction(REMOVE_ALL_SELECTED_MOWERS)

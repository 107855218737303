import { handleActions } from "redux-actions";
import {getTagsOfMowers, getZipcodeOfMower} from "Utils/global";

import {
  ADD_ALL_SELECTED_MOWERS,REMOVE_ALL_SELECTED_MOWERS,
  ADD_SELECTED_MOWER, CLEAR_SELECTED_MOWER,
  GET_MOWERS_PERI_REQUEST,
  GET_MOWERS_PERI_SUCCESS,
  GET_MOWERS_REQUEST,
  GET_MOWERS_SUCCESS,
  SAVE_CURRENT_MOWER_DATA,
  SAVE_FILTERED_MOWERS,
  SEND_MOWER_ACTION_REQUEST,
  SEND_MOWER_ACTION_SUCCESS, ALL_MOWERS_NOTIFICATIONS,
} from "Redux/actionTypes";

const initialState = {
  inProgress: false,
  mowers: null,
  selectedMowerId: null,
  selectedMower: null,
  message: null,
  filteredMowers: [],
  filters: {
    status: [],
    model: [],
    manufacturer: [],
    schedule: [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ],
    zipcode: [],
    tags:[]
  },
  mowerPeripherals: [],
  selectedMowers:[],
  allMowersNotification: null
};

export default handleActions(
  {
    [GET_MOWERS_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      filters: {
        status: [],
        model: [],
        manufacturer: [],
        schedule: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
        zipcode: [],
        tags: []
      },
    }),
    [ALL_MOWERS_NOTIFICATIONS]: (state, payload) => {
      return {
        ...state,
        allMowersNotification: payload.payload
      }
    },
    [GET_MOWERS_SUCCESS]: (state, payload) => {
      const { payload: data } = payload;
      const abbreviatedIds = [];
      if (Array.isArray(data)) {
        for (let mower of data) {
          if (!state.filters.status.includes(mower.status)) {
            state.filters.status.push(mower.status);
          }
          if (!state.filters.model.includes(mower.model)) {
            state.filters.model.push(mower.model);
          }
          if (!state.filters.manufacturer.includes(mower.manufacturer)) {
            state.filters.manufacturer.push(mower.manufacturer);
          }
          // Use last 6 digits for manufacturer
          let numberDigits = 6;
          let abbreviatedId = "";
          do {
            abbreviatedId = mower.id.slice(-numberDigits);
            numberDigits += 1;
          } while (
            abbreviatedIds.includes(abbreviatedId) && numberDigits <= mower.id.length
          );
          abbreviatedIds.push(abbreviatedId);
          mower["abbreviatedId"] = abbreviatedId;
          // For zipcode
          const zipcode = getZipcodeOfMower(mower);
          if (zipcode && !state.filters.zipcode.includes(zipcode)) {
            state.filters.zipcode.push(zipcode);
          };
          const tags = getTagsOfMowers(mower);
          if(tags){
            for (let i = 0; i < tags?.length ; i++){
              if (tags && !state.filters.tags.includes(tags[i])) {
                state.filters.tags.push(tags[i]);
              };
            }
          }
        }
      } else {
        console.log("GET_MOWERS_REQUEST, data is not a array", data);
      }
      return {
        ...state,
        inProgress: false,
        mowers: data,
      };
    },
    [GET_MOWERS_PERI_REQUEST]: (state) => ({
      ...state,
      mowerPeripherals: [],
    }),
    [GET_MOWERS_PERI_SUCCESS] : (state, payload) => ({
      ...state,
      mowerPeripherals: payload.payload.data
    }),
    [SAVE_CURRENT_MOWER_DATA]: (state, payload) => {
      const { payload: data } = payload;
      return {
        ...state,
        message: null,
        selectedMower: data,
        selectedMowerId: data?.id ?? null,
      };
    },
    [SEND_MOWER_ACTION_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      message: null
    }),
    [SAVE_FILTERED_MOWERS]: (state,payload) => {
      const { payload: filteredMowers } = payload;
      return {
        ...state,
        ...filteredMowers
      };
    },
    [SEND_MOWER_ACTION_SUCCESS]: (state, payload) => {
      const { payload: { success, data, error } } = payload;
      let mess = "";
      if (success) {
        mess = data;
      } else {
        mess = error;
      }
      return {
        ...state,
        message: mess,
        inProgress: false,
      };
    },
    [ADD_SELECTED_MOWER]: (state,payload) => {
      const { payload: { id} } = payload;
      if (state.selectedMowers) {
        const tempIndex = state.selectedMowers.findIndex((item) => item.id === id)
        if (tempIndex === -1)
        {
          payload.payload.checked = true;
          state.selectedMowers.push(payload.payload)
        }else{
          state.selectedMowers.splice(tempIndex,1)
        }
      }else{
        payload.payload.checked = true;
        state.selectedMowers = [payload.payload]
      }
      return {
        ...state
      }
    },
    [ADD_ALL_SELECTED_MOWERS]:(state, payload) =>{
      if (state.selectedMowers.length>0)
      {
        for (const mow of payload.payload) {
          const found = state.selectedMowers.find(item => item.id === mow.id)
          if (!found)
          {
            mow.checked = true;
            state.selectedMowers.push(mow)
          }
        }
      }else
      {
        for (const item of payload.payload) {
          item.checked = true;
        }
        state.selectedMowers = [...payload.payload]
      }
      return {
        ...state
      }
    },
    [REMOVE_ALL_SELECTED_MOWERS]: (state, payload) => {
      for (let i = 0; i < state.selectedMowers.length; i++) {
        for (let j = 0; j < payload.payload.length; j++) {
          if (state.selectedMowers[i]?.id === payload.payload[j]?.id)
          {
            state.selectedMowers.splice(i,1);
          }
        }
      }
      return {
        ...state
      }
    },
    [CLEAR_SELECTED_MOWER]: (state) => {
      state.selectedMowers = []
      return {
        ...state
      }
    }
  },
  initialState
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react";
import {Dialog, DialogActions, Divider} from "@material-ui/core";
import styled from "styled-components";

import {CircularProgressComp} from "../Common";
import SuccessAnimation from "../SuccessAnimation";
import {ScheduleItem} from "./ScheduleItem";
import {useSelector} from "react-redux";
import {mowersService} from "../../Utils/mowerActions";
import {scrollBarStyles} from "../Common/styles";

import CloseImg from "../../Assets/images/x.png";
import DropdownArrow from "Assets/images/drop_down.png";
import UpArrow from "Assets/images/up_arrow.png";
import './scheduleDialog.css';


const manageDuplicates = (data, start, end, day ) => {
    if(Array.isArray(data)){
        let exist = false;
        let obj = {
            start: start,
            end: end,
            day: day
        };
        for(let i = 0; i < data.length ; i++) {
            if(data[i].start === obj.start && data[i].end === obj.end && data[i].day === obj.day ) {
                exist = true;
                break;
            }
        }
        return {
            exist : !exist,
            obj: obj
        }
    }
};

export const ScheduleDialog = ({robotName, tasks, result, isMultiple}) => {
    const {selectedMowers} = useSelector((state) => state.mower);
    const {selectedMowerId} = useSelector((state) => state.mower);
    const [selecting, setSelecting] = useState(false);
    const [selectionStartDay, setSelectionStartDay] = useState('');
    const [schedulesArray, setSchedulesArray] = useState({'SUN':[],'MON':[],'TUE':[],'WED':[],'THU':[],'FRI':[],'SAT':[]});
    const [tableCells, setTableCells] = useState([]);
    const [dayStart, setDayStart] = useState(-1);
    const [dayEnd, setDayEnd] = useState(-1);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [animationPopup, setAnimationPopup] = useState(false);

    const timeArray = ['12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM',
        '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM',
        '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'];

    const resetSchedules = () => {
        schedulesArray.SUN = [];
        schedulesArray.MON = [];
        schedulesArray.TUE = [];
        schedulesArray.WED = [];
        schedulesArray.THU = [];
        schedulesArray.FRI = [];
        schedulesArray.SAT = [];
    };

    useEffect(() => {
        const tempArray = [];
        resetSchedules();
        for (let i = 1; i < 8; i++) {
            let tempb = []
            for (let j = 0; j < 25; j++) {
                tempb.push({
                    id: j,
                    isSelected: false,
                    text: '',
                    start: false,
                    end: false
                });
            }
            if (!isMultiple) {
                tempb = getScheduledArray(i, tempb);
            }
            tempArray.push({
                start: -1,
                end: -1,
                slots: tempb,
                day: dayArray[i].day
            });
        }
        setTableCells(tempArray)
    }, [tasks])

    const getStartIndex = (value) => {
        return Math.round(value / 60);
    };

    const dayArray = useMemo(() => {
        return [
            {day: '', isSelected: false}, {day: 'SUN', isSelected: false}, {day: 'MON', isSelected: false},
            {day: 'TUE', isSelected: false}, {day: 'WED', isSelected: false}, {day: 'THU', isSelected: false},
            {day: 'FRI', isSelected: false}, {day: 'SAT', isSelected: false}
        ];
    }, [tasks]);

    const getScheduledArray = (index, tempArray) => {
        let newArray = tempArray;

        const scheduleStateSetter = (startValue, timeArray, durationValue) => {
            for (let i = startValue; i <= durationValue; i++) {
                newArray[i].isSelected = true;
                newArray[i].text = '';
                if (i === startValue) {
                    newArray[startValue].text = 'FROM ' + timeArray[startValue];
                    newArray[startValue].start = true;
                }
                if (i === durationValue) {
                    newArray[durationValue].text = 'TO ' + timeArray[durationValue];
                    newArray[durationValue].end = true;
                }
            }
            return [...newArray];
        };

        if (tasks && tasks.length > 0 ) {
            for (const task of tasks) {
                const startValue = getStartIndex(task.start);
                const durationValue = startValue + getStartIndex(task.duration);
                switch (index) {
                    case 1:
                        if (task.sunday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.SUN, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.SUN.push(res.obj)
                            }
                        }
                        break;
                    case 2:
                        if (task.monday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.MON, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.MON.push(res.obj)
                            }
                        }
                        break;
                    case 3:
                        if (task.tuesday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.TUE, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.TUE.push(res.obj)
                            }
                        }
                        break;
                    case 4:
                        if (task.wednesday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.WED, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.WED.push(res.obj)
                            }
                        }
                        break;
                    case 5:
                        if (task.thursday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.THU, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.THU.push(res.obj)
                            }
                        }
                        break;
                    case 6:
                        if (task.friday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.FRI, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.FRI.push(res.obj)
                            }
                        }
                        break;
                    case 7:
                        if (task.saturday) {
                            scheduleStateSetter(startValue, timeArray, durationValue);
                            let res = manageDuplicates(schedulesArray.SAT, timeArray[startValue],
                                timeArray[durationValue], dayArray[index].day);
                            if(res.exist){
                                schedulesArray.SAT.push(res.obj)
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        if (newArray.every((value) => value.isSelected === true)) {
            dayArray[index].isSelected = true;
        }
        return newArray;
    }
    const beginSelection = (column, index, parentIndex) => {
        if (!column.slots[index].isSelected) {
            setSelecting(true);
            column.start = index;
            tableCells[parentIndex].start = index;
            setTableCells(tableCells)
            setSelectionStartDay(column.day)
            updateSelection(index, column, parentIndex);
        }
    };

    const endSelection = (column, i) => {
        if (column.start !== i && !column.slots[i].isSelected && i > column.start && column.day === selectionStartDay) {
            column.slots[column.start].text = 'From ' + timeArray[column.start];
            column.slots[i].text = 'To ' + timeArray[i];
            let tempStart = column.slots[column.start].text.split(' ');
            column.slots[column.start].start = true;
            tempStart = tempStart[1] + ' ' + tempStart[2];
            let tempEnd = column.slots[column.end].text.split(' ');
            column.slots[column.end].end = true;
            tempEnd = tempEnd[1] + ' ' + tempEnd[2];
            for (let j = column.start; j <= column.end; j++) {
                column.slots[j].isSelected = true;
            }
            column.end = i;
            if (schedulesArray[column.day].length < 10) {
                schedulesArray[column.day].push({
                    start: tempStart,
                    end: tempEnd,
                    day: column.day
                })
            } else {
                alert("Cannot add more than 10 schedules");
                for (let j = column.start; j <= column.end; j++) {
                    column.slots[j].isSelected = false;
                    column.slots[j].text = '';
                }
                column.start = -1;
                column.end = -1;
                return
            }
            setSchedulesArray(schedulesArray)
        } else {
            for (const columnElement of tableCells) {
                columnElement.start = -1;
                columnElement.end = -1;
            }
        }
        setSelecting(false);
    };

    const updateSelection = (i, column, parentIndex) => {
        if (selecting) {
            column.end = i;
            tableCells[parentIndex].end = i;
            setTableCells([...tableCells]);
        }
    };

    const dataSetting = (dayIndex, i, selected, text) => {
        tableCells[dayIndex].slots[i].isSelected = selected;
        tableCells[dayIndex].slots[i].text = text;
        tableCells[dayIndex].slots[i].start = false;
        tableCells[dayIndex].slots[i].end = false;
    };

    const removeSchedule = (index, item) => {
        let startIndex = timeArray.findIndex(it => it === item.start);
        let endIndex = timeArray.findIndex(it => it === item.end);
        let dayIndex = dayArray.findIndex(day => day.day === item.day);
        dayArray[dayIndex].isSelected = false;
        if (--dayIndex !== -1) {
            if (endIndex === 0) {
                endIndex = 24;
            }
            for (let i = 0; i < tableCells[dayIndex].slots.length; i++) {
                if ((i >= startIndex && i <= endIndex)) {
                    dataSetting(dayIndex, i, false, '');
                }
            }
            tableCells[dayIndex].start = -1;
            tableCells[dayIndex].end = -1;
            schedulesArray[item.day].splice(index, 1);
            setSchedulesArray({...schedulesArray});
        }
    }

    const clearDay = (day) => {
        let dayIndex = dayArray.findIndex(item => item.day === day);
        dayArray[dayIndex].isSelected = false;
        if (--dayIndex !== -1) {
            for (let i = 0; i < tableCells[dayIndex].slots.length; i++) {
                dataSetting(dayIndex, i, false, '');
            }
            tableCells[dayIndex].start = -1;
            tableCells[dayIndex].end = -1;
            schedulesArray[day] = [];
            setSchedulesArray({...schedulesArray});
        }
    }

    const allDay = (day) => {
        let dayIndex = dayArray.findIndex(item => item.day === day);
        dayArray[dayIndex].isSelected = true;
        setSelecting(false);
        if (--dayIndex !== -1) {
            for (let i = 0; i < tableCells[dayIndex].slots.length; i++) {
                dataSetting(dayIndex, i, true, ' ');
                if (i === 0) {
                    tableCells[dayIndex].slots[i].text = 'From ' + timeArray[i];
                }
                if (i === 24) {
                    tableCells[dayIndex].slots[i].text = 'To ' + timeArray[i];
                }
            }
            schedulesArray[day] = [{
                start: timeArray[0],
                end: timeArray[24],
                day: tableCells[dayIndex].day
            }]
            setSchedulesArray({...schedulesArray});
        }
    }
    const errorSetter = (res) => {
        if(res?.data?.message?.response?.data?.error){
            if (res?.data?.message?.response?.data?.error?.toLowerCase().includes('when login to husqvarna: simultaneous logins')) {
                return 'Error when login to husqvarna: Simultaneous logins detected';
            }
            return res?.data?.message?.response?.data?.error
        }
        if(res?.data?.message){
            return res?.data?.message
        }
        if(res?.data?.error){
            return res?.data?.error
        }
    }
    const handleResult = async (data) => {
        let res = false;
        switch (data) {
            case "Cancel":
            case "close":
                result();
                break;
            case 'Confirm':
                setOpenProgressDialog(true)
                const finalArray = [...schedulesArray.MON, ...schedulesArray.TUE, ...schedulesArray.WED, ...schedulesArray.THU, ...schedulesArray.FRI, ...schedulesArray.SAT, ...schedulesArray.SUN];
                const tasksArray = [];
                for (let i = 0; i < finalArray.length; i++) {
                    let temp = {
                        "start": 0,
                        "duration": 0,
                        "monday": false,
                        "tuesday": false,
                        "wednesday": false,
                        "thursday": false,
                        "friday": false,
                        "saturday": false,
                        "sunday": false
                    }
                    temp.start = (timeArray.findIndex(time => time === finalArray[i].start) * 60);
                    temp.duration = (timeArray.findIndex(time => time === finalArray[i].end) * 60);
                    temp.duration = temp.duration - temp.start;
                    if (temp.duration === 0) {
                        temp.duration = 1440;
                    }
                    switch (finalArray[i].day) {
                        case 'MON':
                            temp.monday = true;
                            break;
                        case 'TUE':
                            temp.tuesday = true;
                            break;
                        case 'WED':
                            temp.wednesday = true;
                            break;
                        case 'THU':
                            temp.thursday = true;
                            break;
                        case 'FRI':
                            temp.friday = true;
                            break;
                        case 'SAT':
                            temp.saturday = true;
                            break;
                        case 'SUN':
                            temp.sunday = true;
                            break;
                        default:
                            break;
                    }
                    tasksArray.push(temp);
                }
                if (window.confirm("Are you sure you want to change the schedule?")) {
                    if (!isMultiple) {
                        res = await mowersService.scheduleMower(tasksArray, selectedMowerId);
                    } else {
                        res = await mowersService.scheduleMowers(tasksArray, selectedMowers);
                    }
                    setOpenProgressDialog(false);
                    if (res?.result) {
                        setAnimationPopup(true)
                        setTimeout(() => {
                            setAnimationPopup(false)
                            result('Confirm');
                        }, 2000)
                    } else {
                            window.alert(`Error: ${errorSetter(res)}`)
                    }
                }
                break;
            default:
                result();
        }
    }

    const beginDaySelection = (item, index) => {
        if (!item.isSelected) {
            setSelecting(true);
            setDayStart(index);
            updateDaySelection(index, item);
        }
    }
    const endDaySelection = (item, index) => {
        if (dayStart !== index && !item.isSelected) {
            for (let j = dayStart; j <= dayEnd; j++) {
                allDay(dayArray[j].day)
            }
            setDayEnd(index);
            setSelecting(false);
        }
    }
    const updateDaySelection = (item, index) => {
        if (selecting) {
            setDayEnd(index)
        }
    }
    const moveScheduleUp = (e, column, currentIndex, parentIndex, from) => {
        e.stopPropagation();
        let previousIndex = currentIndex - 1;

        const stateSetter = (start, key, startValue) => {
            tableCells[parentIndex].slots[previousIndex].isSelected = true;
            tableCells[parentIndex].slots[previousIndex].text = `${start ? 'FROM ' : 'TO '}` + timeArray[previousIndex];
            tableCells[parentIndex].slots[currentIndex][key] = false;
            tableCells[parentIndex].slots[currentIndex].text = '';
            tableCells[parentIndex].slots[previousIndex][key] = true;
            if(!start){
                tableCells[parentIndex].slots[currentIndex].isSelected = false;
            }

            const objectIndex = schedulesArray[tableCells[parentIndex].day].findIndex((schedule) => schedule[key] === startValue);
            if (objectIndex !== -1) {
                setTableCells([...tableCells]);
                schedulesArray[tableCells[parentIndex].day][objectIndex][key] = timeArray[previousIndex]
                setSchedulesArray({...schedulesArray})
            }
        };

        switch (from) {
            case 'FROM_START':
                if (!tableCells[parentIndex].slots[previousIndex].end) {
                    let startValue = tableCells[parentIndex].slots[currentIndex].text.split(' ');
                    startValue.shift();
                    startValue = startValue.join(' ');
                    stateSetter(true, 'start', startValue);
                }
                break;
            case 'FROM_END':
                if (!tableCells[parentIndex].slots[previousIndex].start) {
                    let startValue = tableCells[parentIndex].slots[currentIndex].text.split(' ');
                    startValue.shift();
                    startValue = startValue.join(' ');
                    stateSetter(false, 'end', startValue);
                }
                break;
            default:
                break;
        }
    };
    const moveScheduleDown = (e, column, currentIndex, parentIndex, from) => {
        let nextIndex = currentIndex + 1;
        e.stopPropagation();

        const stateSetter = (start, key, startValue) => {
            tableCells[parentIndex].slots[currentIndex][key] = false;
            tableCells[parentIndex].slots[currentIndex].text = '';
            tableCells[parentIndex].slots[nextIndex][key] = true;
            tableCells[parentIndex].slots[nextIndex].isSelected = true;
            tableCells[parentIndex].slots[nextIndex].text = `${start ? 'FROM ' : 'TO '}` + timeArray[nextIndex];
            if(start){
                tableCells[parentIndex].slots[currentIndex].isSelected = false;
            }
            const objectIndex = schedulesArray[tableCells[parentIndex].day].findIndex((schedule) => schedule[key] === startValue);
            if (objectIndex !== -1) {
                setTableCells([...tableCells]);
                schedulesArray[tableCells[parentIndex].day][objectIndex][key] = timeArray[nextIndex]
                setSchedulesArray({...schedulesArray})
            }
        };

        switch (from) {
            case 'FROM_START':
                if (!tableCells[parentIndex].slots[nextIndex].end) {
                    let startValue = tableCells[parentIndex].slots[currentIndex].text.split(' ');
                    startValue.shift();
                    startValue = startValue.join(' ');
                    stateSetter(true, 'start', startValue);
                }
                break;
            case 'FROM_END':
                if (!tableCells[parentIndex].slots[nextIndex].start) {
                    let startValue = tableCells[parentIndex].slots[currentIndex].text.split(' ');
                    startValue.shift();
                    startValue = startValue.join(' ');
                    stateSetter(false, 'end', startValue);
                }
                break;
            default:
                break;
        }
    };

    const footerActionButtons = () => (
        <DialogActions style={{marginRight: '12px'}}>
            <ActionButton onClick={() => handleResult('Cancel')} backgroundColor='#565659' marginRight={60}>
                Cancel
            </ActionButton>
            <ActionButton backgroundColor='#9DCA60' marginRight={42} onClick={() => handleResult('Confirm')}>
                Confirm
            </ActionButton>
        </DialogActions>
    );

    return <MainWrapper open={true} onClose={() => result('CLOSED')}
                        aria-labelledby="form-dialog-title" maxWidth="lg">
        <ParentContainer>
            <LeftSide>
                <RobotNameContainer>
                    <RobotName color='#E2E2E2'>{robotName}</RobotName>
                </RobotNameContainer>
                <ScheduleItem day='SUN' schedule={schedulesArray.SUN} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='MON' schedule={schedulesArray.MON} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='TUE' schedule={schedulesArray.TUE} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='WED' schedule={schedulesArray.WED} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='THU' schedule={schedulesArray.THU} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='FRI' schedule={schedulesArray.FRI} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
                <Divider />
                <ScheduleItem day='SAT' schedule={schedulesArray.SAT} remove={removeSchedule} clearAll={clearDay}
                              addAllDay={allDay}/>
            </LeftSide>
            <RightSide>
                <RowContainer marginLeft='10px'>
                    {dayArray.map((item, index) => {
                        return <>
                            {index !== 0 && <DayCell
                                             onMouseDown={() => beginDaySelection(item.day, index)}
                                             onMouseUp={() => endDaySelection(item.day, index)}
                                             onMouseMove={() => updateDaySelection(item.day, index)}
                                             textColor={item.isSelected ? '#E45525' : '#ffffff'}
                                             margin='10px' color='transparent'>
                                <div onClick={() => allDay(item.day)}> {item.day}</div>
                            </DayCell>
                            }
                            {index === 0 && <TimeCell margin='10px' width="48px" color='transparent'>{item.day}</TimeCell>}
                        </>
                    })}
                    <CloseButton>
                        <img onClick={() => handleResult('close')} src={CloseImg} alt="icon" width={18}/>
                    </CloseButton>
                </RowContainer>
                <RowContainer>
                    <ColumnContainer>
                        {timeArray.map((item, index) => {
                            return <TimeCell color='transparent' textColor='#FFFFFF'><p>{item}</p></TimeCell>
                        })}
                    </ColumnContainer>
                    {tableCells.map((column, parentIndex) => (
                        <ColumnContainer key={parentIndex}>
                            {column.slots.map((day, index) => {
                                return <TableCell key={index}
                                                  color={(day.text.length > 0 || day.isSelected) ? '#E45525' : '#363739'}
                                                  onMouseDown={() => beginSelection(column, index, parentIndex)}
                                                  onMouseUp={() => endSelection(column, index)}
                                                  onMouseMove={() => updateSelection(index, column, parentIndex)}
                                                  className={
                                                      (column.end <= index && index <= column.start) || (column.start <= index && index <= column.end) ? "selected" : ""
                                                  }>
                                    <ContentContainer>
                                        {index !== 0 && day.start && <ImageContainer margin="margin-right: 10px">
                                                <DownImage onClick={(e) => moveScheduleUp(e, column, index, parentIndex, 'FROM_START')}
                                                           src={UpArrow}/>
                                                <DownImage onClick={(e) => moveScheduleDown(e, column, index, parentIndex,'FROM_START')}
                                                           src={DropdownArrow}/>
                                            </ImageContainer>
                                        }
                                        <p>{day.text}</p>
                                        {index !== 24 && day.end && <ImageContainer margin="margin-left: 10px">
                                                <DownImage onClick={(e) => moveScheduleUp(e, column, index, parentIndex, 'FROM_END')}
                                                           src={UpArrow}/>
                                                <DownImage onClick={(e) => moveScheduleDown(e, column, index, parentIndex,'FROM_END')}
                                                           src={DropdownArrow}/>
                                            </ImageContainer>
                                        }
                                    </ContentContainer>
                                </TableCell>
                            })}
                        </ColumnContainer>
                    ))}
                </RowContainer>
                {footerActionButtons()}
            </RightSide>
            {animationPopup && <SuccessAnimation/>}
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
            }
        </ParentContainer>
    </MainWrapper>
}

const MainWrapper = styled(Dialog)`
    .MuiDialog-paperWidthLg {
        border-radius: 25px;
    };
`;
const ActionButton = styled.button`
  border-radius: 4px;
  width: 160px;
  margin-right: ${(props) => (props.marginRight)}px;
  height: 33px;
  cursor: pointer;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  border: none;
  background:  ${(props) => props.backgroundColor} ;
`;
const LeftSide = styled.div`
    flex: 20;
    background: #17181B;
    padding:24px 10px;
    min-height : 970px;
    height: fit-content;
`;
const RightSide = styled.div`
    flex: 80;
    background: #1F2125;
    padding: 24px 2px;
    display: flex;
    flex-direction: column;
    border-radius: 0px 35px 35px 0px;
`;
const TableCell = styled.button`
    background: ${props => props?.color};
    color: ${props => props?.textColor ?? '#ffffff'};
    margin: 3px;
    width: 110px;
    font-weight: bold;
    line-height: 11px;
    height: 27px;
    text-align: center;
    border-radius: 2px;
`;
const TimeCell = styled.div`
    background: ${props => props?.color};
    color: ${props => props?.textColor ?? '#ffffff'};
    margin: ${props => props?.margin ?? '3px'};
    width: ${props => props.width ?? "67px"};
    padding-top: 5px;
    font-weight: bold;
    height: 27px;
    text-align: end;
    border-radius: 2px;
    ${props => props.textColor === '#FFFFFF' && 'opacity : 20%'};
    > p {
        font-size: 12px;
        font-family: 'Roboto';
    }
`;
const DayCell = styled.div`
    background: ${props => props?.color};
    color: ${props => props?.textColor ?? '#ffffff'};
    margin: 3px;
    width: 118px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    height: 32px;
    text-align: center;
    border-radius: 2px;
`;
const ParentContainer = styled.div`
    display: flex;
    overflow: auto;
    ${scrollBarStyles}
`;
const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    align-content:flex-start;
    margin-right: 8px;
`;
const RowContainer = styled.div`
    display: flex;
    align-items:flex-start;
    align-content:flex-start;
    margin-right: ${props => props?.marginRight};
    margin-left: ${props => props?.marginLeft};
`;
const RobotNameContainer = styled.div`
    text-align: center;
    margin: 16px 8px;
    word-break: break-all;
    width: 150px;
`;
const RobotName = styled.div`
    color: ${props => props.color};
    font-size: 13px;
    font-weight: bold;
    line-height: 17px;
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0px 12px;
  cursor: pointer;
`;
const DownImage = styled.img`
  width: 10px;
  height: 8px;
  margin: 3px;
  cursor: pointer;
`;
const ImageContainer = styled.span`
    display: flex;
    flex-direction: column;
    ${props => props.margin};
`;
const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: 'Circular-Loom';
    justify-content: center;
    padding: 0px 4px;
`;

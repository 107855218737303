import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import Dropdown from "../Dropdown/Dropdown";
import Input from "../Base/Input";
import {ActionButtonComponent, validateEmail} from "../Common";
import {LeftDiv, RightDiv, ErrorDiv, Header, Wrapper, MainWrapper} from "../Common/styles";
import {roleOptions, statusOptions} from "../../Contants";

import Error from "../../Assets/images/error.png";
import Delete from "../../Assets/images/delete.png";

const AddUser = ({selectedUser, onClose, onAction}) => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [role, setRole] = useState("");
    const [status, setStatus] = useState("");

    const setDataState = () => {
        setUsername(selectedUser?.name ?? "");
        setEmail(selectedUser?.email ?? "");
        setRole(selectedUser?.role ?? "");
        setStatus(selectedUser?.status ?? "");
    }

    useEffect(() => {
        if(selectedUser){
            setDataState();
        }
    },[selectedUser]) // eslint-disable-line

    const confirmedAction = () => {
        if(username?.trim() === '' || username === null){
            setUsername(null);
            return;
        }
        if(email){
            if(!validateEmail(email)){
                setInvalidEmail(true);
                return;
            }
        } else {
            setEmail(null);
            return;
        }
        if(!role){
            setRole(null);
            return;
        }
        if(!status){
            setStatus(null);
            return;
        }
        let data = {
            name: username,
            email: email,
            role: role,
            status: status
        }
        if(selectedUser){
            Object.assign(data, {
                "id": selectedUser?.id,
            })
            onAction(data, "update");
        } else {
            onAction(data, "add");
        }
    }

    const emailSetter = (val) => {
        setEmail(val);
        setInvalidEmail(false);
    }

    const inputComponent = (val, label, type, onChange ) => {
        return <Input
            paddingBottom={false}
            label={label}
            required
            placeholder={label}
            type={type}
            error={val === null}
            value={val}
            onChange={onChange}
            width="94%"
        />
    }

    const headerComponent = () => {
        return <>
            {selectedUser ?
                <Flex>
                    <div className="heading">
                        {`Edit ${selectedUser?.name}`}
                    </div>
                    <div className="deleteButton" onClick={() => onAction(selectedUser, "delete")}>
                        <img src={Delete} alt="delete" />
                    </div>
                </Flex>
                :
                <div className="heading">
                    Add New User
                </div>
            }
        </>
    }
    const dropdownComponent = (sel, label, onSel, options) => {
        return <Dropdown
            label={label}
            hideHeading={true}
            onSelect={(val) => onSel(val)}
            selected={sel} options={options} error={sel === null}
        />
    }
    const inputErrorComp = (text , isEmail) => (
        <ErrorDiv>
            <div className='p'>
                {isEmail ?
                    email === null ? "Email is required field" : "Invalid email address"
                    :
                    text
                }
            </div>
            <ErrorImageWrapper>
                <img src={Error} alt="icon" width={18}/>
            </ErrorImageWrapper>
        </ErrorDiv>
    )

    return (
        <MainWrapper>
            <LeftDiv />
            <RightDiv>
                <Wrapper>
                    <Header>
                        {headerComponent()}
                    </Header>
                    <div>
                        <InputWrapper>
                            {inputComponent(username, "User Name", "text", setUsername)}
                            <ErrorWrapper>
                                {username === null &&
                                    inputErrorComp("User Name is required field", false)
                                }
                            </ErrorWrapper>
                        </InputWrapper>
                        <InputWrapper>
                            {inputComponent(email, "Email", "text", (val) => emailSetter(val) )}
                            <ErrorWrapper>
                                {(email === null || invalidEmail === true) &&
                                    inputErrorComp("", true)
                                }
                            </ErrorWrapper>
                        </InputWrapper>
                        <DropdownCompWrapper>
                            <DropdownWrapper>
                                <div className="heading">
                                    Role  <RequiredIcon>*</RequiredIcon>
                                </div>
                                <div className="dropdown">
                                    {dropdownComponent(role, "Role", setRole, roleOptions)}
                                </div>
                            </DropdownWrapper>
                            <DropdownWrapper>
                                <div className="heading">
                                    Status  <RequiredIcon>*</RequiredIcon>
                                </div>
                                <div className="dropdown">
                                    {dropdownComponent(status, "Status", setStatus, statusOptions)}
                                </div>
                            </DropdownWrapper>
                        </DropdownCompWrapper>
                    </div>
                    <ActionButtonComponent close={onClose} action={confirmedAction} />
                </Wrapper>
            </RightDiv>
        </MainWrapper>
    );
};

const Flex = styled.div`
    display: flex;
`;
const InputWrapper = styled.div`
    margin-top: 10px;
`;
const ErrorWrapper = styled.div`
    height: 40px;
`;
const ErrorImageWrapper = styled.div`
    display: block;
    width: 6%;
    margin: auto 20px;
`;
const DropdownCompWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;
const DropdownWrapper = styled.div`
    width: 50%;
    .heading {
        font-weight: bold;
        margin-left: 6%;
        margin-bottom: 5px;
        font-size: 13px;
    };
    .dropdown{
        width: 95%;
    };
`;
const RequiredIcon = styled.span`
    color: #CE4F25;
    margin-left: 5px;
`;

export default AddUser;
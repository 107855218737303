import React from "react";
import RedHouse from '../Assets/images/redHouse.png';
import GreenHouse from '../Assets/images/greenHouse.png';

export const RED_COLOR = "red";
export const GREEN_COLOR = "green";

const REACT_APP_AVIATOR_API_URL = process.env.REACT_APP_AVIATOR_API_URL;
const MODE_COLOR_MAP = {
  DEMO: RED_COLOR,
  SECONDARY_AREA: RED_COLOR,
  HOME: RED_COLOR,
  UNKNOWN: RED_COLOR,
};

const ACTIVITY_COLOR_MAP = {
  MOWING: GREEN_COLOR,
  GOING_HOME: GREEN_COLOR,
  CHARGING: GREEN_COLOR,
  LEAVING: GREEN_COLOR,
  PARKED_IN_CS: GREEN_COLOR,
};

const STATE_COLOR_MAP = {
  UNKNOWN: RED_COLOR,
  NOT_APPLICABLE: RED_COLOR,
  PAUSED: RED_COLOR,
  IN_OPERATION: GREEN_COLOR,
  WAIT_UPDATING: GREEN_COLOR,
  WAIT_POWER_UP: GREEN_COLOR,
  RESTRICTED: RED_COLOR,
  OFF: RED_COLOR,
  STOPPED: RED_COLOR,
  ERROR: RED_COLOR,
  FATAL_ERROR: RED_COLOR,
  ERROR_AT_POWERUP: RED_COLOR,
};

export const batteryErrorLifted = "Battery Error: Lifted";
export const errorNoDrive = "Robot Error: No drive";
export const mowerNormalOperationCondition = (mower) => {
  return (
      (mower.activity === "PARKED_IN_CS" || mower.mower_activity === "PARKED_IN_CS") &&
      (mower.error_code === 0 || mower.error_code === "0") &&
      (mower.mode === "HOME" || mower.mower_mode === "HOME") &&
      (mower.status === "RESTRICTED" || mower.mower_state === "RESTRICTED")
  );
}
export const checkNeedAttentionCondition = (notifications) => {
  for (let i =0 ; i < notifications?.length; i++)
    if( notifications[i] === batteryErrorLifted || notifications[i] === errorNoDrive){
      return true;
    }
  return false;
}
export const getHouseStatus = (house,  notification = null) => {
  let statuses = [];
  if(Array.isArray(house.mowers)){
    for(let i = 0 ; i < house.mowers.length ; i++){
      let status = getStatusColor(house.mowers[i], notification);
      statuses.push(status);
    }
  }
  for(let i = 0 ; i < statuses.length ; i++){
    if(statuses[i] === RED_COLOR){
      return RED_COLOR
    }
  }
  return GREEN_COLOR;
}
export const houseImgSetter = (val) => {
  if(getHouseStatus(val) === RED_COLOR ){
    return RedHouse;
  } else {
    return GreenHouse;
  }
}
export const getStatusColor = (mower, notification = null) => {
  const mode = mower.mode || mower.mower_mode;
  const activity = mower.activity || mower.mower_activity;
  const status = mower.status || mower.mower_state;
  const normalOprGroupCondition = mowerNormalOperationCondition(mower);
  let needAttentionCondition = null
  if(notification){
    needAttentionCondition = checkNeedAttentionCondition(notification);
  }

  if( notification && needAttentionCondition){
    return RED_COLOR;
  }
  if(normalOprGroupCondition){
    return GREEN_COLOR;
  }
  if (MODE_COLOR_MAP[mode]) {
    return MODE_COLOR_MAP[mode];
  }
  if (ACTIVITY_COLOR_MAP[activity]) {
    return ACTIVITY_COLOR_MAP[activity];
  }
  if (STATE_COLOR_MAP[status]) {
    return STATE_COLOR_MAP[status];
  }
  return RED_COLOR;
};

export const getStatusName = (mower, notification = []) => {
  const color = getStatusColor(mower, notification);
  switch (color) {
    case RED_COLOR:
      return "Fault";
    case GREEN_COLOR:
      return "Ok";
    default:
      return;
  };
};

export const getSchedule = (mower) => {
  const daysInWeek = [
    "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday",
  ];
  let weekInitials = ["S","M","T","W","T","F","S"];
  let dayArray = weekInitials.map((val) => {
    return <span style={{color: "grey"}}>{val}</span>
  })

  const calendarTasks = mower.calendar_tasks;
  if (calendarTasks?.length > 0) {
    for (const calendarTask of calendarTasks) {
      for (let day of daysInWeek) {
        let text = "_";
        if (calendarTask[day]) {
          text = day[0].toUpperCase();
        };
        if (text !== '_')
        {
          switch (day) {
            case 'sunday':
              dayArray[0] = <span>{text}</span>
              break;
            case 'monday':
              dayArray[1] = <span>{text}</span>
              break;
            case 'tuesday':
              dayArray[2] = <span>{text}</span>
              break;
            case 'wednesday':
              dayArray[3] = <span>{text}</span>
              break;
            case 'thursday':
              dayArray[4] = <span>{text}</span>
              break;
            case 'friday':
              dayArray[5] = <span>{text}</span>
              break;
            case 'saturday':
              dayArray[6] = <span>{text}</span>
              break;
            default:
              break;
          }
        }
      };
    }
  };
  return dayArray;
};

export const getZipcodeOfMower = (mower) => {
  if (!mower.location) {
    return null;
  }
  return mower.location?.zip_code?.toString();
};

export const getTagsOfMowers = (mower) => {
  if (mower?.tags){
    return mower.tags;
  } else {
    return null
  }
}


export const getHusqvarnaSearchUrl = (searchText) => {
  return `https://husqvarna.custhelp.com/app/answers/list/kw/${searchText}/search/1`;
};

export const getConsumerUrl = (id, item) => {
  let consumerUrl = `${REACT_APP_AVIATOR_API_URL}/customers/${id}/jobs/new?new_fleet_job=1`;
  if (item) {
    consumerUrl += `&item=${item}`;
  }
  return consumerUrl;
};

export const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');
export const webAddressRegex = new RegExp('(https?:\\/\\/)?\\w+(\\.\\w+)+(\\/\\w+)*(\\/\\w+\\.\\w+)?(\\?[\\w%&=.]*)*(?=[^\\w.?&%=])');

export const getHusqvarnaModel = (mower) => {
  return mower.model.replace("HUSQVARNA AUTOMOWER®", "").trim().toLowerCase();
};

export const removeNullOrUndefined = (data) => {
  for (const element in data) {
    data[element] = Object.entries(data[element]).reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
  }
  return data;
}

export const setStructure = (data) => {
  let structuredData = [];
  for (let i = 0; i < data.length ; i++) {
      let obj = {
        ...data[i],
        "name": data[i]?.system_data?.name,
        "model": data[i]?.system_data?.model,
        "serial_number": data[i]?.system_data?.serialNumber,
      }
    structuredData.push(obj);
  }
  return structuredData;
}

export const checkForManualModels = (mower) => {
 if(mower?.model){
   const modelName = mower.model?.replace("HUSQVARNA AUTOMOWER® ", "");
   return !(modelName === '315X' || modelName === '115H');
 }
}

export const getMowerModel = (mower) => {
  if(mower?.manufacturer && mower?.model){
    if(mower.manufacturer === "husqvarna"){
      return mower.model.replace("HUSQVARNA AUTOMOWER® ", "")
    } else if (mower.manufacturer === "nexmow"){
      return mower.model
    } else {
      return "N/A"
    }
  } else {
    return "N/A"
  }
}

export const calenderTasksNullCase = (color) => {
  return <div style={{color: color ?? "grey"}}>SMTWTFS</div>
}

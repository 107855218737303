export const NotificationHeaderCols = [
  {
    Header: "",
    accessor: "col1",
  },
  {
    Header: "Details",
    accessor: "col2",
  },
  {
    Header: "Time",
    accessor: "col3",
  },
  {
    Header: "",
    accessor: "col4",
  },
]


export const MaintenancePeripheralsHeaderCols = [
  {
    Header: "",
    accessor: "col1",
  },
  {
    Header: "Wear Item",
    accessor: "col2",
  },
  {
    Header: "Remaining",
    accessor: "col3",
  },
  {
    Header: "Action",
    accessor: "col4",
  },
  {
    Header: "Replaced",
    accessor: "col5",
  },
]

export const MaintenanceHistoryPeripheralsHeaderCols = [
  {
    Header: "Item",
    accessor: "col1",
  },
  {
    Header: "Replaced Date",
    accessor: "col2",
  },
  {
    Header: "Technician",
    accessor: "col3",
  },
]

export const ExportRouteHeaderCols = [
  {
    Header: "Sequence",
    accessor: "col1",
  },
  {
    Header: "Address",
    accessor: "col2",
  },
  {
    Header: "Customer Name",
    accessor: "col3",
  },
  {
    Header: "Robot Instance",
    accessor: "col4",
  },
]

export const toggleOptions = [
  { id: 2, value: "consumables", label: "Consumables" },
  { id: 1, value: "mow_time", label: "Mow Time" },
  { id: 3, value: "history", label: "History" }
];

export const NotificationToggleOptions = [
  { id: 2, value: "unread", label: "Unread" },
  { id: 1, value: "unique", label: "Unique" },
  { id: 3, value: "all", label: "All" }
];

export const size_userManagement = "15px";
export const tooltipHeading = "Selecting a 'User View' will allow you to see the same  screens " +
    "as though you were that user.";
export const errorArray = {
  name: "Error: Invalid string",
  email: "Error: Invalid email",
}
export const roleOptions = [
  { id: 1, text: "owner" },
  { id: 2, text: "manager" },
  { id: 3, text: "technician" },
];

export const statusOptions = [
  { id: 1, text: "active" },
  { id: 2, text: "inactive" },
];

export const startOptions = [
  {
    "value":"ResumeSchedule",
    "name":"Resume according to schedule"
  },
  {
    "value":"Start",
    "name":"Start override schedule for duration specified"
  }
];

export const parkOptions = [
  {
    "value":"ParkUntilNextSchedule",
    "name":"Park mower until next scheduled run"
  },
  {
    "value":"ParkUntilFurtherNotice",
    "name":"Park mower until further notice, overriding schedule"
  },
  {
    "value":"Park",
    "name":"Park mower for a duration of time, overriding schedule"
  }
];

export const toggleOptionsNotifications = [
  { id: 1, value: "recent", label: "Recent" },
  { id: 2, value: "unique", label: "Unique" },
];
export const ItemPerPage = 5;

export const NotificationPerPage = 15;

export const DefaultPage = 1;

export const cuttingHeightDialogDesc = "The cutting height can be varied from MIN to MAX. If the grass is too long let " +
    "the robotic lawnmower start mowing at the MAX cutting height. Once the grass is shorter,you can gradually lower it " +
    "to the target cutting height."

export const MAX_RECENT_NOTIFICATIONS = 100;

export const MAX_UNIQUE_NOTIFICATIONS = 10;

export const manufacturerOptions = [
  {id: 1, value: "Husqvarna", label: "Husqvarna", caps: "HUSQVARNA", needCredential: true},
  {id: 2, value: "NexMow", caps: "NEXMOW", label: "NexMow"},
  {id: 3, value: "Echo", caps: "ECHO", label: "Echo"},
  {id: 4, value: "Worx", caps: "WORX", label: "Worx"},
  {id: 5, value: "Robomow", caps: "ROBOMOW", label: "Robomow"},
];

export const ManufacturerDescription = "Please select the manufacturer";

export const defaultRemaining = {
  Battery: 500,
  Blades: 1500,
  Wheels: 2000
};

export const maxAllowedMowersOnRoute = 24;
import React, { useRef } from "react";
import styled from "styled-components";

import arrowLeft from "Assets/images/arrowLeft.png";
import arrowRight from "Assets/images/arrowRight.png";

const Pagination = ({ page, count, onPageChange }) => {
  const TOTAL_PADDING = 14;
  const ITEM_WIDTH = 30;
  const pages = Array.from({ length: count }, (_, i) => i + 1);
  const container = useRef(null);

  const onPageClick = (event) => {
    const curPage = parseInt(event.target.innerText);
    onPageChange(curPage);
  }

  const handleNextPage = () => {
    if (page < count) {
      onPageChange(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const getShowedPages = () => {
    if (count <= 1) {
      return [];
    }
    if (!container || !container.current) {
      return pages;
    }
    const numberItems = Math.floor((container.current.offsetWidth - TOTAL_PADDING ) / ITEM_WIDTH);
    const numberNavItems = page === 1 || page === count ? 1 : 2;
    const numberPages = numberItems - numberNavItems;
    let startPageIndex = page - 1;
    if (page >= count - numberPages + 1) {
      startPageIndex = count - numberPages;
    }
    return pages.slice(startPageIndex, startPageIndex + numberPages);
  };

  return (
    <div ref={container} style={{ paddingLeft: 14, fontFamily: "sans-serif" }}>
      {page > 1 && <NavItem src={arrowLeft} alt="prev" onClick={handlePrevPage} />}
      {getShowedPages().map((i) => {
        return <PageItem key={i} selected={page === i} onClick={onPageClick}>{i}</PageItem>;
      })}
      {page < count && <NavItem src={arrowRight} alt="next" onClick={handleNextPage} />}
    </div>
  )
};

const NavItem = styled.img`
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 12px;
  margin-left: 2px;
`;

const PageItem = styled.div`
  display: inline-block;
  width: ${(props) => props.theme.spacing[4]}px;
  height: ${(props) => props.theme.spacing[4]}px;
  color: ${(props) => props.theme.color.grey[300]};
  opacity: ${(props) => props.selected ? 1 : 0.4};
  font-size: 12px;
  letter-spacing: 0;
  line-height: ${(props) => props.theme.spacing[4]}px;
  text-align: center;
  margin-right: ${(props) => props.theme.spacing[2]}px;
  border-radius: 13.5px;
  background-color: ${(props) => props.selected ? "rgba(253,252,253,0.15)" : ""} ;
  cursor: pointer;
`;

export default Pagination;
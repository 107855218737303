import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import GoogleMapReact from "google-map-react";

import {Container, MapActionButton, Marker, PositionBar} from "../../Pages/Home/Map";
import {getHouseStatus, getStatusColor, GREEN_COLOR, houseImgSetter} from "../../Utils/global";
import {setPropertyMode, setSelectedHouse, setSelectedMower} from "../../Redux/actions/propertActions";
import {imageSetter} from "../Sidebar/SidebarItem";
import MapIcon from "Assets/images/mower-icon.png";
import styled from "styled-components";

const DefaultPoint = { lat: 32.7091351, lng: -96.6963022 };

const getHouseLocation = (house) => {
    if (!house || !house.lat || !house.lng) {
        return null;
    }
    return {
        id: house?.id ?? 1,
        lat: parseFloat(house.lat),
        lng: parseFloat(house.lng),
        house_obj: house,
    };
};
const getMowerLocation = (mower) => {
    if (!mower || !mower?.location?.lat || !mower?.location?.lng) {
        return null;
    }
    return {
        id: mower?.id ?? 1,
        lat: parseFloat(mower?.location.lat),
        lng: parseFloat(mower?.location.lng),
        ...mower,
    };
};

const HouseMarker = ({ house, notifications, onClick }) => (
    <div>
        <Marker errStatus={getHouseStatus(house, notifications)}>
            <img src={houseImgSetter(house)} alt="house" width={40} onClick={onClick} />
        </Marker>
    </div>
);

const MowerMarker = ({ mower, notifications,  onClick, selected }) => (
    <div>
       <MarkerWrapper selected = {selected?.id === mower?.id} errStatus={getStatusColor(mower,notifications)}>
           <Marker selected={selected?.id === mower?.id ?? null} errStatus={getStatusColor(mower,notifications)}>
               <img src={imageSetter(mower)} alt="mower" width={40} onClick={onClick} />
           </Marker>
       </MarkerWrapper>
    </div>
);

const PropertyMap = () => {
    const {selectedMower, selected , filteredHouses} = useSelector((state) => state.property);
    const { allMowersNotification } = useSelector((state) => state.mower);
    const [myMap, setMyMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const dispatch =  useDispatch();

    const propertyViewToggle = () => {
        dispatch(setPropertyMode(false));
    }

    const showedHouses = useMemo(() => {
        const m = [];
        if (!filteredHouses) {
            return m;
        }
        filteredHouses.forEach((e) => {
            if (getHouseLocation(e)) {
                m.push(e);
            };
        });
        return m;
    }, [filteredHouses]);

    const mapCenter = useMemo(() => {
        if (selected) {
            return getHouseLocation(selected);
        } else if (showedHouses.length > 0) {
            let latSum = 0;
            let lngSum = 0;
            showedHouses.forEach((house) => {
                latSum += parseFloat(house.lat);
                lngSum += parseFloat(house.lng);
            });
            return {
                lat: latSum / showedHouses.length,
                lng: lngSum / showedHouses.length,
            };
        }
        return null;
    }, [selected, showedHouses]);

    useEffect(() => {
        if (!myMap || !googleMaps || !filteredHouses || filteredHouses.length === 0) {
            return;
        };
       if(!selected){
           const lats = filteredHouses.map(m => m.lat);
           const lngs = filteredHouses.map(m => m.lng);
           const bounds = new googleMaps.LatLngBounds(
               new googleMaps.LatLng(Math.min(...lats), Math.min(...lngs)),
               new googleMaps.LatLng(Math.max(...lats), Math.max(...lngs)),
           );
           setTimeout(() => {
               myMap.fitBounds(bounds);
           },250)
       }

    }, [myMap, googleMaps, filteredHouses, selected]);

    return (
        <Container>
            {selected && <PositionBar title={selected.address}>{selected.address}</PositionBar>}
            <MapActionButton
                className="map-mode"
                active={false}
                top={selected ? 60 : 10}
                right={240}
                onClick={propertyViewToggle}
                icon={MapIcon}
                toolTipTitle="Mowers View"
            />
            { selected ?
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                    defaultCenter={DefaultPoint}
                    center={mapCenter}
                    zoom={18}
                    yesIWantToUseGoogleMapApiInternals={true}
                >
                    {Array.isArray(selected?.mowers) && selected?.mowers?.map((obj, index) => {
                        let loc = getMowerLocation(obj);
                        if(!loc?.lat && !loc?.lng) return null
                        return (
                            <MowerMarker
                                selected={selectedMower}
                                key={index}
                                lat={loc.lat}
                                lng={loc.lng}
                                mower={obj}
                                notifications={allMowersNotification}
                                onClick={() => {
                                    dispatch(setSelectedMower(obj));
                                }}
                            />
                        )
                    })
                    }
                </GoogleMapReact>
                :
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
                    defaultCenter={DefaultPoint}
                    center={mapCenter}
                    zoom={3}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => {
                        setMyMap(map);
                        setGoogleMaps(maps);
                    }}
                >
                    {Array.isArray(filteredHouses) && filteredHouses?.map((obj, index) => {
                        let loc = getHouseLocation(obj);
                        if(!loc?.lat && !loc?.lng) return null
                        return (
                            <HouseMarker
                                key={index}
                                lat={loc.lat}
                                lng={loc.lng}
                                house={obj}
                                notifications={allMowersNotification}
                                onClick={() => {
                                    dispatch(setSelectedHouse(obj));
                                }}
                            />
                        );
                    })
                    }
                </GoogleMapReact>

            }
        </Container>
    );
};

const MarkerWrapper = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.selected && `box-shadow: 0px 0px 20px 20px rgb(${props.errStatus === GREEN_COLOR ? `129 166 82` : `240 83 72`} / 60%);`}
`;
export default PropertyMap;
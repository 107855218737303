import styled from "styled-components";

export const scrollBarStyles = `
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    };
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.11);
        border-radius: 5px;
    };
    &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
    };
`;

export const LeftDiv = styled.div`
    position: absolute;
    z-index: 9;
    left: 0px;
    background-color: #101010;
    opacity: 0.7;
    width: 50%;
    height: inherit;
`;

export const RightDiv = styled.div`
    position: absolute;
    z-index: 9;
    right: 0px;
    background-color: #1F2125;
    width: 51%;
    height: inherit;
`;

export const Wrapper = styled.div`
  padding: 10px 40px;
  color: white;
`;

export const DialogButton = styled.button`
  border-radius: 4px;
  cursor: pointer;
  ${props => props.margin};
  outline: none !important;
  font-size: 15px;
  color: white;
  font-weight: bold;
  padding: 10px 50px 8px;
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  background: ${(props) => `${props.color ?? 'white'}`};
  :focus {
    background: ${(props) => props.theme.grey};
  }
`;

export const ErrorDiv = styled.div`
    height: inherit;
    display: flex;
    justify-content:space-between;
    width: 90%;
    color: #dd3737;
    border-radius: 5px;
    background-color: rgba(236,77,78,0.08);
    .p {
         padding: 10px 10px;
    };
    .imgWrapper{
        display: block;
        width: 6%;
        margin: auto 20px;
    }
`;

export const Header = styled.div`
    width: 100%;
    padding: 22px 0px 20px 0px;
    position: relative;
    justify-content: space-between;
    .heading {
        align-self: center;
        font-size: 20px;
        font-weight: bold; 
        width: 83%;
    }
    .deleteButton {
        margin-top: 7px;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }
`;

export const MainWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: ${props => props.manufact ? "535px" : "545px"};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    width: 90%;
    padding-left: 40px;
    justify-content: space-between;
    position: absolute;
    bottom: 7%;
`;

export const CloseDiv = styled.div`
    width: 100%;
    text-align: end;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 0px 40px;
    padding-right: 80px;
    padding-bottom: 10px;
    position: relative;
    justify-content: space-between;
    .heading {
        font-size: 22px;
        font-weight: bold; 
    };
    .addButton {
        margin-top: 7px;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    };
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ActionButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: ${props => props.justify};
`;

export const InputRow = styled.div`
  display: flex;
  .img{
    width: 14px;
    height: 14px;
    margin-right: 10px;
  };
`;

export const InputField = styled.input`
   border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1F2125;
  color: white;
  font-family: Roboto;
  width: -webkit-fill-available;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  &:focus {
        outline: none;
    }
`;

export const SearchInputWrapper = styled.div`
  border-bottom: 1px solid white;
  padding-bottom: 10px;
`;

export const ManufactureSelection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  
  .heading{
    color: #E2E2E2;
    opacity: 30%;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.4px;
  };
  .clear {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    color: #E45525;
    letter-spacing: 0.4px;
  };
`;

import React, { useContext } from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

import { saveCurrentMowerData } from "Redux/actions/mowerAction";
import {getStatusColor} from "Utils/global";
import { DeviceContext } from "Context/DeviceContext";
import StatusPoint from "Components/Base/StatusPoint";

import OnlineIcon from "Assets/images/wifi.png";
import OfflineIcon from "Assets/images/no-wifi.png";
import CheckIcon from "Assets/images/ico-check.png";
import UnCheckIcon from "Assets/images/ico-uncheck.png";
import Repair from "../../Assets/images/repair.png";
import OrangeParkingIcon from "../../Assets/images/Orangeparking.png";
import ParkingIcon from "../../Assets/images/parking.png";
import OffIcon from "../../Assets/images/OFF.png";
import ChargeIcon from "Assets/images/charge.png";
import MowingIcon from "Assets/images/mowing.png";
import Husqvarna_MowerImg from "Assets/images/automowers/husqvarna/default.png";

export const imageSetter = (mower) => {
  try{
    const src = require(`../../Assets/images/automowers/${mower.manufacturer?.toLowerCase()}/default.png`);
    return src.default;
  }
  catch(err){
    return Husqvarna_MowerImg
  }
}

export const getMowerStatusIcon = (mow, allMowersNotification) => {
  const notificationType = ["Mowing", "Parked", "Charging", "Mower Off", "Parked until further notice"];
  if(allMowersNotification && notificationType.includes(allMowersNotification?.[mow.id]?.[0]) ) {
    switch (allMowersNotification?.[mow.id]?.[0]) {
      case "Mowing":
        return MowingIcon;
      case "Parked":
        return ParkingIcon;
      case "Parked until further notice":
        return OrangeParkingIcon;
      case "Charging":
        return ChargeIcon;
      case "Mower Off":
        return OffIcon;

      default:
        return null;
    }
  }
}

const SidebarItem = ({
  isCollapsed,
  notifications = [],
  mower,
  onSelect,
  selected,
  isRouteMode,
  routeNum,
  mowerChecked,
  checked,
}) => {
  const {allMowersNotification} = useSelector((state) => state.mower);
  const { smallDevice } = useContext(DeviceContext);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(saveCurrentMowerData(mower));
    onSelect(mower);
  };
  const checkClick = () => {
    mowerChecked(mower);
  };

  const getMaintenanceStr = () => {
    let s = "";
    if (mower.peripherals && Array.isArray(mower.peripherals)) {
      mower.peripherals.forEach((peri) => {
        if (peri.remaining < 0) {
          s += `Replace ${peri.item}\n`;
        }
      });
    }
    return s;
  };

  if (smallDevice && isCollapsed) {
    return (
      <MobileCard onClick={() => mower.instance ? handleClick() : false} selected={selected}>
        {mower.instance ?
            <img alt="mower" src={imageSetter(mower)} />
            :
            <>N/A</>
        }
        <CollapsedErrStatusPoint
          top={1}
          left={3}
          errStatus={getStatusColor(mower)}
         />
        {isRouteMode && routeNum > -1 && <Badge>{routeNum}</Badge>}
      </MobileCard>
    );
  }

  if (!isCollapsed) {
    return (
      <MainRow instance={mower.instance ?? "N/A"}>
        <CheckedDiv onClick={() => mower.instance ? checkClick() : false}>
          <CheckedImage src={checked ? CheckIcon : UnCheckIcon} />
        </CheckedDiv>
        <ItemDiv onClick={() => mower.instance ? handleClick() : false}>
          <Card selected={selected} instance={mower.instance ?? "N/A"}>
            <RowLeftAlign>
              <StatusPoint
                  color={getStatusColor(mower, notifications)}
                  title={getMaintenanceStr()}
              />
              <NameLabel>{mower.name}</NameLabel>
              <Label instance={mower.instance ?? "N/A"}>{mower.instance ?? "N/A"}</Label>
            </RowLeftAlign>
            <RowRightAlign width={getMowerStatusIcon(mower, allMowersNotification) !== ChargeIcon ? "60px" : "80px"}>
              <span>
                {mower.need_maintain && <Icon size="14px" src={Repair} />}
              </span>
              <span>
                {(getMowerStatusIcon(mower, allMowersNotification) && mower.instance) &&
                    <Tooltip title={allMowersNotification?.[mower.id]?.[0]} placement="bottom">
                      <Icon
                          size={getMowerStatusIcon(mower, allMowersNotification) === OffIcon ? "19px" : "14px"}
                          src={getMowerStatusIcon(mower, allMowersNotification)}
                      />
                    </Tooltip>
                }
              </span>
              <span>
                {mower.instance &&
                    <Icon src={mower.connected ? OnlineIcon : OfflineIcon}/>
                }
              </span>
            </RowRightAlign>
            {isRouteMode && routeNum > -1 && <Badge>{routeNum}</Badge>}
          </Card>
        </ItemDiv>
    </MainRow>
    );
  }

  return (
    <Tooltip title={mower?.instance ?? "N/A"} placement="right">
      <CollapsedCard pointer={!!mower?.instance} toggleBackgroundColor={!!mower?.instance} onClick={() => mower.instance ? handleClick() : false} selected={selected}>
        {mower.instance ?
            <MowerIcon alt="mower" src={imageSetter(mower)} />
            :
            <>N/A</>
        }
        <CollapsedErrStatusPoint
          errStatus={getStatusColor(mower)}
        />
        {isRouteMode && routeNum > -1 && <Badge>{routeNum}</Badge>}
      </CollapsedCard>
    </Tooltip>
  );
};

const NameLabel = styled.div`
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 11px;
  font-weight: bold;
  opacity: 0.2;
  color: ${(props) => props.theme.color.text};
`;
const Card = styled.div`
  display: flex;
  position: relative;
  height: 45px;
  padding: 0px ${(props) => props.theme.spacing[3]}px;
  margin: 0px ${(props) => props.theme.spacing[3]}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  justify-content: space-between;
  align-items: center;
  ${props => props.selected && 
    `background-color: ${props.theme.color.grey[500]};
    border-radius: ${props.theme.spacing[2]}px;
    ` 
  };
  :hover {
    border-radius: ${(props) => props.theme.spacing[2]}px;
    background-color: ${(props) => props.instance !== "N/A" && props.theme.color.grey[500]};
  }
`;
const MobileCard = styled.div`
  height: 54px;
  line-height: 54px;
  margin: 15px 15px;
  position: relative;
  border-radius: ${(props) => props.selected && "50%"};
  background-color: ${(props) => props.selected && "#26282D"};
  :hover {
    border-radius: 50%;
    background-color: #26282d;
  }

  img {
    margin: 11px 7.5px 11px 8.5px;
    width: 38px;
  }
`;
const CollapsedCard = styled.div`
  height: 80px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: ${props => props.pointer ? "pointer" : 'default'};
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  background-color: ${(props) => props.selected && props.theme.color.grey[500]};

  :hover {
    background-color: ${(props) => props.toggleBackgroundColor && props.theme.color.grey[500]};
  }
`;
const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
   color: ${(props) => props.instance === "N/A" ? "#8b8b8c" : props.theme.color.text};
`;
const MainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => props.instance === "N/A" && 'default'};
  :hover {
    color: ${(props) => props.instance === "N/A" && props.theme.color.grey};
  }
`;
const CheckedDiv = styled.div`
  padding-left: 20px;
`;
const ItemDiv = styled.div`
  width: 100%;
`;
const RowRightAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${props => props.width};
`;
const RowLeftAlign = styled.div`
  display: flex;
  align-items: center;
`;
const CollapsedErrStatusPoint = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.errStatus === "red"
      ? props.theme.color.red[100]
      : props.errStatus === "green"
      ? props.theme.color.green[500]
      : props.theme.color.red[500]};
  position: absolute;
  top: ${(props) => (props.top ? props.top : props.theme.spacing[2])}px;
  left: ${(props) => (props.left ? props.left : props.theme.spacing[2])}px;
`;
const Icon = styled.img`
  width: ${props => props.size ? props.size : "19px"};
  margin-left: 14px;
`;
const CheckedImage = styled.img`
  width: 19px;
`;
const MowerIcon = styled.img`
  width: 43px;
`;
const Badge = styled.div`
  padding: 0px 7px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: red;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export default SidebarItem;

import { bigCommerceCreate, bigCommerceLogin, bigCommerceSearch } from "Utils/api";


export const gotoBCStore = async (user) => {
  let message = "";
  if (!user) {
    return "Error occured. Please try again";
  };
  let customerId = null;
  const [statusSearch, reqSearch] = await bigCommerceSearch(user.subscriber?.email || user.email);
  if (statusSearch !== 200) {
    const [statusCreate, reqCreate] = await bigCommerceCreate({
      first_name: user.subscriber?.company_name || user.first_name,
      last_name: user.subscriber?.subdomain || user.last_name,
      email: user.subscriber?.email || user.email,
    })
    if (statusCreate === 200) {
      customerId = reqCreate.id;
    } else {
      message = "Can not find or create a customer account";
    };
  } else {
    customerId = reqSearch.id;
  };
  if (customerId) {
    const [statusLogin, reqLogin] = await bigCommerceLogin(customerId);
    if (statusLogin === 200) {
      window.open(reqLogin.url);
    } else {
      message = "Can not login store";
    };
  };
  return message;
};

import React from "react";
import styled from "styled-components";
import CircularProgress from '@material-ui/core/CircularProgress';


const Button = ({ text, onClick, width, fontSize, showShadow, loading, disabled }) => {
  return (
    <StyledButton
      onClick={onClick}
      width={width}
      showShadow={showShadow}
      fontSize={fontSize}
      disabled={disabled}
    >
      {loading ? <CircularProgress color="white" size={fontSize || 14} /> : text}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  border-radius: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.width};
  cursor: pointer;
  outline: none !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) =>  props.theme.color.white};
  opacity: ${(props) => props.disabled ? "20%" : "100%"};
  font-weight: bold;
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  padding: ${(props) => props.theme.spacing[2]}px;
  background: ${(props) => props.disabled ? "#343539" : `linear-gradient(0deg, ${props.theme.color.orange[100]} 0%, ${props.theme.color.orange[200]} 100%)`};

  :focus {
    background: ${(props) => props.theme.color.primary};
  }
`;

export default Button;

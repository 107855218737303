import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Tooltip} from "@material-ui/core";

import PopUpDialog from "./PeripheralDialog";
import {getConsumerUrl, getHusqvarnaModel, getHusqvarnaSearchUrl} from "Utils/global"
import {defaultRemaining} from "../../../Contants";

import SetIcon from "Assets/images/settings.png";
import ResetIcon from "Assets/images/rotate-cw.png";
import UndoIcon from "Assets/images/undo.png";
import OrderIcon from "Assets/images/shopping-cart.png";
import ScheduleJobIcon from "Assets/images/calendar.png";
import ScheduleJobActiveIcon from "Assets/images/calendar-active.png";
import QuestionMarkIcon from "Assets/images/question-mark.png";
import HoveredQuestionMarkIcon from "Assets/images/hover-question-mark.png";

const SET_ACTION = "set";
const RESET_ACTION = "reSet";
export const ORDER_ACTION = "order";
export const SCHEDULEJOB_ACTION = "scheduleJob";
export const HOWTO_ACTION = "howTo";

export const Peripheral = ({peri}) => {
    const {selectedMowerId, selectedMower, mowerPeripherals} = useSelector((state) => state.mower);
    const [openPopup, setOpenPopup] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);
    const [projectedLife, setProjectedLife] = useState(peri?.projected_life);
    const [technician, setTechnician] = useState(null);
    const [dialogTitle, setDialogTitle] = useState(null);
    const [newPeripheral, setNewPeripheral] = useState(false);
    const [restoredRemaining, setRestoredRemaining] = useState({set: null, reSet: null,});

    useEffect(() => {
        setRestoredRemaining({
            set: null,
            reSet: null,
        });
    }, [selectedMowerId]);

    const findItem = (mower, peripherals, itemName) => {
        if (!mower) {
            return;
        }
        itemName = itemName.toLowerCase();
        const make = mower.manufacturer;
        const model = getHusqvarnaModel(mower);
        var peri = peripherals?.find((el) => {
            return el.make === make && el.model === model;
        });
        if (!peri) {
            peri = peripherals?.find((el) => {
                return el.make === "default" && el.model === "default";
            });
        }
        if (!peri) {
            return null;
        }
        return peri.peripherals.find((el) => {
            return el.item === itemName;
        });
    };

    const maxRemaining = useMemo(() => {
        const item = findItem(selectedMower, mowerPeripherals, peri.item);
        if (!item || !item.max) {
            return defaultRemaining[peri.item];
        }
        return item.max;
    }, [selectedMower, mowerPeripherals, peri]);

    const resetValue = () => {
        switch (peri.id) {
            case 'Battery':
                setDialogTitle('Battery Replacement')
                setProjectedLife(peri.projected_life || defaultRemaining.Battery);
                break;
            case 'Wheels':
                setDialogTitle('Wheels Replacement')
                setProjectedLife(peri.projected_life || defaultRemaining.Wheels);
                break;
            default:
                setDialogTitle('Blades Replacement')
                setProjectedLife(peri.projected_life || defaultRemaining.Blades);
                break;
        }
        setCurrentDate(new Date().toISOString().split('T')[0])
        setTechnician('');
        setNewPeripheral(true);
        setOpenPopup(true);
    };

    const setValue = () => {
        switch (peri.id) {
            case 'Battery':
                setDialogTitle('Battery Configuration');
                setProjectedLife(peri.projected_life || defaultRemaining.Battery);
                break;
            case 'Wheels':
                setDialogTitle('Wheels Configuration');
                setProjectedLife(peri.projected_life || defaultRemaining.Wheels);
                break;
            default:
                setDialogTitle('Blades Configuration');
                setProjectedLife(peri.projected_life || defaultRemaining.Blades);
                break;
        }
        setCurrentDate(new Date(peri.replaced_date).toISOString().split('T')[0])
        setTechnician(peri.technician ?? '')
        setNewPeripheral(false)
        setOpenPopup(true);
    };

    const title = (action) => {
        if (action === SET_ACTION && restoredRemaining.set) {
            return `Undo the ${peri.item} ${peri.unit} to the previous value of ${restoredRemaining.set}.`;
        }
        if (action === RESET_ACTION && restoredRemaining.reSet) {
            return `Undo the ${peri.item} ${peri.unit} to the previous value of ${restoredRemaining.reSet}.`;
        }
        if (action === SET_ACTION) {
            return `You can set the remaining ${peri.unit} for the ${peri.item}.`;
        }
        return `You can reset the ${peri.item} ${peri.unit} to the default value of ${maxRemaining}.`;
    };

    const iconSrc = (action) => {
        if (action === SET_ACTION && restoredRemaining.set === null) {
            return SetIcon;
        }
        if (action === RESET_ACTION && restoredRemaining.reSet === null) {
            return ResetIcon;
        }
        return UndoIcon;
    };

    const closeDialogue = () => {
        setOpenPopup(false);
        setCurrentDate(new Date().toISOString().split('T')[0]);
        setTechnician(null);
        setProjectedLife(peri.remaining);
    }

    return (
        <>
            <Icon onClick={setValue} title={title(SET_ACTION)} src={iconSrc(SET_ACTION)}/>
            <Icon onClick={resetValue} title={title(RESET_ACTION)} src={iconSrc(RESET_ACTION)}/>
            {openPopup &&
                <PopUpDialog
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    onClose={closeDialogue}
                    dialogTitle={dialogTitle}
                    newPeripheral={newPeripheral}
                    peri={peri}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    projectedLife={projectedLife}
                    setProjectedLife={setProjectedLife}
                    setTechnician={setTechnician}
                    technician={technician}
                />
            }
        </>
    );
};

export const PeripheralAction = ({action, peri}) => {
    const {selectedMower} = useSelector((state) => state.mower);
    const {user} = useSelector((state) => state.auth);

    const onSchedule = () => {
        if (user?.state !== 'trial' && selectedMower.consumer?.id) {
            window.open(getConsumerUrl(selectedMower.consumer?.id, peri?.item), '_blank').focus();
        }
    };
    const onOrderAction = () => {
        let url = "https://shop.robinautopilot.com/search.php?search_query="
        switch (peri.item) {
            case 'Blades':
                url = url + `blade+set`;
                break;
            case 'Wheels':
                url = url + `wheel+kit`;
                break;
            default:
                url = url + `${peri.item}+${selectedMower.manufacturer}+${getHusqvarnaModel(selectedMower).replaceAll(" ", "")}`;
        }
        window.open(url, "_blank").focus();
    };

    const onHowtoAction = () => {
        window.open(
            getHusqvarnaSearchUrl(`Replace ${peri.item}`),
            "_blank"
        ).focus();
    };

    const peripherals = {
        [ORDER_ACTION]: {
            icon: OrderIcon,
            title: "Order a replacement part from our store",
            action: onOrderAction,
        },
        [SCHEDULEJOB_ACTION]: {
            icon: user?.state === 'trial' || !selectedMower?.consumer?.id ? ScheduleJobIcon : ScheduleJobActiveIcon,
            title: "Schedule a job in your field service management SW",
            action: onSchedule
        },
        [HOWTO_ACTION]: {
            icon: QuestionMarkIcon,
            title: "Search FAQ on Husqvarna’s site",
            action: onHowtoAction,
        },
    }

    return (
        <Tooltip title={peripherals[action].title}>
            <Icon
                src={peripherals[action].icon}
                onClick={peripherals[action].action}
                onMouseOver={event => {
                    if (action === HOWTO_ACTION) {
                        event.target.src = HoveredQuestionMarkIcon;
                    }
                }}
                onMouseOut={(event) => event.target.src = peripherals[action].icon}
            />
        </Tooltip>
    )
};

const Icon = styled.img`
  cursor: pointer;
  width: 15px;
  margin: 0px 3px;
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {nexMowAuth} from "../../Utils/api";
import {useDispatch} from "react-redux";
import {postFleetAuthSuccess} from "../../Redux/actions/authAction";

const nexMowUrl = process.env.REACT_APP_NEXMOW_URL;

const NexMowPopup = ({setProgressDialogue}) => {
    const dispatch = useDispatch();
    function openPopup() {
        var object = window.open(`${nexMowUrl}`, "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 800 + ", height=" + 800);
        window.onmessage = function (e){
           if(e.data?.loginMyth){
               sendLoginMyth(e.data.loginMyth);
               object.close();
           }
        }
    }
    const sendLoginMyth = async (loginMyth) => {
        setProgressDialogue(true);
        let res = await nexMowAuth(loginMyth);
        if(res?.data?.success){
            dispatch(postFleetAuthSuccess({data: res.data?.data ?? [] , error: "", message:""}));
        }
        setTimeout(() => {
            setProgressDialogue(false);
        }, 3000)
    }
    useEffect(() => {
        openPopup()
    },[])
    return (
        <>
        </>
    );
};

export default NexMowPopup;

import { handleActions } from "redux-actions";

import {
  SAVE_FILTER,
  CLEAR_FILTER,
  CLEAR_ALL_FILTER, SELECTED_USER_MODE
} from "Redux/actionTypes";

const initialState = {
  statusFilter: [],
  modelFilter: [],
  manufacturerFilter: [],
  scheduleFilter: [],
  zipcodeFilter: [],
    tagsFilter: [],
  selectedUserMode: null,
};

export default handleActions(
  {
    [CLEAR_FILTER]: (state, payload) => {
      const {
        payload: { filterName },
      } = payload;
      state[filterName] = [];
      return state;
    },
    [CLEAR_ALL_FILTER]: () => ({
      statusFilter: [],
      modelFilter: [],
      manufacturerFilter: [],
      scheduleFilter: [],
      zipcodeFilter: [],
        tagsFilter: []
    }),
    [SAVE_FILTER]: (state, payload) => {
      const {
        payload: { filterName, values },
      } = payload;
      state[filterName] = values;
      return state;
    },
    [SELECTED_USER_MODE] : (state, payload) => {
        state.selectedUserMode = payload.payload;
        return {
            ...state
        }
    }
  },
  initialState
);

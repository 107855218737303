import React from 'react';
import "./style.css";

const SliderToggle = ({checked, handleChange}) => {
    return (
        <div className="switch">
            <input type="checkbox" checked={checked} />
            <span className="slider round" onClick={handleChange} />
        </div>
    );
};

export default SliderToggle;
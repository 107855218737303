import styled from "styled-components";

export const FullPage = styled.div`
  display: flex;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 35%;
  min-width: 450px;
  padding-bottom: ${(props) => props.theme.spacing[10]}px;
  background-color: ${(props) => props.theme.color.grey[700]};

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 62px;
    min-width: 320px;
  }
`;

export const BGContainer = styled.div`
  height: 100vh;
  width: 65%;
  overflow: hidden;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const StyledError = styled.p`
  color: ${(props) => props.theme.color.primary};
  padding-bottom: 20px;
  font-size: 16px;
`;
export const StyledWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  @media (min-width: 1023px) {
    margin-top: 62px;
  }
`;

export const Header = styled.h1`
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.theme.spacing[10]}px;
`;

export const StyledLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: ${(props) => props.theme.spacing[9]}px;
  > a {
    color: ${(props) => props.color};
    font-weight: bold;
    text-decoration: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${(props) => props.theme.spacing[2]}px;
  width: 100%;
  cursor: pointer;
  outline: none !important;
  font-size: 14px;
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 10px;
  background-color: rgba(255,255,255,0.08);
  padding: ${(props) => props.theme.spacing[2]}px;
  margin-bottom: 20px;
  position: relative;
  justify-content: space-around;

  > span {
    position: relative;
    top: 1px;
  }

  :hover {
    background: rgba(255,255,255,0.2);
  }

  :focus {
    background: rgba(255,255,255,0.3);
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const Seperator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 21px;
  color: ${(props) => props.theme.color.grey[450]};
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  margin-top: ${(props) => props.theme.spacing[7]}px;
  margin-bottom: ${(props) => props.theme.spacing[7]}px;

  > div {
    width: 45%;
    background-color: ${(props) => props.theme.color.grey[450]};
    height: 1px;
  }
`;
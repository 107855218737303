import React, {useState} from 'react';
import styled from "styled-components";
import {
    XAxis,
    YAxis,
    Line, LineChart, CartesianGrid,
} from "recharts";
import {Dropdown} from "rsuite";
import {DropdownStyles} from "./LaborSavings";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";


const darkColor = {
    pathColor: "#2D2F33",
    trailColor: "#1F1F23FF",
    textColor:"#2D2F33",
}
const lightColor = {
    pathColor: "#9DCA60",
    trailColor: "#344220",
    textColor:"#9DCA60",
}

const circle = (val, width, dark, heading) => {
    return <Circle width={width}>
        <div style={{width: "90%"}}>
            <CircularBar
                styles={
                    buildStyles({
                        pathColor: dark ? darkColor.pathColor : lightColor.pathColor ,
                        trailColor: dark ? darkColor.trailColor : lightColor.trailColor,
                        textColor: dark ? darkColor.textColor : lightColor.textColor,
                        textSize: "20px"
                    })
                }
                value={val}
                text={`${val}%`}
                strokeWidth={8}
            />
            <Heading color={dark ? darkColor.textColor : lightColor.textColor}>
                {heading}
            </Heading>
        </div>
    </Circle>
}

const FleetWorking = (props) => {
    const [selectedOption, setSelectedOption] = useState("Trend");
    return (
        <>
            <Row>
                <div className='center'>
                    Percentage of Fleet Working
                </div>
                <div>
                    <Dropdown style={DropdownStyles} trigger="hover" title={selectedOption}>
                        <Dropdown.Item onClick={() => setSelectedOption("Trend")}>Trend</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedOption("Comparison")}>Comparison</Dropdown.Item>
                    </Dropdown>
                </div>
            </Row>
            <div>
                {selectedOption === "Trend" ?
                    <LineChart
                        width={330}
                        height={250}
                        data={props?.data?.trend ?? []}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid stroke="grey" vertical={false} strokeDasharray="1 5" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={(tick) => {
                            return `${tick}%`;
                        }}
                        />
                        <Line dot={false} dataKey="uv" strokeWidth={2} stroke="#9AC55E" />
                    </LineChart>
                    :
                    <CircleRow>
                        {circle(props?.data?.comparison?.pastMonth ?? 0, "25%", true, "Past Month")}
                        {circle(props?.data?.comparison?.thisWeek ?? 0, "50%", false, "This Week")}
                        {circle(props?.data?.comparison?.lastWeek ?? 0, "25%", true, "Past Week")}
                    </CircleRow>
                }
            </div>
        </>
    );
};

const CircleRow = styled.div`
    display: flex;
    margin-top: 20px;
`;

const CircularBar = styled(CircularProgressbar)`

    .CircularProgressbar-text {
        transform: translate(-15px, 5px);
    }
`;
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    
    .center {
        align-self: center;
   }
`;

const Circle = styled.div`
    display: flex;
    height: 235px;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: ${props => props.width};
    .heading {
        color: yellow;
    }
`;
const Heading = styled.div`
    color:${props => props.color};
`;
export default FleetWorking;


import React, {createContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import socketIOClient from "socket.io-client";
import {getMowerNotifications} from "../Utils/api";

import {mowerNormalOperationCondition} from "../Utils/global";
import {mowerRef} from "fbconfig";
import {MAX_RECENT_NOTIFICATIONS, MAX_UNIQUE_NOTIFICATIONS} from "../Contants";

const io = socketIOClient(process.env.REACT_APP_SOCKET_PORT);
export const MowerContext = createContext({
    mowTimes: null,
    notifications: null,
    peripherals: null,
    mowerDetails: null
});

let unsubscribeMowerData = null;
let peripheralsRef = null;
let fleetNotificationsRef = null;
let mowingRef = null;
let time = 1;

const MowerProvider = ({children}) => {
    let allNotifications = [];
    const {selectedMowerId, selectedMower} = useSelector((state) => state.mower);
    const [mowTimes, setMowTimes] = useState(null);
    const [notifications, setNotifications] = useState({all: [], recent: [], unique: []});
    const [peripherals, setPeripherals] = useState(null);
    const [mowerDetails, setMowerDetails] = useState(null);

    useEffect(() => {
        if (selectedMowerId) {
            getDetails();
            getNotification();
            getPeripherals();
            getMowTimes();
            time = 1;

            io.on('connect', () => {
                console.log('Connection Established')
            });
            io.on(`AutoMowerNotification_${selectedMowerId}`, data => {
                if (data) {
                    notificationDataSetter([data?.data])
                }
            })
        }

        return () => {
            if (unsubscribeMowerData) {
                unsubscribeMowerData();
            }
            if (peripheralsRef) {
                peripheralsRef();
            }
            if (fleetNotificationsRef) {
                fleetNotificationsRef();
            }
            if (mowingRef) {
                mowingRef();
            }
            setNotifications({all: [], recent: [], unique: []});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMowerId]);

    const getDetails = () => {
        if (selectedMowerId) {
            unsubscribeMowerData = mowerRef
                .doc(selectedMowerId).onSnapshot((data) => {
                    setMowerDetails(data.data());
                });
        } else {
            setMowerDetails(null);
        }
    }

    const notificationDataSetter = (res) => {
        const notificationsArray = {all: [...res, ...allNotifications], recent: [], unique: []};
        let prevTime = new Date();
        const uniqueMess = [];
        // eslint-disable-next-line array-callback-return
        notificationsArray?.all?.map((item) => {
            const docData = item;
            const notificationTime = new Date(docData.created_at);
            let time = notificationTime.getTime() / 1000;
            const totalTime = prevTime - notificationTime;
            prevTime = notificationTime;
            // For notifications
            const notification = {
                mode: docData.mode,
                error_code: docData.error_code,
                activity: docData.activity,
                status: docData.status,
                message: docData.message,
                time: time,
                spent: totalTime,
            }
            if (mowerNormalOperationCondition(notification)) {
                notification.message = "Parked until further notice";
            }
            if (notificationsArray.recent.length < MAX_RECENT_NOTIFICATIONS) {
                notificationsArray.recent.push(notification);
            }
            if (!uniqueMess.includes(notification.message) && notificationsArray.unique.length < MAX_UNIQUE_NOTIFICATIONS) {
                notificationsArray.unique.push(notification);
                uniqueMess.push(notification.message);
            }
            notificationsArray.all.push(notification);
        })
        setNotifications({id: selectedMowerId, ...notificationsArray});
        allNotifications = notificationsArray?.all ?? [];
    }

    const getNotification = async () => {
        if (selectedMowerId) {
            let res = await getMowerNotifications(selectedMowerId);
            notificationDataSetter(res)
        }
    };

    const getPeripherals = () => {
        peripheralsRef = mowerRef.doc(selectedMowerId)
            .collection("peripherals")
            .onSnapshot((snapshot) => {
                const peripherals = [];
                snapshot.docs.forEach((doc) => {
                    const docData = doc.data();
                    peripherals.push({
                        fleet_peripheral_id: docData.fleet_peripheral_id,
                        id: doc.id,
                        item: docData.item,
                        remaining: docData.remaining,
                        unit: docData.unit ? docData.unit : "hours",
                        time: docData.time?.seconds,
                        technician: docData?.technician,
                        replaced_date: docData?.replaced_date?.seconds * 1000,
                        projected_life: docData.projected_life
                    });
                });
                setTimeout(() => {
                    time = 2000;
                    setPeripherals(peripherals);
                    // Check if need maintain
                    let needMaintain = false;
                    peripherals.forEach((peri) => {
                        if (peri.remaining < 0) {
                            needMaintain = true;
                        }
                    });
                    if (selectedMower["need_maintain"] !== needMaintain) {
                        mowerRef.doc(selectedMowerId).update({
                            need_maintain: needMaintain,
                            updated_at: new Date()
                        });
                    }
                    selectedMower["need_maintain"] = needMaintain;
                }, time)
            });
    }

    const getMowTimes = () => {
        mowingRef = mowerRef.doc(selectedMowerId)
            .collection("mowings")
            .onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    const docData = doc.data();
                    let totalMowTime = docData.total;
                    let mowTimeLastDay = docData.day;
                    let mowTimeLast7Day = docData.week;
                    setMowTimes({
                        totalMowTime,
                        mowTimeLast7Day,
                        mowTimeLastDay,
                    })
                });
            });
    }

    return (
        <MowerContext.Provider value={{mowTimes,notifications ,peripherals,mowerDetails }}>
            {children}
        </MowerContext.Provider>
    );
};

export default MowerProvider;

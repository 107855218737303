import React from "react";
import {useDispatch} from "react-redux";
import GoogleLogin from "react-google-login";

import {BGContainer, Icon, Seperator, StyledButton, StyledImage} from "./StyledComponents";
import Button from "../Base/Button";
import {thirdPartyResponseAction} from "../../Redux/actions/authAction";
import {THIRD_PARTY_LOGIN_TYPE} from "../../Redux/reducers/authReducer";

import BgImg from "../../Assets/images/login-bg.png";
import GoogleIcon from "../../Assets/images/google.png";


export const SignInButton = ({text, trigger}) => {
    return <Button
        onClick={trigger}
        fontSize={14}
        showShadow={true}
        text={text}
        width="100%"
    />
};

export const BgContainer = () => {
    return <BGContainer>
        <StyledImage src={BgImg} alt="background" />
    </BGContainer>
}

export const SeperatorComp = () => {
    return <Seperator>
        <div></div><span>OR</span><div></div>
    </Seperator>
}

export const GoogleLoginComp = ({setResponse}) => {
    const dispatch = useDispatch();

    const responseGoogle = (response) => {
        setResponse(response);
        response.googleId && dispatch(thirdPartyResponseAction(THIRD_PARTY_LOGIN_TYPE.google, response));
    };

    return <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
        render={renderProps => (
            <StyledButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
                <Icon src={GoogleIcon}></Icon>
                <span>Login with Google</span>
                <span></span>
            </StyledButton>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'} />
}



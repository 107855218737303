/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";

import IconButton from "../Base/IconButton";

import CloseImg from "../../Assets/images/x.png";

export const ScheduleItem = ({day, schedule, remove, clearAll, addAllDay}) => {
    const removeSchedule = (item,index) => {
        remove(index, item);
    }
    const removeAllDay = () => {
        if (schedule.length > 0)
        {
            clearAll(day);
        }
    }
    return <Container>
        <ItemRow>
            <DynamicColorLabel size="16px" color='#E2E2E2'>{day}</DynamicColorLabel>
            <>
                <DynamicColorLabel
                    size="13px"
                    style={{cursor: "pointer"}}
                    paddingLeft='25px' color='#E4552E'
                    onClick={ () => addAllDay(day)}
                >
                    All Day
                </DynamicColorLabel>
                <DynamicColorLabel
                    size="13px"
                    style={{cursor: schedule.length === 0 ? "not-allowed" : "pointer"}}
                    paddingLeft='10px'
                    color={schedule.length === 0 ? '#FFFFFF' : '#E4552E'}
                    onClick={() => removeAllDay()}
                >
                    Clear
                </DynamicColorLabel>
            </>
        </ItemRow>
        {schedule.length > 0 && schedule.map((item, index) => (
            <TimeRow>
                <TimeLabel>{item.start} - {item.end}</TimeLabel>
                <div>
                    <IconButton
                        url={CloseImg}
                        size="sm"
                        onClick={() => removeSchedule(item,index)}
                        noBackground={true}
                    />
                </div>
            </TimeRow>
        ))}
        {schedule.length === 0 &&
            <DynamicColorLabel align='start' color='#FFFFFF'>EMPTY</DynamicColorLabel>
        }
    </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;
const ItemRow = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
`;
const TimeRow = styled.div`
  display: flex;
  padding-bottom: 8px;
  justify-content: space-between;
`;
const TimeLabel = styled.span`
  font-size: 12px;
  padding-left:20px;
  line-height: 15px;
  font-weight: bold;
  text-align: inherit !important;
  font-family: 'Roboto';
  color: #FFFFFF;
  opacity: 35%;
`;
const DynamicColorLabel = styled.span`
  padding-left: ${props => props.paddingLeft ?? '20px'};
  font-size: ${props => props.size ?? "13px"};
  font-weight: bold;
  text-align: ${props => props.align ?? 'center'};
  line-height: 17px;
  color: ${props => props.color};
  ${props => props.color === '#FFFFFF' && 'opacity : 20%'};
`;

import React, {useState} from 'react';
import {Drawer} from "rsuite";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

import {CircularProgressComp, SearchInput} from "../Common";
import IconButton from "../Base/IconButton";
import RemoveMowerItem from "./RemoveMowerItem";
import {removeMultipleRobots} from "../../Utils/api";
import SuccessAnimation from "../SuccessAnimation";
import {getMowersData} from "../../Pages/Home";
import Spacer from "../Base/Spacer";

import CloseIcon from "../../Assets/images/x.png";

const errorComp = (error) => (
    <div>
        <Description color="red">{error}</Description>
        <Spacer />
    </div>
);

const RemoveRobotPan = ({ isCollapsed, onClose }) => {
    const description = "Select the robots";
    const dispatch = useDispatch();
    const array  = useSelector((state) => state.mower);
    const [input, setInput] = useState("");
    const [animationPopup, setAnimationPopup] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [error, setError] = useState(null);
    const [selectedMowers, setSelectedMowers] = useState([]);

    const deleteMowers = async () => {
        if(selectedMowers.length > 0){
            setOpenProgressDialog(true);
            let mower_ids = [...selectedMowers].map((mower) => {
                return mower.id;
            });
            const res = await removeMultipleRobots({"mower_ids" : mower_ids});
            setOpenProgressDialog(false);
            if(res?.result){
                getMowersData(dispatch);
                setError(null);
                setAnimationPopup(true);
                setSelectedMowers([]);
                
                setTimeout(() => {
                    setAnimationPopup(false);
                    onClose();
                },2000)
            } else {
                setError(res.message);
            }
        }
    }

    const setSelection = (obj) => {
        let array = [...selectedMowers];
        let found = -1
        for(let i =0 ; i< array.length ; i++){
            if(array[i].id === obj.id){
                found = i
                break;
            }
        }
        if(found === -1){
            setSelectedMowers([...selectedMowers, obj]);
        } else {
            array.splice(found, 1);
            setSelectedMowers([...array])
        }
    }

    const checkValidator = (mower) => {
        if(selectedMowers.length > 0){
            for(let i = 0; i<selectedMowers.length ; i++){
                if(selectedMowers[i].id === mower.id){
                    return true;
                }
            }
            return false
        } else {
            return false
        }
    }

    function genericSearch () {
        if(input === ''){
            return array.mowers ?? [] ;
        } else {
            const includeColumns = ["instance","model"];
            const filteredData = array?.mowers.filter(item => {
                return Object.keys(item).some(key =>
                    includeColumns.includes(key) ? item[key]?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) : false
                );
            });
            return  filteredData ?? []
        }
    }
    const sort = genericSearch();

    const headerComponent = () => {
        return <Header>
            <SidebarHeader>
                <FilterText>Remove Robots</FilterText>
                <IconButton url={CloseIcon} onClick={onClose} size="sm" />
            </SidebarHeader>
            <Description>{description}</Description>
            <Spacer />
            <ButtonWrapper>
                <DialogButton onClick={onClose} color="#565659">
                    <span style={{fontSize:'18px'}}>{`< `}</span>Get Back
                </DialogButton>
                <DialogButton onClick={deleteMowers} color="#F75C28">
                    {`Remove (${selectedMowers.length})`}
                </DialogButton>
            </ButtonWrapper>
        </Header>
    }

    return (
        <MainWrapper
            show={isCollapsed}
            onHide={onClose}
            placement="left"
            size={ "xs" }
        >
            {headerComponent()}
            <SeparatorLine/>
            <InputWrapper>
                <Spacer/>
                <SearchInput
                    val={input}
                    setVal={setInput}
                    heading={"Robot or Model Name"}
                />
                <Spacer/>
            </InputWrapper>
            <Scrollbars autoHide style={scrollbarsStyles} >
                {error &&
                    errorComp(error)
                }
                {sort?.map((mower, index) => {
                    return <ListWrapper key={index}>
                            <RemoveMowerItem mower={mower} checked={checkValidator(mower)} selectSetter={setSelection} />
                        </ListWrapper>
                    })
                }
                {animationPopup && <SuccessAnimation/>}
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
                }
            </Scrollbars>
        </MainWrapper>
    );
};
const MainWrapper = styled(Drawer)`
    .rs-drawer-content {
        padding: 50px 0px 40px 0px;
    };
`;
const SeparatorLine = styled.div`
    border-bottom: 1px solid #15151A;
`;
const InputWrapper = styled.div`
    margin: 0px 30px;
`;
const ListWrapper = styled.div`
    margin-left: 40px;
`;
const Header = styled.div`
    margin: 0px 40px;
    padding-bottom: 30px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const FilterText = styled.span`
  font-size: 21px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
  line-height: 24px;
`;
const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  color: ${(props) => props.color ? props.color : props.theme.color.white};
`;
export const DialogButton = styled.button`
  border-radius: 4px;
  cursor: pointer;
  outline: none !important;
  font-size: 15px;
  color: ${(props) => `${props.disabled ? "#fffff" : "white"}` };
  font-weight: bold;
  padding: 5px 19px;
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  background: ${(props) => `${props.disabled ? "#343539" : props.color ?? 'white'}`};
  :focus {
    background: ${(props) => props.theme.grey};
  }
`;
const scrollbarsStyles = {
    width: "100%",
    height: "70%",
    overflowX: "hidden"
}
export default RemoveRobotPan;

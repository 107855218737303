import React from 'react';
import styled from "styled-components";
import Tour from "reactour";


const TourStepContent = ({title, content}) => {
    return (
        <TourContainer>
            <TourTitle>{title}</TourTitle>
            <TourContent>{content}</TourContent>
        </TourContainer>
    );
};

const tourSteps = [
    {
        selector: ".start",
        content: <TourStepContent
            title="Action Button"
            content="You can start the mower by clicking on this button."
        />,
    },
    {
        selector: ".park",
        content: <TourStepContent
            title="Action Button"
            content="The mower will be parked once clicking on this button."
        />,
    },
    {
        selector: ".ticket",
        content: <TourStepContent
            title="Action Button"
            content="You can create a new job in Aviator from the console."
        />,
    },
    {
        selector: ".route",
        content: <TourStepContent
            title="Action Button"
            content={<>You can remove robot from the route view by clicking on the <span>-Route</span> button anytime you want.</>}
        />,
    },
    {
        selector: ".route-mode",
        content: <TourStepContent
            title="Route View"
            content={"Route View is the map that showing the shortest route to visit the robot, starting and ending at the business address."}
        />,
    },
    {
        selector: ".map-mode",
        content: <TourStepContent
            title="Map View"
            content={"Map View is the map that showing the robot's location."}
        />,
    },
];


const TourComp = ({isTourOpen , setTourOpen}) => {
    return (
        <Tour
            steps={tourSteps}
            isOpen={isTourOpen}
            onRequestClose={() => setTourOpen(false)}
        />
    );
};

const TourContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TourTitle = styled.h4`
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.color.primary};
`;
const TourContent = styled.p`
  font-size: 14px;
  line-height: 17px;
  width: 170px;
  color: ${(props) => props.theme.color.grey[450]};

  span {
    color: ${(props) => props.theme.color.red[100]};
    display: inline-block;
  }
`;

export default TourComp;
import React, {useMemo, useState} from "react";

import Input from "../Base/Input";
import {parkOptions} from "../../Contants";
import {
    CollapseImage,
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    DropDownListContainer, ListItem,
    Main
} from "./MultiActionComp";

import DropdownArrow from "../../Assets/images/drop_down.png";
import LeftDropdownArrow from "../../Assets/images/left_drop_down.png";

export default function ParkOptions(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = ({name,value}) => () => {
        setSelectedOption(name);
        setIsOpen(false);
        props.setParkType(value);
    };

    const setTextInput = (value) => {
        props.setDuration(value);
    };

    const options = useMemo(() => {
        let rows = [];
        parkOptions.map(({name,value}) => (
            rows.push(
                <ListItem onClick={onOptionClicked({name,value})} key={Math.random()}>
                    {name}
                </ListItem>
            )
        ))
        return rows;
    },[parkOptions]) // eslint-disable-line

    return (
        <Main>
            <DropDownContainer>
                <DropDownHeader onClick={toggling}>
                    {selectedOption || "Select Park Type"}
                    <CollapseImage src={isOpen ? LeftDropdownArrow : DropdownArrow} />
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options}
                        </DropDownList>
                    </DropDownListContainer>
                )}
                {selectedOption === "Park mower for a duration of time, overriding schedule" &&
                    <Input
                        label="Parking Duration (default to 10 mints)"
                        type="number"
                        value={props.duration}
                        onChange={setTextInput}
                        width="100%"
                    />
                }
            </DropDownContainer>
        </Main>
    );
};



import React, {useEffect} from "react";
import {Popover} from "rsuite";


const NotificationMenu = ({...props}) => {
    useEffect(() => {
        props.setLoading(true)
        if(props.fleetNotifications !== null){
            props.setLoading(false)
            props.showNotificationScreen()
        }       // eslint-disable-next-line
    },[props.fleetNotifications])
    return (
        <div className="account-menu">
            <Popover {...props} />
        </div>
    )
}

export default NotificationMenu
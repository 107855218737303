import React, {useState} from 'react';
import {Dialog} from "@material-ui/core";
import styled from "styled-components";
import {CircularProgressComp, MowerActionFooter} from "../../../Components/Common";
import {mowersService} from "../../../Utils/mowerActions";
import SliderToggle from "../../../Components/SliderToggle";
import SuccessAnimation from "../../../Components/SuccessAnimation";




const ConfirmationPopup = ({showPopup, setShowPopup, onAction, error}) => {
    return <>
        <Dialog open={showPopup} onClose={() => setShowPopup()} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <ConfirmWrapper>
                <div className='heading'>
                    Turn Off Notification
                    {error && <ErrorDiv>Error : can not perform this action</ErrorDiv>}
                </div>
                <div className='subHeading'>
                    Are you sure you want to turn off Notifications?
                </div>
               <div>
                   <MowerActionFooter close={setShowPopup} action={onAction} />
               </div>
            </ConfirmWrapper>
        </Dialog>
    </>
}

const NotificationToggle = ({mower, propertyMode}) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = () => {
        if(!propertyMode){
            if(mower.notify){
                setShowPopUp(true);
            } else {
                action();
            }
        }
    };

    const action = async () => {
        setOpenProgressDialog(true);
        let res = await mowersService.notifyMower(mower, !mower.notify);
        setOpenProgressDialog(false);
        if (process.env.REACT_APP_DEMO_MODE === 'true') {
            setAnimationPopup(true);
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
                setShowPopUp(false);
            }, 2000)
        } else {
            if (res?.result) {
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    setShowPopUp(false);
                }, 2000)
            } else {
                setError(res?.message?.response?.data?.error ?? "Can not perform this action");
                setTimeout(() => {
                    setError(null);
                }, 2000)
            }
        }
    }

    return (
        <>
            <SliderToggle
                checked={mower?.notify ?? false}
                handleChange={handleChange}
            />
            <ConfirmationPopup
                setShowPopup={setShowPopUp}
                showPopup={showPopUp}
                onAction={action}
                error={error}
            />
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
            }
            {animationPopup && <SuccessAnimation/>}
        </>
    );
}

const ErrorDiv = styled.div`
    color: red;
    font-size: 12px;
`;
const ConfirmWrapper = styled.div`
    padding: 40px;
    width: 500px;
    .heading {
        font-size: 21px;
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
    };
    .subHeading {
        font-size: 14px;
        color: #E2E2E2;
        opacity: 65%;
        margin-bottom: 70px;
    };
`;

export default NotificationToggle;
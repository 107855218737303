import { createAction } from "redux-actions";

import {FILTERED_HOUSES, GET_HOUSES_SUCCESS, PROPERTY_MODE, SELECTED_HOUSE, SELECTED_MOWER} from "../actionTypes";
import {getPropertiesAddresses, getPropertiesAddressesBasedOnUser} from "../../Utils/api";


export const getHouses = () => async (dispatch) => {
    let response = await getPropertiesAddresses();
    dispatch(getHousesAction(response.data ?? []));
}
export const getUserBasedHouses = (user) => async (dispatch) => {
    let response = await getPropertiesAddressesBasedOnUser(user.id);
    dispatch(getHousesAction(response.data ?? []));
}
export const setSelectedHouse = (val) => async (dispatch) => {
    dispatch(setSelectedHouseAction(val));
}
export const setSelectedMower = (val) => async (dispatch) => {
    dispatch(setSelectedMowerAction(val));
}
export const setFilteredHouses = (val) => async (dispatch) => {
    dispatch(setFilteredHousesAction(val));
}
export const setPropertyMode = (val) => async (dispatch) => {
    dispatch(setPropertyModeAction(val));
}

const getHousesAction = createAction(GET_HOUSES_SUCCESS);
const setSelectedHouseAction = createAction(SELECTED_HOUSE);
const setFilteredHousesAction = createAction(FILTERED_HOUSES);
const setPropertyModeAction = createAction(PROPERTY_MODE);
const setSelectedMowerAction = createAction(SELECTED_MOWER);
import React from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";

const Table = ({ data, columns, height, headerColor, cellPadding }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <TableWrapper height={height}>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    paddingBottom: 9,
                    color: headerColor,
                    cursor: "pointer",
                    fontSize: 14,
                    textAlign: "left",
                    paddingLeft: 6,
                    textTransform: "capitalize"
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        textAlign: "left",
                        padding: cellPadding,
                        boxSizing: "border-box",
                        color: "#E2E2E2",
                        fontSize: 13,
                        paddingLeft: 6,
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  max-height: ${(props) => (props.height ? props.height : "none")};
  overflow: auto;
`;

export default Table;

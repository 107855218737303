import React from "react";
import styled from "styled-components";

const Toggle = ({ onToggle, currentValue, options }) => {
  return (
    <ButtonWrapper>
      {options &&
        options.map((option) => (
          <ToggleItem onClick={onToggle} key={option.id} active={option.value === currentValue}>
            {option.label}
          </ToggleItem>
        ))}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: ${(props) => props.theme.spacing[4]}px;
  border: 1px solid ${(props) => props.theme.color.grey[450]};
  border-radius: ${(props) => props.theme.spacing[2]}px;
  overflow: hidden;
  min-width: fit-content;
`;

const ToggleItem = styled.div`
  padding: 0px 4px;
  background: ${(props) =>
    props.active ? props.theme.color.grey[450] : "none"};
  color: ${(props) => props.theme.color.text};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
`;

export default Toggle;

const SECOND_OF_1_HOUR = 60 * 60;
const MILI_SECOND_OF_ONE_DAY = 24 * SECOND_OF_1_HOUR * 1000;
const MILI_SECOND_OF_ONE_HOUR = SECOND_OF_1_HOUR * 1000;
const ABBREVIATED_MONTHS = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]

export const TODAY = new Date();

export const getDateObjFromSecond = (seconds) => {
  return new Date(seconds * 1000);
};

export const getDaysFromMiliSecond = (milisecond) => {
  return Math.round(milisecond / MILI_SECOND_OF_ONE_DAY);
};

export const getHoursFromMiliSecond = (milisecond) => {
  return Math.round((milisecond / MILI_SECOND_OF_ONE_HOUR));
};
export const getTimeStrFromSecond = (seconds) => {
  let secondsDelta = Date.now() / 1000 - seconds;
  let hours = Math.floor(secondsDelta / SECOND_OF_1_HOUR);
  let timeStr = "";
  if (hours === 0) {
    timeStr = Math.floor(secondsDelta / 60) + " min ago";
  } else {
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    if (months > 0) {
      timeStr = months + " mon ago";
    } else if (days > 0) {
      timeStr = days + " d ago";
    } else {
      timeStr = hours + " h ago";
    }
  }
  return timeStr;
}

export const getDateWithOffset = (date, dayOffset) => {
  const dateWithOffset = new Date(date.getTime() + dayOffset * MILI_SECOND_OF_ONE_DAY);
  return new Date(
    dateWithOffset.getFullYear(),
    dateWithOffset.getMonth(),
    dateWithOffset.getDate()
  );
};

export const getAbbreviatedMonthAndDay = (date) => {
  return `${ABBREVIATED_MONTHS[date.getMonth()]} ${date.getDate()}`;
};

export const getEndToday = () => {
  return new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate(), 23, 59, 59);
};

export const getYearMonthDayStr = (seconds) => {
  const dateObj = getDateObjFromSecond(seconds);
  return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
};
export const getFormattedDate = (date) => {
  let year = date.getFullYear();
  let month = date.toLocaleString( 'default', { month: 'short' });
  let day = date.getDate().toString().padStart(2, '0');
  return month + ' ' + day + ', ' + year;
}

import React, {useContext, useState} from "react";
import styled from "styled-components";
import {MowerContext} from "Context/MowerContext";
import {Tooltip} from "@material-ui/core";
import {useSelector} from "react-redux";

import {MaintainancePanLoader} from "../../../Components/SkeletonLoaders/SkeletonLoaderDetailMower";
import Toggle from "Components/Toggle";
import Table from "Components/Table";
import Spacer from "Components/Base/Spacer";

import {HOWTO_ACTION, ORDER_ACTION, Peripheral, PeripheralAction, SCHEDULEJOB_ACTION,} from "./Peripheral";
import {MaintenanceHistoryPeripheralsHeaderCols, MaintenancePeripheralsHeaderCols, toggleOptions} from "Contants";
import {getFormattedDate, getTimeStrFromSecond} from "Utils/datetime";

const MaintenancePan = () => {
    const {selectedMower} = useSelector((state) => state.mower);
    const [toggleValue, setToggleValue] = useState(toggleOptions[0].value);
    const {mowTimes, peripherals, mowerDetails} = useContext(MowerContext);

    if (selectedMower?.id !== mowerDetails?.id) {
        return <MaintainancePanLoader/>
    }

    const onToggle = (event) => {
        setToggleValue(event.target.textContent.toLocaleLowerCase().replace(" ", "_"));
    };

    const getPeripheralsDataForHistoryTable = () => {
        const data = [];
        if (!Array.isArray(peripherals)) {
            return data;
        }
        peripherals.forEach((peri) => {
            data.push({
                col1: peri.item,
                col2: <span>
                        {getTimeStrFromSecond(peri.replaced_date / 1000)}
                    </span>,
                col3: peri?.technician && peri?.technician !== "" ? peri?.technician : "N/A"
            })
        });
        return data;
    }

    const getPeripheralsDataForTable = () => {
        const data = [];
        if (!Array.isArray(peripherals)) {
            return data;
        }
        peripherals.forEach((peri) => {
            data.push({
                col1: <StatusPoint remaining={peri.remaining}/>,
                col2: peri.item,
                col3: <>
                    {`${Math.round(peri.remaining)} ${peri.unit}`}
                    <div>
                        <Peripheral peri={peri}/>
                    </div>
                </>,
                col4: peri.remaining > 0 ?
                    "N/A"
                    :
                    <>
                        Replace
                        <div>
                            <PeripheralAction action={ORDER_ACTION} peri={peri}/>
                            <PeripheralAction action={SCHEDULEJOB_ACTION} peri={peri}/>
                            <PeripheralAction action={HOWTO_ACTION} peri={peri}/>
                        </div>

                    </>,
                col5: <Tooltip title={getFormattedDate(new Date(peri.replaced_date))} placement="bottom">
                    <span>
                        {getTimeStrFromSecond(peri.replaced_date / 1000)}
                    </span>
                </Tooltip>
                ,
            })
        });
        return data;
    };

    const MowTimeTab = () => (
        <HRow>
            <DayTime>
                <Time>
                    {mowTimes ? Math.round(mowTimes.mowTimeLastDay) + "h" : "-- : --"}
                </Time>
                <Duration>Last 24h</Duration>
            </DayTime>
            <WeekTime>
                <Time>
                    {mowTimes ? Math.round(mowTimes.mowTimeLast7Day) + "h" : "-- : --"}
                </Time>
                <Duration>Last 7d</Duration>
            </WeekTime>
            <TotalTime>
                <Time>
                    {mowTimes ? Math.round(mowTimes.totalMowTime) + "h" : "-- : --"}
                </Time>
                <Duration>Since purchased</Duration>
            </TotalTime>
        </HRow>
    );
    const ConsumablesTab = () => (
        <Table
            headerColor="#4E4E4E" cellPadding='14px'
            columns={MaintenancePeripheralsHeaderCols}
            data={getPeripheralsDataForTable()}
        />
    )
    const HistoryTab = () => (
        <Table
            headerColor="#4E4E4E" cellPadding='14px'
            columns={MaintenanceHistoryPeripheralsHeaderCols}
            data={getPeripheralsDataForHistoryTable()}
        />
    )

    const conditionalRendering = () => {
        let renderTab = {
            mow_time: <MowTimeTab/>,
            history: <HistoryTab/>,
            consumables: <ConsumablesTab/>
        }
        return renderTab[toggleValue]
    }

    return (
        <Container>
            <Row>
                <TitleSection>
                    <Title>Maintenance</Title>
                </TitleSection>
                <Toggle options={toggleOptions} currentValue={toggleValue} onToggle={onToggle}/>
            </Row>
            <Spacer/>
            {conditionalRendering()}
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 260px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing[5]}px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;
const DayTime = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const WeekTime = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[2]}px 0px;
  border-left: 1px solid ${(props) => props.theme.color.border};
  border-right: 1px solid ${(props) => props.theme.color.border};
`;
const TotalTime = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Time = styled.span`
  font-size: 24px;
  line-height: 26px;
  font-weight: 200;
  color: ${(props) => props.theme.color.text};
`;
const Duration = styled.span`
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: ${(props) => props.theme.color.red[500]};
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.color.text};
  margin-right: ${(props) => props.theme.spacing[1]}px;
`;
const StatusPoint = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) => props.remaining < 0 ? props.theme.color.yellow : props.theme.color.green[500]};
  position: relative;
  left: ${(props) => props.theme.spacing[2]}px;
`;

export default MaintenancePan;

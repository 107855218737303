import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DropdownIcon from "../../Assets/images/dropdownIcon.png";


export default function Dropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showError, setShowError] = useState();
    const options = props?.options || [];

    useEffect(() => {
        setSelectedOption(props?.selected || null);
    }, [props.selected]);

    useEffect(() => {
        setShowError(props?.error || false);
    },[props.error])

    const toggling = () => {
        if(!props.disabled){
            setIsOpen(!isOpen);
        }
    };

    const onOptionClicked = (value) => {
        if(props?.onSelect){
            props.onSelect(value.text);
            setIsOpen(false);
        }
    };

    const checkSelection = (val) => {
        return val?.text === selectedOption;
    };

    return (
        <>
            <DropDownContainer>
                {!props.hideHeading === true &&
                    <Label>
                        {props.label}
                    </Label>
                }
                <DropDownHeader disabled={props.disabled} error={showError} onClick={toggling}>
                    <div>{selectedOption || props.label}</div>
                    {!props.disabled &&
                        <Icon rotate={isOpen}>
                            <img src={DropdownIcon} alt="icon" width={10}/>
                        </Icon>
                    }
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options.map((option, index) => (
                                <ListItem
                                    selected={checkSelection(option)}
                                    onClick={() => onOptionClicked(option)}
                                    key={index}
                                >
                                    {option.text}
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        </>
    );
}
const Icon = styled("div")`
    margin-left: 20px;
    transform:${props => props.rotate ?  "rotate(3.142rad)" : " rotate(0)"}
`;

const Label = styled("div")`
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 13px;
    color: #E2E2E2;
    font-weight: bold;
`;

const DropDownContainer = styled("div")`
  width: 90%;
  text-transform: capitalize;
  position: relative;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  display: flex;
  border: ${props => props.error ? "1px solid red" : "1px solid black"};
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  justify-content: center;
  border-radius: 8px;
  background-color: #16161B;
  margin-bottom: 0.1em;
  text-align: center;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1rem;
  color: white;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  text-align: -webkit-center;
  z-index: 1000;
  width: 100%;
`;

const DropDownList = styled("ul")`
  padding: 0;
  border-radius: 15px;
  margin: 0;
  background-color: #16161B;
  border: 1px solid #484848;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  text-align: center;
  margin: 0px 5px;
  color: ${(props) => (props.selected ? "#16161B" : "white")};
  background-color: ${(props) => (props.selected ? "#565659" : "#16161B")};
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 0.8em;
  &:hover {
    color: black;
    background-color: #565659;
  };
`;

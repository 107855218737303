import React from "react";
import {Menu} from "react-pro-sidebar";
import {useDispatch} from "react-redux";

import Statusbar from "./Statusbar";
import MowersContainer from "./MowersContainer";
import {addSelectedMower} from "../../Redux/actions/mowerAction";


const MowersGroupContainer = ({
                                  mowers, isCollapsed, title, collapse, setCollapse, onSelectMower,
                                  inDetailPageOfMobile, mode, routePath
                              }) => {

    const dispatch = useDispatch();

    const OnMowerChecked = (mower) => {
        dispatch(addSelectedMower(mower))
    };

    const doCollapse = (data) => {
        setCollapse(!data);
    };

    return (
        <>
            {!isCollapsed &&
                <Statusbar title={title} mowers={mowers} mowersCollapsed={doCollapse} status={!collapse} />
            }
            {collapse &&
                <Menu iconShape="square">
                    <MowersContainer
                        mowers={mowers}
                        OnMowerChecked={OnMowerChecked}
                        inDetailPageOfMobile={inDetailPageOfMobile}
                        onSelectMower={onSelectMower}
                        mode={mode}
                        isCollapsed={isCollapsed}
                        routePath={routePath}
                    />
                </Menu>
            }
        </>
    );
};

export default MowersGroupContainer
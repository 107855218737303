import React, {useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "react-google-autocomplete";

import {getStatusColor, GREEN_COLOR} from "Utils/global";
import {maxAllowedMowersOnRoute} from "../../../Contants";
import RouteExporter from "./RouteExporter";
import {saveCurrentMowerData} from "Redux/actions/mowerAction";
import {ConfirmationDialog} from "../../../Components/dialog/ConfirmationDialog";
import {imageSetter} from "../../../Components/Sidebar/SidebarItem";
import {mowersService} from "../../../Utils/mowerActions";
import {getMowersData} from "../index";
import SuccessAnimation from "../../../Components/SuccessAnimation";
import {MowerContext} from "../../../Context/MowerContext";
import {setPropertyMode} from "../../../Redux/actions/propertActions";

import EditIcon from "Assets/images/pencil.png";
import MapIcon from "Assets/images/map-icon.png";
import PropertyIcon from "Assets/images/property-icon.png";
import RouteIcon from "Assets/images/route.png";
import RemoveRouteFromMap from "Assets/images/clear-route-map.png";
import ExportRouteIcon from "Assets/images/export-route.png";
import StartRouteIcon from "Assets/images/startRoute.png";


const MowerMarker = ({mower, notifications, onClick}) => (
    <div>
        <Marker errStatus={getStatusColor(mower, notifications)}>
            <img src={imageSetter(mower)} alt="mower" width={40} onClick={onClick}/>
        </Marker>
        {mower.count && mower.count > 1 && <CountMarker>{mower.count}</CountMarker>}
    </div>
);

const StartRouteMarker = ({address, handleLocationSelected}) => {

    const [showTextField, setShowTextField] = React.useState(false);

    return (
        <RouteMarkerContainer bottom={41 + 20 + 8}>
            <TextMarker>
                <AddressBlockDiv>
                    <AddressDiv>
                        <FirstLineText>Start/End</FirstLineText>
                        <SecondLineText>{address}</SecondLineText>
                    </AddressDiv>
                    <EditButton onClick={() => {
                        setShowTextField((showTextField) => {
                            return !showTextField
                        })
                    }}>
                        <img src={EditIcon} alt="icon" width={12}/>
                    </EditButton>
                </AddressBlockDiv>
                {showTextField &&
                    <div style={{
                        'margin': '5px 10px'
                    }}>
                        <AddressFirstLineText>Address</AddressFirstLineText>
                        <Autocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_API}
                            style={{
                                height: '35px',
                                border: "1px solid transparent",
                                backgroundColor: "white",
                                boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.5)",
                                paddingLeft: '10px',
                                color: "black",
                                width: '100%',
                            }}
                            placeholder={'Address'}
                            options={{
                                componentRestrictions: {country: "us"},
                                types: null,
                            }}
                            onPlaceSelected={(v) => {
                                handleLocationSelected(v);
                                setShowTextField(false);
                            }}
                        />
                    </div>
                }
            </TextMarker>
            <Triggle/>
            <ImageRouter
                src={StartRouteIcon}
                alt="start-route"
                left={85 / 2 - 20 / 2}
                width={20}
                height={20}
            />
        </RouteMarkerContainer>
    );
}

export const MapActionButton = ({
                                    className, icon, active, top, right, onClick, toolTipTitle, customToolTipId
                                }) => {
    const TooltipWrapper = customToolTipId ? React.Fragment : Tooltip;
    return (
        <TooltipWrapper {...(customToolTipId ? {} : {title: toolTipTitle})}>
            <CustomIconButton
                className={className}
                on={active}
                top={top}
                right={right}
                padding={8}
                onClick={onClick}
                data-tip
                data-for={customToolTipId}
            >
                <img src={icon} alt={className}/>
            </CustomIconButton>
        </TooltipWrapper>
    );
};

const DefaultZoomLevel = 3;
const DefaultPoint = {lat: 32.7091351, lng: -96.6963022};

const getMowerLocation = (mower) => {
    if (!mower || !mower.location || !mower.location.lat || !mower.location.lng) {
        return null;
    }
    return {
        id: mower.id,
        lat: parseFloat(mower.location.lat),
        lng: parseFloat(mower.location.lng),
        mower_obj: mower,
    };
};

const MapComponent = ({
                          mowerData,
                          mode,
                          setMode,
                          routePath,
                          setRoutePath,
                          clickedPoint,
                          setClickedPoint,
                      }) => {
    const {mowerDetails: mowerObj} = useContext(MowerContext);
    const [mower, setMowerObj] = useState(null);
    const [show, setShow] = useState(false);
    const [myMap, setMyMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const {filteredMowers, allMowersNotification} = useSelector((state) => state.mower);
    const {user} = useSelector((state) => state.auth);
    const [userClone, setUserClone] = useState(null);
    const mowerLocation = getMowerLocation(mower);
    const [routeMarkers, setRouteMarkers] = useState([]);
    const [showExporter, setShowExporter] = useState(false);
    const [animationPopup, setAnimationPopup] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (mowerData) {
            setMowerObj(mowerObj);
        } else {
            setMowerObj(null);
        }
    }, [mowerData, mowerObj]) // eslint-disable-line

    const handleLocationSelected = (location) => {
        setUserClone({
            ...userClone,
            subscriber: {
                ...userClone.subscriber,
                address: location.formatted_address,
                lat: location.geometry.location.lat(),
                lng: location.geometry.location.lng(),

            }
        })
        setTimeout(function () {
            setMode({route: true});
        }, 100)
    };


    useEffect(() => {
        if (user) {
            setUserClone({...user});
        }
    }, [user])


    const showedMowers = useMemo(() => {
        const m = [];
        if (!filteredMowers) {
            return m;
        }
        filteredMowers.forEach((e) => {
            if (getMowerLocation(e)) {
                m.push(e);
            }
            ;
        });
        return m;
    }, [filteredMowers]);

    const firstPoint = useMemo(() => {
        if (userClone && userClone.subscriber && userClone.subscriber.lat && userClone.subscriber.lng) {
            return {lat: userClone.subscriber.lat, lng: userClone.subscriber.lng};
        }
        return null;
    }, [userClone]);

    const mapCenter = useMemo(() => {
        if (mower && !clickedPoint) {
            return getMowerLocation(mower);
        } else if (showedMowers.length > 0) {
            var latSum = 0;
            var lngSum = 0;
            showedMowers.forEach((mower) => {
                latSum += parseFloat(mower.location.lat);
                lngSum += parseFloat(mower.location.lng);
            });
            return {
                lat: latSum / showedMowers.length,
                lng: lngSum / showedMowers.length,
            };
        }
        return null;
    }, [mower, showedMowers, clickedPoint]);

    const [directionsService, directionsRenderer] = useMemo(() => {
        if (myMap && googleMaps) {
            const directionsService = new googleMaps.DirectionsService();
            const directionsRenderer = new googleMaps.DirectionsRenderer({
                map: myMap,
                suppressMarkers: true,
                preserveViewport: true,
            });
            return [directionsService, directionsRenderer];
        }
        ;
        return [null, null]
    }, [myMap, googleMaps]);

    const mowersOnMap = useMemo(() => {
        if (
            mode.route ||
            (mode.map && (clickedPoint || !mower))
        ) {
            return showedMowers.filter(m => (m.onRoute && mode.route) || mode.map);
        }
        ;
        if (mode.map && mower && mowerLocation) {
            return [mower];
        }
        ;
        return [];
    }, [mode, mower, showedMowers, mowerLocation, clickedPoint]);

    useEffect(() => {
        if (!myMap || !googleMaps || !mowersOnMap || mowersOnMap.length === 0) {
            return;
        }
        ;
        if (
            (mode.map && mower) || (mode.route && clickedPoint)
        ) {
            return;
        }
        ;
        const lats = mowersOnMap.map(m => m.location.lat);
        const lngs = mowersOnMap.map(m => m.location.lng);
        if (mode.route && firstPoint) {
            lats.push(firstPoint.lat);
            lngs.push(firstPoint.lng);
        }
        ;
        const bounds = new googleMaps.LatLngBounds(
            new googleMaps.LatLng(Math.min(...lats), Math.min(...lngs)),
            new googleMaps.LatLng(Math.max(...lats), Math.max(...lngs)),
        );
        myMap.fitBounds(bounds);
    }, [myMap, googleMaps, mowersOnMap, firstPoint, mode, mower, clickedPoint]);

    const concatOrderNo = (route, routePath) => {
        let data = [...route];
        for (let i = 0; i < routePath?.length; i++) {
            if (data?.[i]?.id && routePath?.[i]?.id) {
                if (data[i]?.order_number) {
                    data[i]['order_number'] = routePath.find(obj => obj?.id === data[i].id)?.order_number
                } else {
                    Object.assign(data[i], {"order_number": routePath.find(obj => obj?.id === data[i].id)?.order_number})
                }
            }
        }
        return data;
    }
    useEffect(() => {
        const route = [];
        if (!showedMowers) {
            setRoutePath(route);
            return;
        }
        showedMowers.forEach((mower) => {
            if (mower.onRoute) {
                route.push(getMowerLocation(mower));
            }
        });
        if (firstPoint) {
            var i = 0;
            while (i < route.length) {
                // Start to replace for i-th
                var minDistance = Infinity;
                var minIndex = i;
                for (let j = i; j < route.length; j++) {
                    const d =
                        Math.pow(route[j].lat - firstPoint.lat, 2) +
                        Math.pow(route[j].lng - firstPoint.lng, 2);
                    if (d < minDistance) {
                        minDistance = d;
                        minIndex = j;
                    }
                    // Replace i-th with minIndex-th
                    if (minIndex !== i) {
                        const temp = route[minIndex];
                        route[minIndex] = route[i];
                        route[i] = temp;
                    }
                }
                i += 1;
            }
        } else {
            route.sort((a, b) => a.lat - b.lat);
            route.sort((a, b) => a.lng - b.lng);
        }
        route.unshift(firstPoint);
        let upRoute = concatOrderNo(route, routePath);
        setRoutePath(upRoute);
    }, [showedMowers, firstPoint, setRoutePath]); // eslint-disable-line

    useEffect(() => {
        if (myMap && mode.route) {
            clearRouteMarkers();
            drawRoute();
        }
    }, [myMap, mode, routePath?.length]); // eslint-disable-line
    const [address, fullAddress] = useMemo(() => {
        if (!mower || !mower.location) {
            return ["", ""];
        }
        let consumerName = "";
        let addressName = "";
        if (mower.consumer) {
            consumerName = mower.consumer.first_name + " " + mower.consumer.last_name;
        }
        if (mower.location?.address) {
            addressName = mower.location.address;
            const tempAddress = addressName?.split(',') ?? '';
            tempAddress?.pop();
            addressName = tempAddress.join();
        }
        return [addressName, consumerName + " - " + addressName];
    }, [mower]);

    const drawRoute = () => {
        if (!directionsService || !directionsRenderer) {
            console.warn('No directionsService and directionsRender');
            return;
        }
        ;
        if (!routePath || routePath.length < 2) {
            console.warn('Empty route');
            return;
        }
        ;
        const points = routePath.slice(1).map((location) => ({
            location: location,
            stopover: true,
        }));
        const waypts = points.slice(0, maxAllowedMowersOnRoute);
        const startPoint = new googleMaps.LatLng(routePath[0]?.lat, routePath[0]?.lng)
        const request = {
            origin: startPoint,
            destination: startPoint,
            travelMode: 'DRIVING',
            waypoints: waypts,
            optimizeWaypoints: true
        };
        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                directionsRenderer.setDirections(result);
                const route = result.routes[0];
                const makerPositions = route.legs.map((leg) => leg.end_location);
                const r = [...routePath]
                const markers = makerPositions.map((position, index) => {
                    const orderElement = (route.waypoint_order[index] + 1 || 0);
                    r[orderElement].order_number = index + 1
                    return new googleMaps.Marker({
                        position: position,
                        label: "" + (index + 1),
                        map: myMap,
                    });
                });
                setRoutePath(r);
                setRouteMarkers(markers);
            } else {
                console.warn('Error when calling directionService', status, result);
            }
            ;
        });
    };

    const clearRouteMarkers = () => {
        if (directionsRenderer) {
            directionsRenderer.set('directions', null);
        }
        ;
        routeMarkers.forEach((marker) => {
            marker.setMap(null);
        });
    };
    const removeAll = () => {
        setShow(true)
    }
    const handleResult = async (string) => {
        if (string === 'YES') {
            const temp = []
            onMapMode();
            for (const mower of routePath) {
                mower?.id && temp.push(mower?.id);
            }
            let res = await mowersService.clearFromAllRoutes(temp);
            if (res) {
                getMowersData(dispatch);
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    setShow(false);
                }, 2000)
            }
        }
        if (string === 'NO') {
            setShow(false);
        }
    }
    const onMapMode = () => {
        clearRouteMarkers();
        setMode({map: true});
    };

    const propertyViewToggle = () => {
        dispatch(setPropertyMode(true));
        onMapMode();
    }

    const onRouteMode = () => {
        setMode({route: true});
        dispatch(saveCurrentMowerData(null));
    };

    return (
        <Container>
            {address && <PositionBar title={fullAddress}>{address}</PositionBar>}
            <MapActionButton
                className="map-mode"
                active={false}
                top={address ? 60 : 10}
                right={240}
                onClick={propertyViewToggle}
                icon={PropertyIcon}
                toolTipTitle="Property View"
            />
            <MapActionButton
                className="map-mode"
                active={mode.map}
                top={address ? 60 : 10}
                right={195}
                onClick={onMapMode}
                icon={MapIcon}
                toolTipTitle="Map View"
            />
            <MapActionButton
                className="route-mode"
                active={mode.route}
                top={address ? 60 : 10}
                right={150}
                onClick={onRouteMode}
                icon={RouteIcon}
                toolTipTitle="Route View"
            />
            {mode.route && routePath?.length > 1 && (
                <MapActionButton
                    className="export-route"
                    active={showExporter}
                    top={address ? 60 : 10}
                    right={105}
                    onClick={() => setShowExporter(true)}
                    icon={ExportRouteIcon}
                    toolTipTitle="Share Route Map"
                />
            )}
            {mode.route && routePath?.length > 1 && (
                <MapActionButton
                    className="remove-all-routes"
                    top={address ? 60 : 10}
                    right={60}
                    onClick={() => removeAll()}
                    icon={RemoveRouteFromMap}
                    toolTipTitle="Clear Route"
                />
            )}
            <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API}}
                defaultCenter={DefaultPoint}
                center={mapCenter}
                zoom={DefaultZoomLevel}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => {
                    setMyMap(map);
                    setGoogleMaps(maps);
                }}
            >
                {clickedPoint && (
                    <ClickedPoint lat={clickedPoint.lat} lng={clickedPoint.lng}/>
                )}
                {mowersOnMap.map((m, i) => {
                    const mowerLoc = getMowerLocation(m);
                    return (
                        <MowerMarker
                            key={i}
                            lat={mowerLoc.lat}
                            lng={mowerLoc.lng}
                            mower={m}
                            notifications={allMowersNotification}
                            onClick={() => {
                                if (mowersOnMap.length === 1 && !mode.route) {
                                    return;
                                }
                                ;
                                dispatch(saveCurrentMowerData(m));
                                setClickedPoint({
                                    lat: mowerLoc.lat,
                                    lng: mowerLoc.lng
                                })
                            }}
                        />
                    );
                })}
                {mode.route && firstPoint && (
                    <StartRouteMarker handleLocationSelected={handleLocationSelected} lat={firstPoint.lat}
                                      lng={firstPoint.lng} address={userClone?.subscriber?.address}/>
                )}
            </GoogleMapReact>
            {show && <ConfirmationDialog message={'Are you sure you want to clear all the robots from the route?'}
                                         titleMessage={'Clear Route'} result={handleResult}/>}
            <RouteExporter
                show={showExporter}
                setShow={setShowExporter}
                routePath={routePath}
            />
            {animationPopup && <SuccessAnimation/>}
        </Container>
    );
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.color.white};

  @media (max-width: 1024px) {
    height: 100%;
  }

  @media (max-width: 767px) {
    height: 100vh;
  }
`;

export const Marker = styled.div`
  border: 3px solid
    ${(props) =>
    props.errStatus === "red"
        ? props.theme.color.red[100]
        : props.errStatus === "green"
            ? props.theme.color.green[500]
            : props.theme.color.red[500]};
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.white};
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.selected && `box-shadow: 0px 0px 5px 10px rgb(${props.errStatus === GREEN_COLOR ? `129 166 82` : `240 83 72`} / 60%);`};
`;

const CountMarker = styled.div`
  position: relative;
  left: 45px;
  bottom: 50px;
  font-size: 18px;
`;

const RouteMarkerContainer = styled.div`
  position: relative;
  bottom: ${(props) => props.bottom}px;
  right: 37.5px;
`;

const TextMarker = styled.div`
  min-width: 220px;
  max-width: 100%;
  border-radius: 9px;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: 0 20px 40px 0 rgba(7, 7, 6, 0.3);
  padding: 10px;
`;

const AddressBlockDiv = styled.div`
  display: flex;
`;

const AddressDiv = styled.div`
  min-width: 80%;
  float: left;
`;

const EditButton = styled.button`
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
`;

const FirstLineText = styled.div`
  height: 9px;
  width: 21px;
  opacity: 0.4;
  color: ${(props) => props.theme.color.grey[425]};
  font-family: ${(props) => props.theme.font.FreightSansBold};
  font-size: 9px;
  letter-spacing: 0;
  line-height: 10px;
  margin-left: 8.5px;
`;
const AddressFirstLineText = styled.div`
  width: 21px;
  opacity: 0.4;
  color: ${(props) => props.theme.color.grey[425]};
  font-family: ${(props) => props.theme.font.FreightSansBold};
  font-size: 9px;
  letter-spacing: 0;
  line-height: 10px;
`;
const SecondLineText = styled.div`
  color: ${(props) => props.theme.color.grey[425]};
  font-family: ${(props) => props.theme.font.FreightSansBold};
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  margin-left: 8.5px;
`;

const Triggle = styled.div`
  width: 0;
  height: 0;
  border-left: ${(props) => props.theme.spacing[1]}px solid transparent;
  border-right: ${(props) => props.theme.spacing[1]}px solid transparent;
  border-top: 8px solid white;
  position: relative;
  left: 37.5px;
`;

const ImageRouter = styled.img`
  position: relative;
  left: ${(props) => props.left}px;
`;

export const PositionBar = styled.div`
  width: 100%;
  height: 50px;
  padding: 0px ${(props) => props.theme.spacing[4]}px;
  background-color: ${(props) => props.theme.color.background};
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  font-size: 13px;
  color: ${(props) => props.theme.color.text};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CustomIconButton = styled.button`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid
    ${(props) => (props.on ? props.theme.color.primary : "white")};
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  background-color: white;
  padding: ${(props) => props.padding}px;
  z-index: 1;
  outline: none;

  img {
    width: 100%;
  }

  :hover {
    border: 1px solid ${(props) => props.theme.color.primary};
  }
`;

const ClickedPoint = styled.div`
  position: relative;
  top: -10px;
  left: -10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.primary};
  width: 70px;
  height: 70px;
  opacity: 0.2;
`;

export default MapComponent;

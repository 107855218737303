import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "rsuite";

import { logout } from "Redux/actions/authAction";

const SettingMenu = ({ ...props }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="account-menu">
      <Popover {...props}>
        <Username>{user && user.first_name ? user.first_name : ""} {user && user.last_name ? user.last_name : ""}</Username>
        <div>
          { user?.role?.role_name === "owner" &&
              <>
                <CLickAbleText onClick={() => props.showManagementScreen()}>User Management</CLickAbleText>
                <CLickAbleText onClick={() => props.showMowerManagementScreen()}>Mower Management</CLickAbleText>
              </>
          }
          <CLickAbleText onClick={() => props.showCredentialsScreen()}>Manufacturer Credentials</CLickAbleText>
          <LogoutButton onClick={() => handleLogout()}>Log out</LogoutButton>
        </div>
      </Popover>
    </div>
  );
};

const Username = styled.strong`
  color: white;
  font-size: 16px;
`;

const CLickAbleText = styled.div`
  padding: 10px 0px;
  font-weight: bold;
  border: 1px solid #181717;
  color: #7F8082;
  border-width:1px 0 1px 0;
  font-size: 13px;
  cursor: pointer;
  width: 100%;
`;
const LogoutButton = styled.button`
  margin: 30px 0px 10px 0px;
  width: 100%;
  background-color: red;
  border: none;
  color: white;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: bolder;
  border-radius: 5px;
`;

export default SettingMenu;

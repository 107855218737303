import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Dialog, DialogActions} from "@material-ui/core";

import {maxAllowedMowersOnRoute} from "../../Contants";
import {useSelector} from "react-redux";

const MaxCountReachDialog = ({count, showPopup, setShowPopup, onAction}) => {

    const {selectedMowers} = useSelector((state) => state.mower);
    const [notOnRouteCount, setNotOnRouteCount] = useState(0);

    useEffect(() => {
        let count = 0;
        for(let i = 0; i < selectedMowers.length ; i++){
            if(!selectedMowers[i]?.onRoute){
                count+=1
            }
        }
        setNotOnRouteCount(count);
    },[selectedMowers?.length]) // eslint-disable-line

    return <>
        <Dialog open={showPopup} onClose={() => setShowPopup()} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <ConfirmWrapper>
                <div className='heading'>
                    Mowers on Route
                </div>
                <div className='subHeading'>
                    <p>
                        You currently have (<Count> {count} </Count>) of mowers on this route
                    </p>
                    <p>
                        You selected (<Count> {notOnRouteCount} </Count>) of mowers which is more than the maximum (<Count> {maxAllowedMowersOnRoute} </Count>) allowed
                    </p>
                </div>
                <div>
                    <DialogActions>
                        <ActionButtonWrapper justify="center">
                            <DialogButton padding={{}} onClick={onAction} color="#F75C28">
                                Go Back
                            </DialogButton>
                        </ActionButtonWrapper>
                    </DialogActions>
                </div>
            </ConfirmWrapper>
        </Dialog>
    </>
};

const Count = styled.span`
    color: #F75C28;
    font-weight: bold;
    font-family: 'Roboto';
`;
const ActionButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: ${props => props.justify};
`;
const DialogButton = styled.button`
  border-radius: 4px;
  cursor: pointer;
  outline: none !important;
  font-size: 15px;
  color: white;
  font-weight: bold;
  padding: ${props => props.padding ? "10px 160px 8px" : "10px 50px 8px"};
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  background: ${(props) => `${props.color ?? 'white'}`};
  :focus {
    background: ${(props) => props.theme.grey};
  }
`;
const ConfirmWrapper = styled.div`
    padding: 40px;
    width: 500px;
    .heading {
        font-size: 21px;
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
    };
    .subHeading {
        font-size: 14px;
        color: #E2E2E2;
        opacity: 65%;
        margin-bottom: 70px;
    };
`;

export default MaxCountReachDialog;
import { createAction } from "redux-actions";
import {
    SAVE_FILTER,
    CLEAR_FILTER,
    CLEAR_ALL_FILTER, SELECTED_USER_MODE
} from "Redux/actionTypes";

export const clearFilter = ({ filterName }) => async (dispatch) => {
    dispatch(clearFilterAction({ filterName }));
};

export const saveFilter = ({ filterName, values }) => async (dispatch) => {
    dispatch(saveFilterAction({ filterName, values }));
};

export const clearAllFilter = () => async (dispatch) => {
    dispatch(clearAllFilterAction());
};

export const setViewMode = (val) => async (dispatch) => {
    dispatch(selectedUserMode(val));
};

const clearFilterAction = createAction(CLEAR_FILTER);
const saveFilterAction = createAction(SAVE_FILTER);
const clearAllFilterAction = createAction(CLEAR_ALL_FILTER);
const selectedUserMode = createAction(SELECTED_USER_MODE);

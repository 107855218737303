import React, {useEffect, useMemo, useState} from 'react';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

import {DialogItem} from "./DialogItem";
import StartOptions from "./StartOptions";
import ParkOptions from "./ParkOptions";
import SuccessAnimation from "../SuccessAnimation";
import {CircularProgressComp, MowerActionFooter} from "../Common";
import {getMowersData} from "../../Pages/Home";
import {mowersService} from "../../Utils/mowerActions";
import {clearSelectedMowers} from "../../Redux/actions/mowerAction";
import {scrollBarStyles} from "../Common/styles";
import {maxAllowedMowersOnRoute} from "../../Contants";
import MaxCountReachDialog from "./MaxCountReachDialog";

const MultiActionComp = ({open, onClose, dialogTitle, selectedMower, mode, routePath }) => {
    const {selectedMowers, mowers} = useSelector((state) => state.mower);
    const dispatch = useDispatch();
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [startType, setStartType] = useState(null);
    const [parkType, setParkType] = useState(null);
    const [duration, setDuration] = useState(10);
    const [error, setError] = useState();
    const [addToRouteCount, setAddToRouteCount] = useState(0);
    const [maxAddToRouteCount, setMaxAddToRouteCount] = useState(false);

    useEffect(() => {
        if(selectedMowers?.length === 0){
            closeDialog();
        }
    },[selectedMowers]) // eslint-disable-line

    useEffect(() => {
        if(dialogTitle === "Add To Route") {
            let count = 0;
            for(let i = 0; i < mowers.length ; i++){
                if(mowers[i]?.onRoute){
                    count+=1
                }
            }
            setAddToRouteCount(count);
        }
    },[dialogTitle]) // eslint-disable-line

    const confirmedAction = async () => {
        let res = null;
        let selectedMowersNotOnRoute = [];
        setOpenProgressDialog(true);
        switch (dialogTitle) {
            case 'Add To Route':
                for(let i = 0; i < selectedMowers.length ; i++){
                    if(selectedMowers[i]?.onRoute === false){
                        selectedMowersNotOnRoute.push({...selectedMowers[i]})
                    }
                }
                if((addToRouteCount + selectedMowersNotOnRoute.length) <= maxAllowedMowersOnRoute){
                    res = await mowersService.addMowers(selectedMowers);
                } else {
                    setMaxAddToRouteCount(true);
                }
                break;
            case 'Remove From Route':
                res = await mowersService.removeMowers(selectedMowers);
                break;
            case 'Park Mowers':
                res = await mowersService.parkMowers(dialogTitle, parkType, duration, selectedMowers, dispatch);
                if (!res) {
                    setOpenProgressDialog(false);
                }
                break;
            case 'Start Mowers':
                res = await mowersService.startMowers(dialogTitle, startType, duration, selectedMowers, dispatch);
                if (!res) {
                    setOpenProgressDialog(false);
                }
                break;
            default:
                return;
        }
        setOpenProgressDialog(false);
        if(dialogTitle === "Add To Route" && ((addToRouteCount + selectedMowersNotOnRoute?.length) > maxAllowedMowersOnRoute)) {
            return;
        }
        if (res) {
            setAnimationPopup(true);
            setTimeout(() => {
                getMowersData(dispatch, [...selectedMowers]);
            },2000)
        } else {
            setError("Error: Cannot perform this action");
        }
        setTimeout(() => {
            setAnimationPopup(false);
            setError(null);
            if (res) {
                closeDialog();
                dispatch(clearSelectedMowers())
            }
        }, 2000)
    }

    const closeDialog = () => {
        onClose();
        setDuration(10);
    }

    const dataRows = useMemo(() => {
        let rows = [];
        selectedMowers && selectedMowers.map((mower) => (
           rows.push(
               <DialogItem
                   action={dialogTitle}
                   mower={mower}
                   selected={selectedMower && mower.id === selectedMower.id}
                   isRouteMode={mode.route}
                   routeNum={mower.onRoute && routePath.find(
                       (route) => {
                           return route?.id === mower?.id
                       }
                   )?.order_number}
               />
           )
        ))
        return rows;
    },[selectedMower, selectedMowers, mode, routePath, dialogTitle])

    return (
        <Dialog open={open} onClose={() => closeDialog()}
                aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <Container>
                <DialogTitle style={{paddingTop: '0px'}}>
                    <div>{dialogTitle}</div>
                    {dialogTitle === "Add To Route" &&
                        <SubHeading>
                            The maximum number of mowers allowed on the route is {maxAllowedMowersOnRoute}
                        </SubHeading>
                    }
                </DialogTitle>
                {error && <Error>{error}</Error>}
                <DialogContent>
                    {dataRows}
                </DialogContent>
                <ActionDropdown>
                    {dialogTitle === "Start Mowers" &&
                        <StartOptions setDuration={setDuration} duration={duration} setStartType={setStartType} />
                    }
                    {dialogTitle === "Park Mowers" &&
                        <ParkOptions setDuration={setDuration} duration={duration} setParkType={setParkType} />
                    }
                </ActionDropdown>
                <MowerActionFooter
                    close={() => closeDialog()}
                    action={confirmedAction}
                />
            </Container>
            {maxAddToRouteCount &&
                <>
                    <MaxCountReachDialog
                        count={addToRouteCount}
                        showPopup={maxAddToRouteCount}
                        setShowPopup={setMaxAddToRouteCount}
                        onAction={() => setMaxAddToRouteCount(false)}
                    />
                </>

            }
            {animationPopup && <SuccessAnimation/> }
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
            }
        </Dialog>
    );
};

const SubHeading = styled.div`
    color: #F75C28;
    font-size: 12px; 
`;
const ActionDropdown = styled.div`
    width: 100%;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 30px;
    background-color: ${(props) => props.theme.color.grey[700]};
    padding: ${(props) => props.theme.spacing[7]}px;
    color: ${(props) => props.theme.color.text};
    overflow-Y: auto;
    overflow-X: hidden;
    .MuiDialogContent-root {
       ${scrollBarStyles}
    }
`;
const Error = styled.div`
    color: red;
`;
export const Main = styled("div")`
  font-family: sans-serif;
  margin-top:10px;
  background-color: ${(props) => props.theme.color.grey[700]};
`;
export const DropDownContainer = styled("div")`
  width: 28.5em;
  margin: 0 auto;
`;
export const DropDownHeader = styled("div")`
 display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1rem;
  color: white;
  background: ${(props) => props.theme.color.grey[700]};
  border-radius: 10px
`;
export const DropDownListContainer = styled("div")`
    margin-bottom: 10px;
`;
export const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
  background: ${(props) => props.theme.color.grey[700]};
  border : 1px solid white;
  border-radius: 10px
`;
export const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
  padding-left: 5px;
  margin-right: 5px;
  &:hover {
    border-radius: 7px;
    background-color: ${(props) => props.theme.color.grey[500]};
  };
`;
export const CollapseImage = styled.img`
  width: 10px;
  height: 8px;
  margin-right: 10px;
  cursor: pointer;
`;
export default MultiActionComp;
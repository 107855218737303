import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import styled from "styled-components";
import {Dialog} from "@material-ui/core";

import ManufacturerDropdown from "./ManufacturerDropdown";
import {LeftDiv, RightDiv, ErrorDiv, Header, Wrapper, MainWrapper} from "../Common/styles";
import Input, {PasswordInput} from "../Base/Input";
import {ActionButtonComponent} from "../Common";

import Delete from "../../Assets/images/delete.png";
import Error from "../../Assets/images/error.png";

const minimumPasswordLength = 4;

const DeletePopup = ({showPopup, setShowPopup, onDelete}) => {
    return <>
        <Dialog open={showPopup} onClose={() => setShowPopup()} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <ConfirmWrapper>
                <div className='heading'>
                    <div className='subHeading'>
                        Are you sure you want to delete credentials?
                    </div>
                </div>
                <ActionButtonComponent close={setShowPopup} action={onDelete} />
            </ConfirmWrapper>
        </Dialog>
    </>
}

const AddUser = ({selectedManufacture , onClose, onDelete, onAdd, onUpdate}) => {
    const {user} = useSelector((state) => state.auth);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [manufacturer, setManufacturer] = useState(undefined);
    const [passwordError, setPasswordError] = useState(null);
    const [loginMyth, setLoginMyth] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);

    const setDataState = () => {
        setUsername(selectedManufacture?.username ?? "");
        setPassword(selectedManufacture?.password ?? "");
        setLoginMyth(selectedManufacture?.login_myth ?? "");
    }

    useEffect(() => {
        if(selectedManufacture){
            setManufacturer(selectedManufacture?.manufacturer);
            setDataState();
        }
    },[selectedManufacture]) // eslint-disable-line
    const confirmedAction = () => {
        if(manufacturer === undefined || manufacturer === null){
            setManufacturer(null);
            return
        }
        if(username?.trim() === '' || username === null){
            setUsername(null);
            return;
        }
        if(manufacturer?.toLowerCase() === "husqvarna"){
            if(password?.trim() === '' || password === null ){
                setPassword(null);
                setPasswordError("Password is required field");
                return;
            }
            if(password?.length < minimumPasswordLength){
                setPasswordError(`Password should be minimum ${minimumPasswordLength} characters long`);
                return;
            }
        }
        if(manufacturer?.toLowerCase() === "nexmow"){
            if(loginMyth?.trim() === '' || loginMyth === null ) {
                setLoginMyth(null);
                return;
            }
        }
        if(selectedManufacture){
            let data = {
                id: selectedManufacture.id,
                username: username,
                password: password,
                manufacturer: manufacturer,
                login_myth: loginMyth
            }
            onUpdate(data);
        } else {
            let data = {
                username: username,
                password: password,
                manufacturer: manufacturer,
                login_myth: loginMyth
            }
            onAdd(data);
        }
    }
    const errorComponent = (val) => (
        <ErrorDiv>
            <div className='p'> {val}</div>
            <div className="imgWrapper">
                <img src={Error} alt="icon" width={18}/>
            </div>
        </ErrorDiv>
    )
    const stateUpdateSetter = (val) => {
        setDataState();
        setManufacturer(val?.text);
    }
    const headerComponent = () => {
        return <>
            {selectedManufacture ?
                <div style={{display: "flex"}}>
                    <div className="heading">
                        {`Edit ${selectedManufacture?.username}`}
                    </div>
                    {user?.role?.role_name === "owner" &&
                        <div className="deleteButton" >
                            <img onClick={() => setSelectedUser(selectedManufacture.id)} src={Delete} alt="icon" width={40}/>
                        </div>
                    }
                </div>
                :
                <div className="heading">
                    Add New Credential
                </div>
            }
        </>
    }

    return (
        <MainWrapper manufact>
            {selectedUser &&
                <DeletePopup
                    showPopup={selectedUser}
                    setShowPopup={() => setSelectedUser(null)}
                    onDelete={() => onDelete(selectedUser)}
                />
            }
            <LeftDiv />
            <RightDiv>
                <Wrapper>
                    <Header>
                        {headerComponent()}
                    </Header>
                    <div>
                        <div>
                            <ManufacturerDropdown
                                disable={selectedManufacture}
                                label="Manufacturer" selected={manufacturer}
                                onSelect={(val) => stateUpdateSetter(val)} />
                            <ErrorCompWrapper mb="15px">
                                {manufacturer === null &&
                                    errorComponent("Manufacturer is required field")
                                }
                            </ErrorCompWrapper>
                        </div>
                        <div>
                            <Input
                                paddingBottom={false}
                                label="User Name"
                                placeholder="User Name"
                                type="text"
                                error={username === null}
                                value={username}
                                onChange={setUsername}
                                width="90%"
                            />
                            <ErrorCompWrapper>
                                {username === null &&
                                    errorComponent("User Name is required field")
                                }
                            </ErrorCompWrapper>
                        </div>
                        {(manufacturer?.toLowerCase() === "husqvarna" || !manufacturer )&&
                            <InputWrapper>
                                <PasswordInput
                                    label="Password"
                                    disabled={manufacturer !== "husqvarna"}
                                    paddingBottom={false}
                                    placeholder="Password"
                                    type={selectedManufacture ? "password" : "text"}
                                    error={ manufacturer === "husqvarna" ? password === null : false}
                                    value={password}
                                    onChange={setPassword}
                                    width="90%"
                                />
                                <ErrorCompWrapper>
                                    {passwordError &&
                                        errorComponent(passwordError)
                                    }
                                </ErrorCompWrapper>
                            </InputWrapper>
                        }
                        {manufacturer?.toLowerCase() === "nexmow" &&
                            <InputWrapper>
                                <Input
                                    paddingBottom={false}
                                    label="Login Myth"
                                    placeholder="Login Myth"
                                    type="text"
                                    error={loginMyth === null}
                                    value={loginMyth}
                                    onChange={setLoginMyth}
                                    width="90%"
                                />
                                <ErrorCompWrapper>
                                    {loginMyth === null &&
                                        errorComponent("Login Myth is required field")
                                    }
                                </ErrorCompWrapper>
                            </InputWrapper>
                        }
                    </div>
                    <ActionButtonComponent close={onClose} action={confirmedAction} />
                </Wrapper>
            </RightDiv>
        </MainWrapper>
    );
};

const InputWrapper = styled.div`
    margin-top: 15px;
`;
const ErrorCompWrapper = styled.div`
    height: 40px;
    margin-bottom: ${props => props.mb ? props.mb : "0px"};
`;
const ConfirmWrapper = styled.div`
    padding: 20px;
    text-align: center;
    font-size: 21px;
    color: white;
    font-weight: bold;
    .heading{
        display: flex;
        justify-content: center;
        height: 100px;
    };
    .subHeading{
        width: 90%;
    }
`;

export default AddUser;

import React from "react";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis,} from "recharts";
import styled from "styled-components";

import {scrollBarStyles} from "../Common/styles";

const BarCharts = ({width, chartData}) => {
    return (
        <ResponsiveContainer>
            <BarChart
                width={width}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    bottom: 5,
                }}
                barSize={8}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"/>
                <YAxis datakey="Errors"/>
                <Tooltip/>
                <Bar dataKey="Errors" radius={4} fill="#E64B39"/>
            </BarChart>
        </ResponsiveContainer>
    );
};

const ResponsiveContainer = styled.div`
  flex: 1;
  overflow: auto;
  margin: ${(props) => props.theme.spacing[4]}px 0px;
  ${scrollBarStyles}
`;

export default BarCharts;

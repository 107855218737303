import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";

import DetailInfoPan from "./DetailInfoPan";
import Sidebar from "Components/Sidebar";
import MapComponent from "./Map";
import Filterbar from "Components/Filterbar";
import CreateRobotbar from "Components/CreateRobotbar";
import MobileBottom from "Components/Mobile/Bottom";
import PropertyMap from "../../Components/PropertyMap";
import PropertyDetailInfoPan from "../../Components/PropertyDetailInfoPan";
import RemoveRobotPan from "../../Components/Sidebar/RemoveRobotPan";
import PropertySidebar from "../../Components/PropertySidebar";

import {getMowers, getPeripherals, saveCurrentMowerData} from "Redux/actions/mowerAction";
import {currentUser} from "Redux/actions/authAction";
import {DeviceContext} from "Context/DeviceContext";
import {getHouses, getUserBasedHouses} from "../../Redux/actions/propertActions";


export const getMowersData = (dispatch, fetchSelected = []) => {
    dispatch(getMowers(fetchSelected));
    dispatch(getPeripherals());
}

const Home = () => {
    const dispatch = useDispatch();
    const {propertyMode} = useSelector((state) => state.property);
    const {selectedUserMode} = useSelector((state) => state.filter);
    const {user, isThirdParty} = useSelector((state) => state.auth);
    const {selectedMower} = useSelector((state) => state.mower);
    const {smallDevice, middleDevice, largeDevice} = useContext(DeviceContext);
    const [fetchMowersData, setFetchMowersData] = useState(true);
    const [fetchHouseData, setFetchHouseData] = useState(true);
    const [showFilter, setShowFilterPan] = useState(false);
    const [mode, setMode] = useState({map: true});
    const [routePath, setRoutePath] = useState([]);
    const [showCreateRobotbar, setShowCreateRobotbar] = useState(false);
    const [showCreateRemoveRobotbar, setShowRemoveCreateRobotbar] = useState(false);
    const [clickedPointOnMap, setClickedPointOnMap] = useState(null);
    const Wrapper = middleDevice ? MiddleCol : React.Fragment;

    useEffect(() => {
        if (!isThirdParty) {
            dispatch(currentUser());
        }
    }, [dispatch, isThirdParty]);

    useEffect(() => {
        if (mode.route) {
            setClickedPointOnMap(null);
        }
    }, [routePath, mode]);

    useEffect(() => {
        if (!propertyMode) {
            if (selectedUserMode) {
                getMowersData(dispatch);
                setFetchMowersData(true);
            } else {
                if (user && fetchMowersData) {
                    getMowersData(dispatch);
                    setFetchMowersData(false);
                }
            }
        } else {
            if (selectedUserMode) {
                dispatch(getUserBasedHouses(selectedUserMode));
                setFetchHouseData(true);
            } else {
                if (user && fetchHouseData) {
                    dispatch(getHouses())
                    setFetchHouseData(false);
                }
            }
        }
    }, [user, dispatch, selectedUserMode, propertyMode]); // eslint-disable-line


    useEffect(() => {
        handleCloseDetailInfoPan();
    }, [selectedUserMode]) // eslint-disable-line

    const handleCloseDetailInfoPan = () => {
        setClickedPointOnMap(null);
        dispatch(saveCurrentMowerData(null));
    };

    const handleCloseFilterPan = () => setShowFilterPan(false);
    const handleOpenFilterPan = () => setShowFilterPan(true);
    const handleOpenRobotPan = () => setShowCreateRobotbar(true);
    const handleOpenRemoveRobotPan = () => setShowRemoveCreateRobotbar(true);
    const handleCloseRobotPan = () => setShowCreateRobotbar(false);
    const handleCloseRemoveRobotPan = () => setShowRemoveCreateRobotbar(false);

    return (
        <Page smallDevice={smallDevice} largeDevice={largeDevice}>
            {propertyMode ?
                <>
                    <PropertySidebar/>
                    <Wrapper>
                        <PropertyDetailInfoPan/>
                        <PropertyMap/>
                    </Wrapper>

                </>
                :
                <>
                    <Sidebar
                        isFilterMenu={showFilter}
                        openFilterMenu={handleOpenFilterPan}
                        openCreateRobotbar={handleOpenRobotPan}
                        onOpenRemoveRobotBar={handleOpenRemoveRobotPan}
                        onSelectMower={(m) => {
                            dispatch(saveCurrentMowerData(m));
                            setClickedPointOnMap(null);
                        }}
                        selectedMower={selectedMower}
                        routePath={routePath}
                        mode={mode}
                    />
                    <Wrapper>
                        <DetailInfoPan onClose={handleCloseDetailInfoPan}/>
                        <MapComponent
                            mowerData={selectedMower}
                            mode={mode}
                            setMode={setMode}
                            routePath={routePath}
                            setRoutePath={setRoutePath}
                            clickedPoint={clickedPointOnMap}
                            setClickedPoint={setClickedPointOnMap}
                        />
                    </Wrapper>
                    {smallDevice && !selectedMower && <MobileBottom/>}
                    <Filterbar isCollapsed={showFilter} onClose={handleCloseFilterPan}/>
                    <CreateRobotbar
                        isCollapsed={showCreateRobotbar}
                        onClose={handleCloseRobotPan}
                    />
                    <RemoveRobotPan
                        isCollapsed={showCreateRemoveRobotbar}
                        onClose={handleCloseRemoveRobotPan}
                    />
                </>
            }
        </Page>
    );
};

const Page = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => !props.smallDevice && "100vh"};
  box-sizing: border-box;
  padding-top: ${(props) => !props.smallDevice && props.theme.topBarSize}px;
  flex-direction: ${(props) => (props.smallDevice ? "column" : "row")};
`;
const MiddleCol = styled.div`
  width: 100%;
  overflow: auto;
`;

export default Home;

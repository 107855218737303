import React from 'react';
import styled from "styled-components";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts";

const IncidentCount = (props) => {
    return (
        <div>
            <Row>
                <div className='center'>
                    Incident Count
                </div>
                <div>
                    Responsive Timer
                </div>
            </Row>
            <div>
                <BarChart
                    width={330}
                    height={270}
                    data={props.data ?? []}
                    margin={{
                        top: 30,
                        right: 20,
                        left: 0,
                        bottom: 0,
                    }}
                    barSize={8}
                >
                    <CartesianGrid stroke="grey"  vertical={false} strokeDasharray="1 5" />
                    <XAxis dataKey="date" />
                    <YAxis datakey="val" />
                    <Bar dataKey="val" radius={4} fill="#E64B39" />
                </BarChart>
            </div>
        </div>
    );
};

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    
    .center {
        align-self: center;
   }
`;
export default IncidentCount;

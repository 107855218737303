import React, {useContext, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";

import {DeviceContext} from "Context/DeviceContext";
import {MowerContext} from "../../../Context/MowerContext";

import RobotPan from "./RobotPan";
import NotificationPan from "./NotificationPan";
import MaintenancePan from "./MaintenancePan";
import ErrorStatePan from "./ErrorStatePan";
import TourComp from "./TourComp";

const DetailInfoPan = ({onClose}) => {
    const {selectedMower} = useSelector((state) => state.mower);
    const {mowerDetails} = useContext(MowerContext);
    const {smallDevice, largeDevice} = useContext(DeviceContext);
    const width = selectedMower ? largeDevice ? "520px" : "100%" : "0px";
    const height = selectedMower ? smallDevice ? "100vh" : "100%" : "0px";
    const [isTourOpen, setTourOpen] = useState(false);

    const onTransitionEnd = () => {
        const visitedTour = localStorage.getItem("visitedTour");
        if (mowerDetails && !visitedTour) {
            setTourOpen(true);
            localStorage.setItem("visitedTour", true);
        }
    };

    return (
        <Container onTransitionEnd={onTransitionEnd} width={width} height={height}>
            <Scrollbars>
                <RobotPan onClose={onClose}/>
                <MaintenancePan mower={mowerDetails}/>
                <NotificationPan/>
                <ErrorStatePan/>
            </Scrollbars>
            <TourComp isTourOpen={isTourOpen} setTourOpen={setTourOpen}/>
        </Container>
    );
};

export const Container = styled.div`
  width: ${(props) => (props.width)};
  height: ${(props) => (props.height)};
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  border-left: 1px solid ${(props) => props.theme.color.border};
  border-right: 1px solid ${(props) => props.theme.color.border};
  transition: width 0.5s cubic-bezier(0.47, 1.64, 0.21, 0.8);

  @media (max-width: 1023px) {
    overflow-x: scroll;
  }
`;

export default DetailInfoPan;

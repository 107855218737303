import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {Dialog, DialogActions} from "@material-ui/core";
import CloseImg from "../../Assets/images/x.png";
import {DeviceContext} from "../../Context/DeviceContext";
import Input from "../Base/Input";
import {emailRegex, webAddressRegex} from "../../Utils/global";
import {requestDemo} from "../../Utils/api";
import SuccessAnimation from "../SuccessAnimation";


const reqError = "Field is required";
const urlSetter = (url) => {
    const lastChar = url.charAt(url.length - 1);
    if(lastChar === '/'){
        return url
    }
    return `${url}/`;
}
const DemoRequest = (props) => {
    const { smallDevice } = useContext(DeviceContext);
    const [error, setError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [animationPopup, setAnimationPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');


    const handleConfirm = async () => {
        const errorSetter = () => {
            if(firstName === ""){
                setFirstName(null);
            }
            if(lastName === ""){
                setLastName(null);
            }
            if(email){
                let isValid = emailRegex.test(email);
                if(!isValid){
                    setEmail(null);
                }
            } else {
                setEmail(null);
            }
            if(phone === ""){
                setPhone(null);
            }
        }
        const fields = [email, firstName, lastName, phone];
        if (fields.includes(null) || fields.includes("")) {
            errorSetter();
            return;
        }
        if(phone?.length !== 10){
            setPhoneError(true);
            return ;
        }
        let isValid = emailRegex.test(email);
        if(!isValid){
            setEmail(null);
            return;
        }
        if(companyUrl){
            let isValid = webAddressRegex.test(`${urlSetter(companyUrl.trim())}/`);
            if(!isValid){
                setCompanyUrl(null);
                return;
            }
        }

        let data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            email : email,
            company_name: companyName,
            company_url: companyUrl,
        };
        const res = await requestDemo(data);
        if(res.data?.success){
            setAnimationPopup(true);
            setTimeout(() => {
                setAnimationPopup(false);
                setError(null);
                props.close(false);
            }, 2000)
        } else {
            setError(res?.data?.error ?? "Error: can not perform this action ");
            setTimeout(() => {
                setError(null);
            },2000)
        }
    }
    const phoneLengthSetter = (val) => {
        if(val?.length <= 10){
            setPhone(val);
            if(phoneError){
                setPhoneError(false);
            }
        }
    }

    return (
        <Dialog open={props.demoPopUp} onClose={() => props.close(false)} aria-labelledby="form-dialog-title"
                maxWidth="sm">
            <Container smallDevice={smallDevice}>
                {error && <Error>{error}</Error>}
                {
                    (phoneError && phone !== null && phone.length > 0) &&
                    <Error>Error: Invalid phone number</Error>
                }
                {animationPopup && <SuccessAnimation/>}
                <HeaderSection smallDevice={smallDevice}>
                    {smallDevice ?
                        <>
                            <Header smallDevice={smallDevice}>
                                <div className="heading">
                                    Request a Demo
                                </div>
                                <div>
                                    <img onClick={() => props.close(false)} src={CloseImg} alt="icon" width={13}/>
                                </div>
                            </Header>
                        </>
                        :
                        <>
                            <CloseDiv>
                                <img onClick={() => props.close(false)} src={CloseImg} alt="icon" width={18}/>
                            </CloseDiv>
                            <Header>
                                <div className="heading">
                                    Request a Demo
                                </div>
                            </Header>
                        </>

                    }
                </HeaderSection>
                <Wrapper smallDevice={smallDevice}>
                    <FormBody smallDevice={smallDevice}>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                required={true}
                                label="First Name"
                                placeholder="First Name"
                                type="text"
                                error={firstName === null}
                                helperTxt={firstName === null ? reqError : ""}
                                value={firstName ?? ""}
                                onChange={(value) => setFirstName(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                required={true}
                                label="Last Name"
                                placeholder="Last Name"
                                error={lastName === null}
                                helperTxt={lastName === null ? reqError : ""}
                                type="text"
                                value={lastName ?? ""}
                                onChange={(value) => setLastName(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                required={true}
                                label="Email"
                                placeholder="user@example.com"
                                type="email"
                                error={email === null}
                                helperTxt={email === null ? reqError : ""}
                                value={email ?? ""}
                                onChange={(value) => setEmail(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                required={true}
                                label="Phone"
                                error={phone === null || phoneError === true}
                                helperTxt={phone === null ? reqError : ""}
                                placeholder="_ _ _ - _ _ _ - _ _ _ _"
                                type="number"
                                value={phone ?? ""}
                                onChange={(value) => phoneLengthSetter(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                label="Company Name"
                                placeholder="Company Name"
                                type="text"
                                value={companyName ?? ""}
                                onChange={(value) => setCompanyName(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>
                        <div className='input'>
                            <Input
                                labelColor="#3B3D40"
                                label="Company URL"
                                placeholder="http://"
                                error={companyUrl === null}
                                helperTxt={companyUrl === null ? "Invalid address" : ""}
                                type="text"
                                value={companyUrl ?? ""}
                                onChange={(value) => setCompanyUrl(value)}
                                width={smallDevice ? "100%" : "90%"}
                            />
                        </div>

                    </FormBody>
                    {smallDevice ?
                        <DialogActions style={{marginBottom: "20px", display: 'block'}}>
                            <ActionButton smallDevice={smallDevice} marginLeft={0} backgroundColor='#E55525' marginRight={42} onClick={() => handleConfirm() }>
                                Confirm
                            </ActionButton>
                            <ActionButton smallDevice={smallDevice} marginTop={10} onClick={() => props.close(false)} backgroundColor='#565659' marginRight={60}>
                                Cancel
                            </ActionButton>
                        </DialogActions>
                        :
                        <DialogActions style={{marginBottom: "20px"}}>
                            <ActionButton smallDevice={smallDevice} onClick={() => props.close(false)} backgroundColor='#565659' marginRight={60}>
                                Cancel
                            </ActionButton>
                            <ActionButton smallDevice={smallDevice} backgroundColor='#E55525' marginRight={42} onClick={() => handleConfirm() }>
                                Confirm
                            </ActionButton>
                        </DialogActions>
                    }
                </Wrapper>
            </Container>
        </Dialog>
    );
};
const Error = styled.div`
    color: red;
`;

const FormBody = styled.div`
    width: 100%;
    display: ${props => props.smallDevice ? "block" : "flex"};
    ${props => !props.smallDevice && `flex-wrap: wrap`};

    .input {
        width: ${props => props.smallDevice ? "100%" : "50%"};
    };
`;

const Header = styled.div`
    display: flex;
    width: 100%;
    padding: ${props => props.smallDevice ? "4px 0px" : "10px 0px"};
    position: relative;
    justify-content: space-between;
    .heading {
        font-size: ${props => props.smallDevice ? "19px" : "20px"};
        font-weight: bold;
    }
`;
const ActionButton = styled.button`
  border-radius: 4px;
  width: ${props => props.smallDevice ? "100%" : "120px"};
  margin-right: ${props => props.smallDevice ? "0px" : `${props.marginRight}px`};
  ${props => props.smallDevice && `margin-left: 0px !important`};
  margin-top: ${props => props.marginTop}px;
  height: 33px;
  cursor: pointer;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  border: none;
  background:  ${(props) => props.backgroundColor} ;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: ${props => props?.smallDevice ? "0px 20px" : "10px 40px"};
`;
const HeaderSection = styled.div`
    width: 100%;
    padding: ${props => props?.smallDevice ? "20px 20px" : `10px 40px`};
`;

const CloseDiv = styled.div`
    width: 100%;
    text-align: end;
    cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 68vw;
  flex-direction: column;
  align-items: start;
  background-color: #1F2125;
  padding: ${props => props.smallDevice ? "0px 0px" : "10px 40px"};
  color: ${(props) => props.theme.color.text};
`;

export default DemoRequest;

import {mowerRef} from "fbconfig";
import firebase from "fbconfig";
import {removeNullOrUndefined, setStructure} from "./global";

const axios = require("axios").default;

const BASE_URL = process.env.REACT_APP_API_URL;
const REACT_APP_AVIATOR_API_URL = process.env.REACT_APP_AVIATOR_API_URL;

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getHeader = () => {
    const token = localStorage.getItem("access_token");
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
};

export const getToken = async () => {
    const url = `${REACT_APP_AVIATOR_API_URL}/sessions/token`;
    try {
        const response = await axios.get(url, {withCredentials: true});
        return response.data;
    } catch (error) {
        return null;
    }
};

export const authLogin = async ({email, password}) => {
    const url = `${BASE_URL}/auth/login`;
    try {
        const response = await axios.post(url, {email, password}, config);
        return {
            result: true,
            message: "Login successfully",
            data: response.data,
        };
    } catch (error) {
        return {
            result: false,
            message: error?.response?.data?.error,
            error_type: error.response?.data?.error_type,
            data: null,
        };
    }
};

export const authLoginV2 = async ({email, password}) => {
    const url = `${BASE_URL}/auth/loginv2`;
    try {
        const response = await axios.post(url, {email, password}, config);
        return {
            result: true,
            message: "Login successfully",
            data: response.data,
        };
    } catch (error) {
        return {
            result: false,
            message: error.response.data.error,
            error_type: error.response.data.error_type,
            data: null,
        };
    }
};

export const thirdPartyLogin = async ({email, social_uid, social_type}) => {
    const url = `${BASE_URL}/auth/social`;
    try {
        const response = await axios.post(url, {email, social_uid, social_type}, config);
        return {
            result: response?.data?.success ?? false,
            message: "Login successfully",
            data: response.data,
        };
    } catch (error) {
        return {
            result: false,
            message: error.response.data.error,
            error_type: error.response.data.error_type,
            data: null,
        };
    }
};

export const getCurrentUser = async () => {
    const url = `${BASE_URL}/auth/current`;
    try {
        const response = await axios.get(url, getHeader());
        return response.data;
    } catch (error) {
        console.log("Error when getting current user:", error);
        return {
            user: null,
        };
    }
};

export const authForgotPassword = async ({email}) => {
    const url = `${BASE_URL}/auth/forgot-password`;
    try {
        const response = await axios.post(url, {email}, config);
        return {
            result: response.data?.success ?? false,
            message: response?.data?.message,
        };
    } catch (error) {
        return {
            result: false,
            message: error.response.data.message ?? error.response.data.error,
        };
    }
};

export const authResetPassword = async ({token, password}) => {
    const url = `${BASE_URL}/auth/reset-password`;
    try {
        const response = await axios.post(url, {token, password}, config);
        return {
            result: response.data?.success ?? false,
            message: response?.data?.message ?? "",
        };
    } catch (error) {
        return {
            result: false,
            message: error.response.data.message ?? error.response.data.error,
        };
    }
};

export const authPostFleetCrendential = async ({manufacturer, username, password}) => {
    const url = `${BASE_URL}/fleet_mgmt/auth`;
    try {
        const response = await axios.post(url, {manufacturer, username, password}, getHeader());
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const sendActionAPI = async ({mower, type, item}) => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/${mower.id}/actions`;
    const manufacturer = mower.manufacturer;
    try {
        const response = await axios.post(url, {manufacturer, type, ...item}, getHeader());
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const sendDemoActionAPI = async ({mower, type, item}) => {
    const url = `${BASE_URL}/demo/fleet_mgmt/mowers/${mower.id}/actions`;
    const manufacturer = mower.manufacturer;
    try {
        const response = await axios.post(url, {manufacturer, type, ...item}, getHeader());
        return response?.data;
    } catch (error) {
        return error?.response?.data;
    }
};

export const setCuttingHeight = async ({mower, cutting_height}) => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/${mower.id}/settings`;
    try {
        const response = await axios.post(url, {cutting_height}, getHeader());
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const getMowerPeripherals = async () => {
    const url = `${BASE_URL}/fleet_mgmt/peripherals`;
    try {
        const response = await axios.get(url, getHeader());
        return response.data;
    } catch (error) {
        return [];
    }
};

export const exportRoute = async ({email, mower_ids}) => {
    const url = `${BASE_URL}/fleet_mgmt/export-route`;
    try {
        const response = await axios.post(url, {email, mower_ids}, getHeader());
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
};

export const bigCommerceSearch = async (email) => {
    const url = `${BASE_URL}/sso/account/search`;
    try {
        const response = await axios.post(url, {email});
        return [response.status, response.data];
    } catch (error) {
        return [error?.response.status, error?.response?.data];
    }
};

export const bigCommerceCreate = async ({first_name, last_name, email}) => {
    const url = `${BASE_URL}/sso/account/create`;
    try {
        const response = await axios.post(url, {first_name, last_name, email});
        return [response.status, response.data];
    } catch (error) {
        return [error?.response.status, error?.response?.data];
    }
};

export const bigCommerceLogin = async (customerId) => {
    const url = `${BASE_URL}/sso/login`;
    try {
        const response = await axios.post(url, {customer_id: customerId});
        return [response.status, response.data];
    } catch (error) {
        return [error?.response.status, error?.response?.data];
    }
};
export const addNewFleets = async (data, nexmow = false) => {
    const url = `${BASE_URL}/fleet_mgmt/fleets`;
    let structuredData = data;
    try {
        if (!nexmow) {
            const mowerData = removeNullOrUndefined(data);
            structuredData = setStructure(mowerData);
        }
        const response = await axios.post(url, {fleets: !nexmow ? structuredData : data}, getHeader());
        return response.data;
    } catch (error) {
        return error?.response.data;
    }
};

export const addMowerToRoute = async (mowerId) => {
    const batch = firebase.firestore().batch();
    for (const mow of mowerId) {
        const mRef = mowerRef.doc(mow);
        batch.update(mRef, {
            onRoute: true,
        })
    }
    await batch.commit();
};

export const removeMowerFromRoute = async (mowerId) => {
    await mowerRef.doc(mowerId).update({
        onRoute: false,
    })
};

export const removeAllMowerFromRoute = async (mowerIds) => {
    const batch = firebase.firestore().batch();
    for (const mow of mowerIds) {
        const mRef = mowerRef.doc(mow);
        batch.update(mRef, {
            onRoute: false,
        })
    }
    await batch.commit();
};
export const removeAllMowerFromRouteLive = async (mowerIds) => {
    const url = `${BASE_URL}/fleet_mgmt/toggle_routes`;
    const response = await axios.put(url, {mower_ids: mowerIds, on_route: false}, getHeader());
    return response?.data?.success;
};
export const updateDemoPeripheral = async (mowerId, periId, data) => {
    return await mowerRef.doc(mowerId).collection('peripherals').doc(periId).update(data);
};
export const updateCuttingHeightDemoMower = async (mowerId, data) => {
    return await mowerRef.doc(mowerId).update(data);
};
export const updateCuttingHeightDemoMowers = async (mowers, data) => {
    const batch = firebase.firestore().batch();
    for (const mow of mowers) {
        const mRef = mowerRef.doc(mow.id);
        batch.update(mRef, data)
    }
    await batch.commit();
};
export const updatePeripheral = async (data, id) => {
    const url = `${BASE_URL}/fleet_mgmt/fleet_peripheral/${id}`;
    try {
        const response = await axios.put(url, data, getHeader());
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
}
export const updateCalenderTasks = async (data, id) => {
    const url = `${BASE_URL}/fleet_mgmt/fleet/${id}/update_calendar`;
    try {
        const response = await axios.put(url, data, getHeader());
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
}
export const updateMultiCalenderTasks = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/fleets/update_calendar`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
            data: response.data
        };
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
}
export const createPeripheral = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/fleet_peripheral`;
    try {
        const response = await axios.post(url, data, getHeader());
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
}
export const getAddress = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API}`;
    try {
        const response = await axios.get(url);
        return response.data.results[0].formatted_address;
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
}

export const validateTokenForPassword = async (token) => {
    const url = `${BASE_URL}/auth/set-password/${token}`;
    try {
        const response = await axios.get(url);
        return {
            result: response?.data?.message,
            message: response?.data?.success
        };
    } catch (error) {
        return {
            result: error.response?.data?.success ?? false,
            message: error.response?.data?.error ?? "Token Error",
        };
    }
}

export const setPasswordForFirstTime = async (token, password, confirmPassword) => {
    const url = `${BASE_URL}/auth/set-password/${token}`;
    const body = {
        "password": password,
        "password_confirmation": confirmPassword
    }
    try {
        const response = await axios.post(url, body);
        return {
            result: response?.data?.success ?? false
        }

    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}

export const removeMultipleRobots = async (data) => {
    const url = `${BASE_URL}/robots`;
    try {
        const response = await axios.delete(url, {data: {...data}, ...getHeader()});
        return {
            result: response.data?.success ?? false,
            message: response.data?.message
        };
    } catch (error) {
        return {
            result: false,
            message: error?.response?.data?.error ?? "Error: Cannot delete robots"
        }
    }
};

export const updatePinDemoMower = async (mowerId, data) => {
    return await mowerRef.doc(mowerId).update(data);
};

export const updateNotifyDemoMower = async (mowerId, data) => {
    return await mowerRef.doc(mowerId).update(data);
};

export const scheduleSetterDemoMower = async (data, id) => {
    const url = `${BASE_URL}/demo/fleet_mgmt/fleet/${id}/update_calendar`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
            data: response.data
        };
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
};

export const scheduleSetterDemoMowers = async (mowerId, data) => {
    const url = `${BASE_URL}/demo/fleet_mgmt/fleet/${mowerId}/update_calendar`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
            data: response.data
        };
    } catch (error) {
        return {
            result: false,
            message: error,
        };
    }
};

export const getUser = async () => {
    const url = `${BASE_URL}/users`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: response.data?.success ?? false,
            data: response.data?.data ?? []
        };
    } catch (error) {
        return {
            result: false,
            data: error
        }
    }
}
export const createUser = async (data) => {
    const url = `${BASE_URL}/users`;
    try {
        const response = await axios.post(url, data, getHeader());
        return {
            result: (response.data?.result ?? response.data?.success) ?? false,
            message: response.data?.message ?? response.data?.error,
        };
    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}
export const updateUser = async (data) => {
    const url = `${BASE_URL}/users/${data.id}`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: (response.data?.result ?? response.data?.success) ?? false,
            message: response.data?.message ?? response.data?.error,
        };
    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}
export const deleteUser = async (data) => {
    const url = `${BASE_URL}/users/${data.id}`;
    try {
        const response = await axios.delete(url, getHeader());
        return {
            result: (response.data?.result ?? response.data?.success) ?? false,
            message: response.data?.message ?? response.data?.error,
        };
    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}

export const updateMower = async (id, data) => {
    const url = `${BASE_URL}/fleet_mgmt/mower/${id}`;

    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
            message: response
        };
    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}

export const updateTags = async (id, data) => {
    const url = `${BASE_URL}/fleet_mgmt/mower/${id}/tags`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
            message: response
        };
    } catch (error) {
        return {
            result: false,
            message: error
        }
    }
}

export const mowersDataArrayBasedOnUser = async (user) => {
    const url = `${BASE_URL}/fleet_mgmt/${user.id}/mowers`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: true,
            data: response?.data?.data ?? []
        };
    } catch {
        return {
            result: false,
            data: []
        }
    }
}

export const mowersDataArray = async () => {
    const url = `${BASE_URL}/fleet_mgmt/mowers`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: true,
            data: response.data?.data ?? []
        };
    } catch {
        return {
            result: false,
            data: []
        }
    }
}
export const requestDemo = async (data) => {
    const url = `${BASE_URL}/demo_request`;
    return await axios.post(url, data, getHeader());
}

export const nexMowAuth = async (loginMyth) => {
    const url = `${BASE_URL}/fleet_mgmt/nexmow_auth`;
    const data = {
        "manufacturer": "nexmow",
        "username": loginMyth.split(":")[0],
        "password": "",
        "login_myth": loginMyth
    }
    return await axios.post(url, data, getHeader());
}
export const husqvarnaCredentialsData = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/manufacturer_logins`;
    let res = await axios.get(url, getHeader());
    return {
        result: res.data?.success ?? false,
        data: res.data?.data ?? []
    };
}
export const addHusqvarnaUsers = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/manufacturer_logins`;
    try {
        let res = await axios.post(url, data, getHeader());
        return {
            result: res.data?.success ?? false,
            message: res.data?.message ?? res.data?.error,
        };
    } catch (err) {
        return {
            result: false,
            message: err?.response?.data?.error ?? "Error: can not perform this action"
        }
    }

}
export const editHusqvarnaUsers = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/manufacturer_logins/${data.id}`;
    delete data.id;
    try {
        let res = await axios.put(url, data, getHeader());
        return {
            result: res.data?.success ?? false,
            message: res.data?.message ?? res.data?.error,
        };
    } catch (err) {
        return {
            result: false,
            message: err?.response?.data?.error ?? "Error: can not perform this action"
        };
    }
}

export const getPropertiesAddresses = async () => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/addresses`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: true,
            data: response?.data?.data ?? []
        }
    } catch {
        return {
            result: false,
        }
    }
}
export const getPropertiesAddressesBasedOnUser = async (id) => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/${id}/addresses`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: true,
            data: response?.data?.data ?? []
        }
    } catch {
        return {
            result: false,
        }
    }
}
export const getMowerManagementData = async () => {
    const url = `${BASE_URL}/fleet_mgmt/automowers_users`;
    try {
        const response = await axios.get(url, getHeader());
        return {
            result: response?.data?.success ?? false,
            data: response?.data?.data ?? []
        }
    } catch {
        return {
            result: false,
        }
    }
}
export const updateMowerManagementData = async (data) => {
    const url = `${BASE_URL}/fleet_mgmt/automowers_users`;
    try {
        const response = await axios.put(url, data, getHeader());
        return {
            result: response?.data?.success ?? false,
        }
    } catch {
        return {
            result: false,
        }
    }
}
export const deleteHusqvarnaUsers = async (id) => {
    const url = `${BASE_URL}/fleet_mgmt/manufacturer_logins/${id}`;
    try {
        let res = await axios.delete(url, getHeader());
        return {
            result: res.data?.success ?? false,
        };
    } catch {
        return {
            result: false,
        };
    }
}


export const getMowerNotifications = async (id) => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/${id}/notifications`;
    const response = await axios.get(url, getHeader());
    return response.data.data ?? [];
};

export const getUserNotifications = async (pageNo, toggleValue) => {
    let queryParams = `page=${pageNo}&limit=20`;
    if (toggleValue === 'unread') {
        queryParams += '&read=false';
    } else if (toggleValue === 'unique') {
        queryParams += '&unique=true';
    }
    const url = `${BASE_URL}/user/notifications?${queryParams}`;
    const response = await axios.get(url, getHeader());
    return response;
};

export const updateUserNotificationsReadStatus = async (data) => {
    const url = `${BASE_URL}/user/notification/${data}`;
    try {
        const response = await axios.put(url, {}, getHeader());
        return {
            result: response?.data?.success ?? false,
        }
    } catch {
        return {
            result: false,
        }
    }
}

export const userNotificationsMarkAllReadStatus = async (data) => {
    const url = `${BASE_URL}/user/update/notifications?unique=${data}`;
    try {
        const response = await axios.put(url, {}, getHeader());
        return {
            result: response?.data?.success ?? false,
        }
    } catch {
        return {
            result: false,
        }
    }
}

export const getMowerLatestNotifications = async () => {
    const url = `${BASE_URL}/fleet_mgmt/mowers/notifications`;
    const response = await axios.get(url, getHeader());
    return response.data.data ?? [];
};

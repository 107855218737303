import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Drawer} from "rsuite";
import {Scrollbars} from "react-custom-scrollbars";

import Spacer from "Components/Base/Spacer";
import IconButton from "Components/Base/IconButton";
import {postFleetAuth, startAddNewRobot} from "Redux/actions/authAction";
import SuccessAnimation from "../SuccessAnimation";
import {getMowersData} from "../../Pages/Home";
import NexMowPopup from "../NexMowPopup/NexMowPopup";
import {CircularProgressComp, SearchInput} from "../Common";
import {DialogButton} from "../Sidebar/RemoveRobotPan";
import {ManufacturerDescription, manufacturerOptions} from "../../Contants";

import {addNewFleets, husqvarnaCredentialsData} from "Utils/api";
import RobotRow from "./RobotRow";
import SelectDropdownComp from "./SelectDropdownComp";

import CloseIcon from "Assets/images/x.png";
import "rsuite/dist/styles/rsuite-default.css";

const setArrayData = (array) => {
    let data = [];
    for (let i = 0; i < array?.length; i++) {
        if (array[i].manufacturer === "husqvarna") {
            data.push(array[i]);
        }
    }
    return data;
};

const ErrorComp = ({error}) => {
    return <div>
        <Description color="red">{error}</Description>
        <Spacer/>
    </div>
}

const HeaderComponent = ({onClose}) => {
    return <SidebarHeader>
        <FilterText>Add Robots</FilterText>
        <IconButton url={CloseIcon} onClick={() => onClose()} size="sm"/>
    </SidebarHeader>
};

const CreateNewRobotPan = ({isCollapsed, onClose}) => {
    const dispatch = useDispatch();
    const {mowers} = useSelector((state) => state.mower);
    const {newMowers, inProgress, err} = useSelector((state) => state.auth);
    const [animationPopup, setAnimationPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [manu, setManufacturer] = useState(null);
    const [error, setError] = useState(null);
    const [description, setDescription] = useState(ManufacturerDescription);
    const [showCredential, setShowCredential] = useState(false);
    const [selectedModels, setSelectedModels] = useState([]);
    const [inputString, setInputString] = useState("");
    const [showNexMowIframe, setShowNexMowIframe] = useState(null);
    const [husqvarnaUsers, setHusqvarnaUsers] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [dropdownSelection, setDropdownSelection] = useState(null);
    const [input, setInput] = useState("");

    useMemo(() => {
        dispatch(startAddNewRobot());
    }, [dispatch]);

    useEffect(() => {
        if (!inProgress && err) {
            setOpenProgressDialog(false);
            setError(err);
        } else if (newMowers.length > 0) {
            const mowerIDs = mowers ? mowers.map((m) => (m.id)) : [];
            newMowers.forEach((m) => {
                if (mowerIDs.includes(m.id)) {
                    m["added"] = true;
                }
            });
            setCurrentStep(2);
        } else {
            setCurrentStep(1);
        }
    }, [inProgress, err, newMowers, mowers]);

    useEffect(() => {
        if (newMowers?.length > 0) {
            setOpenProgressDialog(false);
        }
    }, [newMowers])

    const handleSelect = async (data) => {
        setManufacturer(data);
        setDropdownSelection(null);
        setInputString("");
        setShowCredential(data.needCredential === true);
        if (data.id === 2) {
            setShowNexMowIframe(true);
        }
        if (data.needCredential) {
            setOpenProgressDialog(true);
            let res = await husqvarnaCredentialsData();
            if(res.result){
                setOpenProgressDialog(false);
            }
            setHusqvarnaUsers(setArrayData(res.data));
            setDescription("Select a user from existing credentials to proceed");
            setTimeout(() => {
                setOpenProgressDialog(false);
            }, 3000)
        } else {
            setDescription(data.value + " has been chosen");
        }
        setError(null);
    };

    const handleClearManufacturer = () => {
        setManufacturer(null);
        setShowCredential(false);
        setInputString("");
        setDropdownSelection(null);
    };
    const handleClearHusqvarnaUser = () => {
        setInputString("");
        setDropdownSelection(null);
    };

    const handleNext = async (username, password) => {
        if (manu && showCredential) {
            setSelectedModels([]);
            setError(null);
            setOpenProgressDialog(true);
            const manufacturer = manu.value.toLowerCase();
            dispatch(postFleetAuth({manufacturer, username, password}));
            setTimeout(() => {
                setOpenProgressDialog(false);
            }, 4000)
        } else {
            setError("Choose manufacturer");
        }
    };

    const handlePrev = () => {
        if (currentStep === 2) {
            setCurrentStep(1);
        }
        setInputString("");
        setDropdownSelection(null);
        setShowNexMowIframe(false);
        setInput("");
        setManufacturer(null);
        setShowCredential(false);
        setError(null);
        setDescription(ManufacturerDescription);
    };

    const handleFinish = async () => {
        setOpenProgressDialog(true);
        let response = await addNewFleets(selectedModels, manu?.id === 2);
        setOpenProgressDialog(false);
        setShowNexMowIframe(false);
        if(response?.success){
            setInput("");
            setAnimationPopup(true);
            getMowersData(dispatch);

            setTimeout(() => {
                setAnimationPopup(false);
                onClose();
                handlePrev();
            }, 2000)
        } else {
            setError(response?.error ?? "Some error occurred");
        }
    };

    const getHeading = () => {
        if (currentStep === 1) {
            return description;
        }
        if (currentStep === 2) {
            return "Select the robots";
        }
    };

    const actionButtonsSetter = () => {
        const backButton = () => (
            <DialogButton onClick={() => handlePrev()} color="#565659">
                <Icon>{`< `}</Icon> Back
            </DialogButton>
        );

        if (currentStep === 1) {
            return <>
                {manu && backButton()}
                <DialogButton
                    disabled={!dropdownSelection }
                    onClick={() => manu ?
                        handleNext(dropdownSelection.username, dropdownSelection.password)
                        :
                        handleSelect(dropdownSelection)} color="#F75C28">
                    Next
                </DialogButton>
            </>
        }
        if (currentStep === 2) {
            return <>
                {backButton()}
                <DialogButton onClick={handleFinish} color="#F75C28">
                    Add
                </DialogButton>
            </>
        }
    }
    function genericSearch () {
        if(input === ''){
            return newMowers;
        } else {
            const includeColumns = ["name", "model"];
            let filteredData = [];
            let dataArray = newMowers.map((item) => {
                if (!item.model || !item.name) {
                    return {
                        ...item,
                        name: item.system_data.name,
                        model: item.system_data.model
                    }
                } else {
                    return item;
                }
            })
            filteredData = dataArray.filter(item => {
                return Object.keys(item).some(key =>
                    includeColumns.includes(key) ? item[key]?.toString()?.toLowerCase()?.includes(input?.toLowerCase()) : false
                );
            });
            return  filteredData ?? []
        }
    }
    const sort = genericSearch();

    return (
        <MainWrapper
            show={isCollapsed}
            onHide={onClose}
            placement="left"
            size={currentStep === 1 ? "xs" : "sm"}
        >
            <HeaderComponent onClose={onClose}/>
            <SidebarContent showBorderBottom={true}>
                <Description>{getHeading()}</Description>
                <Spacer/>
                <ActionButtonWrapper manu={manu}>
                    {actionButtonsSetter()}
                </ActionButtonWrapper>
                <Spacer size="lg"/>
            </SidebarContent>
            <Scrollbars>
                {currentStep === 1 && (
                    <SidebarContent>
                        <Spacer/>
                        {!manu && (
                            <div>
                                <SelectDropdownComp
                                    clear={() => handleClearManufacturer()} list={manufacturerOptions}
                                    dropdownSelection={dropdownSelection}
                                    setDropdownSelection={setDropdownSelection} inputString={inputString}
                                    attribute={"value"}
                                    setInputString={setInputString} heading={"Manufacturer"}
                                />
                            </div>
                        )}
                        {showCredential && (
                            <div>
                                <SelectDropdownComp
                                    clear={() => handleClearHusqvarnaUser()} list={husqvarnaUsers}
                                    dropdownSelection={dropdownSelection}
                                    setDropdownSelection={setDropdownSelection} inputString={inputString}
                                    setInputString={setInputString}
                                    attribute={"username"} heading={"Username"}
                                />
                            </div>
                        )}
                        {showNexMowIframe &&
                            <NexMowPopup setProgressDialogue={(val) => setOpenProgressDialog(val)}/>
                        }
                    </SidebarContent>
                )}
                {error && <ErrorComp error={error}/>}
                {currentStep === 2 && (
                    <SidebarContent>
                        <Spacer/>
                        <SearchInput
                            val={input}
                            setVal={setInput}
                            heading={"Robot or Model Name"}
                        />
                        <Spacer/>
                        <Spacer/>
                        {newMowers &&
                            sort.map((model, index) => (
                               <>
                                   <RobotRow
                                       index={index}
                                       model={model}
                                       selectedModels={selectedModels}
                                       setSelectedModels={setSelectedModels}
                                   />
                               </>
                            ))}
                        <Spacer/>
                    </SidebarContent>
                )}
                {animationPopup && <SuccessAnimation/>}
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
                }
            </Scrollbars>
        </MainWrapper>
    );
};
const MainWrapper = styled(Drawer)`
    .rs-drawer-content {
        padding: 50px 0px 0px 0px;
    };
`;
const Icon = styled.span`
  font-size: 18px;
`;
const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.manu ? "space-between" : "flex-end"};
`;
const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px 0px 40px; 
`;
const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 0px 40px; 
  ${props => props.showBorderBottom && `border-bottom: 1px solid #15151A;`};
`;
const FilterText = styled.span`
  font-size: 21px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
  line-height: 24px;
`;
const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  color: ${(props) => props.color ? props.color : props.theme.color.white};
`;

export default CreateNewRobotPan;
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import Button from "../Base/Button";
import theme from "../../Theme";
import styled from "styled-components";

export const ConfirmationDialog = ({titleMessage,message, result}) => {


    return ( <Dialog open={true} maxWidth="sm" fullWidth style={{ backgroundColor: theme.color.grey, borderRadius: '35px' }}>
        <DialogTitle>{titleMessage}</DialogTitle>
        <Box position="absolute" top={0} right={0}>
        </Box>
        <DialogContent>
            <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions style={{marginRight:'12px'}}>
            <CancelButton  onClick={() => {
                result('NO');
            }}>
                Cancel
            </CancelButton>
            <Button onClick={() => {
                result('YES');
            }} text={'Confirm'} color={theme.color.orange}>
            </Button>
        </DialogActions>
    </Dialog>)
}

const CancelButton = styled.button`
  border-radius: ${(props) => props.theme.spacing[2]}px;
  width: ${(props) => props.width};
  cursor: pointer;
  outline: none !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  padding: ${(props) => props.theme.spacing[2]}px;
  background: ${(props) => `linear-gradient(0deg, ${props.theme.color.grey[700]} 0%, ${props.theme.color.grey[800]} 100%)`};

  :focus {
    background: ${(props) => props.theme.grey};
  }
`;

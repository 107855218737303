import { handleActions } from "redux-actions";

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_POST_FLEET_CRED_SUCCESS,
  AUTH_POST_FLEET_CRED_REQUEST,
  START_ADD_NEW_ROBOT,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CLEAR_FORGOT_PASSWORD,
  THIRD_PARTY_RESPONSE,
} from "Redux/actionTypes";

const initialState = {
  inProgress: false,
  isAutherized: null,
  isForgotMessageSent: null,
  isPasswordReseted: null,
  user: null,
  message: "",
  newMowers: [],
  err: "",
  gettingUser: false,
  isThirdParty: false,
};

export const THIRD_PARTY_LOGIN_TYPE = {
  google: "google",
};

export default handleActions(
  {
    [AUTH_LOGIN_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      isForgotMessageSent: null,
      errorType: null
    }),
    [AUTH_LOGIN_SUCCESS]: (state, payload) => {
      const {
        payload: {result, message, error_type},
      } = payload;
      return {
        ...state,
        inProgress: false,
        isAutherized: result,
        message: message,
        errorType: error_type,
      };
    },
    [CURRENT_USER_REQUEST]: (state) => ({
      ...state,
      gettingUser: true,
      user: null,
    }),
    [CURRENT_USER_SUCCESS]: (state, payload) => {
      const {
        payload: { data },
      } = payload;
      var newState = null;
      if (data) {
        newState =  {
          ...state,
          gettingUser: false,
          isAutherized: true,
          user: data,
        };
      } else {
        newState = {
          ...state,
          gettingUser: false,
        }
      }
      return newState;
    },
    [AUTH_LOGOUT]: () => {
      sessionStorage.setItem("pressLogout", true);
      return initialState;
    },
    [AUTH_FORGOT_PASSWORD_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      isAutherized: null,
      message: "",
    }),
    [AUTH_FORGOT_PASSWORD_SUCCESS]: (state, payload) => {
      const {
        payload: { result, message },
      } = payload;
      return {
        ...state,
        inProgress: false,
        isForgotMessageSent: result,
        message: message,
      };
    },
    [AUTH_RESET_PASSWORD_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      isPasswordReseted: null,
      message: "",
    }),
    [AUTH_RESET_PASSWORD_SUCCESS]: (state, payload) => {
      const {
        payload: { result, message },
      } = payload;
      return {
        ...state,
        inProgress: false,
        isPasswordReseted: result,
        message: message,
      };
    },
    [CLEAR_FORGOT_PASSWORD]: (state) => ({
      ...state,
      isForgotMessageSent: null,
    }),
    [START_ADD_NEW_ROBOT]: (state) => ({
      ...state,
      err: "",
      newMowers: [],
    }),
    [AUTH_POST_FLEET_CRED_REQUEST]: (state) => ({
      ...state,
      inProgress: true,
      err: "",
      newMowers: [],
    }),
    [AUTH_POST_FLEET_CRED_SUCCESS]: (state, payload) => {
      const {
        payload: { data, error, message },
      } = payload;
      return {
        ...state,
        inProgress: false,
        err: error ? error : message,
        newMowers: data,
      }
    },
    [THIRD_PARTY_RESPONSE]: (state, payload) => {
      const {
        payload: {type, response}
      } = payload;
      if (type === THIRD_PARTY_LOGIN_TYPE.google) {
        if (response.googleId) {
          return {
            ...state,
            isAutherized: true,
            user: {
              email: response.profileObj.email,
              first_name: response.profileObj.familyName,
              last_name: response.profileObj.givenName,
              subscriber_id: response.googleId,
            },
            isThirdParty: true,
          };
        };
      }
      return state;
    },
  },
  initialState
);

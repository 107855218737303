import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Whisper } from "rsuite";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

import IconButton from "Components/Base/IconButton";
import User from "Assets/images/user.png";
import notificationIcon from "Assets/images/notificationIcon.png"
import SettingMenu from "Components/Topbar/SettingMenu";
import { gotoBCStore } from "Utils/bigcommerce";
import LoadingPopUp from "Components/LoadingPopUp";
import UserManagement from "../UserManagement/UserManagement";
import HusqvarnaCredentials from "../HusqvarnaCredentials/HusqvarnaCredentials";
import MowerManagement from "../MowerManagement/MowerManagement";
import NotificationMenu from "./NotificationMenu";
import UserNotificationScreen from "./UserNotificationScreen";
import {getUserNotifications} from "../../Utils/api";
import {DefaultPage, NotificationToggleOptions} from "../../Contants";

const NavActionButton = ({
   onClick, selected, text
}) => {
    return (
      <NavLink
        onClick={onClick}
        selected={selected}
      >
        {text}
      </NavLink>
    );
};

const NavButtonGroup = () => {
  const [loading, setLoading] = useState(false);
  const [showUserManagementPopup, setShowUserManagementPopup] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showMowerManagementPopup, setShowMowerManagementPopup] = useState(false);
  const [showCredentialsPopup, setShowCredentialsPopup] = useState(false);
  const [message, setMessage] = useState("");            // eslint-disable-next-line
  const [isNotification, setIsNotification] = useState(false)
  const [page, setPage] = useState(DefaultPage);
  const [noOfPages, setNoOfPages] = useState(null)
  const [fleetNotifications, setFleetNotifications] = useState(null);
  const [toggleValue, setToggleValue] = useState(NotificationToggleOptions[0].value);
  const { user } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();

  const isAllNotificationsRead = fleetNotifications?.every(notification => notification.read === true);


  useEffect(() => {
      if(Array.isArray(fleetNotifications)){
          for (let i = 0; i < fleetNotifications?.length; i++) {
              if(fleetNotifications[i]?.read === false){
                  setIsNotification(true)
              }
          }
      }
  },[fleetNotifications?.length]) // eslint-disable-line
    useEffect(() => {
        getFleetNotifications(page);    // eslint-disable-next-line
    },[page, toggleValue])
    const getFleetNotifications = async(page) => {
        let res = await getUserNotifications(page, toggleValue);
        setNoOfPages(res?.data.meta?.total_pages)
        setFleetNotifications(res?.data.data ?? []);
    }
    const updateParentState = (notificationId) => {     // eslint-disable-next-line
        const notificationIndex = fleetNotifications.findIndex(notification => notification.id == notificationId);
        if (notificationIndex !== -1) {
            let updatedNotifications;
            if (toggleValue === "unread") {
                updatedNotifications = [...fleetNotifications];
                updatedNotifications.splice(notificationIndex, 1);
            } else {
                updatedNotifications = fleetNotifications.map(notification => {     // eslint-disable-next-line
                    if (notification.id == notificationId) {
                        const updatedNotification = {
                            ...notification,
                            read: true
                        };
                        return updatedNotification;
                    } else {
                        return notification;
                    }
                });
            }
            setFleetNotifications(updatedNotifications);
        }
    };
  const onClickConsole = async () => {
    history.push("/console");
  };
  const onClickDashboard = () => {
      history.push("/dashboard");
  };
  const navItems = () => {
    let links = [
      {
        text: "Fleet Console",
        path: '/console',
        onClick: onClickConsole,
        selected: true,
      },
    ];

    if(process.env.REACT_APP_DEMO_MODE === 'true'){
      links.unshift(
          {
            text: "Dashboard",
            path: '/dashboard',
            onClick: onClickDashboard,
            selected: true,
          }
      )
    }
    return links;
  }
  const showNotificationScreen = () => {
        setShowNotificationPopup(true);
    }
  const showUserManagementScreen = () => {
    setShowUserManagementPopup(true);
  }
  const showCredentialsScreen = () => {
    setShowCredentialsPopup(true);
  }
  const showMowerManagementScreen = () => {
    setShowMowerManagementPopup(true);
  }
  const checkActiveNavLink = (item) => {
    if(location.pathname === item.path){
      return true;
    }
    return false;
  }
  return (
    <StyledContainer>
      <ReactTooltip
        disable={user?.state !== 'trial'}
        place="left"
        id="aviator-redirect"
        className="tooltip-popup"
        backgroundColor="white"
        effect="solid"
        type="dark"
      >
        <PopupContainer>
          <PopupHeader>Aviator Software</PopupHeader>
          <p>
            Become a full subscriber of Robin to access<br />
            our business management software.
          </p>
        </PopupContainer>
      </ReactTooltip>
      {navItems().map((navItem, index) => (
        <NavActionButton
          key={index}
          onClick={navItem.onClick}
          selected={checkActiveNavLink(navItem)}
          text={navItem.text}
        >
        </NavActionButton>
      ))}
      <div style={{marginRight: "20px"}}>
        <Whisper trigger="click" placement="bottom"
                 speaker={<NotificationMenu showNotificationScreen={showNotificationScreen} setLoading={setLoading} fleetNotifications={fleetNotifications}/>}
        >
            <div style={{position: 'relative'}} onClick={() => {setIsNotification(false)}}>
                <IconButton url={notificationIcon} size="sm" />
                {isNotification === true &&
                    <NotificationDot/>}
            </div>
        </Whisper>
      </div>
      <div style={{marginRight: "20px"}}>
        <Whisper trigger="click" placement="bottom"
                 speaker={<SettingMenu showManagementScreen={showUserManagementScreen}
                                       showCredentialsScreen={showCredentialsScreen}
                                       showMowerManagementScreen={showMowerManagementScreen}
                 />}
        >
          <IconButton url={User} size="sm" />
        </Whisper>
      </div>
        {showNotificationPopup &&
            <UserNotificationScreen
                showNotificationPopup={showNotificationPopup}
                setShowNotificationPopup={setShowNotificationPopup}
                fleetNotifications={fleetNotifications}
                setFleetNotifications={setFleetNotifications}
                page={page}
                setPage={setPage}
                noOfPages={noOfPages}
                toggleValue={toggleValue}
                setToggleValue={setToggleValue}
                updateParentState={updateParentState}
                markAllReadDisable={isAllNotificationsRead}
            />
        }
      {showUserManagementPopup &&
          <UserManagement
              showUserManagementPopup={showUserManagementPopup}
              setShowUserManagementPopup={setShowUserManagementPopup}
          />
      }
      {showCredentialsPopup &&
          <HusqvarnaCredentials
              showCredentialsPopup={showCredentialsPopup}
              setShowCredentialsPopup={setShowCredentialsPopup}
          />
      }
      {showMowerManagementPopup &&
          <MowerManagement
              showMowerManagementPopup={showMowerManagementPopup}
              setShowMowerManagementPopup = {setShowMowerManagementPopup}
          />

      }
      <LoadingPopUp loading={loading} message={message} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.div`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  line-height: 15px;
  color: ${(props) => props.theme.color.grey[100]};
  position: relative;
  text-decoration: none;
  margin-right: ${(props) => props.theme.spacing[8]}px;

  &:hover {
    opacity: 0.5;
  }

  ::after {
    content: "";
    position: absolute;
    display: ${(props) => (props.selected ? "block" : "none")};
    width: 100%;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 49.44%,
      rgba(255, 255, 255, 0) 100%
    );
    bottom: -22px;
    left: 0px;
  }
`;

const PopupContainer = styled.div`
  background-color: white;
  color: black;
`;

const PopupHeader = styled.h4`
  color: ${(props) => props.theme.color.primary};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 10px;
`;

const NotificationDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #F75C28;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
`

export default NavButtonGroup;

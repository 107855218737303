import React, {useContext} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";
import styled from "styled-components";

import {
    cuttingHeightAllowed, disabledColor, getMowerName, pinAllowed, scheduleAllowed,
    serviceComponent, startParkAllowed
} from "../../Pages/Home/DetailInfoPan/RobotPan";
import {
    calenderTasksNullCase, checkForManualModels, getMowerModel, getSchedule,
    getStatusColor, getStatusName
} from "../../Utils/global";
import {Container} from "../../Pages/Home/DetailInfoPan";
import {DeviceContext} from "../../Context/DeviceContext";
import NotificationToggle from "../../Pages/Home/DetailInfoPan/NotificationToggle";
import IconButton from "../Base/IconButton";
import {setSelectedHouse, setSelectedMower} from "../../Redux/actions/propertActions";
import StatusPoint from "../Base/StatusPoint";
import Spacer from "../Base/Spacer";
import {imageSetter} from "../Sidebar/SidebarItem";
import {getMowerStatusIcon} from "../Sidebar/SidebarItem";

import OnlineImg from "../../Assets/images/wifi.png";
import OfflineImg from "../../Assets/images/no-wifi.png";
import PencilIcon from '../../Assets/images/editPencil.png';
import CloseImg from "Assets/images/x.png";
import cuttingHeightImg from "../../Assets/images/cuttingHeight.png";
import OffIcon from "../../Assets/images/OFF.png";


const title = (mower) => {
    return `Make / Model: ${mower?.manufacturer?.slice(0, 4)} / ${mower.model}
Manf ID: ${mower?.id}
Serial number: ${mower?.serial_number}`;
};

const mowerAbbreviatedId = (mower) => {
    let numberDigits = 6;
    return mower.id.slice(-numberDigits)
};

export const LeftButtonGroup = ({mower, setPinUpdate, setTagUpdate, propertyMode = false}) => {

    const pinUpdateSetter = (e) => {
        if (setPinUpdate) {
            if (getMowerName(mower)?.includes(pinAllowed)) {
                e.stopPropagation();
                setPinUpdate(true);
            }
        }
    }
    const tagUpdateSetter = (e) => {
        if (setTagUpdate) {
            e.stopPropagation();
            setTagUpdate(true);
        }
    }

    const tagsUi = () => {
        let tagsString = [];

        if(mower?.tags) {
            if(mower?.tags?.length === 1){
                return `${mower?.tags?.join(' |\n') ?? ''}`
            } else {
                for(let i=0; i < mower?.tags?.length ; i++) {
                    let str = mower.tags[i].substr(0, 6);
                    if(i === 0){
                        tagsString.push(<span>{str}</span>)
                    } else {
                        tagsString.push(<span style={{color: "grey"}}> | </span>)
                        tagsString.push(<span>{str}</span>)
                    }
                }
                return tagsString
            }
        } else {
            return ""
        }
    }

    return (
        <div style={{position: 'relative'}}>
            <DivWrapper>
                <div className='heading'>
                    Instance
                </div>
                <div className='desc'>
                    {mower?.instance ?? ""}
                </div>
            </DivWrapper>
            <DivWrapper>
                <div className='heading'>
                    Make / Model
                </div>
                <div className='desc'>
                    {mower?.manufacturer === 'husqvarna' ?
                        <a
                            style={{color: "white", cursor: "pointer"}}
                            href={`https://www.husqvarna.com/us/robotic-lawn-mowers/automower-${getMowerModel(mower).replace(" ", "")}/`}
                            target="_blank" rel="noreferrer"
                        >
                            <p>{mower?.manufacturer?.slice(0, 4)} / {getMowerModel(mower)}</p>
                        </a>
                        :
                        <>{mower?.manufacturer?.slice(0, 4)} / {getMowerModel(mower)}</>
                    }
                </div>
            </DivWrapper>
            <DivWrapper title={title(mower)}>
                <div className='heading'>
                    Manf ID
                </div>
                <div className='desc'>
                    {mowerAbbreviatedId(mower) !== mower.id && "..."}{mowerAbbreviatedId(mower)}
                </div>
            </DivWrapper>
            <PencilIconWrapper>
                <DivWrapper color={getMowerName(mower)?.includes(pinAllowed) ? 'white' : disabledColor}
                            style={setPinUpdate ? getMowerName(mower)?.includes(pinAllowed) ? {cursor: "pointer"} : {cursor: 'not-allowed'} : {}}
                            dashedBottom={true}
                >
                    <div className='heading'>
                        PIN Code
                    </div>
                    <div
                        style={{color: getMowerName(mower)?.includes(pinAllowed) ? 'white' : disabledColor}}
                        className='desc' onClick={(e) => pinUpdateSetter(e)}
                    >
                        {`PIN: ${mower?.pin ?? ''}`}
                    </div>
                </DivWrapper>
                <span>
                <PencilIconImg src={PencilIcon}/>
            </span>
            </PencilIconWrapper>
            <PencilIconWrapper>
                <DivWrapper color={getMowerName(mower)?.includes(pinAllowed) ? 'white' : disabledColor}
                            style={{cursor: "pointer"}}
                            dashedBottom={true}
                >
                    <div className='heading'>
                        Tags
                    </div>
                    <div
                        style={{color: getMowerName(mower)?.includes(pinAllowed) ? 'white' : disabledColor}}
                        className='desc'
                        onClick={(e) => tagUpdateSetter(e)}
                    >
                        {tagsUi()}
                    </div>
                </DivWrapper>
                <span>
                <PencilIconImg onClick={(e) => tagUpdateSetter(e)} src={PencilIcon}/>
            </span>
                <TagsHoverDiv count={mower?.tags?.length ?? 0}>
                    <div className={"title"}>
                        Tags
                    </div>
                    <div className={"tag"}>
                        {mower?.tags?.map(item => (<p>- {item}</p>))}
                    </div>
                </TagsHoverDiv>
            </PencilIconWrapper>
            <div>
                {serviceComponent(mower)}
            </div>
        </div>
    )
};
export const RightButtonGroup = ({mower, openDialogModal, setCuttingHeightPopup, actionsSetter, propertyMode = false}) => {

    const actions = (action) => {
        if (actionsSetter) {
            if (action === 'START') {
                actionsSetter(getMowerName(mower)?.includes(startParkAllowed) ? "START" : "")
            }
            if (action === 'PARK') {
                actionsSetter(getMowerName(mower)?.includes(startParkAllowed) ? "PARK" : "")
            }
            if (action === 'ROUTE') {
                actionsSetter(mower.onRoute ? "REMOVE" : "ADD")
            }
        }
    }
    const scheduleSetter = () => {
        if (openDialogModal) {
            openDialogModal(mower, getSchedule(mower));
        }
    }
    const cuttingHeightSetter = () => {
        if (setCuttingHeightPopup) {
            if (getMowerName(mower)?.includes(cuttingHeightAllowed)) {
                setCuttingHeightPopup(true)
            }
        }
    }
    return (
        <RightSideWrapper>
            <BoxButton color={getMowerName(mower)?.includes(startParkAllowed) ? 'white' : disabledColor}
                       style={actionsSetter ? getMowerName(mower)?.includes(scheduleAllowed) ? {cursor: "pointer"} : {cursor: 'not-allowed'} : {}}
                       onClick={() => actions("START")}
            >
                <p>Start</p>
            </BoxButton>
            <BoxButton color={getMowerName(mower)?.includes(startParkAllowed) ? 'white' : disabledColor}
                       style={actionsSetter ? getMowerName(mower)?.includes(scheduleAllowed) ? {cursor: "pointer"} : {cursor: 'not-allowed'} : {}}
                       onClick={() => actions("PARK")}
            >
                <p>Park</p>
            </BoxButton>
            <BoxButton color={mower.onRoute && "red"}
                       style={{cursor: "pointer"}}
                       onClick={() => actions("ROUTE")}
            >
                <p>{mower.onRoute ? "- " : "+ "}Route</p>
            </BoxButton>
            <PencilIconWrapper>
                <Image mt="8px">
                    <img style={{width: "14px"}} src={PencilIcon} alt="edit"/>
                </Image>
                <DivWrapper
                    color={getMowerName(mower)?.includes(scheduleAllowed) ? 'white' : disabledColor} dashedBottom={true}
                    style={openDialogModal ? getMowerName(mower)?.includes(scheduleAllowed) ? {cursor: "pointer"} : {cursor: 'not-allowed'} : {}}
                    onClick={() => scheduleSetter()}
                >
                    <div className='heading'>
                        Schedule
                    </div>
                    <div className='schedule'>
                        <p>
                            {mower?.calendar_tasks && getSchedule(mower).map((spell, index) => (
                                <React.Fragment key={index}>{spell}</React.Fragment>
                            ))}
                            {mower.calendar_tasks === null &&
                                <>
                                    {calenderTasksNullCase(getMowerName(mower).includes(pinAllowed) ? "white" : disabledColor)}
                                </>
                            }
                        </p>
                    </div>
                </DivWrapper>
            </PencilIconWrapper>
            <PencilIconWrapper>
                {checkForManualModels(mower) ?
                    <>
                        <Image mt="6px">
                            <img style={{width: "14px"}} src={PencilIcon} alt="edit"/>
                        </Image>
                        <DivWrapper
                            color={getMowerName(mower)?.includes(cuttingHeightAllowed) ? 'white' : disabledColor}
                            dashedBottom={true}
                            style={setCuttingHeightPopup ? getMowerName(mower)?.includes(pinAllowed) ? {cursor: "pointer"} : {cursor: 'not-allowed'} : {}}
                            onClick={() => cuttingHeightSetter()}
                        >
                            <div className='schedule'>
                                <p style={{fontSize: "1.3rem", marginRight: '5px',}}>
                                    {mower?.settings?.cutting_height ?? mower?.settings?.cuttingHeight}
                                </p>
                                <img
                                    width={14}
                                    height={14}
                                    src={cuttingHeightImg}
                                    alt="edit"
                                    style={getMowerName(mower)?.includes(cuttingHeightAllowed) ? {} : {mixBlendMode: "overlay"}}
                                />
                            </div>
                        </DivWrapper>
                    </>
                    :
                    <>
                        <DivWrapper
                            color={getMowerName(mower)?.includes(cuttingHeightAllowed) ? 'white' : disabledColor}
                            dashedBottom={true}
                        >
                            <div className='schedule'>
                                <p data-tooltip="Cutting height change is disabled for manual mowers"
                                   style={{fontSize: "0.8rem", marginRight: '5px',}}>
                                    MANUAL
                                </p>
                                <img
                                    width={14}
                                    height={14}
                                    src={cuttingHeightImg}
                                    alt="edit"
                                />
                            </div>
                        </DivWrapper>
                    </>

                }
            </PencilIconWrapper>
            <NotifyWrapper>
                <div className='heading'>
                    Notify
                    <div>
                        <NotificationToggle mower={mower} propertyMode={propertyMode}/>
                    </div>
                </div>
            </NotifyWrapper>
        </RightSideWrapper>
    )
};

const RobotWrapper = ({mower, allMowersNotification, selected, dispatch}) => {
    return <RobotContainer selected={selected?.id === mower?.id}>
        <LeftSide>
            <div style={{display: "flex"}}>
                <StyledImg src={mower.connected ? OnlineImg : OfflineImg}/>
                <div style={{paddingLeft: "30px"}}>
                    <StatusPoint center={true} color={getStatusColor(mower, allMowersNotification[mower.id])}>
                        {getStatusName(mower, allMowersNotification[mower.id])}
                    </StatusPoint>
                </div>
                {(getMowerStatusIcon(mower, allMowersNotification) && mower.instance) &&
                    <div>
                        <Icon
                            size={getMowerStatusIcon(mower, allMowersNotification) === OffIcon ? "19px" : "14px"}
                            src={getMowerStatusIcon(mower, allMowersNotification)}
                        />
                        <StatusText>{allMowersNotification?.[mower.id]?.[0]}</StatusText>
                    </div>
                }
            </div>
            <Spacer/>
            <LeftButtonGroup mower={mower} propertyMode={true}/>
        </LeftSide>
        <ImageDiv>
            <ImagePan onClick={() => dispatch(setSelectedMower(mower))} src={imageSetter(mower)}/>
        </ImageDiv>
        <RightSide>
            <RightButtonGroup mower={mower}/>
        </RightSide>
    </RobotContainer>
}
const PropertyDetailInfoPan = () => {
    const {selected: house, selectedMower} = useSelector((state) => state.property);
    const {allMowersNotification} = useSelector((state) => state.mower);
    const dispatch = useDispatch();
    const {smallDevice, largeDevice} = useContext(DeviceContext);
    const width = house ? largeDevice ? "520px" : "100%" : "0px";
    const height = house ? smallDevice ? "100vh" : "100%" : "0px";

    return (
        <Container width={width} height={height}>
            <Scrollbars style={scrollbarStyles}>
                <HeadingWrapper>
                    <div className='heading'>
                        <div>
                            Mowers
                        </div>
                         <div className="count">
                             (  <span> {house?.mowers?.length ?? 0} </span>  )
                         </div>
                    </div>
                    <div>
                        <IconButton url={CloseImg} onClick={() => {
                            dispatch(setSelectedHouse(null));
                            dispatch(setSelectedMower(null));
                        }} size="sm" noBackground={true}/>
                    </div>
                </HeadingWrapper>
                <div>
                    {Array.isArray(house?.mowers) && house.mowers.map((mower, index) => {
                        return <RobotWrapper
                            dispatch={dispatch}
                            key={mower.id}
                            mower={mower}
                            allMowersNotification={allMowersNotification}
                            selected={selectedMower}
                        />
                    })

                    }
                </div>
            </Scrollbars>
        </Container>
    );
};

const ImageDiv = styled.div`
    cursor: pointer;
    align-self: center;
`;
const Icon = styled.img`
  width: ${props => props.size ? props.size : "19px"};
  margin-left: 14px;
  margin-right: 10px;
  margin-bottom: 6px;
`;
const StatusText = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 13px;
  font-weight: 700;
  opacity: 0.4;  
  position: absolute;
`;
const NotifyWrapper = styled.div`
    margin-top: 15px;
    .heading {
        color: white;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        > div {
            margin-left: 10px;
            display: flex;
            align-self: center;
        }
    }
`;
const HeadingWrapper = styled.div`
    padding: 20px 20px;
    background-color: #1A1B1F;;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
        color: white;
        font-weight: bold;
        font-size: 18px;
        display: flex;
         .count {
            margin-left: 10px;
            color: #E2E2E2;
            opacity: 40%;
            font-size: 16px;
            display: flex;
            span {
                padding: 0px 4px;
            };
        };
    };
`;
const Image = styled.span`
    margin-right: 10px;
    margin-top: ${props => props.mt};   
`;
const RobotContainer = styled.div`
    display: flex;
    padding: 25px;
    border-bottom: 1px solid #181818;
    ${props => props.selected && `background-color: rgb(38, 40, 45) `};
`;
const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const ImagePan = styled.img`
  width: 150px;
  height: auto;
`;
const PencilIconWrapper = styled.div`
    display: flex;
    text-align : left;
    margin-top: 10px;
    height: 37px;
  .hover-div {
    display: none;
  }

  .DivWrapper:hover + .hover-div {
    display: block;
  }
`;
const PencilIconImg = styled.img`
    margin-top: 15px;
    margin-left: 10px;
    width: 14px;
    cursor: pointer;
`;
const TagsHoverDiv = styled.div`
  height: 85px;
  width: 100px;
  position: absolute;
  left: ${props => props.count === 3 ? "140px" : props.count === 2 ? "94px" : props.count === 1 ? "72px" : "75px"};
  bottom: -5px;
  border: 1px #D99242 solid;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  display: none;
  color: white;
  background-color: #292B2F;
  padding: 5px 10px;
  z-index: 10;
  ${PencilIconWrapper}:hover & {
    display: ${props => props.count !== 0 ? "block" : "none"};
  }
  .title {
    color: #E2E2E2;
    opacity: 35%;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .tag {
    color: #E2E2E2;
    font-weight: bold;
    font-size: 11px;
    p {
        margin-top: 0px;
    }
  }
`;
const DivWrapper = styled.div`
    line-height: 1;
    margin-bottom: 20px;
    color: ${props => props.color ? props.color : props.theme.color.text};
    .heading{
        font-size: 11px;
        line-height: 13px;
        font-weight: bold;
        opacity: 0.2;
    };
    .schedule{
        display: flex;
        align-items: center;
        width: 50px;
        font-weight: bold;
        width: fit-content;
         ${props => props.dashedBottom && `
            border-bottom: 1px dashed ${props.color};
            padding-bottom: 4px;
       `}
    }
    .desc{
       font-size: 13px;
       font-weight: bold;
       width: fit-content;
       
       ${props => props.dashedBottom && `
            border-bottom: 1px dashed  ${props.color};
            padding-bottom: 4px;
       `}
    };
`;
const BoxButton = styled.div`
    margin: 5px 0px;
    width: 65px;
    background: none;
    height: 22px;
    color: ${(props) => props.color ? props.color : props.theme.color.text};
    border-radius: 0px;
    border: 1px solid ${(props) => props.theme.color.grey[450]};
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    padding: 4px;
    display: flex;
    justify-content: center;
    &:hover {
        background-color: rgba(235, 9, 9, .1);
    }
`;
const RightSideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: self-end;
`;
const RightSide = styled.div`
    flex: 1;
    margin-top: 39px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;
const StyledImg = styled.img`
    width: ${(props => props.width || 19)}px;
    height: ${(props => props.height || 12)}px;
    cursor: ${(props => props.cursor)};
`;
const scrollbarStyles = {width: "100%", height: "100%"};

export default PropertyDetailInfoPan;
import React, {useCallback} from 'react';
import styled from "styled-components";

import {calenderTasksNullCase, getSchedule, getStatusColor} from "../../Utils/global";
import {imageSetter} from "../Sidebar/SidebarItem";


import CheckIcon from "../../Assets/images/ico-check.png";
import UnCheckIcon from "../../Assets/images/ico-uncheck.png";
import {disabledColor} from "../../Pages/Home/DetailInfoPan/RobotPan";

const RobotRow = ({index, model, selectedModels, setSelectedModels}) => {

    const handleSelectModel = (model) => {
        if (model.added) {
            return;
        }
        const removeId = selectedModels.findIndex((m) => m.id === model.id);
        if (removeId > -1) {
            const newSelectedModels = selectedModels.filter((m) => m.id !== model.id);
            setSelectedModels(newSelectedModels);
        } else {
            setSelectedModels([...selectedModels, model]);
        }
    };

    const isSelected = useCallback(
        (model) => {
            if (selectedModels.length > 0) {
                return selectedModels.findIndex((m) => m.id === model.id) > -1;
            }

            return false;
        },
        [selectedModels]
    );

    const schedule = (model) => (
        <p>
            {model?.calendar_tasks && getSchedule(model).map((spell, index) => (
                <React.Fragment key={index}>{spell}</React.Fragment>
            ))}
            {model.calendar_tasks === null &&
                <>
                    {calenderTasksNullCase(disabledColor)}
                </>
            }
        </p>
    )

    return (
        <>
            <Card
                key={index}
                selected={isSelected(model)}
                onClick={() => handleSelectModel(model)}
            >
                <Wrapper>
                    <CollapsedErrStatusPoint
                        errStatus={getStatusColor(model)}
                    />
                    <MowerIcon src={imageSetter(model)}/>
                    <NameLabel>{model?.subscriber}</NameLabel>
                    <Label>{model?.system_data?.name ?? model?.name}</Label>
                </Wrapper>
                <Wrapper>
                    {schedule(model)}
                   <ActionWrapper>
                       {model?.added && (
                           <Label>Added</Label>
                       )}
                       {!model?.added && (
                           <CheckMark
                               src={isSelected(model) || model?.added ? CheckIcon : UnCheckIcon}
                           />
                       )}
                   </ActionWrapper>
                </Wrapper>
            </Card>
        </>
    );
};
const NameLabel = styled.span`
  position: absolute;
  top: 8px;
  left: 83px;
  font-size: 11px;
  font-weight: bold;
  opacity: 0.2;
  color: ${(props) => props.theme.color.text};
`;
const ActionWrapper = styled.div`
    min-width: 56px;
    text-align: center;
`;
const Card = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  height: 60px;
  padding: 0px ${(props) => props.theme.spacing[4]}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${(props) => props.theme.color.grey[900]};
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#1A1B1F" : "transparent")};

  :hover {
    border-radius: ${(props) => props.theme.spacing[2]}px;
    background-color: ${(props) => props.theme.color.grey[500]};
  }

  p {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.color.text};
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.span`
  font-size: 14px;
  line-height: 15px;
  color: ${(props) => props.theme.color.text};
  margin-left: 20px;
`;
const CollapsedErrStatusPoint = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.errStatus === "red"
        ? props.theme.color.red[100]
        : props.errStatus === "green"
            ? props.theme.color.green[500]
            : props.theme.color.red[500]};
  position: absolute;
  top: ${(props) => props.theme.spacing[2]}px;
  left: ${(props) => props.theme.spacing[2]}px;
`;
const MowerIcon = styled.img`
  width: 43px;
`;
const CheckMark = styled.img`
  width: ${props => props.width ?? "14px"}; 
  margin-left: 20px;
`;
export default RobotRow;
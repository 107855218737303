import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Drawer } from "rsuite";

import IconButton from "Components/Base/IconButton";
import Selector from "./Selector";
import Spacer from "Components/Base/Spacer";

import CloseImg from "Assets/images/x.png";
import "rsuite/dist/styles/rsuite-default.css";

import { clearAllFilter } from "Redux/actions/filterAction";


const Filterbar = ({isCollapsed, onClose}) => {
  const dispatch = useDispatch();
  const [clearCount, setClearCount] = useState(0);
  const { filters } = useSelector((state) => state.mower);

  const getOptions = (filterName) => {
    let opts = [];
    if (filterName in filters && Array.isArray(filters[filterName])) {
      for (let opt of filters[filterName]) {
        opts.push({value: opt, label: opt});
      }
    }
    if (filterName === 'status')
    {
      return [{value: ['MOWING','LEAVING','GOING_HOME'], label: 'Mowing',isMulti: true},
        {value: 'PARKED_IN_CS', label: 'Parked',isMulti: false},
        {value: 'CHARGING', label: 'Charging',isMulti: false},
        {value: 'NOT_APPLICABLE', label: 'Error',isMulti: false},
        {value: 'need_maintain', label: 'Need Maintenance',isMulti: false},
      ]
    }
    return opts;
  }

  const clearAll = () => {
    dispatch(clearAllFilter());
    setClearCount(clearCount + 1);
  }

  return (
    <Drawer show={isCollapsed} onHide={onClose} placement="left" size="xs">
      <SidebarHeader>
        <FilterText>Filter</FilterText>
        <IconButton url={CloseImg} onClick={onClose} size="sm" />
      </SidebarHeader>
      <SidebarContent key={clearCount}>
        <Spacer />
        <Selector label="Status" options={getOptions("status")} />
        <Spacer />
        <Selector label="Model" options={getOptions("model")} />
        <Spacer />
        <Selector label="Manufacturer" options={getOptions("manufacturer")} />
        <Spacer />
        <Selector label="Schedule" options={getOptions("schedule")} />
        <Spacer />
        <Selector label="Zip Code" options={getOptions("zipcode")} />
        <Spacer />
        <Selector label="Tags" options={getOptions("tags")} />
        <Spacer />
        <TextButton onClick={clearAll}>Clear All</TextButton>
        <Spacer />
        <StyledButton onClick={onClose}>Finish</StyledButton>
      </SidebarContent>
    </Drawer>
  );

};

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterText = styled.span`
  font-size: 21px;
  font-weight: bold;
  color: ${(props) => props.theme.color.primary};
  line-height: 24px;
`;

const TextButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.color.primary};
`;

const StyledButton = styled.button`
  background: ${(props) => props.theme.color.primary};
  height: 26px;
  border-radius: 2px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: ${(props) => props.theme.color.white};
`;

export default Filterbar;

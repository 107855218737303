import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";

import IconButton from "Components/Base/IconButton";
import Spacer from "Components/Base/Spacer";
import StatusPoint from "Components/Base/StatusPoint";
import {getDateObjFromSecond, getYearMonthDayStr, TODAY} from "Utils/datetime";

import {getStatusColor, getStatusName} from "Utils/global";

import OnlineImg from "Assets/images/wifi.png";
import OfflineImg from "Assets/images/no-wifi.png";
import CloseImg from "Assets/images/x.png";

import {DeviceContext} from "Context/DeviceContext"
import {MowerContext} from "../../../Context/MowerContext";
import {getMowerStatusIcon} from "../../../Components/Sidebar/SidebarItem";
import CuttingHeightDialog from "../../../Components/Sidebar/CuttingHeightDialog";
import {ScheduleDialog} from "../../../Components/ScheduleDialog/ScheduleDialog";
import {imageSetter} from "../../../Components/Sidebar/SidebarItem";
import PinDialogue from "../../../Components/Sidebar/PinDialogue";
import {LeftButtonGroup, RightButtonGroup} from "../../../Components/PropertyDetailInfoPan";
import ConfirmationPopup from "./ConfirmationPopup";
import {RobotPanLoader} from "../../../Components/SkeletonLoaders/SkeletonLoaderDetailMower";

import OffIcon from "../../../Assets/images/OFF.png";
import TagDialogue from "../../../Components/Sidebar/TagDialog";

export const getMowerName = (mower) => {
    return mower.manufacturer?.toLowerCase() ?? ""
}
export const pinAllowed = ['husqvarna'];
export const startParkAllowed = ['husqvarna'];
export const cuttingHeightAllowed = ['husqvarna'];
export const scheduleAllowed = ['husqvarna'];

export const disabledColor = "#464848"

export const serviceComponent = (mower) => {
    if (mower && mower.next_service && getDateObjFromSecond(mower.next_service.seconds) > TODAY) {
        return <Label>Next Service {getYearMonthDayStr(mower.next_service.seconds)}</Label>;
    } else {
        return <Label></Label>;
    }
};

const ActionButtonGroup = ({mower, robotAction, setCuttingHeightPopup}) => {
    const calendarTasks = mower?.calendar_tasks ?? [];
    const {notifications} = useContext(MowerContext);
    const {user} = useSelector((state) => state.auth);
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const [selectedDialogModal, setSelectedDialogModal] = useState('');

    const handleResult = (data) => {
        setTimeout(() => {
            setShowScheduleDialog(false);
        }, 0)
    }
    const openDialogModal = (mower, modelName) => {
        if (getMowerName(mower)?.includes(scheduleAllowed)) {
            const nameArray = [];
            for (const tempNameElement of modelName) {
                nameArray.push(tempNameElement?.props?.children)
            }
            const name = nameArray.join('');
            setSelectedDialogModal(name);
            setShowScheduleDialog(true);
        }
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://robinautopilotusa.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-lgwqy6/b/3/9edb822e6c4db3635f27806476c088cb/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2b03d855";
        script.onload = () => scriptLoaded();
        document.head.appendChild(script);
    });
    const scriptLoaded = () => {
        window.ATL_JQ_PAGE_PROPS = {
            fieldValues: {
                summary: 'Creating a ticket from Fleet Console',
                description: `
            Robot: ${mower?.instance}
            Robot Make / Model: ${mower?.model}
            Serial #: ${mower?.serial_number}
            Address: ${mower?.location?.address}
            Reason: ${notifications?.recent?.[notifications?.recent?.length - 1]?.message || ''}
        `,
                email: user?.email,
                priority: '3'
            }
        };
    }

    return (
        <>
            <RightButtonGroup mower={mower} openDialogModal={openDialogModal}
                              setCuttingHeightPopup={() => setCuttingHeightPopup(true)}
                              actionsSetter={robotAction}
            />
            {showScheduleDialog &&
                <ScheduleDialog isMultiple={false} robotName={selectedDialogModal} result={handleResult}
                                tasks={calendarTasks}/>}
        </>
    )
};

const RobotPan = ({onClose}) => {
    const {smallDevice} = useContext(DeviceContext);
    const { selectedMower } = useSelector((state) => state.mower);
    const {mowerDetails : mower} = useContext(MowerContext);
    const {allMowersNotification} = useSelector((state) => state.mower);
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [cuttingHeightPopup, setCuttingHeightPopup] = useState(false);
    const [pinUpdate, setPinUpdate] = useState(false);
    const [tagUpdate, setTagUpdate] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');

    if(selectedMower?.id !== mower?.id){
        return <RobotPanLoader />
    }

    if (!mower) {
        return null;
    }

    const robotAction = async (data) => {
        switch (data) {
            case 'PARK':
                setDialogTitle('Park Mowers')
                break;
            case 'ADD':
                setDialogTitle('Add To Route')
                break;
            case 'REMOVE':
                setDialogTitle('Remove From Route')
                break;
            case 'START':
                setDialogTitle('Start Mowers')
                break;
            default:
                break;
        }
        if (data !== "") {
            setConfirmationPopup(true)
        }
    }

    if (smallDevice) {
        return (
            <Container direction="column">
                <Row noBorder={true}>
                    <div>
                        <StyledImg src={mower.connected ? OnlineImg : OfflineImg}/>
                        <StatusPoint color={getStatusColor(mower, allMowersNotification[mower.id])}>
                            {getStatusName(mower, allMowersNotification[mower.id])}
                        </StatusPoint>
                        <span>
                            {(getMowerStatusIcon(mower, allMowersNotification) && mower.instance) &&
                                <div>
                                    <Icon
                                        size={getMowerStatusIcon(mower, allMowersNotification) === OffIcon ? "19px" : "14px"}
                                        src={getMowerStatusIcon(mower, allMowersNotification)}
                                    />
                                    <StatusText>{allMowersNotification?.[mower.id]?.[0]}</StatusText>
                                </div>
                            }
                        </span>
                    </div>
                    <IconButton url={CloseImg} onClick={onClose} size="sm" noBackground={true}/>
                </Row>
                <CenterPan src={imageSetter(mower)}/>
                <Spacer/>
                <LeftButtonGroup mower={mower} setPinUpdate={setPinUpdate} setTagUpdate={setTagUpdate}/>
                {serviceComponent(mower)}
                <Row noBorder={true} noMargin={true}>
                    <ActionButtonGroup setCuttingHeightPopup={setCuttingHeightPopup} robotAction={robotAction}
                                       mower={mower}/>
                </Row>
            </Container>
        )
    }

    return (
        <Container>
            {cuttingHeightPopup &&
                <CuttingHeightDialog
                    cuttingHeightPopup={cuttingHeightPopup}
                    setCuttingHeightPopup={setCuttingHeightPopup}
                    mower={mower}
                />
            }
            {pinUpdate &&
                <PinDialogue
                    pinUpdate={pinUpdate}
                    setPinUpdate={setPinUpdate}
                    mower={mower}
                />
            }
            {tagUpdate &&
                <TagDialogue
                    tagUpdate={tagUpdate}
                    setTagUpdate={setTagUpdate}
                    mower={mower}
                />
            }
            {confirmationPopup &&
                <ConfirmationPopup
                    confirmationPopup={confirmationPopup}
                    setConfirmationPopup={() => setConfirmationPopup(false)}
                    dialogTitle={dialogTitle}
                />
            }

            <LeftPan>
                <Flex>
                    <StyledImg src={mower.connected ? OnlineImg : OfflineImg}/>
                    <div className='status'>
                        <StatusPoint center={true} color={getStatusColor(mower, allMowersNotification[mower.id])}>
                            {getStatusName(mower, allMowersNotification[mower.id])}
                        </StatusPoint>
                    </div>
                    <span>
                        {(getMowerStatusIcon(mower, allMowersNotification) && mower.instance) &&
                            <div>
                                <Icon
                                    size={getMowerStatusIcon(mower, allMowersNotification) === OffIcon ? "19px" : "14px"}
                                    src={getMowerStatusIcon(mower, allMowersNotification)}
                                />
                                <StatusText>{allMowersNotification?.[mower.id]?.[0]}</StatusText>
                            </div>
                        }
                    </span>
                </Flex>
                <Spacer/>
                <LeftButtonGroup mower={mower} setPinUpdate={setPinUpdate} setTagUpdate={setTagUpdate}/>
            </LeftPan>
            <CenterPan src={imageSetter(mower)}/>
            <RightPan>
                <IconButton
                    url={CloseImg}
                    onClick={onClose}
                    size="sm"
                    noBackground={true}
                />
                <ViewModeGroup>
                    <ActionButtonGroup setCuttingHeightPopup={setCuttingHeightPopup} robotAction={robotAction}
                                       mower={mower}/>
                </ViewModeGroup>
            </RightPan>
        </Container>
    );
};

const Flex = styled.div`
    display: flex;
    margin-top: 15px;
    .status {
        padding-left: 30px;
    }
`;
const Icon = styled.img`
  width: ${props => props.size ? props.size : "19px"};
  margin-left: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
`;
const StatusText = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 13px;
  font-weight: 700;
  opacity: 0.4;  
  position: absolute;
`;
const Container = styled.div`
  padding: ${(props) => props.theme.spacing[4]}px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: radial-gradient(circle, #292b30 0%, #1f2125 100%);
  box-sizing: border-box;
  flex-direction: ${(props) => props.direction};
  height: ${(props) => props.direction ? null : "300px"};
`;
const LeftPan = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const CenterPan = styled.img`
  width: 150px;
  height: auto;
`;
const RightPan = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;
const StyledImg = styled.img`
  width: ${(props => props.width || 19)}px;
  height: ${(props => props.height || 12)}px;
  ${props => props.disabled && `mix-blend-mode: overlay`};
`;
const ViewModeGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  color: ${(props) => props.theme.color.text};
  font-size: 13px;
  font-weight: 500;
  opacity: 0.4;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: ${(props) => props.theme.color.text};
  margin-bottom: ${(props) => props.noMargin ? 0 : 15}px;
  padding-bottom: 15px;
  border-bottom: ${(props) => props.noBorder ? "" : "1px solid #16161B"} ;
  > span {
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    opacity: 0.2;
  };
  p {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    text-align: right;
  }
`;

export default RobotPan;

import { createAction } from "redux-actions";

import {
  thirdPartyLogin, authLogin, authLoginV2, authForgotPassword, authResetPassword,
  authPostFleetCrendential, getCurrentUser, getToken,
} from "Utils/api";
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_REQUEST,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_POST_FLEET_CRED_REQUEST,
  AUTH_POST_FLEET_CRED_SUCCESS,
  START_ADD_NEW_ROBOT,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CLEAR_FORGOT_PASSWORD,
  THIRD_PARTY_RESPONSE,
} from "Redux/actionTypes";

import firebase from "fbconfig";
import {THIRD_PARTY_LOGIN_TYPE} from "Redux/reducers/authReducer";


export const login = ({ email, password }) => async (dispatch) => {
  dispatch(loginRequest());
  const response = await authLogin({ email, password });
  dispatch(loginSuccess(response));
  localStorage.setItem(
    "access_token",
    response.data ? response.data.access_token : null
  );
  localStorage.setItem(
    "refresh_token",
    response.data ? response.data.refresh_token : null
  );
};

export const loginV2 = ({ email, password }) => async (dispatch) => {
  dispatch(loginRequest());
  const response = await authLoginV2({ email, password });
  dispatch(loginSuccess(response));
  localStorage.setItem(
      "access_token",
      response.data ? response.data.access_token : null
  );
  localStorage.setItem(
      "refresh_token",
      response.data ? response.data.refresh_token : null
  );
};

export const currentUser = () => async (dispatch) => {
  dispatch(currentUserRequest());
  let response = await getCurrentUser();
  if (!response.data) {
    const token = await getToken();
    if (token) {
      localStorage.setItem("access_token", token);
      response = await getCurrentUser();
    }
  }
  if (response.firebase_token) {
    await firebase.auth()
      .signInWithCustomToken(response.firebase_token)
      .catch((error) => {
        console.log("Error when login user in firebase:", error);
        response.user = null;
      })
      .finally(() => {
        dispatch(currentUserSuccess(response));
      });
  }
  dispatch(currentUserSuccess(response));
};

export const forgotPassword = ({ email }) => async (dispatch) => {
  dispatch(forgotPasswordRequest());
  const response = await authForgotPassword({ email });
  dispatch(forgotPasswordSuccess(response));
};

export const resetPassword = ({ token, password }) => async (dispatch) => {
  dispatch(resetPasswordRequest());
  const response = await authResetPassword({ token, password });
  dispatch(resetPasswordSuccess(response));
};

export const clearForgotPassword = createAction(CLEAR_FORGOT_PASSWORD);

export const logout = () => (dispatch) => {
  localStorage.setItem("access_token", null);
  dispatch(logoutRequest());
};

export const postFleetAuth = ({ manufacturer, username, password }) => async (dispatch) => {
  dispatch(postFleetAuthRequest());
  const response = await authPostFleetCrendential({ manufacturer, username, password });
  dispatch(postFleetAuthSuccess(response));
};

export const startAddNewRobot = () => (dispatch) => {
  dispatch(startAddNewRobotAction());
};

export const thirdPartyResponseAction = (type, thirdPartyResponseObject) => async (dispatch) => {
  if (type === THIRD_PARTY_LOGIN_TYPE.google) {
    dispatch(loginRequest());
    const response = await thirdPartyLogin({
      email: thirdPartyResponseObject.profileObj.email,
      social_uid: thirdPartyResponseObject.googleId,
      social_type: type
    });
    dispatch(loginSuccess(response));
    localStorage.setItem(
        "access_token",
        response.data ? response.data.access_token : null
    );
    localStorage.setItem(
        "refresh_token",
        response.data ? response.data.refresh_token : null
    );
  }else {
    dispatch(thirdPartyResponse({type, thirdPartyResponseObject}));
  }
};

const loginRequest = createAction(AUTH_LOGIN_REQUEST);
const loginSuccess = createAction(AUTH_LOGIN_SUCCESS);
const logoutRequest = createAction(AUTH_LOGOUT);

const forgotPasswordRequest = createAction(AUTH_FORGOT_PASSWORD_REQUEST);
const forgotPasswordSuccess = createAction(AUTH_FORGOT_PASSWORD_SUCCESS);

const resetPasswordRequest = createAction(AUTH_RESET_PASSWORD_REQUEST);
const resetPasswordSuccess = createAction(AUTH_RESET_PASSWORD_SUCCESS);

const startAddNewRobotAction = createAction(START_ADD_NEW_ROBOT);
const postFleetAuthRequest = createAction(AUTH_POST_FLEET_CRED_REQUEST);
export const postFleetAuthSuccess = createAction(AUTH_POST_FLEET_CRED_SUCCESS);

const currentUserRequest = createAction(CURRENT_USER_REQUEST);
const currentUserSuccess = createAction(CURRENT_USER_SUCCESS);

const thirdPartyResponse = createAction(THIRD_PARTY_RESPONSE);

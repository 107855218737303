import React, {useMemo, useContext, useEffect} from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {useDispatch} from "react-redux";

import NavButtonGroup from "./NavButtonGroup";

import Logo from "Assets/images/bird-logo.png";

import { DeviceContext } from "Context/DeviceContext";
import {logout} from "../../Redux/actions/authAction";


const Topbar = () => {
  const dispatch = useDispatch();
  const initVersion = '1.0';
  const { smallDevice, largeDevice } = useContext(DeviceContext);
  const location = useLocation();
  const [inDashboard , inConsole, inLanding] = useMemo(() => {
    return [
      location.pathname === "/dashboard",
      location.pathname === "/console",
      location.pathname === "/"
    ]
  }, [location]);


  const clearLocalStorage = (devVersion) => {
    localStorage.setItem("access_token" , null);
    localStorage.removeItem("refresh_token");
    localStorage.setItem("DEPLOYMENT_VERSION", devVersion );
    dispatch(logout());
  }

  useEffect(() => {
    let checkDevVersion = true
    if(checkDevVersion){
      const devVersion = process.env.REACT_APP_DEPLOYMENT_VERSION ?? initVersion;
      let appVersion = localStorage.getItem('DEPLOYMENT_VERSION') ?? initVersion;
      if(appVersion !== devVersion){
        clearLocalStorage(devVersion);
      }
      if(localStorage.getItem('DEPLOYMENT_VERSION') === null){
        localStorage.setItem("DEPLOYMENT_VERSION", devVersion )
      }
    }

    return () => {
      checkDevVersion = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <StyledWrapper inConsole={inConsole} inLanding={inLanding} largeDevice={largeDevice}>
      <Link to={"/"}>
        <StyledLogo>
          <img src={Logo} alt="logo" />
          <Title>
            Fleet Console
          </Title>
        </StyledLogo>
      </Link>
      {((inLanding || inConsole || inDashboard) && !smallDevice) && <NavButtonGroup />}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: ${(props) => props.largeDevice && "fixed"};
  z-index: 999;
  top: 0;
  left: 0;
  width: ${(props) => props.largeDevice && !props.inConsole ? "100%" : "100%"};
  box-sizing: border-box;
  border-bottom: 1px solid
    ${(props) => (props.inConsole ? "transparent" : props.theme.color.border)};
  height: ${(props) => props.theme.topBarSize}px;
  padding: 0px ${(props) => props.theme.spacing[12]}px 0px ${(props) => props.theme.spacing[6]}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${
    (props) => props.largeDevice && !props.inLanding && props.theme.color.grey[700]
  };

  > a {
    text-decoration: none;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 30px;
    margin-right: ${(props) => props.theme.spacing[3]}px;
  }
`;

const Title = styled.h1`
  font-size: 18px;
  text-decoration: none;
  line-height: 21px;
  color: ${(props) => props.theme.color.white};
`;

export default Topbar;

import React, {useMemo, useState} from "react";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {useDispatch} from "react-redux";
import styled from "styled-components";

import {CircularProgressComp, MowerActionFooter} from "../Common";
import {CuttingHeightDialogItem} from "./CuttingHeightDialogItem";
import SuccessAnimation from "../SuccessAnimation";
import Slider from '../Slider/index';
import {mowersService} from "../../Utils/mowerActions";
import {clearSelectedMowers} from "../../Redux/actions/mowerAction";
import {cuttingHeightDialogDesc} from "../../Contants";
import {scrollBarStyles} from "../Common/styles";

import cropHeight from '../../Assets/images/cropHeightImage.png';

const ConfirmView = ({value}) => (
    <ResponseWrapper>
        <div className='value'>
            <h1>{value}</h1>
            <img src={cropHeight} alt={"not found"}/>
        </div>
        <WrapperDesc>
            The cutting height has been changed to <span>{value}</span>
        </WrapperDesc>
    </ResponseWrapper>
);

const MowerHeightView = ({value, setValue}) => (
    <>
        <MowerHeightWrapper>
            <p>Cutting Height</p>
            <div className='value'>
                <h1>{value}</h1>
                <img src={cropHeight} alt={"not found"}/>
            </div>
        </MowerHeightWrapper>
        <DescriptionWrapper>
            <p>{cuttingHeightDialogDesc}</p>
        </DescriptionWrapper>
        <Slider setValue={setValue} current={value} label={"Min"} step={1} min={1} max={9}/>
    </>
)

const CuttingHeightDialog = (props) => {
    const [value, setValue] = useState(props?.mower?.settings?.cutting_height ?? props?.mower?.settings?.cuttingHeight);
    const dispatch = useDispatch();
    const [animationPopup, setAnimationPopup] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);

    const confirmedAction = async () => {
        if (value) {
            setOpenProgressDialog(true);
            if (props?.mower) {
                let res = await mowersService.cuttingHeightMower(value, props.mower);
                setOpenProgressDialog(false);
                if (res.result) {
                    setAnimationPopup(true);
                    setTimeout(() => {
                        setAnimationPopup(false)
                        props.setCuttingHeightPopup(false)
                    }, 2000)
                } else {
                    setError(res?.error ?? "Error: Can not perform this action")
                    setTimeout(() => {
                        setError(null);
                    }, 3000)
                }
            } else if (props?.mowers) {
                let res = await mowersService.cuttingHeightMowers(value, props.mowers);
                setOpenProgressDialog(false);
                if (res?.result) {
                    setResponse(res?.result);
                } else {
                    setError("Error: Can not perform this action")
                    setTimeout(() => {
                        setError(null);
                    }, 3000)
                }
            }
        } else {
            setError("Select value");
            setTimeout(() => {
                setError(null);
            }, 2000)
        }
    }

    const listing = useMemo(() => {
        let rows = [];
        props?.mowers?.map((mower) => (
            rows.push(
                <CuttingHeightDialogItem
                    mower={mower}
                    response={response}
                    selected={props?.mowers && mower.id === props?.mowers.id}
                    isRouteMode={props.mode.route}
                    routeNum={mower.onRoute && props.routePath.find(
                        (route) => {
                            return route?.id === mower?.id
                        }
                    )?.order_number}
                />
            )
        ))
        return rows;
    }, [response, props?.mowers, props?.mode?.route, props.routePath])

    const confirm = () => {
        const goBack = () => {
            props.setCuttingHeightPopup(false);
            dispatch(clearSelectedMowers());
        }

        return <DialogActions>
            <ActionButtonWrapper justify="center">
                <DialogButton padding={{}} onClick={goBack} color="#F75C28">
                    Ok
                </DialogButton>
            </ActionButtonWrapper>
        </DialogActions>
    };

    const individualMowerActions = () => (
        <DialogActions>
            <DialogButton onClick={() => props.setCuttingHeightPopup(false)} color="#565659">
                Cancel
            </DialogButton>
            <DialogButton onClick={confirmedAction} color="#F75C28">
                Confirm
            </DialogButton>
        </DialogActions>
    );

    return (
        <DialogDiv multiple={props.mowers} open={props.cuttingHeightPopup}
                   onClose={() => props.setCuttingHeightPopup(false)} aria-labelledby="form-dialog-title"
                   maxWidth="sm">
            <Container>
                {error && <Error>{error}</Error>}
                <DialogContent>
                    {response ?
                        <>
                            <ConfirmView value={value}/>
                        </>
                        :
                        <>
                            <MowerHeightView value={value} setValue={setValue}/>
                        </>
                    }
                    {props?.mowers &&
                        <MowerResponseWrapper response={response}>
                            {listing}
                        </MowerResponseWrapper>
                    }
                </DialogContent>
                {props.mower && individualMowerActions()}
                {(response === null && props.mowers) &&
                    <MowerActionFooter
                        close={() =>  props.setCuttingHeightPopup(false)}
                        action={confirmedAction}
                    />
                }
                {response && props.mowers && confirm()}
            </Container>
            {animationPopup && <SuccessAnimation/>}
            {openProgressDialog &&
                <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)}/>
            }
        </DialogDiv>
    )
}
const ActionButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: ${props => props.justify};
`;
const MowerResponseWrapper = styled.div`
    ${props => props.response ?
    `margin-bottom: 10px;
     margin-top: 50px;`
    :
    `margin-bottom: 10px;`
    };
`;
const DescriptionWrapper = styled.div`
    > p {
        fontS-size: 14px;
        color: #E2E2E2;
    };
`;
const MowerHeightWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    > p {
        font-weight: bold;
        font-size: 22px;
    };
    .value {
        display: flex;
        align-items: baseline;
        > h1 {
            font-family: sans-serif;
        };
        > img {
            width: 50px;
            margin-left: 5px;
        };
    }
`;
const ResponseWrapper = styled.div`
    align: center;
    .value {
        display: flex;
        align-items: baseline;
        justify-content: center;
        > h1 {
            font-family: sans-serif;
        };
        > img {
            width: 50px;
            margin-left: 5px;
        };
    };   
`;
const Error = styled.div`
    color: red;
`;
const DialogDiv = styled(Dialog)`
    .MuiPaper-root {
        ${props => props.multiple && `width: 40%`};
    };
    .MuiDialogContent-root {
        width: -webkit-fill-available;  
    };
`;
const WrapperDesc = styled.div`
    color: grey;
    font-size: 31px;
    font-weight: bold;
    text-align: center;
    > span {
        color: white;
    };
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: ${(props) => props.theme.spacing[7]}px;
  color: ${(props) => props.theme.color.text};
  overflow-Y: auto;
  overflow-X: hidden;
  .MuiDialogContent-root {
     ${scrollBarStyles}
  }
`;
const DialogButton = styled.button`
  border-radius: 4px;
  cursor: pointer;
  outline: none !important;
  font-size: 15px;
  color: white;
  font-weight: bold;
  padding: ${props => props.padding ? "10px 180px 8px" : "10px 50px 8px"};
  border: none;
  box-shadow: ${(props) =>
    props.showShadow ? "0 18px 30px 0 rgba(228,85,37,0.25)" : "none"};
  background: ${(props) => `${props.color ?? 'white'}`};
  :focus {
    background: ${(props) => props.theme.grey};
  }
`;
export default CuttingHeightDialog;

import React, {useState} from 'react';
import {Dialog, DialogContent} from "@material-ui/core";
import styled from "styled-components";

import {CircularProgressComp, MowerActionFooter} from "../Common";
import SuccessAnimation from "../SuccessAnimation";
import AddTag from "../../Assets/images/+.png";
import AddTagDisabled from "../../Assets/images/disabledPlusTag.png";
import RemoveTag from "../../Assets/images/minusTag.png";
import {mowersService} from "../../Utils/mowerActions";

const TagDialogue = (props) => {
    const [animationPopup, setAnimationPopup] = useState(false);
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [error, setError] = useState(null);
    const [tags, setTags] = useState(props?.mower?.tags ?? []);
    const confirmedAction = async () => {
        setOpenProgressDialog(true);
        let res = await mowersService.updateMowerTags(props.mower, tags);
        setOpenProgressDialog(false);
        if (process.env.REACT_APP_DEMO_MODE === 'true') {
            setAnimationPopup(true);
            setTimeout(() => {
                setAnimationPopup(false);
                props.setTagUpdate(false);
                setError(null)
            }, 2000)
        } else {
            if (res?.result) {
                setAnimationPopup(true);
                setTimeout(() => {
                    setAnimationPopup(false);
                    props.setTagUpdate(false);
                }, 2000)
            } else {
                setError(res?.message?.response?.data?.error ?? true);
                setTimeout(() => {
                    setError(null);
                }, 2000)
            }
        }
    }
    const addTag = () => {
        if (tags.length >= 3) {
            return;
        }
        setTags([...tags, ""]);
    };
    const removeTagInput = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
    };
    const updateTag = (index, value) => {
        let newTags = [...tags];
        newTags[index] = value;
        setTags(newTags);
    };
    return (
        <Dialog
            open={props.tagUpdate} maxWidth="sm" aria-labelledby="form-dialog-title"
            onClose={() => props.setTagUpdate(false)}
        >
            <Container>
                {error && <ErrorDiv>Error : can not perform this action</ErrorDiv>}
                <DialogContentWrapper>
                    <HeaderWrapper>
                        <HeaderLeftWrapper>
                            <Header>Edit Tags</Header>
                            <TagLimit>3 tags maximum allowed</TagLimit>
                        </HeaderLeftWrapper>
                        <AddTagWrapper onClick={addTag} tagCount={tags?.length ?? 0}>
                            <img style={{ margin: "3px 2px 0 0" }} src={tags?.length === 3 ? AddTagDisabled : AddTag} alt="icon" width={18} />
                            <span className={"actionButton"}>New Tag</span>
                        </AddTagWrapper>
                    </HeaderWrapper>
                    {tags.map((tag, index) => (
                        <TagInputWrapper key={index}>
                            <InputField
                                type="text"
                                value={tag}
                                maxLength={10}
                                onChange={(e) => updateTag(index, e.target.value)}
                            />
                            <RemoveTagImg style={{ margin: "3px 2px 0 0" }} src={RemoveTag} alt="icon"
                                 onClick={() => removeTagInput(index)}/>
                        </TagInputWrapper>
                    ))}
                </DialogContentWrapper>
                <MowerActionFooter
                    close={() => props.setTagUpdate(false)}
                    action={confirmedAction}
                />
                {openProgressDialog &&
                    <CircularProgressComp open={openProgressDialog} close={() => setOpenProgressDialog(false)} />
                }
                {animationPopup && <SuccessAnimation />}
            </Container>
        </Dialog>
    );
};

const DialogContentWrapper = styled(DialogContent)`
  width: -webkit-fill-available;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;
const AddTagWrapper = styled.div`
  cursor: ${props => props.tagCount === 3 ? "default" : "pointer"};
  .actionButton {
    color: ${props => props.tagCount === 3 ? "grey" : "#E2E2E2"};
  }
`;
const TagInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
`;
const HeaderLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-weight: bold;
`;
const TagLimit = styled.div`
  align-self: center;
  color: gray;
`;
const ErrorDiv = styled.div`
  color: red;
`;
const InputField = styled.input`
  border: 1px #090a0a solid;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #16171a;
  font-family: Roboto;
  border-radius: 3px;
  font-size: 15px;
  width: 85%;
  padding: 10px;
  &:focus {
    outline: none;
  }
`;
const RemoveTagImg = styled.img`
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  align-self: center;
  cursor: pointer;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 30px;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: ${(props) => props.theme.spacing[7]}px;
  color: ${(props) => props.theme.color.text};
`;
export default TagDialogue;

import React, {useContext, useMemo, useState} from 'react';
import {DeviceContext} from "../../Context/DeviceContext";
import {ProSidebar, SidebarContent, SidebarHeader} from "react-pro-sidebar";
import styled from "styled-components";
import {Container, SearchBar, SearchIcon, SerachBarWrapper} from "../Sidebar/Header";
import {useDispatch, useSelector} from "react-redux";

import IconButton from "../Base/IconButton";
import Spacer from "../Base/Spacer";
import {setFilteredHouses, setSelectedHouse} from "../../Redux/actions/propertActions";
import {UserViewComponent, UserViewComponentCollapsed} from "../Sidebar";
import {getHouseStatus} from "../../Utils/global";

import ExitImg from "Assets/images/exit.png";
import ExpandImg from "Assets/images/expand.png";
import SearchImg from "../../Assets/images/search.png";
import StatusPoint from "../Base/StatusPoint";


const SearchBarComp = ({isCollapsed, onCollapse, onSearch, value}) => {
    const { smallDevice } = useContext(DeviceContext);

    return <Container isCollapsed={isCollapsed}>
        {!smallDevice && (
            <IconButton
                url={isCollapsed ? ExpandImg : ExitImg}
                onClick={onCollapse}
                size="sm"
                tooltip={isCollapsed ? "Expand" : "Collapse"}
            />
        )}
        {!isCollapsed ?
            <>
                <SerachBarWrapper>
                    <SearchIcon src={SearchImg} />
                    <SearchBar value={value} onChange={onSearch} placeholder="Search" />
                </SerachBarWrapper>
            </>
            :
            <>
                <Spacer size="sm" />
                <IconButton url={SearchImg} size="sm" onClick={onCollapse} tooltip="Search" />
            </>
        }
    </Container>
}

const ListingAddresses = ({isCollapsed ,selected, setSelected, houses}) => {
    return <>
        {Array.isArray(houses) && houses?.map((item, index) => {
           return <RowWrapper collapsed={isCollapsed} selected={item?.address === selected?.address} key={index}>
               <div className="status">
                   <StatusPointWrapper collapsed={isCollapsed} >
                       <StatusPoint
                           color={getHouseStatus(item)}
                       />
                   </StatusPointWrapper>
               </div>
               <AddressRow selected={item?.address === selected?.address} onClick={() => setSelected(item)}>
                   <div className='address'>
                       {isCollapsed ? item.address.substring(0,4) : item.address}
                   </div>
                   {!isCollapsed &&
                       <div className='count'>
                           {item?.mowers?.length ?? 0}
                       </div>
                   }
               </AddressRow>
            </RowWrapper>
        })
        }
    </>
}

const PropertySidebar = () => {
    const {properties, selected : selectedHouse, filteredHouses} = useSelector((state) => state.property);
    const {selectedUserMode} = useSelector((state) => state.filter);
    const dispatch =  useDispatch();
    const [searchKey, setSearchKey] = useState("");
    const {smallDevice, middleDevice} = useContext(DeviceContext);
    const [isCollapsed, setCollapsed] = useState(
        (smallDevice && selectedHouse != null) || middleDevice
    );
    const handleToggleCollapse = () => setCollapsed(!isCollapsed);

    useMemo(() => {
        if(properties){
            if(searchKey === ""){
                dispatch(setFilteredHouses(properties))
            } else {
                let filtered =  properties.filter((houses) =>
                    houses.address?.toLowerCase().includes(searchKey.toLowerCase())
                );
                dispatch(setFilteredHouses(filtered))
            }
        }
    },[dispatch ,searchKey, selectedHouse]) // eslint-disable-line

    return (
        <ProSidebar collapsed={isCollapsed}>
            <>
                {(selectedUserMode && !isCollapsed) &&
                    <UserViewComponent selectedUserMode={selectedUserMode} dispatch={dispatch} />
                }
                {(selectedUserMode && isCollapsed) &&
                    <UserViewComponentCollapsed dispatch={dispatch} />
                }
            </>
            <>
                <SidebarHeader>
                    <SearchBarComp
                        value={searchKey}
                        isCollapsed={isCollapsed}
                        onCollapse={handleToggleCollapse}
                        onSearch={(e) => setSearchKey(e.target.value)}
                    />
                </SidebarHeader>
                <Scroll>
                    <SidebarContent>
                        <Wrapper>
                            {!isCollapsed &&
                                <Heading>
                                    <div>
                                        Property Addresses
                                    </div>
                                    <div className='count'>
                                        {filteredHouses?.length}
                                    </div>
                                </Heading>
                            }
                            <ListingWrapper>
                                <ListingAddresses  isCollapsed={isCollapsed} selected={selectedHouse}
                                                  setSelected={(item) => dispatch(setSelectedHouse(item))} houses={filteredHouses}
                                />
                            </ListingWrapper>
                        </Wrapper>
                    </SidebarContent>
                </Scroll>
                <Bottom>
                    <p>&#169; 2023 Robin Autopilot USA </p>
                </Bottom>
            </>
        </ProSidebar>
    );
};

const Scroll = styled.div`
    height: auto;
    overflow-y: auto;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    };
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255,0.7);
        border-radius: 5px;
    };
    &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
    };
`;
const Bottom = styled.div`
    background-color: #1A1B1F;
    text-align: center;
    padding: 10px 0px;
    p {
        color: grey;
        font-weight: bold;
        letter-spacing: 0.8px;
    };
`;
const Wrapper = styled.div`
    padding-left: 22px;
  min-height: 80vh;
`;
const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: white;
    .count {
        margin-right: 19px;
        font-size: 14px;
        font-family: 'Roboto';
    }
`;
const StatusPointWrapper = styled.div`
  ${props => !props.collapsed && `margin-top: 13px`};
  margin-left: 17px;
`;
const RowWrapper = styled.div`
    display: flex;
    border-bottom: 1px solid #181818;
    width: 100%;
    color: #E2E2E2;
    padding: 15px 0px;
    cursor: pointer;
    ${props => props.selected && `
        background-color: rgb(38, 40, 45); 
        border-radius: 10px;
        `};
    .status {
        align-items: center;
        ${props => !props.collapsed && `margin-right: 10px`};
       
    };
`;
const ListingWrapper = styled.div`
    margin-top: 20px;
`;
const AddressRow = styled.div`
    width: 88%;
    display: flex;
    justify-content: space-between;
    .address {
        width: 95%;
    };
    .count {
        font-family: 'Roboto';
        color: #E2E2E2;
        ${props => props.selected ? `opacity: 100%` : `opacity: 35%`};
    };
`;

export default PropertySidebar;
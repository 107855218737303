import React from "react";
import styled from "styled-components";
import { css } from "@emotion/react";
import { CircleLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoadingSpinner = ({ loading, height }) => (
  <LoadingWrapper height={height}>
    <CircleLoader
      css={override}
      size={80}
      color={"#789B4B"}
      loading={loading}
    />
  </LoadingWrapper>
);

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100vh")};
  justify-content: center;
  align-items: center;
`;

export default LoadingSpinner;

import React, {useState} from 'react';
import styled from "styled-components";
import {Dropdown} from "rsuite";
import Labour1 from "../../Assets/images/labor-1.png";
import Labour2 from "../../Assets/images/labor-copy-2.png";



const LabourSaving = (props) => {
    const [selectedOption, setSelectedOption] = useState("This Year");

    const amountView = (amount) => {
        return amount.toLocaleString()
    }

    return (
        <>
            <Row>
                <div className='center'>
                    Per Year
                </div>
                <div>
                    <Dropdown style={DropdownStyles} trigger="hover" title={selectedOption}>
                        <Dropdown.Item onClick={() => setSelectedOption("This Year")}>This Year</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedOption("Projection")}>Projection</Dropdown.Item>
                    </Dropdown>
                </div>
            </Row>
            <Cost>
                <Dollar>$</Dollar>{amountView(props.data.amount)}
            </Cost>
            {selectedOption === "Projection" ?
                <ProjectionWrapper>
                    <div className="color">
                        #movers 1
                    </div>
                    <div>
                        100
                    </div>
                </ProjectionWrapper>
                :
                <LabourRow>
                    <Labour>
                        <img height={"33px"} width={"33px"} src={Labour1} alt={'Labour'} />
                        <LabourAmount>{`${props.data.labour1}k`}</LabourAmount>
                    </Labour>
                    <Labour>
                        <img height={"33px"} width={"33px"} src={Labour1} alt={'Labour'} />
                        <LabourAmount>{`${props.data.labour2}k`}</LabourAmount>
                    </Labour>
                    <Labour>
                        <img height={"33px"} width={"33px"} src={Labour2} alt={'Labour'} />
                        <LabourAmount>{`${props.data.labour3}k`}</LabourAmount>
                    </Labour>
                </LabourRow>

            }
        </>
    );
};
export const DropdownStyles = {
    textColor: "white", backgroundColor: "black", borderRadius: "15px", size:"sm"
}
const ProjectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 25px;
    border: 2px solid green;
    margin: 0px 15px;
    font-weight: lighter;
    .color {
        color: #414345 ;
      }
`;
const LabourRow= styled.div`
    display: flex;
    justify-content: space-evenly;
`;
const LabourAmount = styled.div`
    margin-left: 5px;
`;
const Labour= styled.div`
    display: flex;
    justify-content: space-evenly;
`;
const Row = styled.div`
    display: flex;
    justify-content: space-between;
    
   .center {
        align-self: center;
   }
`;
const Cost = styled.div`
   display: flex;
   margin: 40px 0px;
   font-family: Roboto;
   justify-content: center;
   font-weight: 100;
   font-size: 85px;
   self-align: center;
`;

const Dollar = styled.span`
    font-size: 26px;
    align-self: center;
    height: 70px;
    margin-right: 5px;
`;

export default LabourSaving;

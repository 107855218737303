import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "rc-slider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

const Handle = Slider.Handle;
const ToolTipTitle = ({value}) =>{
    return(
        <div>
            <div>
                <p style={{fontSize:11}}>Height</p>
            </div>
            <div style={{textAlign:"center",paddingTop:3}}>
                <p style={{color:"#F75C28",fontSize:14}}>{value}</p>
            </div>
        </div>
    )
}
class StepSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.current
        };
    }

    onSliderChange = value => {
        this.setState({
            value
        });
        this.props.setValue(value)
    };

    handle = props => {
        const { ...restProps } = props;
        return (
            <div style={{backgroundColor:"#fff"}}>
                <Tooltip color={"#1F2125"} title={<ToolTipTitle value={this.state.value} />} placement="top">
                    <Handle value={this.state.value} {...restProps} />
                </Tooltip>
            </div>
        );
    };



    render() {
        const { classes, step, min, max, current } = this.props;
        return (
            <div>
                <div className={classes.slider}>
                    <Slider
                        step={step}
                        min={min}
                        max={max}
                        defaultValue={min}
                        value={current}
                        onChange={this.onSliderChange}
                        handle={this.handle}
                        trackStyle={{ backgroundColor: "#F75C28" }}
                        railStyle={{ backgroundColor: '#F75C28'}}
                        handleStyle={{
                            borderColor: '#F75C28',
                            height: 22,
                            width: 22,
                            marginTop: -9,
                            backgroundColor: '#F75C28',
                        }}
                    />
                    <div style={{display:"flex",justifyContent:"space-between",paddingTop:5}}>
                        <Typography style={{display:"flex",justifyContent:"space-between",alignItems:"end"}} className={classes.label}>
                            <p
                                style={{
                                    marginRight: 6,
                                    fontSize: 22,
                                    marginBottom:-2,
                                    color:"#F75C28"
                                }}
                            >{min}</p>{"Min"}</Typography>
                        <Typography style={{display:"flex",justifyContent:"space-between",alignItems:"end"}} className={classes.label}>
                            <p
                                style={{
                                    marginRight: 6,
                                    fontSize: 22,
                                    marginBottom:-2,
                                    color:"#F75C28"
                                }}
                            >{max}</p>
                            {"Max"}</Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    slider: {
        margin: "60px 0px 10px 0px"
    },
    label: {
        fontWeight: "bold",
        marginBottom: "10px"
    }
});

export default withStyles(styles)(StepSlider);

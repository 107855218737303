import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-apollo";
import {Link, useHistory, useLocation} from "react-router-dom";

import LoadingSpinner from "Components/LoadingSpinner";
import Input from "Components/Base/Input";
import Footer from "Components/Base/Footer";

import { login, currentUser } from "Redux/actions/authAction";
import { GET_LOGIN_ASSET } from "Graphql/gql";

import {
  FormContainer, FullPage, Header, Row,
  StyledError, StyledLabel,
  StyledWrapper
} from "../../Components/SignInComponents/StyledComponents";
import {
  BgContainer, GoogleLoginComp,
  SeperatorComp,
  SignInButton
} from "../../Components/SignInComponents/SharedComponents";
import theme from "../../Theme";
import DemoRequest from "../../Components/DemoRequest/DemoRequest";

const AlternateSignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const [password, setPassword] = useState(location.state?.password || "");
  const [loginErr, setLoginErr] = useState(false);
  const [demoPopUp, setDemoPopUp] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState(null);
  const [googleResponse, setGoogleResponse] = useState(null);
  const { loading, data } = useQuery(GET_LOGIN_ASSET);
  const {isAutherized, inProgress, message, errorType, gettingUser} = useSelector(
      (state) => state.auth
  );

  useEffect(() => {
    if (!sessionStorage.getItem("pressLogout") && isAutherized === null) {
      dispatch(currentUser());
    }
  }, [dispatch, isAutherized]);

  useEffect(() => {
    if (inProgress || gettingUser) {
      return;
    }
    if (isAutherized === false) {
      if (errorType === "not_found") {
        return;
      }
      if (errorType === "trial_expired") {
        setPopUpMessage(<>Your trial has expired. If you wish to re-activate,
          call our sales team at 972.666.9750 or contact them
          by email at <a href="mailto:sales@robinautopilot.com">sales@robinautopilot.com</a>.</>);
        return;
      }
      setLoginErr(true);
      setEmail("");
      setPassword("");
    } else if (isAutherized === true) {
      setLoginErr(false);
      setEmail("");
      setPassword("");
      history.push("/");
    }
  }, [inProgress, isAutherized, history, gettingUser, googleResponse, errorType]);

  const title = useMemo(() => {
    if (data) {
      return data.loginCollection.items[0].title;
    }
    return null;
  }, [data]);

  const handleClick = () => {
    dispatch(login({ email, password }));
  };

  if (gettingUser) {
    return <LoadingSpinner loading={loading} />;
  }

  return (
      <FullPage>
        <FormContainer>
          <StyledWrapper>
            <Header>{title}</Header>
            <Input
                label="Email"
                placeholder="Email"
                error={loginErr}
                helperTxt={loginErr ? message : ""}
                type="text"
                value={email}
                onChange={setEmail}
                width="100%"
            />
            <Input
                label="Password"
                placeholder="Password"
                error={loginErr}
                helperTxt={loginErr ? message : ""}
                type="password"
                value={password}
                onChange={setPassword}
                width="100%"
            />
            <Row>
              <StyledLabel color={theme.color.primary}>
                <Link to={"/forgot-password"}>Reset your password</Link>
              </StyledLabel>
              <StyledLabel color={theme.color.primary}>
                <Link onClick={() => setDemoPopUp(true)}>Request a Demo</Link>
              </StyledLabel>
            </Row>
            {demoPopUp &&
                <DemoRequest demoPopUp={demoPopUp} close={setDemoPopUp} />

            }
            { popUpMessage && <StyledError>{popUpMessage}</StyledError>}
            <SignInButton text="Sign In" trigger={handleClick} />
            <SeperatorComp />
            <GoogleLoginComp setResponse={setGoogleResponse} />
          </StyledWrapper>
          <Footer />
        </FormContainer>
        <BgContainer />
      </FullPage>
  );
};

export default AlternateSignIn;

import styled from "styled-components";
import React, {useContext, useState} from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import BarChart from "Components/BarChart";
import {MowerContext} from "Context/MowerContext";
import {
    getAbbreviatedMonthAndDay, getDateObjFromSecond, getDateWithOffset,
    getDaysFromMiliSecond, getEndToday
} from "Utils/datetime";

const ErrorStatePan = () => {
    const {notifications, mowerDetails} = useContext(MowerContext);

    const today = getEndToday();
    const defaultDateRange = [getDateWithOffset(today, -7), today];
    const [dateRange, setDateRange] = useState(defaultDateRange);

    if (mowerDetails?.id !== notifications?.id) {
        return <></>
    }

    const getLastUpdatedTime = () => {
        if (Array.isArray(notifications.all)) {
            const lastNotification = notifications.all[0];
            if (lastNotification) {
                return getDateObjFromSecond(lastNotification.time).toLocaleString();
            }
        }
        return "...";
    };

    const getChartData = () => {
        const chartData = [];
        const errorData = {};
        const days = getDaysFromMiliSecond(dateRange[1] - dateRange[0]);
        for (let offset = 1; offset <= days; offset++) {
            const date = getDateWithOffset(dateRange[0], offset - 1);
            errorData[getAbbreviatedMonthAndDay(date)] = 0;
        }
        for (let notification of notifications.all) {
            const notificationDate = getDateObjFromSecond(notification.time);
            if (notificationDate < dateRange[0]) {
                break;
            } else if (notificationDate > dateRange[1]) {
                continue;
            } else if (
                notification.message.indexOf("Error") > -1 ||
                notification.status.indexOf("ERROR") > -1
            ) {
                errorData[getAbbreviatedMonthAndDay(notificationDate)] += 1;
            }
        }
        for (let monthDay in errorData) {
            chartData.push({
                date: monthDay,
                Errors: errorData[monthDay],
            });
        }
        return chartData;
    };

    return (
        <Container>
            <Row>
                <TitleSection>
                    <Title>Error Stat</Title>
                    <SLabel>Last updated {getLastUpdatedTime()}</SLabel>
                </TitleSection>
                <DateRangePicker
                    onChange={setDateRange}
                    value={dateRange}
                    calendarIcon={null}
                    clearIcon={null}
                    className="date-range-picker"
                    maxDate={today}
                />
            </Row>
            <HRow>
                <BarChart width={468} chartData={getChartData()}/>
            </HRow>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 293px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing[5]}px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.color.text};
  margin-right: ${(props) => props.theme.spacing[1]}px;
`;
const SLabel = styled.span`
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.color.text};
  opacity: 0.4;
`;
const HRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export default ErrorStatePan;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";

import SentIcon from "Assets/images/export-route-sent.png";
import CloseImg from "Assets/images/x.png";
import Table from "Components/Table";
import { ExportRouteHeaderCols } from "Contants";
import Input from "Components/Base/Input";
import Button from "Components/Base/Button";
import { useSelector } from "react-redux";
import { exportRoute, getAddress } from "Utils/api";


const RouteExporter = ({ show, setShow, routePath }) => {
  const { user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState(user?.email);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  useEffect(() => {
    setSent(false);
  }, [email, error]);

  const onEmailChange = (e) => {
    setEmail(e);
    if (!e) {
      setError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(e)) {
      setError("Email is not correct");
    } else {
      setError(null);
    };
  };

  const onSend = async () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Please check email");
      return;
    };
    if (!routePath || routePath.length <= 1) {
      setError("Plesase add mower to route path");
      return;
    };
    setLoading(true);
    const response = await exportRoute({
      email,
      mower_ids: routePath.slice(1).map((mower) => mower.id)
    });
    setLoading(false);
    if (!response.success) {
      setError(response.message);
    } else {
      setSent(true);
    };
  };

  const getPreviewTableData = () => {
    const mowersWithUniqueAddress = [];
    const firstPath = routePath[0];
    let startRoute = '';
    if (firstPath)
    {
      startRoute = getAddress(firstPath.lat,firstPath.lng);
    }
    routePath.slice(1).forEach((mower) => {
      const addedMower = mowersWithUniqueAddress.find(el => el?.id === mower?.id);
      const mowerObj = mower.mower_obj;
      const customerName = [
        mowerObj.consumer?.first_name?.trim(), mowerObj.consumer?.last_name?.trim()
      ].join(" ");
      const instance = mowerObj.instance;
      if (!addedMower) {
        mowersWithUniqueAddress.push({
          order_number: mower.order_number,
          lat: mower.lat,
          lng: mower.lng,
          address: mowerObj.location.address,
          customer_names: [customerName],
          instances: [instance]
        })
      } else {
        if (!addedMower.customer_names.includes(customerName)) {
          addedMower.customer_names.push(customerName);
        };
        if (!addedMower.instances.includes(instance)) {
          addedMower.instances.push(instance);
        };
      };
    });
    const tableData = [{
      col1: "Start",
      col2: user?.subscriber?.address ?? startRoute,
      col3: "",
      col4: "",
    }];
    mowersWithUniqueAddress.forEach((mower, index) => {
      tableData.push({
        col1: mower.order_number,
        col2: mower.address.split(",")[0],
        col3: mower.customer_names.join(", "),
        col4: mower.instances.join(", "),
      });
    });
    return tableData.sort((a, b) => a.col1 - b.col1);
  };

  const getRouteMapUrl = () => {
    if (!routePath || routePath.length < 2) return;
    const origin = `${user?.subscriber?.lat},${user?.subscriber?.lng}`;
    const waypoints = routePath.slice(1).map((mower) => {
      return `${mower.lat},${mower.lng}`;
    })
    return encodeURI(
      `https://www.google.com/maps/dir/?api=1&origin=${origin}` +
      `&destination=${origin}&waypoints=${waypoints.join('|')}`
    );
  }

  return (
    <Dialog
      open={show}
      onClose={() => {
        setShow(false);
        setSent(false);
      }}
      fullWidth={true}
    >
      <Container>
        <IconButton
          src={CloseImg}
          onClick={() => {
            setShow(false);
            setSent(false);
          }}
        />
        {sent ? (
          <>
            <img src={SentIcon} alt="sent-success" />
            <SubText>Route map has been sent.</SubText>
            <SubText>Please check your email box.</SubText>
          </>
        ) : (
          <>
            <Header>Share Route Map</Header>
            <SubText>Send this route map and locations to your email</SubText>
            <Input
              placeholder="Email"
              type="text"
              value={email}
              onChange={onEmailChange}
              width="100%"
            />
            {error && <HelperText>{error}</HelperText>}
            <Table
              headerColor="#4E4E4E" cellPadding='14px'
              columns={ExportRouteHeaderCols}
              data={getPreviewTableData()}
            />
            <RouteMapLink
              href={getRouteMapUrl()}
              target="_bank">
                Open route in google map
            </RouteMapLink>
            <Button
              onClick={onSend}
              fontSize={14}
              showShadow={true}
              text="Send"
              width="100%"
              loading={loading}
            />
          </>
        )}
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color.grey[700]};
  padding: ${(props) => props.theme.spacing[10]}px;
  color: ${(props) => props.theme.color.text};
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: ${(props) => props.theme.spacing[1]}px;
`;

const SubText = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  opacity: 0.6;
`

const HelperText = styled.span`
  font-size: 11px;
  line-height: 13px;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  color: ${(props) => props.theme.color.red[100]};
  font-weight: bold;
`;

const RouteMapLink = styled.a`
  color: white;
  margin: ${(props) => props.theme.spacing[2]}px;
`;

const IconButton = styled.img`
  align-self: flex-end;
  width: 12px;
  cursor: pointer;
  margin: 4px;
  position: relative;
  top: -${(props) => props.theme.spacing[5]}px;
  right: -${(props) => props.theme.spacing[5]}px;
`;

export default RouteExporter;

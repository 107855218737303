import React, {useState} from "react";
import Autocomplete from "react-google-autocomplete";
import styled from "styled-components";
import theme from "Theme";

import Hide from '../../../Assets/images/hide.png'
import Show from '../../../Assets/images/show.png'
import Search from "../../../Assets/images/search.png";

const Input = ({
                   label, helperTxt, placeholder, type, error, value, hideHeading, onChange,
                   width, disabled, labelColor, required, paddingBottom = null
               }) => {
    const handleChange = (evt) => onChange(evt.target.value);

    return (
        <StyledWrapper paddingBottom={paddingBottom} width={width}>
            {!hideHeading === true &&
                <StyledLabel labelColor={labelColor}>
                    {label}
                    {required &&
                        <RequiredIcon>*</RequiredIcon>
                    }
                </StyledLabel>
            }
            <StyledInput
                disabled={disabled}
                error={error}
                type={type}
                placeholder={error ? helperTxt : placeholder}
                value={value}
                onChange={handleChange}
            />
        </StyledWrapper>
    );
};

export const LocationAutoCompleteInput = ({label, placeholder, onSeleted, width}) => {
    return (
        <StyledWrapper width={width}>
            <StyledLabel>{label}</StyledLabel>
            <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API}
                style={{
                    height: theme.spacing[8],
                    border: "1px solid transparent",
                    backgroundColor: theme.color.grey[800],
                    boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.5)",
                    paddingLeft: theme.spacing[4],
                    color: theme.color.text,
                }}
                placeholder={placeholder}
                options={{
                    componentRestrictions: {country: "us"},
                    types: null,
                }}
                onPlaceSelected={onSeleted}
            />
        </StyledWrapper>
    );
}

export const PasswordInput = ({
                                  label, helperTxt, placeholder, error, value, type, paddingBottom,
                                  onChange, width, disabled, hideHeading, labelColor, required
                              }) => {
    const handleChange = (evt) => onChange(evt.target.value);
    const [show, setShow] = useState(true);
    return (
        <StyledWrapper paddingBottom={paddingBottom} width={width}>
            {!hideHeading === true &&
                <StyledLabel labelColor={labelColor}>
                    {label}
                    {required &&
                        <RequiredIcon>*</RequiredIcon>
                    }
                </StyledLabel>
            }
            <ContentWrapper>
                <StyledInput
                    disabled={disabled}
                    error={error}
                    type={show ? type : type === 'password' ? "text" : "password"}
                    value={value}
                    placeholder={error ? helperTxt : placeholder}
                    onChange={handleChange}
                />
                <IconWrapper>
                    <img onClick={() => setShow(!show)} src={show ? Show : Hide} alt={""}/>
                </IconWrapper>
            </ContentWrapper>
        </StyledWrapper>
    );
};

export const InputWithClear = ({input, setInput, clear}) => {

    return <>
        <InputRow clearCl={input?.length > 0}>
            <div className={"img"}>
                <img
                    height={"14px"} alt={"search"} src={Search}
                    style={{verticalAlign: "-webkit-baseline-middle"}} width={"14px"}
                />
            </div>
            <InputField placeholder={"Filter"} type="text" value={input} onChange={e => setInput(e.target.value)}/>
            <div className={"clear"} onClick={() => clear()}>
                Clear
            </div>
        </InputRow>
    </>

}
const InputRow = styled.div`
  display: flex;
  border-bottom: 1px solid #FFFFFF;
  width: 200px;
  .img{
    width: 14px;
    height: 30px;
    margin-right: 10px;
  };
  .clear {
    margin-left: 5px;
    align-self: center;
    ${props => props.clearCl ? "color: #F75C28;" : "color: #FFFFFF; opacity: 25%;"};
    cursor: pointer;
  };
`;
const InputField = styled.input`
   border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1F2125;
  color: white;
  font-family: Roboto;
  width: 100%;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  &:focus {
        outline: none;
  };
  ::placeholder {
        color: #FFFFFF;
        opacity: 25%;
  };
  :-ms-input-placeholder { 
        color: #FFFFFF;
        opacity: 25%;
  };
  ::-ms-input-placeholder {
        color: #FFFFFF;
        opacity: 25%;
  };
`;
const IconWrapper = styled.div`
    position: absolute;
    right: ${props => props.right ?? "20px"};
    top: 11px;
    width: 27px;
    line-height: 0;
`;

const ContentWrapper = styled.div`
    position: relative;
    width: 100%;;
`;
const RequiredIcon = styled.span`
    color: #CE4F25;
    margin-left: 5px;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.paddingBottom === false ? "10" : props.theme.spacing[8]}px;
`;

const StyledLabel = styled.span`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: ${(props) => props.theme.spacing[2]}px;
  color: ${(props) => props.labelColor ? props.labelColor : props.theme.color.text};
  font-weight: bold;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => props.theme.spacing[8]}px;
  border: 1px solid
    ${(props) => (props.error ? props.theme.color.red[100] : "transparent")};
  border-radius: ${(props) => props.theme.spacing[1]}px;
  padding-left: ${(props) => props.theme.spacing[4]}px;
  font-size: 14px;
  outline: none;
  line-height: 15px;
  color: ${(props) => props.theme.color.text};
  background-color: ${(props) => props.theme.color.grey[800]};
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.5);

  ::placeholder {
    color: ${(props) =>
    props.error ? props.theme.color.red[100] : props.theme.color.grey[450]};
  }
`;

export default Input;
